/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC, useState, useRef, useEffect } from 'react';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-datepicker/dist/react-datepicker.css';

import ICON_ARROW_UP from '../../assets/imgs/icon/arrow_card_up.svg';
import ICON_ARROW_DOWN from '../../assets/imgs/icon/arrow_card_down.svg';
import ICON_BACK from '../../assets/imgs/icon/back.svg';
import ICON_DOLLAR from '../../assets/imgs/icon/dollar.svg';
import ICON_IL from '../../assets/imgs/icon/IL.svg';
import ICON_EXCHANGE from '../../assets/imgs/icon/exchange.svg';
import { THEME } from '../../constants';
import { TransferSubmitModal } from '../transfer-submit-modal';
import { TransferReasonModal } from '../transfer-reason-modal';
import './date-picker-popper.css';

const WrapBankAccountSelector = styled.div<{ multipleAccounts: boolean, isOpen: boolean }>`
  ${({ multipleAccounts, isOpen }) => `
  position: relative;
  & :hover {
    background: ${multipleAccounts ? '#394e7d' : THEME.ligher_dark_blue};
    border-radius: ${isOpen ? '6px 6px 0px 0px;' : '6px'};
  }
`}
`;

const WrapBankAccount = styled.div.attrs({
  className: 'WrapBankAccount',
})<{ isOpen: boolean, multipleAccounts: boolean }>`
  ${({ isOpen, multipleAccounts }) => `
  margin-top: 15px;
  padding: 25px;
  background: ${THEME.ligher_dark_blue};
  border-radius: ${isOpen ? '6px 6px 0px 0px;' : '6px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: ${multipleAccounts ? 'pointer' : 'default'};
  height: 63px;
  img {
    cursor: ${multipleAccounts ? 'pointer' : 'default'};
  }
`}
`;
const WrapBankAccountOptions = styled.div.attrs({
  className: 'WrapBankAccountOptions',
})`
  position: absolute;
  z-index: 1000;
  width: 100%;
  & :first-child {
    margin-top: 0px;
  }
  margin-top: -15px;
  & :last-child {
    border-radius: 0px 0px 6px 6px;
  }
  border-radius: 0px 0px 6px 6px;
`;

const WrapBankAccountOption = styled.div.attrs({
  className: 'WrapBankAccountOption',
})`
  margin-top: 0px;
  padding: 25px;
  background: #2d3d62;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  cursor: pointer;
  img {
    cursor: pointer;
  }
  & :hover {
    background: #394e7d;
  }
`;

const Text = styled.div`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.9);
  padding-right: 30px;
  width: 100%;
`;

const PanelHeader = styled.div`
  padding: 40px 27px;
  @media (max-width: 768px) {
    padding: 16px 16px;
  }
  border-bottom: 1px solid ${THEME.light_blue};
  .d-flex {
    align-items: center;
    justify-content: space-between;
  }
  h3 {
    margin: 0px 0px 7px;
    font-family: 'Fontsfree net proxima nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${THEME.white};
  }
  p {
    margin: 0px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.24em;
    color: ${THEME.gray};
  }
`;

const WrapPanelContent = styled.div`
  padding: 27px;
  h6 {
    margin: 0;
    margin-bottom: 7px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.24em;
    color: ${THEME.gray};
  }
`;

const WrapInlineInput = styled.div`
  padding: 10px 18px 17px;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: ${THEME.border_color};
  border-radius: 2px;
  position: relative;
  padding-right: 100px;
  label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.1em;

    color: #96a1c1;
  }
  input {
    width: 99%;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    &:focus-visible {
      outline: none;
    }
  }
`;

const WrapExhange = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 47px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: 99;
  background: #1c2439;
  border-left: 1px solid ${THEME.border_color};
  cursor: pointer;
`;

const WrapBackArrow = styled.div`
  cursor: pointer;
`;

const DollarWrapper = styled.img`
  position: absolute;
  right: 47px;
  top: 0;
  height: 100%;
  width: 40px;
  background: #1c2439;
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: 99;
  padding: 13px;
`;

const PrefixWrap = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${THEME.gray};
  line-height: 21px;
  padding-right: 1px;
  white-space: nowrap;
`;

const ConfirmButton = styled.button<{ disabled: boolean }>`
  ${({ disabled }) => `
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    background: ${disabled ? THEME.dark_blue : THEME.yellow};
    color: ${disabled ? THEME.yellow : THEME.white};
    border-radius: 6px;
    padding: 22px;
    margin-top: 14px;
    opacity: 0.95;
    cursor: ${disabled ? 'default' : 'pointer'};
    border: ${disabled ? '1px solid' + THEME.yellow : ''};
    opacity: ${disabled ? 0.5 : 0.9};
    &:hover {
      opacity: ${disabled ? 0.5 : 1};
    }
  `}
`;

const TextHint = styled.p`
  margin-top: 12px;
  padding-right: 11px;
  padding-left: 11px;
  font-family: 'Fontsfree net proxima nova';
  font-weight: 300;
  text-align: justify;
  font-size: 13px;
  line-height: 16px;
  color: #96a1c1;

  color: ${THEME.gray};
  a {
    color: ${THEME.white};
  }
`;

const showFeeBreakdown = true;

const FeeBreakdown = styled.div.attrs({
  className: 'FeeBreakdown',
})`
  // margin-top: 12px;
  padding-right: 11px;
  padding-left: 11px;
  margin-bottom: 15px;
  font-family: 'Fontsfree net proxima nova';
  font-weight: 300;
  text-align: left;
  font-size: 13px;
  height: 100%;
  display: block;
  color: ${THEME.gray};
  a {
    color: ${THEME.white};
  }
  // background: #25304e;
  border-radius: 4px;
  // padding: 10px;
  align-items: center;
  justify-content: center;
  &: hover {
    cursor: pointer;
  }
`;

const FeeBreakdownTitle = styled.div.attrs({
  className: 'FeeBreakdownTitle',
})`
  text-decoration: none;
  &: hover {
    text-decoration: underline;
  }
`;

const FeeBreakdownDetails = styled.div.attrs({
  className: 'FeeBreakdownDetails',
})`
  text-decoration: none !important;
  &: hover {
    text-decoration: none !important;
  }
`;

const FeeBreakdownQuestion = styled.p`
  margin-top: 12px;
  padding-right: 0px;
  padding-left: 11px;
  font-family: 'Fontsfree net proxima nova';
  font-weight: 300;
  text-align: left;
  font-size: 13px;
  line-height: 6px;
  height: 6px;
  color: ${THEME.gray};
  a {
    color: ${THEME.white};
  }
`;

const FeeBreakdownAnswer = styled.p`
  margin-top: 0px;
  padding-right: 11px;
  padding-left: 0px;
  font-family: 'Fontsfree net proxima nova';
  font-weight: 300;
  text-align: justify;
  font-size: 13px;
  line-height: 6px;
  color: #96a1c1;
  float: right;

  color: ${THEME.gray};
  a {
    color: ${THEME.white};
  }
`;

const StyledTooltip = styled(Tooltip).attrs({
  className: 'StyledTooltip',
})`
  max-width: 300px;
  background: black;
`;

const WrapInputDate = styled.div``;

const InputDate = styled.div`
  margin-bottom: 0px;
  height: 63px;
  padding: 10px 18px;
  border-radius: 6px;
  position: relative;
  font-family: 'Inter';
  font-size: 10px;
  background: ${THEME.ligher_dark_blue_2};
  label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.1em;
    color: ${THEME.gray};
  }
  input {
    margin-bottom: 0px;
    width: 99%;
    background: transparent;
    border: none;
    color: ${THEME.white};
    font-size: 16px;
    font-weight: 700;
    &:focus-visible {
      outline: none;
    }
  }
  .datePicker {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    margin: -16px -12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const BankAccountsOptions = ({ display, bankAccounts, direction, handleChangeBankAccount }) => {
  const accounts = bankAccounts.map((bankAccount) => (
    <WrapBankAccountOption
      key={bankAccount.uuid}
      onClick={() => handleChangeBankAccount(bankAccount)}
    >
      <Text>
        Transfer {direction} {bankAccount.name} {bankAccount.account_number ? bankAccount.account_number.slice(-4) : ''}
      </Text>
    </WrapBankAccountOption>
  ));
  return display ? accounts : null;
};

const BankAccountSelector = ({
  bankAccounts,
  country,
  type,
  bankAccountSelected,
  handleSelectBankAccount,
  multipleAccounts,
}) => {
  const ref = React.useRef(null);

  const [bankAccountOptions, setBankAccountOptions] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const direction = type === 'sending' ? 'from' : 'to';

  const handleChangeBankAccount = (bankAccount) => {
    handleSelectBankAccount(bankAccount);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!bankAccountSelected) return setBankAccountOptions(bankAccounts[country]);
    setBankAccountOptions(
      bankAccounts[country]?.filter((bankAccount) => {
        if (country === 'US') {
          return bankAccount.uuid != bankAccountSelected.uuid;
        }
        return bankAccount.uuid != bankAccountSelected.uuid;
      }),
    );
  }, [bankAccountSelected]);

  useEffect(() => {
    const handleOnClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOnClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOnClickOutside);
    };
  }, [ref]);

  return (
    <WrapBankAccountSelector ref={ref} multipleAccounts={multipleAccounts} isOpen={isOpen}>
      <WrapBankAccount
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        multipleAccounts={multipleAccounts}
      >
        <Text>
          {bankAccountSelected
            ? `Transfer ${direction} ${
                bankAccountSelected.name
              } ${bankAccountSelected.account_number? bankAccountSelected.account_number.slice(-4) : ''}`
            : 'Select an Account'}
        </Text>
        {multipleAccounts ? <img src={!isOpen ? ICON_ARROW_DOWN : ICON_ARROW_UP} alt="" /> : null}
      </WrapBankAccount>
      {multipleAccounts ? (
        <WrapBankAccountOptions>
          <BankAccountsOptions
            bankAccounts={bankAccountOptions}
            direction={direction}
            display={isOpen}
            handleChangeBankAccount={handleChangeBankAccount}
          ></BankAccountsOptions>
        </WrapBankAccountOptions>
      ) : null}
    </WrapBankAccountSelector>
  );
};

export const TransferSubmitPanel: any = ({
  bankAccounts,
  selectedBankAccount,
  setTransferPanelIsOpen,
  getTransfers,
  transferRate,
  profile,
  transfers,
}) => {
  const US = { name: 'THE UNITED STATES', code: 'US' };
  const IL = { name: 'ISRAEL', code: 'IL' };

  const [transferDate, setTransferDate] = useState(new Date());
  const [currentTransferAmount, setCurrentTransferAmount] = React.useState(0);
  const [isUSD, setIsUSD] = React.useState(selectedBankAccount?.country?.alpha_2 == 'US');
  const [countrySending, setCountrySending] = React.useState(
    selectedBankAccount?.country?.alpha_2 == 'US' ? US : IL,
  );
  const [countryReceiving, setCountryReceiving] = React.useState(
    selectedBankAccount?.country?.alpha_2 == 'US' ? IL : US,
  );
  const [confirmButtonIsEnabled, setConfirmButtonIsEnabled] = React.useState(false);
  const [bankAccountSending, setBankAccountSending] = React.useState(selectedBankAccount);
  const [bankAccountReceiving, setBankAccountReceiving] = React.useState(
    bankAccounts[isUSD ? 'IL' : 'US'] ? bankAccounts[isUSD ? 'IL' : 'US'][0] : {},
  );
  const [transferReason, setTransferReason] = useState("");

  const [transferSubmitModalIsOpen, setTransferSubmitModalIsOpen] = useState(false);
  const [transferReasonModalIsOpen, setTransferReasonModalIsOpen] = useState(false);

  const [feeDetailsIsOpen, setFeeDetailsIsOpen] = useState(false);
  const [olehpayFee, setOlehPayFee] = useState(0.02);

  const multipleAccountsSending = bankAccounts[countrySending.code]?.length > 1;
  const multipleAccountsReceiving = bankAccounts[countryReceiving.code]?.length > 1;

  const currencySending = isUSD ? 'USD' : 'ILS';
  const sortedTransfers = transfers
    ?.filter(transfer => transfer?.currency_sending?.code === currencySending)
    ?.filter(transfer => transfer?.status !== "CANCELED")
    ?.sort((a,b) => new Date(b.created_at) < new Date(a.created_at) ? -1 : 1);
  const lastRelevantTransfer = sortedTransfers?.length ? sortedTransfers[0] : null;

  const reasonIsRequired = (
    (isUSD && currentTransferAmount >= 5000) || 
    (!isUSD && currentTransferAmount >= 10000) ||
    (currentTransferAmount * 0.8 >= lastRelevantTransfer?.amount_sending)
  );

  const currentRate = Number(transferRate?.amount || 1);

  const feeAmount = currentTransferAmount ? (currentTransferAmount * olehpayFee).toFixed(2) : null;
  const totalConverted = currentTransferAmount
    ? (currentTransferAmount - currentTransferAmount * olehpayFee).toFixed(2)
    : 0;

  const actualTransferRate = currentRate ? isUSD ? currentRate : 1/currentRate : 1.0;
  const transferRateFormatted =
    transferRate && transferRate?.amount
      ? isUSD
        ? currentRate.toFixed(4)
        : (1 / currentRate).toFixed(4)
      : 0;
  const expectedReceived =
    currentTransferAmount && totalConverted && transferRate && transferRate?.amount
      ? (actualTransferRate * (currentTransferAmount - currentTransferAmount * olehpayFee)).toFixed(2)
      : 0;

  const swapTransferDirection = () => {
    setIsUSD(!isUSD);
    setCountrySending(!isUSD ? US : IL);
    setCountryReceiving(!isUSD ? IL : US);
  
    setBankAccountSending(bankAccountReceiving);
    setBankAccountReceiving(bankAccountSending);
  };

  const submitTransfer = () => {
    if (reasonIsRequired && !transferReason) return setTransferReasonModalIsOpen(true);
    else setTransferSubmitModalIsOpen(true);
  };

  useEffect(() => {
    if (!isUSD) return setOlehPayFee(0.02);
    let percentFee = .02;
    if (isUSD && currentTransferAmount < 12500) percentFee = 0.02;
    else if (isUSD && currentTransferAmount >= 12500 && currentTransferAmount < 25000)
      percentFee = (250 + (currentTransferAmount - 12500) * 0.007) / currentTransferAmount;
    else if (isUSD && currentTransferAmount >= 25000) percentFee = 0.007;
    if (percentFee > profile?.percent_fee/100.0) percentFee = profile.percent_fee/100.0;
    setOlehPayFee(percentFee);
  }, [currentTransferAmount, isUSD, profile?.percent_fee]);

  useEffect(() => {
    const aboveMinimum = isUSD ? currentTransferAmount >= 300 : currentTransferAmount >= 1000;
    if (bankAccountReceiving && bankAccountSending && aboveMinimum) setConfirmButtonIsEnabled(true);
    else setConfirmButtonIsEnabled(false);
  }, [currentTransferAmount, bankAccountSending, bankAccountReceiving]);

  return (
    <>
      <PanelHeader>
        <div className="d-flex">
          <div>
            <h3>Open Transfer Request</h3>
            <p>CONFIRM TRANSFER DETAILS</p>
          </div>
          <WrapBackArrow>
            <img src={ICON_BACK} alt="" onClick={() => setTransferPanelIsOpen(false)} />
          </WrapBackArrow>
        </div>
      </PanelHeader>
      <WrapPanelContent>
        <h6>
          TRANSFER FROM {countrySending.name} TO {countryReceiving.name}
        </h6>
        <BankAccountSelector
          bankAccounts={bankAccounts}
          country={countrySending.code}
          type={'sending'}
          bankAccountSelected={bankAccountSending}
          handleSelectBankAccount={(bankAccount) => setBankAccountSending(bankAccount)}
          multipleAccounts={multipleAccountsSending}
        ></BankAccountSelector>
        <WrapInlineInput>
          <label htmlFor="">Transfer Amount</label>
          <div className="d-flex">
            <PrefixWrap>{isUSD ? 'USD/$' : 'ILS/₪'} </PrefixWrap>
            <CurrencyInput
              id="currency-input"
              name="input-name"
              placeholder="0.00"
              decimalsLimit={2}
              maxLength={9}
              prefix=""
              value={currentTransferAmount}
              onValueChange={(value, name) => setCurrentTransferAmount(value as any)}
            />
            {isUSD && currentTransferAmount < 300 ? (
              <span style={{ zIndex: 10 }}>
                <Tooltip
                  anchorId="currency-input"
                  content="Transfers must be a minimum of $300"
                  place="top"
                  data-tooltip-show="1000"
                />
              </span>
            ) : null}
            {!isUSD && currentTransferAmount < 1000 ? (
              <span style={{ zIndex: 10 }}>
                <Tooltip
                  anchorId="currency-input"
                  content="Transfers must be a minimum of ₪1000"
                  place="top"
                  data-tooltip-show="1000"
                />
              </span>
            ) : null}
            <DollarWrapper src={isUSD ? ICON_DOLLAR : ICON_IL} alt="" />

            <WrapExhange onClick={swapTransferDirection}>
              <img src={ICON_EXCHANGE} alt="" />
            </WrapExhange>
          </div>
        </WrapInlineInput>
        <BankAccountSelector
          bankAccounts={bankAccounts}
          country={countryReceiving.code}
          type={'receiving'}
          bankAccountSelected={bankAccountReceiving}
          handleSelectBankAccount={(bankAccount) => setBankAccountReceiving(bankAccount)}
          multipleAccounts={multipleAccountsReceiving}
        ></BankAccountSelector>
        <FeeBreakdown onClick={() => setFeeDetailsIsOpen(!feeDetailsIsOpen)}>
          <FeeBreakdownTitle>See fee breakdown</FeeBreakdownTitle>
          {feeDetailsIsOpen ? (
            <FeeBreakdownDetails>
              <FeeBreakdownQuestion>
                {isUSD ? 'USD' : 'ILS'} Being Sent:{' '}
                <FeeBreakdownAnswer>{currentTransferAmount}</FeeBreakdownAnswer>
              </FeeBreakdownQuestion>
              <FeeBreakdownQuestion>
                &minus; OlehPay Fee:{' '}
                <FeeBreakdownAnswer>
                  {isUSD ? '$' : '₪'}
                  {feeAmount}
                </FeeBreakdownAnswer>
              </FeeBreakdownQuestion>
              <FeeBreakdownQuestion>
                &#61; Total Converted Amount{' '}
                <FeeBreakdownAnswer>{totalConverted}</FeeBreakdownAnswer>{' '}
              </FeeBreakdownQuestion>
              <FeeBreakdownQuestion id="rate">
                &times; *Representative Rate:{' '}
                <FeeBreakdownAnswer>{transferRateFormatted}</FeeBreakdownAnswer>
              </FeeBreakdownQuestion>
              <StyledTooltip
                anchorId="rate"
                content="*Representative rate provides an approximation of your currency exchange if you were to
                  place the transfer with us this instance. The actualy rate you receive may be different
                  than this. The rate you will receive in the future is subject to change for various
                  reasons, including market factors out of our control. The rate you received is typically
                  assigned on the day on which we receive your money, which may 1-3 days from today."
                place="top"
              />
              <FeeBreakdownQuestion style={{ fontWeight: '400' }}>
                Expected {isUSD ? 'ILS' : 'USD'} Received:{' '}
                <FeeBreakdownAnswer style={{ fontWeight: '400' }}>
                  {expectedReceived}
                </FeeBreakdownAnswer>
              </FeeBreakdownQuestion>
            </FeeBreakdownDetails>
          ) : null}
        </FeeBreakdown>
        <WrapInputDate>
          <InputDate style={{ pointerEvents: 'none' }}>
            <label htmlFor="">Transfer Start Date</label>
            <DatePicker
              className="datePicker"
              popperProps={{
                positionFixed: true, // use this to make the popper position: fixed
              }}
              selected={transferDate}
              onChange={(date: Date) => setTransferDate(date)}
              minDate={new Date()}
              dateFormat={'MMMM d, yyyy'}
            />
          </InputDate>
        </WrapInputDate>
        <ConfirmButton disabled={!confirmButtonIsEnabled} onClick={submitTransfer}>
          CONFIRM TRANSFER
        </ConfirmButton>
        <TextHint>
          Your transfer will be assigned the exchange rate of the day on which we receive your
          funds, which may be 2-3 days from the time you place your transfer.{' '}
          <a href="#">Learn more.</a>
        </TextHint>
      </WrapPanelContent>
      <TransferSubmitModal
        transferSubmitModalIsOpen={transferSubmitModalIsOpen}
        setTransferReasonModalIsOpen={setTransferReasonModalIsOpen}
        setTransferSubmitModalIsOpen={setTransferSubmitModalIsOpen}
        bankAccountSending={bankAccountSending}
        bankAccountReceiving={bankAccountReceiving}
        isUSD={isUSD}
        currentTransferAmount={currentTransferAmount}
        transferDate={transferDate}
        getTransfers={getTransfers}
        setTransferPanelIsOpen={setTransferPanelIsOpen}
        reasonIsRequired={reasonIsRequired}
        transferReason={transferReason}
      ></TransferSubmitModal>
      <TransferReasonModal
        modalIsOpen={transferReasonModalIsOpen}
        setTransferReasonModalIsOpen={setTransferReasonModalIsOpen}
        setTransferSubmitModalIsOpen={setTransferSubmitModalIsOpen}
        bankAccountSending={bankAccountSending}
        bankAccountReceiving={bankAccountReceiving}
        isUSD={isUSD}
        currentTransferAmount={currentTransferAmount}
        transferDate={transferDate}
        transferReason={transferReason}
        setTransferReason={setTransferReason}
      ></TransferReasonModal>
    </>
  );
};
