import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import moment from 'moment';
import { SortingState } from '@tanstack/react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Table } from '../../components/table';
import { THEME } from '../../constants';
import GREEN_CHECK from '../../assets/imgs/green_check.png';
import { DocumentPreviewModal } from '../document-preview-modal';

Modal.defaultStyles.overlay.backgroundColor = 'rgb(144 144 144 / 98%)';

const WrapContent = styled.div`
  margin-top: 30px;
  @media (max-width: ${THEME.breakpoint}px) {
    padding-bottom: 0;
  }
`;

const TitleTable = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: ${THEME.white};
  margin-top: 20px;
  position: relative;
  display: table;
  padding-bottom: 15px;
  margin-bottom: 20px;
  &:after {
    content: '';
    border: 1px solid ${THEME.yellow};
    width: 100%;
    bottom: 0;
    position: absolute;
    left: 0;
  }
`;

const ViewButton = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  padding: 3px;
  align-items: center;
  text-align: center;
  background: transparent;
  color: #fbb919;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    opacity: 0.9;
  }
`;

const WrapDocumentsDesktop = styled.div`
  @media (max-width: ${THEME.breakpoint}px) {
    display: none;
  }
`;

const WrapGreenCheck = styled.img`
  height: 1.3em;
`;

const Placeholder = styled.div.attrs({
  className: 'Placeholder',
})`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${THEME.white};
  font-size: 2em;
`;

export const DocumentsContent = ({ documents, numberOfDocuments }) => {
  const columns = [
    {
      accessorFn: (row: any) => row.file_metadata.original_name,
      id: 'name',
      cell: (info: any) => info.getValue(),
      header: () => <span>Name</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => moment(row.created_at).format('MMM D, YYYY'),
      id: 'created',
      cell: (info: any) => info.getValue(),
      header: () => <span>Created</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => (row.is_signed ? <WrapGreenCheck src={GREEN_CHECK} /> : null),
      id: 'is_signed',
      cell: (info: any) => info.getValue(),
      header: () => <span>Signed</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => row.type.value.replaceAll('_', ' '),
      id: 'type',
      cell: (info: any) => {
        const cls = info.getValue().replaceAll(' ', '_').toLowerCase();
        return (
          <>
            <div
              id={`status-tooltip-${info.getValue().toLowerCase()}-${info.row.id}`}
              className={`tag tag-${cls}`}
            >
              {info.getValue()}
            </div>
          </>
        );
      },
      header: () => <span>Type</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'action',
      header: () => <span>Preview</span>,
      cell: (info: any) => {
        return (
          <ViewButton type="button" onClick={() => openDocumentPreviewModal(info)}>
            Preview
          </ViewButton>
        );
      },
      footer: (props: any) => props.column.id,
    },
  ];

  // table, chart states:
  const [currentPage, setPage] = useState<any>(1);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [documentInstance, setDocumentInstance] = React.useState<any>(null);
  const [documentPreviewModalIsOpen, setDocumentPreviewModalIsOpen] = React.useState(false);

  const documentTableData = documents.filter((document) => document.file);

  const openDocumentPreviewModal = (infor) => {
    setDocumentInstance(infor.row.original);
    setDocumentPreviewModalIsOpen(true);
  };

  return documentTableData?.length ? (
    <WrapContent>
      <WrapDocumentsDesktop>
        <TitleTable>{documentTableData?.length} Documents</TitleTable>
        <div>
          <Table
            data={documentTableData}
            columns={columns}
            setSorting={setSorting}
            sorting={sorting}
            setPage={setPage}
            page={currentPage}
            pageSize={10}
            total={documentTableData?.length}
          />
        </div>
      </WrapDocumentsDesktop>
      <DocumentPreviewModal
        documentPreviewModalIsOpen={documentPreviewModalIsOpen}
        closeDocumentPreviewModal={() => setDocumentPreviewModalIsOpen(false)}
        documentInstance={documentInstance}
      />
    </WrapContent>
  ) : (
    <Placeholder>Your documents will be displayed here.</Placeholder>
  );
};

// export const TransfersContentMobile = ({
//   transfers,
//   numberOfTransfers,
//   loadingTransfersResponse,
// }) => {
//   const columns = [
//     {
//       accessorFn: (row: any) => row.number,
//       id: 'number',
//       cell: (info: any) => info.getValue(),
//       header: () => <span>ID</span>,
//       footer: (props: any) => props.column.id,
//     },
//     {
//       accessorFn: (row: any) => row.date,
//       id: 'date',
//       cell: (info: any) => info.getValue(),
//       header: () => <span>Date</span>,
//       footer: (props: any) => props.column.id,
//     },
//     {
//       accessorKey: 'debited',
//       header: () => <span>Amount Debited</span>,
//       cell: (info: any) => info.getValue(),
//       footer: (props: any) => props.column.id,
//     },
//     {
//       accessorKey: 'credited',
//       header: () => <span>Credited</span>,
//       cell: (info: any) => info.getValue(),
//       footer: (props: any) => props.column.id,
//     },
//     {
//       accessorKey: 'status',
//       header: () => <span>Status</span>,
//       cell: (info: any) => {
//         return (
//           <>
//             <div
//               id={`status-tooltip-${info.getValue().toLowerCase()}-${info.row.id}`}
//               className={`tag tag-${info.getValue().toLowerCase()}`}
//             >
//               {info.getValue()}
//             </div>
//             <ReactTooltip
//               anchorId={`status-tooltip-${info.getValue().toLowerCase()}-${info.row.id}`}
//               place="bottom"
//               className="adp-tooltip--component"
//               content={CONSTANT_TRANSACTION[info.getValue().toLowerCase()]}
//             />
//           </>
//         );
//       },
//       footer: (props: any) => props.column.id,
//     },
//     {
//       accessorKey: 'action',
//       header: () => <span>Details</span>,
//       cell: (info: any) => {
//         return (
//           <ViewButton type="button" onClick={() => openModalTransfer(info)}>
//             Details
//           </ViewButton>
//         );
//       },
//       footer: (props: any) => props.column.id,
//     },
//   ];

//   const [currentPage, setPage] = useState<any>(1);
//   const [sorting, setSorting] = React.useState<SortingState>([]);
//   const [transferDetails, setTransferDetails] = React.useState<any>(null);
//   const [transferDetailsModalIsOpen, setTransferIDetailsModalsOpen] = React.useState(false);

//   const openModalTransfer = (infor) => {
//     setTransferDetails(infor.row.original.origin);
//     setTransferIDetailsModalsOpen(true);
//   };

//   return (
//     <>
//       <TitleTable>Transaction History</TitleTable>
//       <div className="wrap-table">
//         <Table
//           data={transfers}
//           columns={columns}
//           setSorting={setSorting}
//           sorting={sorting}
//           setPage={setPage}
//           page={currentPage}
//           total={numberOfTransfers}
//           loading={loadingTransfersResponse}
//         />
//       </div>
//       <TransferDetailsModal
//         transferDetailsModalIsOpen={transferDetailsModalIsOpen}
//         closeTransferDetailsModal={() => setTransferIDetailsModalsOpen(false)}
//         transferDetails={transferDetails}
//       />
//     </>
//   );
// };
