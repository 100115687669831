/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../hooks/axios';
import { HeaderHome } from '../../components/header-home';
import { FooterHome } from '../../components/footer-home';

import moment from 'moment';
export const Security = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken }) => {
  return (
    <div className="home-content text-white">
      <HeaderHome
        userIsAdmin={userIsAdmin}
        userIsRegistered={userIsRegistered}
        userIsAuthenticated={userIsAuthenticated}
        setToken={setToken}
        isGrayHeader
      />

      <section className="main">
        <div
          id="indexPageCalcHolder"
          className="calculator_holder"
          style={{ margin: '0 auto 0', maxWidth: '100%' }}
        >
          <div className="marginBottom">
            <div className="spacing_half started2">
              <nav style={{ textAlign: 'center' }}>
                <div className="menu-footer-menu-unregistered-container" style={{ margin: 'auto' }}>
                  <ul id="primary-menu" className="add_nav">
                    <li className="menu-item current-menu-item">
                      <a href="#secure">Digitally Secure</a>
                    </li>
                    <li className="menu-item">
                      <a href="#safe">Financially Safe</a>
                    </li>
                    <li className="menu-item">
                      <a href="#regulated">Licensed and Regulated</a>
                    </li>
                    <li className="menu-item">
                      <a href="#transparent">Totally Transparent</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="info_full_upload upload_mobile3 started1">
                <div
                  style={{ width: '100%', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                >
                  <div
                    className="registerSize started_mobile started text-white"
                    style={{ backgroundColor: 'rgba(55, 65, 88, 0.35)', marginBottom: '80px' }}
                  >
                    <h3 className="startedH3">Security</h3>
                    <h4
                      className="startedH4 postedByDate dateInfoMobile"
                      style={{ textAlign: 'right' }}
                    >
                      Last Updated July 23, 2018
                    </h4>
                    <h3 className="startedH4" id="secure">
                      Digitally Secure
                    </h3>
                    <p className="startedP">
                      OlehPay goes great lengths to keep all of your sensitive information safe.
                    </p>
                    <h4 className="startedH4">Server Security</h4>
                    <p className="startedP">
                      OlehPay implements many security measures to ensure information passes from
                      our clients to our servers safely. OlehPay leverages TLS encryption through
                      bank-standard 256bit SSL to pass user inputted information to OlehPay&apos;s
                      servers. Sensitive information, like bank account numbers are tokenized and
                      regularly dumped following the highest security protocols in the industry.
                      Employee access is heavily restricted and we require background checks on all
                      employees. For more information on what we do with your information, visit our{' '}
                      <a href="https://olehpay.co.il/privacy">privacy policy</a> page.
                    </p>
                    <h4 className="startedH4" id="plaid">
                      Digitally connected accounts
                    </h4>
                    <p className="startedP">
                      In the past, for US banks, we allowed verification of bank accounts by
                      entering your bank credentials. You may be familiar with this process if
                      you&apos;ve ever verified a bank account on Paypal or similar services. These
                      banking credentials are never sent to OlehPay, but are shared with an
                      integrated, trusted third-party, Plaid Technologies, Inc., to facilitate
                      instant account verification. This service may store the provided credentials
                      for verification purposes or use them on a per-transaction basis for fraud
                      prevention, and to help verify a sufficient balance is available to process
                      your transaction.
                    </p>
                    <p className="startedP">
                      We have since removed this functionality for adding new bank accounts,though
                      some accounts may remain connected as such. New bank accounts can be added by
                      manually entering information, however, we require confirmation of the entered
                      credentials by providing a bank statement, blank or voided bank check, or
                      screenshot of your American bank account with the account numbers clearly
                      listed.
                    </p>
                    <p className="startedP">
                      You may change your online banking password at any time after instant
                      verification. You may use a temporary password without losing access to
                      OlehPay&apos;s transfer services. If you do change your password, transactions
                      may be delayed, as we pull important information through the Plaid-linked
                      accounts about the status of your transaction from within the sending bank.
                    </p>
                    <p className="startedP">
                      From time to time, for reasons of security and fraud prevention, either your
                      financial institution or OlehPay may automatically sever your bank connection
                      to OlehPay&apos;s platform. OlehPay will ask that you re-add your bank
                      accounts in these circumstances.
                    </p>
                    <p className="startedP">
                      For more information on security audits of Plaid Technologies, Inc., please
                      visit Plaid&apos;s{' '}
                      <a href="https://plaid.com/legal/">Security and Compliance Overview</a>
                    </p>
                    <p className="startedP">
                      For more information on how Plaid Technologies, Inc., collects, uses, and
                      protects your information, please visit{' '}
                      <a href="https://plaid.com/legal/#privacy-policy">
                        Plaid&apos;s Privacy Policy.
                      </a>
                    </p>

                    <h4 className="startedH4" id="manual">
                      Manually connected accounts
                    </h4>
                    <p className="startedP">
                      If digital connection to your account is not feasible, OlehPay provides users
                      an additional method to connect accounts through a manual upload of a check
                      and account numbers. This creates a manual connection to the account. As the
                      account is connected manually, we lose insights into your account we would
                      otherwise have access to with digitally connected accounts. As a result,
                      transfer times are delayed additional business days making the complete
                      transfer time between 5-7 business days.
                    </p>

                    <h3 className="startedH4" id="safe">
                      Financially Safe
                    </h3>
                    <p className="startedP">
                      Money sent through OlehPay gets routed to regulated and secure accounts to
                      prevent counter-party risk. This guarantees that funds for transfer are never
                      pooled with any other funds, ensuring proper mangement of funds.
                    </p>
                    <p className="startedP">
                      Funds are debited directly through secure methods, drawing money accurately
                      from accounts, and leaving no risk for misspelled IBANs or any human error.
                    </p>
                    <h3 className="startedH4" id="regulated">
                      Licensed and Regulated
                    </h3>
                    <p></p>
                    <p className="startedP">
                      Through partnership with established financial institutions, OlehPay is
                      licensed by Israel’s{' '}
                      <a href="http://mof.gov.il/hon/Currency-service-providers/Pages/Stock-exchange-service-providers.aspx">
                        Ministry of Finance
                      </a>{' '}
                      a as a{' '}
                      <a href="http://www.clearshiftinc.com/Safety/Regulation/Israel-License-2017.aspx">
                        currency service provider
                      </a>
                      .
                    </p>
                    <p className="startedP">
                      In the United States, OlehPay&apos;s partners are registered with the{' '}
                      <a href="http://www.fincen.gov/financial_institutions/msb/msbstateselector.html">
                        Department of Treasury{' '}
                      </a>
                      as a Money Service Business, and are Licensed as a Money Transmitter.
                    </p>
                    <h3 className="startedH4" id="transparent">
                      Totally Transparent
                    </h3>
                    <p className="startedP">
                      OlehPay has the greatest transparency possible in the banking world for the
                      following four reasons:
                    </p>
                    <p className="startedP">
                      OlehPay uses the official and objective{' '}
                      <a href="http://www.boi.org.il/en/Markets/ExchangeRates/Pages/Default.aspx">
                        Bank of Israel{' '}
                      </a>
                      rate also known as the (שער יציג) to determine a daily USD/NIS exchange rate.
                      We urge our customers to stop relying on arbitrary rate contrived by other
                      forex companies.
                      <p className="startedP">
                        OlehPay uses a simple and transparent mechanism for determining pricing:
                        Tier 1: under $12,500.01, 2%; Tier 2: $12,500.01-$24,999.99, 0.7% + fee of
                        Tier 1; Tier 3: equal to or over $25,000, 0.7% total. This price
                        transparency allows us to process transactions without hidden fees or
                        additional charges.
                      </p>
                      <p className="startedP">
                        OlehPay explains exactly how money is sent and how profit is made. OlehPay
                        does not make any money on the exchange rate, we simply make money on the
                        low percentage which we take on the transaction.
                      </p>
                    </p>

                    <p className="startedH4">
                      For more information or general inquiries contact us at{' '}
                      <a href="mailto:info@olehpay.co.il">info@olehpay.co.il</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterHome isHideIntro />
    </div>
  );
};
