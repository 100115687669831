import React, { FC, useState } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const WrapperInput = styled.input`
  background: #222a42;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #465170;
  border-radius: 6px;
  margin-bottom: 30px;
  width: 100%;
  color: #fff;
  font-size: 12px;
  padding: 15px;
`;

interface input {
  typeButton?: any;
  setValueFunc?: any;
  className?: string;
  defaultValue?: string;
  type?: string;
  placeholder?: string;
  id?: string;
  styleInput?: any;
  iconRight?: any;
}

export const InputForm: FC<input> = ({
  setValueFunc,
  className,
  defaultValue = '',
  type = 'text',
  placeholder = '',
  id = '',
  styleInput,
  iconRight,
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <Wrapper>
      <WrapperInput
        style={{
          borderColor: value !== '' ? 'rgb(251, 185, 25)' : '',
          ...styleInput,
        }}
        id={id}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (setValueFunc) {
            setValueFunc(e.target.value);
          }
        }}
        placeholder={placeholder}
        type={type}
        className={className}
      />
      {iconRight && iconRight}
    </Wrapper>
  );
};
