/* eslint-disable no-unused-expressions */

setTimeout(() => {
  /* global $ */
  //function for reporting time, console, and message.
  function reporting(console, task, verbose = true, type = false) {
    var currentdate = new Date();
    var datetime =
      currentdate.getDate() +
      '/' +
      (currentdate.getMonth() + 1) +
      '/' +
      currentdate.getFullYear() +
      ' ' +
      String(currentdate.getHours()).padStart(2, '0') +
      ':' +
      String(currentdate.getMinutes()).padStart(2, '0') +
      ':' +
      String(currentdate.getSeconds()).padStart(2, '0') +
      '.' +
      String(currentdate.getMilliseconds()).padStart(3, '0');
    var time_console = '%c' + datetime + '%c [' + console + '] - ' + '%c' + task;
    var time_color = 'color:#145357';
    var console_color = 'color:#3f646e';
    var task_color = 'color:#8c6b06';
    // if (verbose) {
    //   if (!type) {
    //     window.console.log(time_console, time_color, console_color, task_color);
    //   } else {
    //     window.console.type(time_console, time_color, console_color, task_color);
    //   }
    // }
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  reporting(
    'REPORTER',
    'Reporting function set when user navigated to ' + window.location.href + '.',
  );
  // tels and support
  $('#israeli-tel').click(function () {
    window.location.href = 'tel:+97223762028';
  });
  $('#american-tel').click(function () {
    window.location.href = 'tel:+13239094767';
  });
  $('#email-support').click(function () {
    window.location.href = 'mailto:support@olehpay.co.il';
  });

  // reveal and show the calculator function
  $(function () {
    $('.textfield')
      .focus(function () {
        $('.live').addClass('tcb-mid-live');
        $('#bird').css('display', 'none');
        $('#transfer-comparison').addClass('transfer-comparison');
      })
      .blur(function () {
        $('.live').removeClass('tcb-mid-live');
      });
  });

  $(function () {
    $('.close-tooltip-wrapper').click(function () {
      $('.info-tooltip-container').removeClass('info-tooltip-container-live');
    });
  });

  $('#learn-fast').click(
    function () {
      $('.learn-fast').addClass('info-tooltip-container-live');
    },
    function () {
      $('.learn-fast').removeClass('info-tooltip-container-live'); // remove class when mouseout happen
    },
  );

  $('#learn-insured').hover(function () {
    $('.learn-insured').addClass('info-tooltip-container-live');
  });
  $('#learn-bank-fees').hover(function () {
    $('.learn-bank-fees').addClass('info-tooltip-container-live');
  });
  $('#learn-convenient').hover(function () {
    $('.learn-convenient').addClass('info-tooltip-container-live');
  });

  // keypress to curate numbers

  $('.textfield').keypress(function (event) {
    var $this = $(this);
    if (
      (event.which != 46 || $this.val().indexOf('.') != -1) &&
      (event.which < 48 || event.which > 57) &&
      event.which != 0 &&
      event.which != 8
    ) {
      event.preventDefault();
    }

    var text = $(this).val();
    if (event.which == 46 && text.indexOf('.') == -1) {
      setTimeout(function () {
        if ($this.val().substring($this.val().indexOf('.')).length > 3) {
          $this.val($this.val().substring(0, $this.val().indexOf('.') + 3));
        }
      }, 1);
    }

    if (
      text.indexOf('.') != -1 &&
      text.substring(text.indexOf('.')).length > 2 &&
      event.which != 0 &&
      event.which != 8 &&
      $(this)[0].selectionStart >= text.length - 2
    ) {
      event.preventDefault();
    }
  });

  $('.number').bind('paste', function (e) {
    var text = e.originalEvent.clipboardData.getData('Text');
    if ($.isNumeric(text)) {
      if (text.substring(text.indexOf('.')).length > 3 && text.indexOf('.') > -1) {
        e.preventDefault();
        $(this).val(text.substring(0, text.indexOf('.') + 3));
      }
    } else {
      e.preventDefault();
    }
  });

  var boi_rate = Number($('#BOI_RATE').val());
  reporting('EXCHANGE RATE', 'Setting display rate: ' + boi_rate + ' USD/ILS');
  var exchangerate = Number(parseFloat(boi_rate).toFixed(4));

  $('.rate').html(exchangerate);

  //exchange rate fixed
  $('#exchangerateindex').html(parseFloat(exchangerate).toFixed(4) + 'USD/ILS');

  //calculate OlehPay's fees
  window.pushCalculations = function pushCalculations() {
    var amount = $('#USD')
      .val()
      .replace(/[^0-9.]/g, '');
    var check = (amount * 0.0275).toFixed(2);
    var atm = (amount * 0.03 + 5).toFixed(2);
    var wire = (amount * 0.0215 + 67).toFixed(2);
    // var oleh = (amount * 0.02);
    var oleh =
      amount >= 25000
        ? amount * 0.007
        : amount >= 12500
        ? (amount - 12500) * 0.007 + 250
        : amount * 0.02;
    var olehpay_fee =
      amount >= 25000 ? 0.007 : amount >= 12500 ? ((amount - 12500) * 0.007 + 250) / amount : 0.02;
    olehpay_fee = parseFloat(olehpay_fee.toFixed(6)) * 100;
    var savings = ((parseFloat(atm) + parseFloat(check) + parseFloat(wire)) / 3).toFixed(2);
    if (amount == '0' || amount == null || amount == '' || amount == '0.00') {
      $('.check').html('$0');
      $('.atm').html('$0');
      $('.wire').html('$0');
      $('.oleh').html('$0');
      $('.savings').html(
        'Enter an amount. <br></span><span class="view-savings-details-cta">View Details',
      );
    } else {
      $('.check').html('$' + parseFloat(check).toFixed(2));
      $('.atm').html('$' + parseFloat(atm).toFixed(2));
      $('.wire').html('$' + parseFloat(wire).toFixed(2));
      $('.oleh').html('$' + parseFloat(oleh).toFixed(2));
      $('.savings').html(
        'You save $' +
          parseFloat(savings).toFixed(2) +
          ' in fees!</br></span><span class="view-savings-details-cta">View Details',
      );
    }

    //these are Check fees
    $('.check-rate').html((exchangerate * 0.9825).toFixed(4));
    $('.check-total').html('$' + (amount * 0.99).toFixed(2));
    $('.check-total-received').html(
      '<span>&#8362</span>' + (amount * 0.9725 * exchangerate).toFixed(2),
    );
    $('.check-fee').html('$' + (amount * 0.01).toFixed(2));

    //These are ATM fees
    $('.atm-rate').html(parseFloat(exchangerate * 0.97).toFixed(4));
    $('.atm-total').html('$' + (amount * 0.97 - 5).toFixed(2));
    $('.atm-total-received').html(
      '<span>&#8362</span>' + ((amount * 0.97 - 5) * exchangerate).toFixed(2),
    );

    //these are Wire fees
    $('.wire-rate').html((exchangerate * 0.9785).toFixed(4));
    $('.wire-total').html('$' + (amount - 67).toFixed(2));
    $('.wire-total-received').html(
      '<span>&#8362</span>' + ((amount * 0.9785 - 67) * exchangerate).toFixed(2),
    );

    //These are OlehPay fees
    $('.oleh.c2').html(
      '$' + parseFloat(oleh).toFixed(2) + ' (' + parseFloat(olehpay_fee.toFixed(4)) + '%)',
    );
    $('.boi-rate').html(exchangerate);
    $('.oleh-total').html('$' + (amount - oleh).toFixed(2));
    $('.oleh-total-received').html(
      '<span>&#8362</span>' + ((amount - oleh) * exchangerate).toFixed(2),
    );
  };

  $('#ILS').keyup(function (event) {
    var ILSamount = $(this).val().replace(/\D/g, '');
    var Dollar = (ILSamount / exchangerate / 0.98)
      .toFixed(2)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    var ILS = ILSamount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    $('input[name=USD]').val('$ ' + Dollar);
    $('input[name=ILS]').val('₪ ' + ILS);
    window.pushCalculations();
  });

  $('#USD').keyup(function (event) {
    var USDamount = $(this).val().replace(/\D/g, '');
    var fee = USDamount >= 25000
        ? USDamount * 0.007
        : USDamount >= 12500
        ? (USDamount - 12500) * 0.007 + 250
        : USDamount * 0.02;
    var convertedAmount = USDamount - fee
    var Shekel = (convertedAmount * exchangerate)
      .toFixed(2)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    // console.table({
    //   USDamount, fee, convertedAmount, Shekel
    // })
    var USD = USDamount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    $('input[name=ILS]').val('₪ ' + Shekel);
    $('input[name=USD]').val('$ ' + USD);
    window.pushCalculations();
  });

  //these to maintain currency formats upon click
  $('#USD').click(function (event) {
    $('input[name=USD]').val('$ ');
  });

  $('#ILS').click(function (event) {
    $('input[name=USD]').val('₪ ');
  });

  $('.fee-breakdown').click(function (event) {
    window.pushCalculations();
  });

  // in-viewport and video playing //
  !(function (e, n) {
    'object' == typeof exports && 'undefined' != typeof module
      ? // eslint-disable-next-line @typescript-eslint/no-var-requires
        console.log('static ready 3') // n(require('jquery'), require('window'))
      : // eslint-disable-next-line no-undef
      'function' == typeof define && define.amd
      ? // eslint-disable-next-line no-undef
        define('isInViewport', ['jquery', 'window'], n)
      : n(e.$, e.window);
  })(this, function (e, n) {
    

    function t(n) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var t = this;
      if ((1 === arguments.length && 'function' == typeof n && (n = [n]), !(n instanceof Array)))
        throw new SyntaxError(
          'isInViewport: Argument(s) passed to .do/.run should be a function or an array of functions',
        );
      return (
        n.forEach(function (n) {
          'function' != typeof n
            ? (console.warn(
                'isInViewport: Argument(s) passed to .do/.run should be a function or an array of functions',
              ),
              console.warn('isInViewport: Ignoring non-function values in array and moving on'))
            : [].slice.call(t).forEach(function (t) {
                return n.call(e(t));
              });
        }),
        this
      );
    }

    function o(n) {
      var t = e('<div></div>').css({ width: '100%' });
      n.append(t);
      var o = n.width() - t.width();
      return t.remove(), o;
    }

    function r(t, i) {
      var a = t.getBoundingClientRect(),
        u = a.top,
        c = a.bottom,
        f = a.left,
        l = a.right,
        d = e.extend({ tolerance: 0, viewport: n }, i),
        s = !1,
        p = d.viewport.jquery ? d.viewport : e(d.viewport);
      p.length ||
        (console.warn(
          'isInViewport: The viewport selector you have provided matches no element on page.',
        ),
        console.warn('isInViewport: Defaulting to viewport as window'),
        (p = e(n)));
      var w = p.height(),
        h = p.width(),
        v = p[0].toString();
      if (p[0] !== n && '[object Window]' !== v && '[object DOMWindow]' !== v) {
        var g = p[0].getBoundingClientRect();
        (u -= g.top),
          (c -= g.top),
          (f -= g.left),
          (l -= g.left),
          (r.scrollBarWidth = r.scrollBarWidth || o(p)),
          (h -= r.scrollBarWidth);
      }
      return (
        (d.tolerance = ~~Math.round(parseFloat(d.tolerance))),
        d.tolerance < 0 && (d.tolerance = w + d.tolerance),
        l <= 0 || f >= h
          ? s
          : (s = d.tolerance ? u <= d.tolerance && c >= d.tolerance : c > 0 && u <= w)
      );
    }

    function i(n) {
      if (n) {
        var t = n.split(',');
        return (
          1 === t.length && isNaN(t[0]) && ((t[1] = t[0]), (t[0] = void 0)),
          { tolerance: t[0] ? t[0].trim() : void 0, viewport: t[1] ? e(t[1].trim()) : void 0 }
        );
      }
      return {};
    }
    (e = 'default' in e ? e.default : e),
      (n = 'default' in n ? n.default : n),
      /**
       * @author  Mudit Ameta
       * @license https://github.com/zeusdeux/isInViewport/blob/master/license.md MIT
       */
      e.extend(e.expr[':'], {
        'in-viewport': e.expr.createPseudo
          ? e.expr.createPseudo(function (e) {
              return function (n) {
                return r(n, i(e));
              };
            })
          : function (e, n, t) {
              return r(e, i(t[3]));
            },
      }),
      (e.fn.isInViewport = function (e) {
        return this.filter(function (n, t) {
          return r(t, e);
        });
      }),
      (e.fn.run = t);
  });
  //viewport-init
  $(document).ready(function () {
    $(function () {
      
      /* activate pause for lightbulb video if scrolled out of viewport */
      $(window).scroll(function () {
        // $('video').each(function () {
        //   if ($(this).is(':in-viewport( 400 )')) {
        //     $(this)[0].play();
        //   } else {
        //     $(this)[0].pause();
        //   }
        // });
      });
    });
  });

  var url_string = window.location.href;
  var url = new URL(url_string);
  var get_parameter = url.searchParams.get('r');
  reporting('REFERRAL', 'Setting referral code for r: ' + get_parameter);
  if (get_parameter) {
    var referral_name = 'r';
    var value = get_parameter;
    var days = '60';
    createCookie(referral_name, value, days);
  }

  function createCookie(referral_name, value, days) {
    var expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    } else {
      expires = '';
    }
    document.cookie = referral_name + '=' + value + expires + '; path=/';
  }

  reporting('REFERRAL', 'Retrieivng cookie from cache: ' + getCookie('r'));
}, 1000);
