import React, { FC, useState } from 'react';

import styled from 'styled-components';

const WrapperInput = styled.input`
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 20.5px 12px;
  margin-bottom: 12px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
  cursor: text;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &.h-70 {
    min-height: 70px;
  }
  &:focus,
  &:active,
  &:hover {
    // border: 1px solid #fbb919;
    outline: none;
    opacity: 1;
  }
`;

interface input {
  typeButton?: any;
  setValueFunc?: any;
  className?: string;
  defaultValue?: string;
  type?: string;
  placeholder?: string;
  id?: string;
  onKeyPress?: any;
  isLowerCase?: boolean;
}

export const Input: FC<input> = ({
  setValueFunc,
  className,
  defaultValue = '',
  type = 'text',
  placeholder = '',
  id = '',
  onKeyPress,
  isLowerCase = false,
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <>
      <WrapperInput
        style={{
          borderColor: value !== '' ? 'rgb(251, 185, 25)' : '',
        }}
        id={id}
        value={value}
        onChange={(e) => {
          let vl = e.target.value;
          if (isLowerCase) {
            vl = vl.toLowerCase();
          }
          setValue(vl);
          if (setValueFunc) {
            setValueFunc(vl);
          }
        }}
        placeholder={placeholder}
        type={type}
        className={className}
        onKeyPress={onKeyPress}
      />
    </>
  );
};
