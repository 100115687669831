/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC } from 'react';
import styled from 'styled-components';
import { THEME } from '../../constants';
import { BankAccountsList } from '../bank-account-list';
import { TransferSubmitPanel } from '../transfer-submit-panel';
import { SocialSecurityNumberPanel } from '../social-security-number-panel';

const WrapPanel = styled.div`
  width: 330px;
  // min-width: 330px;
  height: 100%;
  background: linear-gradient(0deg, #1b2337, #1b2337);
  border-left: 1px solid ${THEME.light_blue};
  position: relative;
  overflow-x: hidden;
  @media (max-width: ${THEME.breakpoint}px) {
    width: 100%;
    height: fit-content;
    overflow-y: visible;
    border-left: 0;
    border-top: 1px solid ${THEME.light_blue};
    border-bottom: 1px solid ${THEME.light_blue};
  }
`;

const AddButton = styled.button<{ hasAccounts: boolean }>(
  ({ hasAccounts }) => `
    border: 1px solid #fbac1f;
    background: ${hasAccounts ? 'transparent' : THEME.yellow};
    border-radius: 3px;
    font-family: 'Fontsfree net proxima nova';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 8px;
    color: ${hasAccounts ? THEME.yellow : THEME.white};
    padding: 12px 13px;
    white-space: nowrap;
    width: 100%;
    margin-left: auto;
    cursor: pointer;
    margin-bottom: 40px;
    height: 48px;

    opacity: ${hasAccounts ? 1 : 0.9};
    &:hover {
      ${hasAccounts ? null : 'opacity: 1.0;'}
    }
  `,
);

const PanelHeader = styled.div`
  padding: 40px 27px;
  @media (max-width: 768px) {
    padding: 16px 16px;
  }
  border-bottom: 1px solid ${THEME.light_blue};
  h3 {
    margin: 0;
    font-family: 'Fontsfree net proxima nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${THEME.white};
    margin-bottom: 7px;
  }
  p {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.24em;
    color: ${THEME.gray};
  }
`;

const WrapItemBank = styled.div`
  padding: 27px;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const TextHint = styled.p`
  margin-top: 12px;
  padding-right: 11px;
  padding-left: 11px;
  font-family: 'Fontsfree net proxima nova';
  font-weight: 300;
  text-align: justify;
  font-size: 13px;
  line-height: 16px;
  color: ${THEME.gray};
  // font-weight: 700;
  // font-size: 8px;
  // line-height: 10px;
  // /* or 125% */

  // text-align: justify;
  // letter-spacing: 0.1em;

  // color: ${THEME.gray};
  // a {
  //   color: ${THEME.white};
  // }
`;

const WrapTransfer = styled.div<{ isOpen: boolean }>(
  ({ isOpen }) => `
    position: absolute;
    top: 0;
    right: ${isOpen ? 0 : '-100%'};
    width: 100%;
    height: 100%;
    overflow: auto;
    background: linear-gradient(0deg, #1C2338, #1C2338);
    border-left: 1px solid #202D53;
    transition: .5s all;
    @media (max-width: ${THEME.breakpoint}px) {
      position: fixed;
      z-index: 999;
    }
`,
);

export const RightPanel: FC<any> = ({
  bankAccounts,
  fundingForms,
  setAddBankAccountModalIsOpen,
  getTransfers,
  transferPanelIsOpen,
  ESignatureIOModalIsOpen,
  setESignatureIOModalIsOpen,
  setTransferPanelIsOpen,
  selectedBankAccountSending,
  setSelectedBankAccountSending,
  setActiveESignatureIODocument,
  socialSecurityNumber,
  SSNVerificationDocument,
  setSSNVerificationDocument,
  getSocialSecurityNumber,
  activeESignatureIODocument,
  transferRate,
  profile,
  transfers,
  KYCFormDocument,
  setKYCFormDocument,
  kycIsRequired,
}) => {
  const hasUSBankAccounts = Boolean(bankAccounts.US?.length);
  const hasILBankAccounts = Boolean(bankAccounts.IL?.length);

  const actionableBankAccounts = {
    IL: bankAccounts?.IL,
    US: bankAccounts?.US?.filter(bankAccount => bankAccount?.is_debit_authorized)
  }

  const handleAddAccountOnClick = () => {
    setAddBankAccountModalIsOpen(true);
  };
  return (
    <WrapPanel>
      {socialSecurityNumber?.verification_document_is_signed ? (
        <>
          <PanelHeader>
            <h3>Open Accounts</h3>
            <p>CONNECTED ACCOUNTS</p>
          </PanelHeader>
          <WrapItemBank>
            {!transferPanelIsOpen &&
              Object.keys(bankAccounts)
                .sort()
                .reverse()
                .map((country, index, b) => {
                  return (
                    <BankAccountsList
                      key={'country-' + index}
                      setTransferPanelIsOpen={setTransferPanelIsOpen}
                      setSelectedBankAccountSending={setSelectedBankAccountSending}
                      bankAccounts={bankAccounts[country]}
                      fundingForms={fundingForms}
                      ESignatureIOModalIsOpen={ESignatureIOModalIsOpen}
                      setESignatureIOModalIsOpen={setESignatureIOModalIsOpen}
                      setActiveESignatureIODocument={setActiveESignatureIODocument}
                      country={country}
                      hasUSBankAccounts={hasUSBankAccounts}
                      hasILBankAccounts={hasILBankAccounts}
                      kycIsRequired={kycIsRequired}
                      KYCFormDocument={KYCFormDocument}
                      setKYCFormDocument={setKYCFormDocument}
                    />
                  );
                })}
            <AddButton
              hasAccounts={hasUSBankAccounts || hasILBankAccounts}
              onClick={handleAddAccountOnClick}
            >
              ADD ACCOUNT
            </AddButton>
          </WrapItemBank>
          <WrapTransfer isOpen={transferPanelIsOpen}>
            {transferPanelIsOpen && (
              <TransferSubmitPanel
                setTransferPanelIsOpen={setTransferPanelIsOpen}
                selectedBankAccount={selectedBankAccountSending}
                bankAccounts={actionableBankAccounts}
                getTransfers={getTransfers}
                transferRate={transferRate}
                profile={profile}
                transfers={transfers}
              />
            )}
          </WrapTransfer>{' '}
        </>
      ) : (
        <>
          <PanelHeader>
            <h3>Connect Your Banks</h3>
            <p>LINK YOUR ACCOUNTS TO OLEHPAY</p>
          </PanelHeader>
          <WrapItemBank>
            <SocialSecurityNumberPanel
              SSNVerificationDocument={SSNVerificationDocument}
              setSSNVerificationDocument={setSSNVerificationDocument}
              setActiveESignatureIODocument={setActiveESignatureIODocument}
              activeESignatureIODocument={activeESignatureIODocument}
              getSocialSecurityNumber={getSocialSecurityNumber}
              socialSecurityNumber={socialSecurityNumber}
            />
            <TextHint>
              Note: both US and Israeli law require that we obtain certain information from our
              users before we allow digitally connecting bank accounts to the OlehPay platform.
            </TextHint>
          </WrapItemBank>
        </>
      )}
    </WrapPanel>
  );
};
