import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import {
  SubtitleContainer,
  StyledFooter,
  StyledNextButton,
  StyledInput,
} from '../common-components';
import ICON_ARROW_DOWN from '../../../assets/imgs/icon/arrow_card_down.svg';
import states from './states';

const SELECT_THEME = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral50: '#ccc', // Placeholder color
  },
});

const FormGridContainer = styled.div.attrs({
  className: 'FormGridContainer',
})<{ isShowAddress: any, isShowPhone: any }>(
  ({ isShowAddress, isShowPhone }) => `
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: ${isShowAddress && isShowPhone ? 'repeat(4, 1fr)' : 'repeat(1, 1fr)'};
  gap: 12px;
  margin-bottom: 12px;
`,
);

const PhoneCountryCodeContainer = styled.div.attrs({
  className: 'PhoneCountryCodeContainer',
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const DownArrowIcon = styled.img.attrs({
  className: 'DownArrowIcon',
})`
  position: absolute;
  right: 12px;
  cursor: pointer;
`;

const CountryContainer = styled.div.attrs({
  className: 'CountryContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StateContainer = styled.div.attrs({
  className: 'StateContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledSelect = styled.select.attrs({
  className: 'StyledSelect',
})`
  height: 100%;
  appearance: none;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 20.5px 12px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
  cursor: pointer;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  option {
    color: #222;
  }
  &:focus,
  &:active,
  &:hover {
    border: 1px solid #fff;
    outline: none;
    opacity: 1;
  }
`;

const WrapRegistrationUSStateSelect = styled.div.attrs({
  className: 'WrapRegistrationUSStateSelect',
})<{ isFilledOut: any }>(
  ({ isFilledOut }) => `
    height: 100%;
    width: 100%;
    appearance: none;
    background-color: transparent;
    font-family: 'Fontsfree net proxima nova';
    z-index: 1000;
    border-radius: 8px;
    cursor: pointer;
    opacity: 0.8;
    border: 1px solid ${isFilledOut ? 'rgb(251, 185, 25)' : 'rgb(204, 204, 204)'};
    &:visited {
      border: 1px solid #fbb919;
      opacity: 0.8;
      outline: none;
    }
    &:focus,
    &:active,
    &:hover {
      outline: none;
      border: 1px solid ${isFilledOut ? 'rgb(251, 185, 25)' : 'rgb(204, 204, 204)'};
      opacity: 1;
    }

    [class*="-control"],
    [class*="-container"] {
      height: 100% !important;
      width: 100% !important;
      font-family: 'Fontsfree net proxima nova'!important;
      font-size: 23px !important;
      line-height: 23px !important;
      font-weight: 300 !important;
      background-color: transparent !important;
      border: none !important;
      cursor: pointer !important;
    }
    [class*="-IndicatorsContainer"],
    [class*="-indicatorSeparator"],
    [class*="-indicatorContainer"] {
      display: none !important;
    }
    [class*="-singleValue"] {
      color: rgb(255, 255, 255) !important;
    }
    [class*="-Input"],
    [class*="-ValueContainer"] {
      color: rgb(255, 255, 255) !important;
    }
    [class*="-control"] {
      box-shadow: none !important;
    }
  `,
);

export const RegistrationAddressAndPhone = ({
  address,
  setAddress,
  phoneNumber,
  setPhoneNumber,
  handleNextOnClick,
  nextButtonIsEnabled,
  setNextButtonIsEnabled,
  isUpdate = false,
  isShowAddress = true,
  isShowPhone = true,
}) => {
  const [phoneNumberCountryCodeValue, setPhoneNumberCountryCodeValue] = useState(
    phoneNumber?.country,
  );
  const [stateValue, setStateValue] = useState(address?.region);
  const [USStreetAddress, setUSStreetAddress] = useState(
    address?.street_number && address?.street_name
      ? `${address?.street_number} ${address?.street_name}`
      : address?.street_number || address?.street_name,
  );

  const phoneNumberCountryOptions = [
    { alpha2: 'US', countryCode: '+1' },
    { alpha2: 'IL', countryCode: '+972' },
  ].map((option) => (
    <option key={option.alpha2} value={option.alpha2}>
      {option.countryCode}
    </option>
  ));

  const countryOptions = [
    { label: 'Israel', value: 'IL' },
    { label: 'United States', value: 'US' },
  ].map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  const stateOptions = states.map((option) => (
    <option key={option.code} value={option.code}>
      {option.code}
    </option>
  ));

  const stateSelectOptions = states
    ?.filter((state) => state.code)
    ?.map((state) => ({
      label: state.code,
      value: state.code,
    }));

  const stateOption = address?.region
    ? {
        label: address?.region,
        value: address?.region,
      }
    : null;

  const formatPhoneNumber = (phoneNumber) => {
    const numberText = [phoneNumber.area_code, phoneNumber.number].join('');
    if (!numberText.length) return '';
    const areaCode = numberText?.slice(0, 3);
    const numberFirstThree = numberText?.slice(3, 6);
    const numberLastFour = numberText?.slice(6);
    const len = numberText?.length;
    const charactersToInclude = len <= 3 ? len + 1 : len > 3 && len <= 6 ? len + 3 : len + 6;
    return `(${areaCode}) ${numberFirstThree} - ${numberLastFour}`?.slice(0, charactersToInclude);
  };

  useEffect(() => {
    if (
      phoneNumber?.number &&
      phoneNumber?.country &&
      phoneNumber?.area_code &&
      address?.street_name &&
      address?.street_number &&
      address?.city &&
      address?.postal &&
      (address?.region || address?.country === 'IL')
    )
      setNextButtonIsEnabled(true);
    else setNextButtonIsEnabled(false);
  }, [phoneNumber, address]);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleCountryOnChange = (event) =>
    setAddress((address) => ({
      ...address,
      country: event.target.value,
    }));

  const handlePhoneNumberCountryOnChange = (event) => {
    const countryCode = event.target.value === 'IL' ? '+972' : '+1';
    setPhoneNumber((phoneNumber) => ({
      ...phoneNumber,
      country: event.target.value,
      country_code: countryCode,
    }));
    setPhoneNumberCountryCodeValue(event.target.value);
  };

  const handleOnPhoneNumberOnKeyPress = (event) => {
    const key = event.which;
    if ((key < 48 || key > 57) && (key < 96 || key > 105) && key != 8)
      return event.preventDefault();
  };

  const handlePhoneNumberOnChange = (event) => {
    const data = event.target.value;
    const value = data?.match(/\d/g)?.join('');
    if (value?.length > 10) return event.preventDefault();
    const areaCode = value?.slice(0, 3);
    const number = value?.slice(3);
    setPhoneNumber((phoneNumber) => ({
      ...phoneNumber,
      area_code: areaCode,
      number: number,
    }));
  };

  const splitAddress = (address) => {
    let streetNumber;
    let streetName;
    const firstSpaceIndex = address.trim().indexOf(' ');

    if (firstSpaceIndex !== -1) {
      // Extract street number and name
      streetNumber = address.slice(0, firstSpaceIndex);
      streetName = address.slice(firstSpaceIndex + 1);
    } else {
      // Only a street number or name has been typed so far
      if (isNaN(address)) {
        streetName = address;
      } else {
        streetNumber = address;
      }
    }
    return { streetNumber, streetName };
  };

  const handleUSStreetAddressOnChange = (event) => {
    setUSStreetAddress(event.target.value);
    const { streetNumber, streetName } = splitAddress(event.target.value);
    setAddress((address) => ({
      ...address,
      street_name: streetName ? toTitleCase(streetName) : null,
      street_number: streetNumber,
    }));
  };

  const handleStreetNameOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      street_name: toTitleCase(event.target.value),
    }));
  };

  const handleStreetNumberOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      street_number: event.target.value,
    }));
  };

  const handleStateOptionOnChange = (option) =>
    setAddress((address) => ({
      ...address,
      region: option?.value,
    }));

  const handlePostalOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      postal: event.target.value,
    }));
  };

  const handleCityOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      city: toTitleCase(event.target.value),
    }));
  };

  const handleApartmentNumberOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      apartment_number: event.target.value,
    }));
  };

  useEffect(() => {
    if (address?.country === 'IL')
      setAddress((address) => ({
        ...address,
        region: null,
      }));
  }, [address?.country]);

  return (
    <>
      {isShowAddress && (
        <SubtitleContainer>
          Your {address?.country === 'US' ? 'American' : 'Israeli'} Address
        </SubtitleContainer>
      )}
      <FormGridContainer isShowAddress={isShowAddress} isShowPhone={isShowPhone}>
        {isShowAddress && (
          <>
            <CountryContainer style={{ gridColumn: 'span 20' }}>
              <StyledSelect
                onChange={handleCountryOnChange}
                value={address?.country}
                style={{ borderColor: address?.country ? '#fbb919' : '' }}
              >
                {countryOptions}
              </StyledSelect>
              <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
            </CountryContainer>
            {address?.country === 'US' ? (
              <>
                <StyledInput
                  style={{ gridColumn: 'span 14' }}
                  placeholder="Street Address"
                  value={USStreetAddress || ''}
                  onChange={handleUSStreetAddressOnChange}
                  isFilledOut={address?.street_number}
                />
                <StyledInput
                  style={{ gridColumn: 'span 6' }}
                  placeholder="Apt #"
                  value={address?.apartment_number || ''}
                  onChange={handleApartmentNumberOnChange}
                  isFilledOut={address?.apartment_number}
                />
              </>
            ) : (
              <>
                <StyledInput
                  style={{ gridColumn: 'span 10' }}
                  placeholder="Street Name"
                  value={address?.street_name || ''}
                  onChange={handleStreetNameOnChange}
                  isFilledOut={address?.street_name}
                />
                <StyledInput
                  style={{ gridColumn: 'span 10' }}
                  placeholder="House + Unit #"
                  value={address?.street_number || ''}
                  onChange={handleStreetNumberOnChange}
                  isFilledOut={address?.street_number}
                />
              </>
            )}
            {address?.country === 'US' ? (
              <>
                <StyledInput
                  style={{ gridColumn: 'span 9' }}
                  placeholder="City"
                  value={address?.city || ''}
                  onChange={handleCityOnChange}
                  isFilledOut={address?.city}
                />
                <StateContainer style={{ gridColumn: 'span 5' }}>
                  <WrapRegistrationUSStateSelect isFilledOut={address?.region}>
                    <Select
                      value={stateOption}
                      blurInputOnSelect={true}
                      options={stateSelectOptions}
                      maxMenuHeight={180}
                      placeholder="State"
                      theme={(theme) => SELECT_THEME(theme)}
                      onChange={(option) => handleStateOptionOnChange(option)}
                    ></Select>
                  </WrapRegistrationUSStateSelect>
                  <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
                </StateContainer>
                <StyledInput
                  style={{ gridColumn: 'span 6' }}
                  placeholder="Postal"
                  value={address?.postal || ''}
                  onChange={handlePostalOnChange}
                  isFilledOut={address?.postal}
                />
              </>
            ) : (
              <>
                <StyledInput
                  style={{ gridColumn: 'span 10' }}
                  placeholder="City"
                  value={address?.city || ''}
                  onChange={handleCityOnChange}
                  isFilledOut={address?.city}
                />
                <StyledInput
                  style={{ gridColumn: 'span 10' }}
                  placeholder="Postal Code"
                  value={address?.postal || ''}
                  onChange={handlePostalOnChange}
                  isFilledOut={address?.postal}
                />
              </>
            )}
          </>
        )}

        {isShowPhone && (
          <>
            <PhoneCountryCodeContainer style={{ gridColumn: 'span 7' }}>
              <StyledSelect
                onChange={handlePhoneNumberCountryOnChange}
                value={phoneNumberCountryCodeValue}
                style={{ borderColor: phoneNumberCountryCodeValue ? '#fbb919' : '' }}
              >
                {phoneNumberCountryOptions}
              </StyledSelect>
              <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
            </PhoneCountryCodeContainer>
            <StyledInput
              style={{ gridColumn: 'span 13' }}
              value={formatPhoneNumber(phoneNumber) || ''}
              onChange={handlePhoneNumberOnChange}
              onKeyPress={handleOnPhoneNumberOnKeyPress}
              placeholder="Phone Number"
              isFilledOut={phoneNumber}
            ></StyledInput>
          </>
        )}
      </FormGridContainer>
      <StyledNextButton disabled={!nextButtonIsEnabled} onClick={handleNextOnClick}>
        {isUpdate ? 'Save' : 'Next'}
      </StyledNextButton>
      {isShowAddress && (
        <StyledFooter>
          Tip: If you don&apos;t currently have an Israeli address, you can use an American address.
          Please only enter your address in English.
        </StyledFooter>
      )}
    </>
  );
};
