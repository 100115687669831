import styled from 'styled-components';
import { THEME } from '../../constants';
import moment from 'moment';
import Modal from 'react-modal';
import { CUSTOM_MODAL_STYLE } from '../../constants';
import { useEffect, useState } from 'react';
import { StyledInput } from '../../pages/registration/common-components';
import { RegistrationAddressAndPhone } from '../../pages/registration/address-and-phone';
import { toast } from 'react-toastify';

import useAxios from 'axios-hooks';

const WrapCard = styled.div.attrs({
  className: 'WrapCard',
})`
  background-color: ${THEME.ligher_dark_blue_2};
  border-radius: 10px;
  padding: 5px 25px;
  color: rgba(255, 255, 255, 0.8);
  box-shadow: rgb(0 10 20 / 40%) 0px 8px 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
`;

const WrapTitle = styled.div.attrs({
  className: 'WrapTitle',
})`
  // background-color: ${THEME.ligher_dark_blue_2};
  border-radius: 10px;
  padding: 10px;
  color: ${THEME.white};
  margin: 5px 0;
`;

const ButtonEdit = styled.button`
  border: 1px solid #fbac1f;
  background: #fbac1f;
  margin: 0;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
`;

const ButtonEditMFA = styled.button.attrs({
  className: 'ButtonEditMFA',
})<{ mfaEnabled }>(
  ({ mfaEnabled }) => `
    border: 1px solid #fbac1f;
    background: ${mfaEnabled ? 'transparent' : '#fbac1f'};
    margin: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: ${mfaEnabled ? '#fbac1f' : '#fff'};
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  `,
);

const FormGridContainer = styled.div.attrs({
  className: 'FormGridContainer',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  gap: 12px;
  padding: 0 20px;
`;

const ConfirmButton = styled.button`
  cursor: pointer;
  -webkit-appearance: button;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  color: ${THEME.white};
  font-size: 20px;
  height: 56px;
  width: -webkit-fill-available;
  margin: 20px;
  margin-top: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  transition-duration: 200ms;
  &:hover {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
    box-shadow: none;
    opacity: 0.3;
  }
`;

const WrapperPhoneAndAddress = styled.div`
  padding: 20px;
  padding-top: 0;
`;

const SocialSecurityNumberModal = ({
  isShowEditModal,
  setShowEditModal,
  profile,
  editType,
  refetchProfile,
  setPhoneNumber,
  setAddress,
  address,
  phoneNumber,
}) => {
  const [formData, setFormData] = useState(profile || {});
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const [saveButtonIsEnabled, setSaveButtonIsEnabled] = useState(false);

  const [{ data: dataUpdateProfile, loading: loadingUpdateProfile }, updateProfile] = useAxios(
    {
      url: `/profiles/${profile?.uuid}/`,
      method: 'PATCH',
      data: formData,
    },
    { manual: true },
  );

  const [{ loading: loadingUpdateAddress }, updateAddress] = useAxios(
    {
      url: `/addresses/${address?.uuid}/`,
      method: 'PATCH',
      data: {
        ...address,
        region: address?.country === 'IL' ? null : address?.region,
      },
    },
    { manual: true },
  );
  const [{ loading: loadingUpdatePhoneNumber }, updatePhoneNumber] = useAxios(
    {
      url: `/phone_numbers/${phoneNumber?.uuid}/`,
      method: 'PATCH',
      data: phoneNumber,
    },
    { manual: true },
  );

  // const [{ loading: loadingUpdateAlias }, updateAlias] = useAxios(
  //   {
  //     url: `/aliases/${alias?.uuid}/`,
  //     method: 'PATCH',
  //     data: alias,
  //   },
  //   { manual: true },
  // );

  const handleFirstNameOnChange = (event) =>
    setFormData((formData) => ({
      ...formData,
      first_name: capitalize(event.target.value),
    }));

  const handleLastNameOnChange = (event) =>
    setFormData((formData) => ({
      ...formData,
      last_name: capitalize(event.target.value),
    }));

  useEffect(() => {
    if (dataUpdateProfile) {
      refetchProfile();
      setShowEditModal(false);
    }
  }, [dataUpdateProfile]);

  useEffect(() => {
    if (formData?.first_name && formData?.last_name) setSaveButtonIsEnabled(true);
    else setSaveButtonIsEnabled(false);
  }, [profile]);
  const handleSave = () => {
    switch (editType) {
      case 'name':
        updateProfile({
          data: {
            first_name: formData?.first_name,
            last_name: formData?.last_name,
          },
        });

        break;
      case 'address':
        updateAddress();
        setShowEditModal(false);
        break;

      case 'contact':
        updatePhoneNumber();
        setShowEditModal(false);
        break;

      default:
        break;
    }
  };
  return (
    <Modal
      style={{
        content: {
          ...CUSTOM_MODAL_STYLE.content,
          ...{
            height: 'auto',
          },
        },
      }}
      isOpen={isShowEditModal}
      onRequestClose={() => setShowEditModal(false)}
      appElement={document.getElementById('root')}
    >
      <>
        <h2 className="modal-title">
          <span className="Navbar__button Navbar__button--is-left"></span>
          Edit your {editType === 'name' && 'Name'} {editType === 'address' && 'Address'}
          {editType === 'contact' && 'Email and Phone'}
          <span
            className="Navbar__button Navbar__button--is-right"
            onClick={() => setShowEditModal(false)}
          >
            <i className="Icon Navbar__icon">
              <svg viewBox="0 0 24 24">
                <path
                  fill="#fff"
                  d="M10.695 12.5l-4.342 4.343c-.482.481-.468 1.276.03 1.774.498.499 1.293.512 1.774.03l4.343-4.342 4.317 4.317c.482.482 1.276.468 1.775-.03.498-.499.512-1.293.03-1.775L14.305 12.5l4.317-4.317c.482-.482.468-1.276-.03-1.775-.498-.498-1.293-.512-1.775-.03L12.5 10.695 8.157 6.352c-.481-.481-1.276-.468-1.774.03-.499.5-.512 1.294-.03 1.775l4.342 4.343z"
                ></path>
              </svg>
            </i>
          </span>
        </h2>
        {editType === 'name' && (
          <FormGridContainer>
            <>
              <StyledInput
                placeholder="First Name"
                value={formData?.first_name || ''}
                onChange={handleFirstNameOnChange}
                style={{ borderColor: formData?.first_name ? '#fbb919' : '' }}
                isFilledOut={formData?.first_name}
              />
              <StyledInput
                placeholder={'Last Name'}
                value={formData?.last_name || ''}
                onChange={handleLastNameOnChange}
                style={{ borderColor: formData?.last_name ? '#fbb919' : '' }}
                isFilledOut={formData?.last_name}
              />
            </>

            <ConfirmButton
              disabled={!saveButtonIsEnabled || loadingUpdateProfile}
              onClick={handleSave}
            >
              Save
            </ConfirmButton>
          </FormGridContainer>
        )}
        {editType === 'address' && (
          <WrapperPhoneAndAddress>
            <RegistrationAddressAndPhone
              isUpdate={true}
              address={address}
              isShowPhone={false}
              setAddress={setAddress}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              handleNextOnClick={handleSave}
              nextButtonIsEnabled={saveButtonIsEnabled}
              setNextButtonIsEnabled={setSaveButtonIsEnabled}
            />
          </WrapperPhoneAndAddress>
        )}
        {editType === 'contact' && (
          <WrapperPhoneAndAddress>
            <RegistrationAddressAndPhone
              isUpdate={true}
              address={address}
              isShowAddress={false}
              setAddress={setAddress}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              handleNextOnClick={handleSave}
              nextButtonIsEnabled={saveButtonIsEnabled}
              setNextButtonIsEnabled={setSaveButtonIsEnabled}
            />
          </WrapperPhoneAndAddress>
        )}
      </>
    </Modal>
  );
};

export const ProfileContent = ({
  user,
  phoneNumber,
  profile,
  address,
  setAddress,
  setPhoneNumber,
  identifications,
  refetchProfile,
}) => {
  const [isShowEditModal, setShowEditModal] = useState(false);
  const [editType, setEditType] = useState();
  const [MFAEnabled, setMFAEnabled] = useState(user?.mfa_is_enabled);
  const [{loading: updateMFAIsLoading}, patchMFA] = useAxios(
    {
      url: `/users/${user?.uuid}/`,
      method: 'PATCH',
      data: {
        mfa_is_enabled: !MFAEnabled,
      },
    },
    {
      manual: true,
    }
  );

  if (typeof String.prototype.replaceAll === 'undefined') {
    String.prototype.replaceAll = function (match, replace) {
      return this.replace(new RegExp(match, 'g'), () => replace);
    };
  }

  const toTitleCase = (str) => {
    if (!str) {
      return '';
    }
    return str?.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const updateMFA = () => {
    patchMFA().then(response => {
      const mfa_is_enabled = response?.data?.mfa_is_enabled;
      mfa_is_enabled ? (
        toast.success(`MFA Enabled`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'colored',
        })
      ) : (
          toast.error(`MFA Disabled`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'colored',
        })
      );      
      setMFAEnabled(mfa_is_enabled);
    })
  }
  
  const handleShowEditModal = (type) => {
    setEditType(type);
    setShowEditModal(true);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const hasPlus = phoneNumber?.country_code && phoneNumber?.country_code[0] === '+';
    return `${hasPlus ? phoneNumber?.country_code : '+' + phoneNumber?.country_code} (${
      phoneNumber?.area_code
    }) ${phoneNumber?.number?.slice(0, 3)}-${phoneNumber?.number?.slice(-4)}`;
  };

  return (
    <>
      <WrapTitle>My Personal Details</WrapTitle>
      <WrapCard>
        <p>
          Legal Name: {profile?.first_name} {profile?.last_name}
        </p>
        <ButtonEdit type="button" onClick={() => handleShowEditModal('name')}>
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </ButtonEdit>

        {/* <p>Sex: {profile.sex == 'M' ? 'Male' : 'Female'}</p>
        <p>Date of Birth: {moment(profile.date_of_birth).format('MMMM Do, YYYY')}</p> */}
      </WrapCard>
      <WrapTitle>My Contact Information</WrapTitle>
      <WrapCard>
        <div className="w-100">
          <p>Email: {user?.email}</p>

          <div className="d-flex align-items-center justify-content-between w-100">
            <p>Phone Number: {formatPhoneNumber(phoneNumber)}</p>
            <ButtonEdit type="button" onClick={() => handleShowEditModal('contact')}>
              <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
            </ButtonEdit>
          </div>
        </div>
      </WrapCard>
      <WrapTitle>My Home Address</WrapTitle>
      <WrapCard>
        <p>
          {address?.street_number} {address?.street_name}, {address?.city}
          {address?.country === 'US' ? ` ${address?.region}` : null}, {address?.postal}
        </p>
        <ButtonEdit type="button" onClick={() => handleShowEditModal('address')}>
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </ButtonEdit>
      </WrapCard>
      <WrapTitle>
        MFA
      </WrapTitle>
      <WrapCard>
        <p>
          {MFAEnabled ? "MFA is Enabled" : "MFA is Disabled"}
        </p>
        <ButtonEditMFA 
          mfaEnabled={MFAEnabled}
          onClick={updateMFA}
          disabled={updateMFAIsLoading}
        >
          {MFAEnabled ? "DISABLE" : "ENABLE"}
        </ButtonEditMFA>
      </WrapCard>
      <SocialSecurityNumberModal
        isShowEditModal={isShowEditModal}
        setShowEditModal={setShowEditModal}
        editType={editType}
        profile={profile}
        refetchProfile={refetchProfile}
        phoneNumber={phoneNumber}
        address={address}
        setPhoneNumber={setPhoneNumber}
        setAddress={setAddress}
      />
    </>
  );
};
