import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { notification } from 'antd';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Logo from '../../assets/imgs/logo.svg';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { useAxios } from '../../hooks/axios';

const MFAInput = styled(Input).attrs({
  className: 'MFAInput',
})<{ visible }>(
  ({ visible }) => `
    visibility: ${visible ? 'visible' : 'hidden'};
    // height: ${visible ? 'auto' : 0};
    display: ${visible ? 'block' : 'none'};
    transition: height 0.3s ease-out;
  `,
);

const Wrapper = styled.div`
  background-color: #171c2d;
  height: 100vh;
  width: 100vw;
  overflow: auto;
`;

const WrapperFlex = styled.div`
  display: flex;
  button {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
  }
`;
const WrapperDiv = styled.div``;

const LoginPanel = styled.div`
  width: calc(100% - 20px);
  max-width: 386px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  .logo-img {
    width: 200px;
    margin: 0 auto;
    display: block;
  }
  h2 {
    text-align: center;
    padding-left: 0px;
    display: block;
    color: hsla(0, 0%, 100%, 0.53);
  }
  .submit-button-2 {
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: rgb(251, 185, 25);
    font-family: 'Fontsfree net proxima nova';
    font-size: 28px;
    opacity: 0.95;
    font-weight: 500;
    border: none;
    color: rgb(255, 255, 255);
    margin-left: 0;
    width: 100%;
    &:disabled {
      background-color: #cccccc;
      border-color: transparent;
      opacity: 0.8 !important;
      cursor: default;
    }
  }
`;

export const SignIn = ({ setToken }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [MFACode, setMFACode] = useState('');
  const [error, setError] = useState('');
  const [isGoogleLogin, setShowGoogleLogin] = useState(false);
  const [showMFASection, setShowMFASection] = useState(false);

  const _location = useLocation();
  const _navigate = useNavigate();

  const [{ loading }, login] = useAxios(
    {
      url: '/tokens/session/',
      method: 'POST',
    },
    { manual: true },
  );

  const [{ loading: loadingSocial }, loginAsSocial] = useAxios(
    {
      url: '/login/social/token_user/',
      method: 'POST',
    },
    { manual: true },
  );

  const [{ loading: loadingUserInfo }, getUserByuuid] = useAxios(
    {
      url: '',
      method: 'GET',
    },
    { manual: true },
  );

  useEffect(() => {
    document.body.style.overscrollBehavior = 'none';

    return () => {
      document.body.style.overscrollBehavior = '';
    };
  }, []);

  const loginAsFb = async (code) => {
    try {
      const { data } = await loginAsSocial({
        data: {
          provider: 'facebook',
          code: code,
          redirect_uri: 'postmessage',
        },
      });
      if (data && data?.token) {
        if (data && data?.token) {
          const { data: userData } = await getUserByuuid({
            url: '/users/' + data.uuid,
            headers: {
              authorization: 'Token ' + data?.token,
            },
          });

          setToken({
            token: data?.token,
            user: userData,
          });
          if (userData?.is_superuser) return (window.location.href = '/transfers');
          else if (userData?.is_registered) return (window.location.href = '/account');
          else return (window.location.href = '/join');
        }
        if (data?.user?.is_superuser) return (window.location.href = '/transfers');
        else if (data?.user?.is_registered) return (window.location.href = '/account');
        else return (window.location.href = '/join');
      }
    } catch (error: any) {
      console.log(error);
      setError(
        error.response.data.non_field_errors
          ? error.response.data.non_field_errors.join(' ')
          : JSON.stringify(error.response.data),
      );
    }
  };

  useEffect(() => {
    if (searchParams && searchParams.get('code')) {
      const code = searchParams.get('code');
      loginAsFb(code);
    }
  }, [searchParams]);

  const onLogin = async () => {
    try {
      const { data } = await login({
        data: {
          username: email,
          password: password,
          mfa_code: MFACode ? MFACode : null,
        },
      })      
      if (data && data.mfa_code) {
        setShowMFASection(true);
        toast.success('Check your email for the One Time Passcode!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'colored',
        });
      }
      else if (data && data?.token) {
        setToken(data);
        if (data?.user?.is_superuser) return (window.location.href = '/transfers');
        else if (data?.user?.is_registered) return (window.location.href = '/account');
        else return (window.location.href = '/join');
      }
    } catch (error: any) {
      setError(error.response.data.non_field_errors.join(' '));
    }
  };

  const responseFacebook = async (response) => {
    const code = JSON.parse(atob(response.signedRequest.split('.')[1])).code;
    try {
      const { data } = await loginAsSocial({
        data: {
          provider: 'facebook',
          code: code,
          redirect_uri: 'postmessage',
        },
      });
      if (data && data?.token) {
        if (data && data?.token) {
          const { data: userData } = await getUserByuuid({
            url: '/users/' + data.uuid,
            headers: {
              authorization: 'Token ' + data?.token,
            },
          });

          setToken({
            token: data?.token,
            user: userData,
          });
          if (userData?.is_superuser) return (window.location.href = '/transfers');
          else if (userData?.is_registered) return (window.location.href = '/account');
          else return (window.location.href = '/join');
        }
        if (data?.user?.is_superuser) return (window.location.href = '/transfers');
        else if (data?.user?.is_registered) return (window.location.href = '/account');
        else return (window.location.href = '/join');
      }
    } catch (error) {
      console.log(error);
      // window.location.href = '/signup';
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setShowGoogleLogin(true);
      try {
        const { data } = await loginAsSocial({
          data: {
            provider: 'google-oauth2',
            code: codeResponse.code,
            redirect_uri: 'postmessage',
          },
        });
        if (data && data?.token) {
          const { data: userData } = await getUserByuuid({
            url: '/users/' + data.uuid,
            headers: {
              authorization: 'Token ' + data?.token,
            },
          });

          setToken({
            token: data?.token,
            user: userData,
          });
          if (userData?.is_superuser) return (window.location.href = '/transfers');
          else if (userData?.is_registered) return (window.location.href = '/account');
          else return (window.location.href = '/join');
          setShowGoogleLogin(false);
        }
      } catch (error) {
        setShowGoogleLogin(false);
        console.log(error);
        // window.location.href = '/signup';
      }
    },
    flow: 'auth-code',
    select_account: true,
  });
  return (
    <Wrapper>
      <LoginPanel>
        <img src={Logo} alt="logo" className="logo-img" style={{ marginBottom: '58px' }} />
        <h2 className="text-block-2">
          {error ? (
            //{error}
            <span style={{ color: 'red' }}>Username or password is incorrect</span>
          ) : (
            'Sign in to your OlehPay account'
          )}
        </h2>

        <WrapperFlex>
          <button
            className="auth0-lock-social-button"
            id="google"
            data-provider="google"
            type="button"
            onClick={() => googleLogin()}
          >
            Sign in with Google
          </button>
          <a
            href={`${process.env.REACT_APP_OLEHPAY_ENDPOINT}/login/facebook/`}
            className="auth0-lock-social-button facebook-btn"
            id="facebook"
            data-provider="facebook"
            type="button"
          >
            Sign in with Facebook
          </a>
          {/* <FacebookLogin
            useRedirect
            appId={process.env.REACT_APP_FACEBOOK_APPID}
            // fields="name,email"
            onSuccess={responseFacebook}
            onFail={(error) => {
              console.log('Login facebook Failed!', error);
            }}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="auth0-lock-social-button facebook-btn"
                id="facebook"
                data-provider="facebook"
                type="button"
              >
                Sign in with Facebook
              </button>
            )}
          /> */}
        </WrapperFlex>
        {!isGoogleLogin && (
          <>
            <Input id="email" placeholder="Email" setValueFunc={setEmail} isLowerCase={true} />
            <Input
              id="password"
              placeholder="Password"
              setValueFunc={setPassword}
              type="password"
              onKeyPress={(event) => {
                if (event.key === 'Enter') onLogin();
              }}
            />
            <MFAInput 
              visible={showMFASection}
              id="mfa_code" 
              placeholder="6-Digit One Time Passode" 
              setValueFunc={setMFACode} 
            />
          </>
        )}

        <Button
          onClick={() => {
            onLogin();
          }}
          disabled={email === '' || password === '' || loading || loadingSocial || loadingUserInfo}
          className="submit-button-2"
          label={
            loading || loadingSocial || loadingUserInfo
              ? isGoogleLogin
                ? 'Signing in with google'
                : 'Loading...'
              : 'Sign In'
          }
        />
        <WrapperDiv className="paragraph-2">
          Don&apos;t have an account?{' '}
          <a href="/signup">
            <span className="text-span-4">join OlehPay here.</span>
            <br />
          </a>
          Forgot your password?{' '}
          <a href="/forgot">
            <span className="text-span-4">click here.</span>
          </a>
        </WrapperDiv>
      </LoginPanel>
      <ToastContainer style={{ zIndex: 999 }} draggable={false} />
    </Wrapper>
  );
};
