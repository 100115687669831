import React, { FC, useState, useEffect } from 'react';
import {
  Column,
  Table as ReactTable,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  OnChangeFn,
  flexRender,
} from '@tanstack/react-table';

import ArrowDown from '../../assets/imgs/arrow-down.svg';
import ArrowUp from '../../assets/imgs/arrow-up.svg';
import { THEME } from '../../constants';

import styled from 'styled-components';

const WrapLoading = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  color: ${THEME.gray};
`;

const Wrapper = styled.div`
  position: relative;
  margin-top: 23px;
  .page-total {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
    letter-spacing: 0.24em;
    color: ${THEME.gray};
    text-transform: uppercase;
    padding: 8px;
  }
`;

const NextButton = styled.button(
  ({ disabled }) => `
  ${
    disabled
      ? `
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
    letter-spacing: 0.24em;
    color: ${THEME.gray};
  `
      : `
  border: 1px solid #ffffff;
  border-radius: 3px;
  letter-spacing: 0.24em;
  padding: 8px 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 8px;
  margin-left: 15px;
  color: ${THEME.white};
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  @media (max-width: 768px) {
    margin-right: 16px;
  }
  `
  }`,
);

const PrevButton = styled.button(
  ({ disabled }) => `
  ${
    disabled
      ? `
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
    letter-spacing: 0.24em;
    color: ${THEME.gray};
  `
      : `
  border: 1px solid #ffffff;
  letter-spacing: 0.24em;
  border-radius: 3px;
  padding: 8px 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 8px;
  margin-right: 15px;
  color: ${THEME.white};
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }`
  }`,
);

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;

        color: ${THEME.white};
        padding: 13px 16px;
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: ${THEME.gray2};
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      }
    }
  }
`;

interface table {
  data: any;
  columns: any;
  sorting: any;
  setSorting: any;
  setPage: any;
  page: any;
  total: any;
  pageSize: any;
  loading?: boolean;
}

export const Table: FC<table> = ({
  data,
  columns,
  setSorting,
  sorting,
  setPage,
  page,
  total,
  loading,
  pageSize,
}) => {
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination: {
        pageIndex: page - 1,
        pageSize,
      },
    },
    manualPagination: false,
    onSortingChange: setSorting,
    pageCount: Math.ceil(total / pageSize),

    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    debugTable: false,
  });
  // eslint-disable-next-line no-debugger
  // useEffect(() => {
  //   if (table) {
  //     // eslint-disable-next-line no-debugger
  //     debugger;
  //     table.setPageCount(total);
  //     table.setPageSize(5);
  //   }
  // }, [table]);

  return (
    <Wrapper>
      <div className="h-2" />
      {loading && <WrapLoading>Loading...</WrapLoading>}

      <TableWrapper>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <img src={ArrowUp} alt="" />,
                          desc: <img src={ArrowDown} alt="" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {!data ||
            (data.length === 0 && (
              <tr>
                <td colSpan={columns.length}>There is no data</td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
      {data && data.length > 0 && (
        <>
          <div className="mt-3" />
          <div className="d-flex footer-control-table">
            <span className="d-flex items-center gap-1">
              <div className="page-total">
                Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </div>
            </span>

            <div className="d-flex items-center gap-2">
              {/* <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button> */}
              <PrevButton
                onClick={() => {
                  setPage(page - 1);
                  table.previousPage();
                }}
                disabled={loading || !table.getCanPreviousPage()}
              >
                PREVIOUS PAGE
              </PrevButton>
              <NextButton
                onClick={() => {
                  setPage(page + 1);
                  table.nextPage();
                }}
                disabled={loading || !table.getCanNextPage()}
              >
                NEXT PAGE
              </NextButton>
              {/* <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button> */}

              {/* <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded w-16"
          />
        </span> */}
              {/* <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

function Filter({ column, table }: { column: Column<any, any>; table: ReactTable<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();
  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  );
}
