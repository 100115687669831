import useAxios from 'axios-hooks'

import { StyledStatusButton } from './status-buttons-style'

const CancelButton = ({ row, getPendingTransfers, getProcessingTransfers, isUpdatingData, setIsUpdatingData }) => {
  const [{ loading: loadingCancel }, cancelTransfer] = useAxios(
    {
      url: `/transfers/${row?.uuid}/cancel/`,
      method: 'POST',
    },
    { manual: true },
  )

  // const buttonIsDisabled = !row.is_processable || loadingCancel || isUpdatingData
  const buttonIsDisabled = loadingCancel

  const handleOnClick = () => {
    if (buttonIsDisabled) {
      return
    }
    console.log('CANCEL', row?.number, row?.uuid, row?.status)

    setIsUpdatingData(true)
    cancelTransfer()
      .then((response) => {
        if (getPendingTransfers) getPendingTransfers()
        if (getProcessingTransfers) getProcessingTransfers()
        setIsUpdatingData(false)
      })
      .catch((error) => {
        setIsUpdatingData(false)
        const data = error?.response?.data
        if (!data) return console.log(error)
        let errorMessage = ''
        for (const key in data) {
          // eslint-disable-next-line no-loop-func
          data[key].forEach((message) => {
            errorMessage += `[${key}]: ${message}\n`
          })
        }
        alert(errorMessage)
      })
  }

  return (
    <StyledStatusButton onClick={handleOnClick} disabled={buttonIsDisabled}>
      CANCEL
    </StyledStatusButton>
  )
}

export default CancelButton
