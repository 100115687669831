import React, { useState, useEffect, useCallback } from "react";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { notification } from "antd";
import ReactGA from "react-ga4";

import { Home } from "./pages/home";
import { SignInFromFacebook } from "./pages/signinFromFacebook";
import { SignIn } from "./pages/signin";
import { SignUp } from "./pages/signup";
import { Account } from "./pages/account";
import { FAQ } from "./pages/faq";
import { TransferTimes } from "./pages/transfer-times";
import { Security } from "./pages/security";
import { Privacy } from "./pages/privacy";
import { Terms } from "./pages/terms";
import { Contact } from "./pages/contact";
import { Info } from "./pages/info";

import { Loading } from "./components/loading";
import { MainLayout } from "./components/layout/main-layout";
import { SignedESignatureDocument } from "./components/signed-esignature-document";

import WebFont from "webfontloader";
import "./i18n";

import useToken from "./useToken";
import { Registration } from "./pages/registration";
import { PasswordReset } from "./pages/password-reset";
import { AdminLayout } from "./components/layout/admin-layout";
import { Admin } from "./pages/admin";
import { useIdleTimer } from "react-idle-timer";
import styled from "styled-components";
import { PromotionsPage } from "./pages/promotions/ATMSticker";
import { DigitalAdPage } from "./pages/promotions/DigitalAd1";

const WrapOneTimeBanner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 2rem;
  background: rgba(55,65,88,.95);
  color: white;
  z-index: 9999;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media (max-width: 768px) {
    font-size: 11.4px;
    padding: 15px;
  }
`;

const WrapOneTimeMessage = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 2rem;
  background: #00202f;
  color: #96a1c1;
  z-index: 9999;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const WrapButtonOneTimeMessage = styled.button.attrs({
  type: "button",
})`
  width: 100px;
  color: #eee;
  border-radius: 6px;
  background-color: #f2b200;
  padding: 5px 20px;
  cursor: pointer;
`;

notification.config({
  maxCount: 1,
});

function App() {
  const { token, setToken, user } = useToken();
  const [firstName, setFirstName] = useState(null);
  const [isFontLoaded, setFontLoaded] = useState(false);
  const [isHeavyFontLoaded, setHeavyFontLoaded] = useState(false);
  const [oneTimeMessage, setOneTimeMessage] = useState(
    localStorage.getItem("one_time_message_status")
  );
  const [oneTimeBanner, setOneTimeBanner] = useState(
    localStorage.getItem("one_time_banner_status")
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user?.is_superuser) return;
    if (!ReactGA?.isInitialized) return;
    ReactGA.send({
      hitType: "pageview",
      page: location?.pathname,
      title: `page view - ${location?.pathname?.replace("/", "") || "home"}`,
    });
  }, [location, user]);

  const onIdle = async () => {
    if (!token) {
      return;
    }
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    await setToken(null);
    navigate("/");
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    crossTab: true,
    timeout: 10 * 60 * 1000, //10 minute idle timeout
  });

  const waitForWebfonts = (fonts, callback) => {
    let loadedFonts = 0;
    for (let i = 0, l = fonts.length; i < l; ++i) {
      // eslint-disable-next-line no-loop-func
      (function (font) {
        let node = document.createElement("span");
        // Characters that vary significantly among different fonts
        node.innerHTML = "giItT1WQy@!-/#";
        // Visible - so we can measure it - but not on the screen
        node.style.position = "absolute";
        node.style.left = "-10000px";
        node.style.top = "-10000px";
        // Large font size makes even subtle changes obvious
        node.style.fontSize = "300px";
        // Reset any font properties
        node.style.fontFamily = "sans-serif";
        node.style.fontVariant = "normal";
        node.style.fontStyle = "normal";
        node.style.fontWeight = "normal";
        node.style.letterSpacing = "0";
        document.body.appendChild(node);

        // Remember width with no applied web font
        const width = node.offsetWidth;

        node.style.fontFamily = font;

        let interval;
        function checkFont() {
          // Compare current width with original width
          if (node && node.offsetWidth != width) {
            ++loadedFonts;
            node.parentNode.removeChild(node);
            node = null;
          }

          // If all fonts have been loaded
          if (loadedFonts >= fonts.length) {
            if (interval) {
              clearInterval(interval);
            }
            if (loadedFonts == fonts.length) {
              callback();
              return true;
            }
          }
        }

        if (!checkFont()) {
          interval = setInterval(checkFont, 50);
        }
      })(fonts[i]);
    }
  };

  useEffect(() => {
    WebFont.load({
      custom: {
        families: [
          "Fontsfree net pr16",
          "Fontsfree net proxima nova:n9,n7,n5,n4,n3",
          "Proxima Nova",
        ],
      },
      google: {
        families: [
          "Inter:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700",
        ],
      },
      active: function () {
        setFontLoaded(true);
      },
    });
  }, []);
  const userIsAdmin = Boolean(token) && user?.is_superuser;
  const userIsAuthenticated = Boolean(token);
  const userIsRegistered = user?.is_registered;

  const homeElement = (
    <Home
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const signInFromFacebook = <SignInFromFacebook setToken={setToken} />;
  const signedEsignatureDocumentElement = <SignedESignatureDocument />;
  const signInElement = <SignIn setToken={setToken} />;

  const accountElement = (
    <Account setFirstName={setFirstName} userData={user} />
  );
  const registrationElement = <Registration setToken={setToken} />;
  const signUpElement = <SignUp setToken={setToken} />;
  const passwordResetElement = <PasswordReset />;
  const FAQElement = (
    <FAQ
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const transferTimesElement = (
    <TransferTimes
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const securityElement = (
    <Security
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const privacyElement = (
    <Privacy
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const termsElement = (
    <Terms
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const contactElement = (
    <Contact
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const infoElement = (
    <Info
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const promotionsPage = (
    <PromotionsPage
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const digitalPage = (
    <DigitalAdPage
      userIsAdmin={userIsAdmin}
      userIsRegistered={userIsRegistered}
      userIsAuthenticated={userIsAuthenticated}
      setToken={setToken}
    />
  );
  const navigateToHome = <Navigate to="/" />;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderOneTimeMessage = useCallback(() => {
    if (oneTimeMessage === "yes") {
      return <></>;
    }
    return (
      <WrapOneTimeMessage>
        We made some changes around here. See something that&apos;s not right?
        Contanct us at support@olehpay.co.il{" "}
        <WrapButtonOneTimeMessage
          onClick={() => {
            setOneTimeMessage("yes");
            localStorage.setItem("one_time_message_status", "yes");
          }}
        >
          Got It
        </WrapButtonOneTimeMessage>
      </WrapOneTimeMessage>
    );
  }, [oneTimeMessage]);

  const renderOneTimeBanner = useCallback(() => {
    if (oneTimeBanner === "yes") {
      return <></>;
    }
    return (
      <WrapOneTimeBanner>
        OlehPay stands with Israel during these difficult times. 10% of proceeds will be donated to {' '}
        <a href="https://afmda.org/" target="_blank" rel="noreferrer" style={{all: 'unset', display: 'contents', cursor: 'pointer'}}>Magen David Adom (https://afmda.org/)</a> to support humanitarian aid in our homeland.
        <WrapButtonOneTimeMessage
          onClick={() => {
            setOneTimeBanner("yes");
            localStorage.setItem("one_time_banner_status", "yes");
          }}
        >
          Got It
        </WrapButtonOneTimeMessage>
      </WrapOneTimeBanner>
    );
  }, [oneTimeBanner]);

  const mainLayoutElement = (
    <>
      {/* {renderOneTimeMessage()} */}
      <MainLayout
        setToken={setToken}
        is_superuser={user?.is_superuser}
        firstName={firstName}
      />
    </>
  );

  const adminLayoutElement = (
    <>
      {/* {renderOneTimeMessage()} */}
      <AdminLayout setToken={setToken} />
    </>
  );
  const adminElement = <Admin setToken={setToken} />;

  // if (!isFontLoaded) {
  //   return ;
  // }
  return (
    <>
      {/* {renderOneTimeBanner()} */}
      <Loading opacity={!isFontLoaded && !isHeavyFontLoaded ? 1 : 0}></Loading>
      {isFontLoaded && (
        <div className={oneTimeBanner !== "yes" ? "has-one-time-banner" : ""}>
          <Routes>
            <Route path="/" element={homeElement} />
            <Route
              path="/document_is_signed"
              element={signedEsignatureDocumentElement}
            />
            <Route path="/signin" element={signInElement} />
            <Route path="/api/complete/facebook" element={signInFromFacebook} />
            <Route path="/signup" element={signUpElement} />
            <Route path="/forgot" element={passwordResetElement} />
            <Route path="/faq" element={FAQElement} />
            <Route path="/transfer-times" element={transferTimesElement} />
            <Route path="/security" element={securityElement} />
            <Route path="/privacy" element={privacyElement} />
            <Route path="/terms" element={termsElement} />
            <Route path="/contact" element={contactElement} />
            <Route path="/info" element={infoElement} />
            <Route path="/BC70EA" element={promotionsPage} />
            <Route path="/65B2C2" element={digitalPage} />

            {userIsAdmin ? (
              <>
                <Route element={adminLayoutElement}>
                  <Route path="/users" element={adminElement} />
                  <Route path="/accounts" element={adminElement} />
                  <Route path="/transfers" element={adminElement} />
                  <Route path="/reports" element={adminElement} />
                  {/* <Route path="/subscriptions" element={adminElement} />
              <Route path="/referrals" element={adminElement} /> */}
                </Route>
              </>
            ) : (
              <>
                {userIsAuthenticated && (
                  <Route path="/join" element={registrationElement} />
                )}
                {userIsRegistered && (
                  <Route element={mainLayoutElement}>
                    <Route path="/account" element={accountElement} />
                    <Route path="/profile" element={accountElement} />
                    <Route path="/documents" element={accountElement} />
                    <Route path="/subscriptions" element={accountElement} />
                    <Route path="/referrals" element={accountElement} />
                  </Route>
                )}
              </>
            )}
            <Route path="*" element={navigateToHome} />
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
