/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../hooks/axios';
import { HeaderHome } from '../../components/header-home';
import { FooterHome } from '../../components/footer-home';

import moment from 'moment';
export const TransferTimes = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken }) => {
  return (
    <div className="home-content text-white">
      <HeaderHome
        userIsAdmin={userIsAdmin}
        userIsRegistered={userIsRegistered}
        userIsAuthenticated={userIsAuthenticated}
        setToken={setToken}
        isGrayHeader
      />

      <section className="main">
        <div
          id="indexPageCalcHolder"
          className="calculator_holder"
          style={{ margin: '0 auto 0', maxWidth: '100%' }}
        >
          <div className="marginBottom">
            <div className="spacing_half started2">
              <nav style={{ textAlign: 'center' }}>
                <div className="menu-footer-menu-unregistered-container" style={{ margin: 'auto' }}>
                  <ul id="primary-menu" className="add_nav">
                    <li className="menu-item current-menu-item">
                      <a href="#business-days">Business Days</a>
                    </li>
                    <li className="menu-item">
                      <a href="#national-holidays">National Holidays</a>
                    </li>
                    <li className="menu-item">
                      <a href="#conneted-accounts">Connected Accounts</a>
                    </li>
                    <li className="menu-item">
                      <a href="#urgent-transfers">Urgent Transfers</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="info_full_upload upload_mobile3 started1">
                <div
                  style={{ width: '100%', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                >
                  <div
                    className="registerSize started_mobile started text-white"
                    style={{ backgroundColor: 'rgba(55, 65, 88, 0.35)', marginBottom: '80px' }}
                  >
                    <h3 className="startedH3">How long do transfers take?</h3>
                    <h4
                      className="startedH4 postedByDate dateInfoMobile"
                      style={{ textAlign: 'right' }}
                    >
                      Last Updated September 29, 2020
                    </h4>
                    <h4 className="startedH4" id="business-days">
                      Weekend or weekday, what do we consider a business day?
                    </h4>
                    <p className="startedP">
                      By nature of foreign tranfers, such as those made through OlehPay, transfer
                      times vary greatly depending on whether banks are in operation. A day when
                      banks both in the United States and Israel are in operation is considered a
                      business day.{' '}
                    </p>
                    <p className="startedP">
                      Because of OlehPay&apos;s financial mechanics, where money is routed locally
                      either in the United States or Israel, certain days may be considered business
                      days in one country but not the other. For instance, transfers requested after
                      end of banking day in the United States on Friday will only begin processing
                      the following Monday - requests for transfers on the weekend already see a
                      three day delay! This discrepency can work in your favor, however, such as on
                      Sundays in Israel, when banks are open and domestic transfers can settle. It
                      can take several full business days to coordinate and complete a transfer.{' '}
                    </p>
                    <br />
                    <h4 className="startedH4" id="national-holidays">
                      How are my transfers affected by national holidays?
                    </h4>
                    <p className="startedP">
                      National holidays always affect transfers as banks are almost always sure to
                      be closed, both for sending and receiving transfers.
                    </p>
                    <p className="startedP">
                      For instance, during Labor Day Weekend in the United States banks are closed
                      for a long weekend, causing transfers to be delayed significantly. However,
                      transfers in process over the weekend may have been completed in Israel on
                      that Sunday or Monday despite banks being closed in the USA. Long holidays in
                      Israel, such as a three-day Rosh Hashana, can significantly affect transfer
                      times, especially during the longer Israeli holidays like Sukkot and Pesach
                      when banks may be in and out of operation unpredictably.
                    </p>
                    <br />
                    <h4 className="startedH4" id="conneted-accounts">
                      Accounts connected{' '}
                      <a href="https://olehpay.co.il/security#plaid"> &quot;digitally&quot;</a>
                    </h4>
                    <p className="startedP">
                      Most commonly, OlehPay users have connected their bank accounts using the
                      digital console we provide on the OlehPay accounts page. This is done by
                      logging in with your user and password as you would on your own bank when
                      prompted on the OlehPay account page. Note that we do not save or even see
                      your password. This console is essentially a &quot;window&quot; into your bank
                      account provided by your bank directly to allow applications like OlehPay to
                      instantly authenticate your account.
                    </p>
                    <p className="startedP">
                      This system also allows us to track the progress of your transfer from your
                      side. This transparency allows us to send complete transfers on the Israeli
                      side in some case as soon as the next business day.{' '}
                    </p>{' '}
                    <br />
                    <h4 className="startedH4" id="manual">
                      Accounts connected{' '}
                      <a href="https://olehpay.co.il/security#manual">&quot;manually&quot;</a>
                    </h4>
                    <p className="startedP">
                      When connecting your bank account digitally is not feasible, users are given
                      an additional option to link their bank accounts manually. Instead, use the
                      manual upload console where you&apos;ll be prompted to upload a check or a
                      bank statement and confirm your account numbers be entering them directly.
                      Some users have sent us documents by email and we&apos;ve connected those bank
                      accounts manually on their behalf. While this is a great alternative to
                      linking bank accounts without granting access via user and password, the
                      downside is that this method provides us less insight into your bank account.
                      This means OlehPay loses access to instant bank account number authentication
                      and debit confirmations. OlehPay uses these signals to process transactions
                      faster. Without them, transfers can see a delay with a total transfer time of
                      5-7 business days.{' '}
                    </p>
                    <br />
                    <h4 className="startedH4" id="safe">
                      Accounts &quot;Pending Approval&quot;
                    </h4>
                    <p className="startedP">
                      Before OlehPay can begin processing transfers, your OlehPay account must be
                      approved. While OlehPay provides the ability to schedule transfers even before
                      your OlehPay account is approved, the transfer will only begin after your
                      OlehPay account has been approved and your transaction is submitted. As it may
                      take up to several business days for us to approve OlehPay accounts, the total
                      transfer time from request to settlement can be misrepresentatively long.
                    </p>
                    <br />
                    <h4 className="startedH4" id="safe">
                      Severed Digital Connections{' '}
                    </h4>
                    <p className="startedP">
                      Digitally connected bank accounts can default to the status of a manually
                      connected bank account if our digital connection to the bank account is
                      severed. This can happen as your bank may, from time to time, automatically
                      severe any digital connections. Additionally, accounts can also be severed
                      either if you changed your password or made internal changes in your bank
                      account settings.
                    </p>
                    <br />
                    <h4 className="startedH4" id="urgent-transfers">
                      What can I do to make a transfer faster if it&apos;s really urgent? Send us a
                      screen shot.
                    </h4>
                    <p className="startedP">
                      In some cases, as a last resort, we can settle transfers sooner with your
                      help. The most common reason we delay a transfer is that we cannot verify a
                      debit on your account has been made. Transfers without a digital connection
                      are assumed to be debited after a few days, however, we can confirm that a
                      debit has already taken place sooner if you send us a screenshot of that debit
                      leaving your account.
                    </p>
                    <p className="startedP">
                      If you found this article helpful, let us know!
                      <br />
                      For more information or general inquiries contact us at{' '}
                      <a href="mailto:info@olehpay.co.il">info@olehpay.co.il</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterHome isHideIntro />
    </div>
  );
};
