/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC } from 'react';
import styled from 'styled-components';
import { THEME } from '../../constants';
import { BankAccountCard } from '../bank-account-card';

const PanelHeader = styled.div`
  h3 {
    margin: 0;
    font-family: 'Fontsfree net proxima nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${THEME.white};
    margin-bottom: 7px;
  }
  p {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.24em;
    color: ${THEME.gray};
  }
`;

const AddButton = styled.button<{ hasAccounts: boolean }>(
  ({ hasAccounts }) => `
    border: 1px solid #fbac1f;
    background: ${THEME.yellow};
    border-radius: 3px;
    font-family: 'Fontsfree net proxima nova';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 8px;
    color: ${THEME.white};
    padding: 12px 13px;
    white-space: nowrap;
    width: 100%;
    height: 48px;
    margin-left: auto;
    cursor: pointer;
    // margin-bottom: 40px;
    // opacity: ${0.9};
    // &:hover {
    //   opacity: 1;
    // }
    // border: 1px solid #fbac1f;
    // background: ${hasAccounts ? 'transparent' : THEME.yellow};
    // border-radius: 3px;
    // font-family: 'Proxima Nova';
    // font-style: normal;
    // font-weight: 500;
    // font-size: 9px;
    // line-height: 8px;
    // color: ${hasAccounts ? THEME.yellow : THEME.white};
    // padding: 12px 13px;
    // white-space: nowrap;
    // width: 100%;
    // margin-left: auto;
    // cursor: pointer;
    // margin-bottom: 40px;
    opacity: ${hasAccounts ? 1 : 0.9};
    &:hover {
      ${hasAccounts ? null : 'opacity: 1.0;'}
    }
  `,
);

const WrapNoAccountsMessage = styled.div`
  // margin-top: 15px;
  padding: 25px;
  border: 1px solid ${THEME.ligher_dark_blue};
  color: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
`;

export const BankAccountsList: FC<any> = ({
  country,
  bankAccounts,
  fundingForms,
  setTransferPanelIsOpen,
  setSelectedBankAccountSending,
  hasUSBankAccounts,
  hasILBankAccounts,
  ESignatureIOModalIsOpen,
  setESignatureIOModalIsOpen,
  setActiveESignatureIODocument,
  kycIsRequired,
  KYCFormDocument,
  setKYCFormDocument,
}) => {
  const countryAdjective = country === 'US' ? 'American' : 'Israeli';
  const title = bankAccounts.length
    ? `${countryAdjective} Accounts`
    : `Connect an ${countryAdjective} Account`;
  const desc = bankAccounts.length ? `CONNECTED ${countryAdjective.toUpperCase()} ACCOUNTS` : null;
  const bankAccountCards = bankAccounts.map((bankAccount, index) => {
    const fundingForm = fundingForms?.filter(
      (fundingForm) => fundingForm.bank_account == bankAccount.uuid,
    )[0];

    return (
      <BankAccountCard
        setTransferPanelIsOpen={setTransferPanelIsOpen}
        setSelectedBankAccountSending={setSelectedBankAccountSending}
        key={'bank-' + index}
        bankAccount={bankAccount}
        fundingForm={fundingForm}
        hasUSBankAccounts={hasUSBankAccounts}
        hasILBankAccounts={hasILBankAccounts}
        ESignatureIOModalIsOpen={ESignatureIOModalIsOpen}
        setESignatureIOModalIsOpen={setESignatureIOModalIsOpen}
        setActiveESignatureIODocument={setActiveESignatureIODocument}
        kycIsRequired={kycIsRequired}
        KYCFormDocument={KYCFormDocument}
        setKYCFormDocument={setKYCFormDocument}
      ></BankAccountCard>
    );
  });

  return (
    <PanelHeader>
      <div className="d-flex" style={{ marginBottom: '15px' }}>
        <div>
          {/* <h3>{title}</h3> */}
          <p>{desc}</p>
        </div>
      </div>
      {bankAccountCards.length ? (
        bankAccountCards
      ) : (
        <WrapNoAccountsMessage> NO {country} BANK ACCOUNTS CONNECTED </WrapNoAccountsMessage>
      )}
    </PanelHeader>
  );
};
