/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../hooks/axios';
import { HeaderHome } from '../../components/header-home';
import { FooterHome } from '../../components/footer-home';

import moment from 'moment';
export const Info = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken }) => {
  return (
    <div className="home-content text-white">
      <HeaderHome
        userIsAdmin={userIsAdmin}
        userIsRegistered={userIsRegistered}
        userIsAuthenticated={userIsAuthenticated}
        setToken={setToken}
        isGrayHeader
      />

      <section className="main">
        <div
          id="indexPageCalcHolder"
          className="calculator_holder"
          style={{ margin: '0 auto 0', maxWidth: '100%' }}
        >
          <div className="marginBottom">
            <div className="spacing_half started2">
              <nav style={{ textAlign: 'center' }}>
                <div className="menu-footer-menu-unregistered-container" style={{ margin: 'auto' }}>
                  <ul id="primary-menu" className="add_nav">
                    <li className="menu-item current-menu-item">
                      <a href="#case">The case for OlehPay</a>
                    </li>
                    <li className="menu-item">
                      <a href="#analysis">Cost analysis and comparison</a>
                    </li>
                    <li className="menu-item">
                      <a href="#Registration">Registration</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="info_full_upload upload_mobile3 started1">
                <div
                  style={{ width: '100%', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                >
                  <div
                    className="registerSize started_mobile started text-white"
                    style={{ backgroundColor: 'rgba(55, 65, 88, 0.35)', marginBottom: '80px' }}
                  >
                    <h3 id="case" className="startedH3" style={{ color: '#fbc011' }}>
                      THE CASE FOR OLEHPAY
                    </h3>
                    <h4
                      className="startedH4 postedByDate dateInfoMobile"
                      style={{ textAlign: 'right' }}
                    >
                      Last Updated June 15th, 2023
                    </h4>
                    <h4 className="startedH4">What we do</h4>
                    <p className="startedP">
                      OlehPay allows you to transfer money from America to Israel with more
                      convenience, affordability, and security than any other method on the market.
                    </p>
                    <br />

                    <h4 className="startedH4">Who we are</h4>

                    <p className="startedP">
                      OlehPay was founded by American Olim on the principle that there simply had to
                      be a better way to move money between the United States and Israel.
                    </p>
                    <br />
                    <h4 className="startedH4">How we work</h4>
                    <p className="startedP">
                      OlehPay conveniently and securely links your US and Israeli bank accounts.
                      Leveraging our strategic financial network, we can transfer dollar funds out
                      of American banks and into Israeli banks both through local transactions -
                      this enables fund transfer directly between foreign accounts, but without
                      international wire, transfer, or bank fees.
                    </p>
                    <br />
                    <h4 className="startedH4">Why we&apos;re different </h4>
                    <p className="startedP">
                      Banks, exchange houses, wire services, and other such transfer services charge
                      over the top fees, exploiting unfavorable exchange rates to bump up their
                      commission. We use the Bank of Israel&apos;s official daily rate, and
                      therefore, we do not control the exchange rate to our advantage. This gives
                      our users the fairest rate possible, establishing confidence that they&apos;re
                      getting the most bang for their buck.
                    </p>

                    <p className="startedP">
                      Beware of other exchange services that claim to charge a lower percentage -
                      they can often hide their fees in their exchange rates and not take into
                      account international sending and receiving wire fees.
                    </p>
                    <br />
                    <br />
                    <h3 id="analysis" className="startedH3" style={{ color: '#fbc011' }}>
                      COST ANALYSIS AND COMPARISON
                    </h3>
                    <p className="startedP">
                      There are many ways one can send money to Israel; initiating an international
                      wire, pulling cash from an ATM, writing a check, or bringing cold-hard cash
                      from the States - there are all very commonly used methods. It&apos;s
                      important for one to be aware of how each system presents its costs.
                    </p>
                    <br />
                    <h4 className="startedH4">Wire Transfers:</h4>

                    <p className="startedP">
                      By far the most costly method to transfer money, the international wire
                      presents a misleading fee structure. Banks levy hefty fees to send money
                      across borders. Typically, those sending money are charged several fees, many
                      of which one might be entirely unaware.
                      <br />
                      <br />
                      There are two fixed costs of an international wire: an initiating wire fee,
                      and a receiving wire fee.
                      <br />
                      <br />
                      Sending wire fees average $45 across banks in the United States, and are often
                      more expensive at more popular banks; smaller banks attract customers by
                      lowering these fees. You can see an aggregated list of examples of sending
                      wire fees from the states here{' '}
                      <a href="https://www.nerdwallet.com/blog/banking/wire-transfers-what-banks-charge/">
                        https://www.nerdwallet.com/blog/banking/wire-transfers-what-banks-charge/
                      </a>
                      .
                      <br />
                      <br />
                      Receiving wire fees in Israel average $20 for flat rates, but are often
                      presented as a 0.33% percentage fee of the overall transfer amount. You can
                      read more about the costs of receiving wire fees in Israel on the Nefesh
                      B&apos;Nefesh website{' '}
                      <a href="http://www.nbn.org.il/aliyahpedia/getting-started-planning-aliyah/financial-planning/banking-in-israel/money-transfer-and-currency-conversion/">
                        http://www.nbn.org.il/aliyahpedia/getting-started-planning-aliyah/financial-planning/banking-in-israel/money-transfer-and-currency-conversion/.
                      </a>
                    </p>

                    <p className="startedP">
                      For instance, an exchange company that claims to move money at 1% below the
                      day&apos;s exchange mid-rate must work through several fees:
                      <sup>1 &amp; 2</sup>.{' '}
                    </p>

                    <p className="startedP">1) 0.33% receiving fee. </p>

                    <p className="startedP">
                      2) 0.1% lost on purchase of currency (this number could be more or less
                      depending on the volume of currency traded).{' '}
                    </p>

                    <p className="startedP">3) 0.57% left as profit for the trader.</p>

                    <p className="startedP">
                      This, along with the initial transfer fees, adds up to quite the sum.
                    </p>
                    <br />

                    <h4 className="startedH4">Withdrawing Money From an ATM</h4>
                    <p className="startedP">
                      For most people, withdrawing money from an ATM can be quite costly.
                      <br />{' '}
                    </p>

                    <p className="startedP">
                      1) Typical fees from US banks when using ATM&apos;s can be up to $5 per
                      withdrawal.{' '}
                    </p>

                    <p className="startedP">
                      2) Israeli ATM&apos;s often charge fees on their end.{' '}
                    </p>

                    <p className="startedP">
                      3) Industry standard exchange rates on regular debit cards are 3% lower than
                      posted mid-rate.{' '}
                    </p>

                    <p className="startedP">
                      4) ATMs often place withdrawal limits on their users, these limits are set by
                      both by limits on the institution&apos;s issuing card and the ATMs host bank.
                    </p>

                    <p className="startedP">
                      Without even considering the sheer inconvenience of a trip to the ATM, the
                      fees make it clear that this option simply isn&apos;t fair to its users.
                    </p>
                    <br />

                    <h4 className="startedH4">Transferring Money By Writing Yourself A Check: </h4>
                    <p className="startedP">
                      Writing yourself a check is not a secure or convenient way to send yourself
                      money. Because of the risk associated with cashing checks, it&apos;s very
                      possible - and not uncommon - for money changers to reject your check.
                    </p>
                    <p className="startedP">
                      Depending on your relationship with money changers, we&apos;ve seen money
                      changers cash checks for as high as a 5% fee. Check cashing fees are often
                      presented broken down into two fees: a checking processing fee, and an
                      exchange fee (the exchange fee can often be hidden as an unfavorable exchange
                      rate). These often high fees, in fact, indicate checks are clearly not an
                      economical way to receive money in Israel.
                    </p>
                    <br />

                    <h4 className="startedH4">Exchange Rates</h4>
                    <p className="startedP">
                      {' '}
                      Exchange rates are the easiest way an exchange company can hide fees. This is
                      sometimes done unwittingly, as exchange rates are constantly moving targets
                      and are based on the relationship of two currencies. In our case, the Shekel
                      and Dollar see constantly fluctuating values as they are susceptible to
                      influence from many uncontrollable economic variables. In order to hide
                      additional profits (and even in some cases to prevent risk from currency
                      fluctuation), exchange companies present a rate that is far lower than the
                      mid-rate - mid-rate being the value of how many Shekels one Dollar is worth.
                    </p>

                    <p className="startedP">
                      It is important to note fundamentally that the amount of Shekels one Dollar is
                      worth is different from the number of Shekels one Dollar can buy. While the
                      first is often averaged from the day&apos;s trades and is determined daily by
                      the Bank of Israel, the latter is often posed with a degree a subjectivity by
                      the bank exchanging your funds. In our experience, these presented exchange
                      rates differ deceivingly between inattentive clients.
                    </p>

                    <p className="startedP">
                      Clearly, ATMs, Checks, and Wire Transfers take advantage of their users in
                      costly ways - OlehPay, in contrast, leverages its ability to transmit funds
                      locally in the United States, and Israel, eliminating all the obstacles that
                      lead to many of these fees. Further to establish objectivity when exchanging
                      currencies, OlehPay trades at the true USD/ILS market mid-rate determined by
                      the Bank of Israel, so dollars purchase the highest amount of Shekels possible
                      during the trade. OlehPay takes only a modest 2% fee to continue running its
                      services.
                    </p>
                    <br />
                    <h3 id="pricing" className="startedH3" style={{ color: '#fbc011' }}>
                      PRICING
                    </h3>
                    <h4 className="startedH4">Current OlehPay Pricing Terms</h4>
                    <p className="startedP">
                      *please note the current OlehPay pricing is effective as per the date of the
                      &quot;last updated&quot; date noted at the top of this page. Note also that
                      OlehPay pricing is subject to change. On these occasions, we notify our users.{' '}
                    </p>
                    <p className="startedP">
                      The OlehPay pricing is calculated based on the daily Shaar - the Shaar Yatzig
                      - as a means to enforce objectivity when exchanging currencies. We use the
                      Shaar Yatzig on the day we receive your money, which may be one to several
                      days after you initiate your transfer.
                    </p>
                    <p className="startedP">
                      We strive to offer the least expensive currency exchange service in the market
                      at the highest convenience, and it is our pledge to enforce this. Our team has
                      conducted a great deal of research to identify the cheapest and fastest
                      mechanisms for moving money into Israel and exchanging currencies, and we
                      stand by our claims regarding pricing and exchange rates.
                    </p>

                    <h4 className="startedH4">Current OlehPay Pricing</h4>
                    <p className="startedP">
                      We charge 2% for transfers between our minimum of $300 and $12500.
                    </p>
                    <p className="startedP">
                      We charge 0.7% for transfers between $25,000 and over.
                    </p>
                    <p className="startedP">
                      We charge a sliding scale for transfers between $12,500 and $25,000 which
                      range from 0.7% to 2% depending on currency availability.
                    </p>
                    <p className="startedP">
                      We will match or beat any price quote from any other company (matching only
                      regular rates, excluding promotions, sales, or special discounts).
                    </p>

                    <h3 id="Registration" className="startedH3" style={{ color: '#fbc011' }}>
                      REGISTRATION
                    </h3>

                    <h4 className="startedH4">Getting Started </h4>
                    <p className="startedP">
                      OlehPay is committed to providing you with a smooth and convenient experience
                      from start to finish. Begin the registration process by hitting &quot;Sign
                      In&quot; at the top of the page; you can choose to create an account via
                      email, Facebook, or Google.
                    </p>
                    <br />

                    <h4 className="startedH4">Documents and Information </h4>
                    <p className="startedP">
                      Once you sign up, you&apos;ll be asked to begin uploading your documents and
                      information to set up your account.{' '}
                    </p>
                    <br />
                    <h4 className="startedH4">
                      1<sup>st</sup>Document{' '}
                    </h4>
                    <div className="i-style">
                      <i>
                        If you&apos;re Israeli, you will be required to upload your Teudat Zehut or
                        Israeli passport <br />
                        or
                        <br />
                        if you&apos;re American, and do not hold Israeli citizenship, you&apos;ll be
                        required to upload your US passport.
                      </i>
                    </div>
                    <br />
                    <br />

                    <h4 className="startedH4">
                      2<sup>nd</sup>Document{' '}
                    </h4>
                    <p className="startedP">
                      Your second document can either be a passport (if not already uploaded above),
                      or a driver&apos;s license.
                      <br />
                      After you submit your documents and information, you&apos;ll be all set to add
                      your US and Israeli bank accounts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterHome isHideIntro />
    </div>
  );
};
