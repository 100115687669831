import { useEffect } from 'react';
import { debounce } from '@mui/material';
import useAxios from 'axios-hooks';
import styled from 'styled-components';

import {
  StyledFieldset,
  StyledLegend,
  EditableInput,
  StyledButton,
  SavingContainer,
} from '../../common-components';
import { useIsMount } from '../../../../hooks/useIsMount';

const AliasDetailsForm = styled.div.attrs({
  className: 'AliasDetailsForm',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
`;

export const AliasDetails = ({ alias, setAlias }) => {
  const isFirstRender = useIsMount();
  const [{ loading: aliasIsSaving }, updateAlias] = useAxios(
    {
      url: `/aliases/${alias?.uuid}/`,
      method: 'PATCH',
      data: alias,
    },
    { manual: true },
  );

  useEffect(() => {
    if (isFirstRender) return;
    const debouncedUpdate = debounce(() => {
      updateAlias().catch((error) => {
        if (error.code === 'ERR_CANCELED') return;
      });
    }, 500);
    debouncedUpdate();
    return () => {
      debouncedUpdate.clear();
    };
  }, [alias]);

  const handleFirstNameOnChange = (event) =>
    setAlias((alias) => ({
      ...alias,
      first_name: event.target.value,
    }));

  const handleLastNameOnChange = (event) =>
    setAlias((alias) => ({
      ...alias,
      last_name: event.target.value,
    }));

  return (
    <>
      <StyledFieldset>
        <StyledLegend>Hebrew Name</StyledLegend>
        <AliasDetailsForm>
          <EditableInput
            value={alias?.first_name || ''}
            onChange={handleFirstNameOnChange}
            placeholder="hebrew first name"
          />
          <EditableInput
            value={alias?.last_name || ''}
            onChange={handleLastNameOnChange}
            placeholder="hebrew last name"
          />
        </AliasDetailsForm>
      </StyledFieldset>
      <SavingContainer active={aliasIsSaving}>saving alias...</SavingContainer>
    </>
  );
};
