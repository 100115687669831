import React, { useEffect, useRef } from 'react';
import components from './styled';
import { InputForm } from '../../components/input-form';
import Copy from '../../assets/imgs/icon/copy.svg';
import Messaging from '../../assets/imgs/icon/messaging_icon.svg';
import MoneyStacks from '../../assets/imgs/icon/money_stacks.svg';
import IdCard from '../../assets/imgs/icon/id_card.svg';

const {
  InfoContainer,
  IconBubble,
  RefCode,
  NoReferrals,
  ReferralMetrics,
  SubmitAction,
  ReferralContainer,
  Text,
  WrapTitle,
  ReferralInfo,
  WrapContent,
  ReferralHeader,
} = components;

export function ReferralsContent({ user, referralsResponse, getReferrals }) {
  const containerRef = useRef();
  useEffect(() => {
    getReferrals();
  }, []);

  const copyReferral = () => {
    navigator.clipboard.writeText(user?.uuid);
  };
  return (
    <WrapContent>
      <ReferralHeader>
        <WrapTitle>Referrals</WrapTitle>
        <Text>Invite your friends to OlehPay and earn payouts!</Text>
      </ReferralHeader>
      {/* Sending emails is a paid service therefore leaving event handlers blank until I speak with Sokol bros - Carlos */}
      <ReferralInfo>
        <InfoContainer>
          <IconBubble />
          <img src={Messaging} alt="Messaging" />
          <div>
            <span>Send Invitation</span>&#10;
            <div>Send your referral link to friends and tell them how cool OlehPay is!</div>
          </div>
        </InfoContainer>
        <InfoContainer>
          <IconBubble />
          <img src={MoneyStacks} alt="Money Stacks" />
          <div>
            <span>Registration</span>&#10;
            <div>Let them register to our services using your referral link.</div>
          </div>
        </InfoContainer>
        <InfoContainer>
          <IconBubble />
          <img src={IdCard} alt="Id Card" />
          <div>
            <span>Rewards</span>&#10;
            <div>Let them register to our services using your referral link.</div>
          </div>
        </InfoContainer>
      </ReferralInfo>
      <div>
        <div>
          <WrapTitle>Invite your friends</WrapTitle>
          <Text>
            Insert your friends&apos; email address and send them invitations to join OlehPay!
          </Text>
          <InputForm placeholder="Email Address" />
        </div>
        <div>
          <WrapTitle>Share the referral link</WrapTitle>
          <Text>You can also share your referral link by copying and sending it.</Text>
          <ReferralContainer ref={containerRef}>
            <RefCode>{user?.uuid}</RefCode>
            <SubmitAction onClick={copyReferral}>
              <img src={Copy} alt="Copy" />
            </SubmitAction>
          </ReferralContainer>
        </div>
      </div>
      <NoReferrals>Referral Data Coming Soon</NoReferrals>
    </WrapContent>
  );
}
