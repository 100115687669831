import { useEffect, useState } from 'react';
import { debounce } from '@mui/material';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import Select from 'react-select';
import 'react-widgets/scss/styles.scss';

import { THEME } from '../../../../constants';
import { UploadDocumentWidget } from '../../../../components/upload-document-widget';
import {
  StyledFieldset,
  StyledLegend,
  EditableInput,
  StyledSelect,
  DownArrowIcon,
  StyledButton,
  Detail,
  SavingContainer,
  StyledOption,
} from '../../common-components';
import ICON_ARROW_DOWN from '../../../../assets/imgs/icon/arrow_card_down.svg';
import { useIsMount } from '../../../../hooks/useIsMount';
import states from '../../../registration/address-and-phone/states';

const SELECT_THEME = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral50: '#ccc', // Placeholder color
  },
});

const WrapRegistrationUSStateSelect = styled.div.attrs({
  className: 'WrapRegistrationUSStateSelect',
})<{ isFilledOut: any }>(
  ({ isFilledOut }) => `
    height: 100%;
    width: 100%;
    appearance: none;
    background-color: ${THEME.dark_blue};
    z-index: 1000;
    border-radius: 8px;
    cursor: pointer;

    [class*="-control"],
    [class*="-container"] {
      height: 100% !important;
      width: 100% !important;
      background-color: transparent !important;
      border: none !important;
      cursor: pointer !important;
    }
    [class*="-singleValue"] {
      opacity: 0.8;
      color: rgb(255, 255, 255) !important;
    }
    [class*="-menu"],
    [class*="-ValueContainer"] {
      opacity: 1 !important;
      color: black !important;
    }
    [class*="-control"] {
      box-shadow: none !important;
    }
  `,
);

const BankAccountsForm = styled.div.attrs({
  className: 'BankAccountsForm',
})`
  display: grid;
  gap: 1em;
  margin-bottom: 1em;
`;

const StyledUploadDocumentButton = styled.button.attrs({
  className: 'StyledUploadDocumentButton',
})`
  // height: 70px;
  padding: 1em;
  border-radius: 8px;
  font-family: 'Fontsfree net proxima nova';
  // font-size: 28px;
  // font-weight: 600;
  border: none;
  color: rgb(255, 255, 255);
  margin-bottom: 0.75em;
  width: 100%;
  background-color: rgb(251, 185, 25);
  opacity: 0.95;
  cursor: pointer;
`;

const DocumentPreviewImageContainer = styled.div.attrs({
  className: 'DocumentPreviewImageContainer',
})`
  width: 100%;
  max-height: 100%;
  border-radius: 10px;
  margin-bottom: 1em;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const DocumentPreviewImage = styled.img.attrs({
  className: 'DocumentPreviewImage',
})`
  width: 100%;
  max-height: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const SingleAccountForm = styled.fieldset.attrs({
  className: 'SingleAccountForm',
})`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  border: none;
  margin-top: 1em;
`;

const SelectContainer = styled.div.attrs({
  className: 'SelectContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const RowSeparator = styled.div.attrs({
  className: 'RowSeparator',
})`
  background-color: ${THEME.border_color};
  height: 1px;
  width: 100%;
`;

const AddressForm = styled.div.attrs({
  className: 'AddressForm',
})`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1em;
  margin-bottom: 1em;
`;

const StyledHolderRow = styled.div.attrs({
  className: 'StyledHolderRow',
})`
  margin: 5px;
`;

const IframePreview = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 500px;
`;

const AddressDetails = ({ address, setAddress }) => {
  const isFirstRender = useIsMount();
  const [{ loading: addressIsSaving }, updateAddress] = useAxios(
    {
      url: `/bank_account_billing_addresses/${address?.uuid}/`,
      method: 'PATCH',
      data: { ...address, country: 'US' },
    },
    { manual: true },
  );

  useEffect(() => {
    if (isFirstRender) return;
    const debouncedUpdate = debounce(() => {
      updateAddress().catch((error) => {
        if (error.code === 'ERR_CANCELED') return;
      });
    }, 500);
    debouncedUpdate();
    return () => {
      debouncedUpdate.clear();
    };
  }, [address]);

  const stateSelectOptions = states
    ?.filter((state) => state.code)
    ?.map((state) => ({
      label: state.code,
      value: state.code,
    }));
  
  const stateOption = address?.region
    ? {
        label: address?.region,
        value: address?.region,
      }
    : null;

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  
  const handleStateOptionOnChange = (option) => 
    setAddress({
      ...address,
      region: option?.value,
    });  
  
  const handleStreetNameOnChange = (event) =>
    setAddress({
      ...address,
      street_name: toTitleCase(event.target.value),
    });

  const handleStreetNumberOnChange = (event) =>
    setAddress({
      ...address,
      street_number: event.target.value,
    });

  const handlePostalOnChange = (event) =>
    setAddress({
      ...address,
      postal: event.target.value,
    });

  const handleCityOnChange = (event) =>
    setAddress({
      ...address,
      city: toTitleCase(event.target.value),
    });

  const handleApartmentNumnberOnChange = (event) =>
    setAddress({
      ...address,
      apartment_number: event.target.value,
    });

  return (
    <>
      <AddressForm>
        <EditableInput
          placeholder="Street #"
          value={address?.street_number || ''}
          onChange={handleStreetNumberOnChange}
        />
        <EditableInput
          placeholder="Street Name"
          value={address?.street_name || ''}
          onChange={handleStreetNameOnChange}
        />
        <EditableInput
          placeholder="Apt #"
          value={address?.apartment_number || ''}
          onChange={handleApartmentNumnberOnChange}
        />
        <EditableInput
          placeholder="City"
          value={address?.city || ''}
          onChange={handleCityOnChange}
        />
        <WrapRegistrationUSStateSelect isFilledOut={address?.region}>
          <Select
            value={stateOption}
            blurInputOnSelect={true}
            options={stateSelectOptions}
            maxMenuHeight={180}
            placeholder="State"
            theme={(theme) => SELECT_THEME(theme)}
            onChange={(option) => handleStateOptionOnChange(option)}
            isClearable={true}
          ></Select>
        </WrapRegistrationUSStateSelect>
        <EditableInput
          placeholder="Postal Code"
          value={address?.postal || ''}
          onChange={handlePostalOnChange}
        />
      </AddressForm>
      <SavingContainer active={addressIsSaving}>saving address...</SavingContainer>
    </>
  );
};

const BankAccountRow = ({
  user,
  getBankAccountDocuments,
  banks,
  bankAccount,
  setBankAccount,
  bankAccountDocument,
  handleRemoveBankAccount,
  setSavingIsActive,
  bankAccountBillingAddress,
  setBankAccountBillingAddress,
  bankAccountHolders,
}) => {
  const isFirstRender = useIsMount();
  const [uploadDocumentWidgetIsOpen, setUploadDocumentWidgetIsOpen] = useState(false);
  const [verificationDocumentFileData, setVerificationDocumentFileData] = useState(null);
  const [isPdf, setShowPdf] = useState(false);
  const [deleteButtonIsEnabled, setDeleteButtonIsEnabled] = useState(true);
  const [verificationButtonIsEnabled, setVerificationButtonIsEnabled] = useState(true);
  const [branches, setBranches] = useState(null);
  const [{ data: branchesResponse, loading: branchesResponseIsLoading }, getBranches] = useAxios(
    `/branches/?size=400&bank__uuid=${bankAccount?.bank?.uuid}`,
  );
  const [{ data: unityfiFundingAccountResponse }, getUnityfiFundingAccount] = useAxios(
    `/unityfi_funding_accounts/?bank_account__uuid=${bankAccount.uuid}`,
  );
  const [, deleteBankAccount] = useAxios(
    {
      url: `/bank_accounts/${bankAccount?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  );
  const [{ loading: bankAccountIsSaving }, updateBankAccount] = useAxios(
    {
      url: `/bank_accounts/${bankAccount?.uuid}/`,
      method: 'PATCH',
    },
    { manual: true },
  );
  const [{ loading: verificationDocumentIsSaving }, updateVerificationDocument] = useAxios(
    {
      url: `/documents/${bankAccountDocument?.document?.uuid}/`,
      method: 'PATCH',
    },
    { manual: true },
  );
  const [
    { data: newBankAccountDocumentResponse, loading: newBankAccountDocumentIsLoading },
    postNewBankAccountDocument,
  ] = useAxios(
    {
      url: '/bank_account_documents/',
      method: 'POST',
    },
    { manual: true },
  );

  useEffect(() => {
    setBranches(branchesResponse?.results);
  }, [branchesResponse]);

  useEffect(() => {
    if (bankAccount?.branch) return;
    if (!branches?.length) return;
    const branch = branches[0];
    setBankAccount({
      ...bankAccount,
      branch: branch,
      routing_number: `${bankAccount?.bank?.code}-${branch?.code}`,
    });
  }, [branches]);

  const unityfiFundingAccount = unityfiFundingAccountResponse?.results[0];

  const bankOptions = banks?.map((bank, index) => (
    <StyledOption key={index} value={bank.uuid}>
      {bankAccount?.country?.alpha_2 === 'US' ? bank.name : `${bank.code} - ${bank.name}`}
    </StyledOption>
  ));

  const branchOptions = branches?.map((branch, index) => (
    <StyledOption key={index} value={branch.uuid}>
      {branch.code} - {branch.name}
    </StyledOption>
  ));

  const typeOptions = ['CHECKING', 'SAVINGS', 'CERTIFICATE_OF_DEPOSIT', 'MONEY_MARKET_DEPOSIT'].map(
    (option) => (
      <StyledOption key={option} value={option}>
        {option}
      </StyledOption>
    ),
  );

  const handleBankOnChange = (event) => {
    const bank = banks?.find((bank) => bank.uuid === event.target.value);
    if (bankAccount?.country?.alpha_2 === 'US')
      setBankAccount({
        ...bankAccount,
        bank: bank,
      });
    else
      setBankAccount({
        ...bankAccount,
        bank: bank,
        branch: null,
        routing_number: `${bank?.code}-___`,
      });
  };

  const handleBranchOnChange = (event) => {
    const branch = branches?.find((branch) => branch.uuid === event.target.value);
    setBankAccount({
      ...bankAccount,
      branch: branch,
      routing_number: `${bankAccount?.routing_number.split('-')[0]}-${branch?.code}`,
    });
  };

  const handleTypeOnChange = (event) =>
    setBankAccount({
      ...bankAccount,
      type: event.target.value,
    });

  const handleRoutingNumberOnChange = (event) => {
    setBankAccount({
      ...bankAccount,
      routing_number: event.target.value,
    });
  };

  const handleAccountNumberOnChange = (event) =>
    setBankAccount({
      ...bankAccount,
      account_number: event.target.value,
    });

  const handleVerifyAccount = (event) => {
    setVerificationButtonIsEnabled(false);
    setDeleteButtonIsEnabled(false);
    setSavingIsActive(true);
    updateBankAccount({
      data: {
        is_verified: !bankAccount.is_verified,
      },
    })
      .then((response) => {
        setBankAccount({
          ...bankAccount,
          is_verified: !bankAccount?.is_verified,
        });
        setSavingIsActive(false);
        setDeleteButtonIsEnabled(true);
        setVerificationButtonIsEnabled(true);
        getUnityfiFundingAccount();
      })
      .catch((error) => {
        setSavingIsActive(false);
        setDeleteButtonIsEnabled(true);
        setVerificationButtonIsEnabled(true);
        const data = error?.response?.data;
        if (!data) return console.log(error);
        let errorMessage = '';
        for (const key in data) {
          data[key].forEach((message) => {
            errorMessage += `[${key}]: ${message}\n`;
          });
        }
        alert(errorMessage);
      });
  };

  const handleDeleteOnClick = () => {
    if (!window.confirm(`are you sure you want to delete ${bankAccount?.name}?`)) return;
    setDeleteButtonIsEnabled(false);
    deleteBankAccount().then((response) => {
      handleRemoveBankAccount();
      setDeleteButtonIsEnabled(true);
    });
  };

  useEffect(() => {
    if (isFirstRender) return;

    if (!bankAccountDocument?.document) {
      const fileName =
        verificationDocumentFileData?.image?.name || `${bankAccount?.name}.verification.jpg`;
      const bodyFormData = new FormData();

      if (!verificationDocumentFileData?.blob) return;

      bodyFormData.append('file', verificationDocumentFileData?.blob, fileName);
      bodyFormData.append('user', user?.uuid);
      bodyFormData.append('type', 'BANK_VERIFICATION');
      bodyFormData.append('bank_account', bankAccount?.uuid);

      postNewBankAccountDocument({
        method: 'post',
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .catch((error) => console.log(error))
        .then((response) => {
          console.log(response);
          getBankAccountDocuments();
        });
    } else {
      const fileName =
        verificationDocumentFileData?.image?.name || `${bankAccount?.name}.verification.jpg`;
      const verificationForm = new FormData();

      if (!verificationDocumentFileData?.blob) return;

      verificationForm.append('file', verificationDocumentFileData?.blob, fileName);
      verificationForm.append('type', 'BANK_VERIFICATION');
      verificationForm.append('bank_account', bankAccount?.uuid);

      updateVerificationDocument({
        data: verificationForm,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).catch((error) => console.log(error));
    }
  }, [verificationDocumentFileData?.blob]);

  useEffect(() => {
    if (isFirstRender) return;
    setVerificationButtonIsEnabled(false);
    setDeleteButtonIsEnabled(false);
    const debouncedUpdate = debounce(() => {
      updateBankAccount({
        data: {
          bank: bankAccount?.bank?.uuid,
          branch: bankAccount?.branch?.uuid,
          routing_number: bankAccount?.routing_number,
          account_number: bankAccount?.account_number,
          type: bankAccount?.type,
        },
      })
        .then((response) => {
          setVerificationButtonIsEnabled(true);
          setDeleteButtonIsEnabled(true);
        })
        .catch((error) => {
          if (error.code === 'ERR_CANCELED') return;
        });
    }, 500);
    debouncedUpdate();
    return () => {
      debouncedUpdate.clear();
    };
  }, [
    bankAccount?.bank,
    bankAccount?.branch,
    bankAccount?.routing_number,
    bankAccount?.account_number,
    bankAccount?.type,
  ]);

  useEffect(() => {
    setSavingIsActive(bankAccountIsSaving || verificationDocumentIsSaving);
  }, [bankAccountIsSaving, verificationDocumentIsSaving]);

  const handleImageOnClick = () => {
    window.open(bankAccountDocument?.document?.file, '_blank');
  };

  const holderRows = bankAccountHolders?.map((holder) => (
    <StyledHolderRow key={holder.uuid}>{holder.name}</StyledHolderRow>
  ));

  return (
    <>
      <SingleAccountForm>
        <legend>{bankAccount?.name}</legend>
        {(verificationDocumentFileData?.document &&
          verificationDocumentFileData?.document !== '') ||
        bankAccountDocument?.document?.file ? (
          <DocumentPreviewImageContainer style={{ gridColumn: 'span 2' }}>
            {bankAccountDocument?.document?.file_metadata?.content_type === 'application/pdf' ||
            verificationDocumentFileData?.image?.type === 'application/pdf' ? (
              <IframePreview
                src={bankAccountDocument?.document?.file || verificationDocumentFileData?.document}
                frameBorder="0"
              ></IframePreview>
            ) : (
              <DocumentPreviewImage
                onClick={handleImageOnClick}
                src={verificationDocumentFileData?.document || bankAccountDocument?.document?.file}
              />
            )}
          </DocumentPreviewImageContainer>
        ) : null}
        <StyledUploadDocumentButton
          style={{ gridColumn: 'span 2' }}
          onClick={() => setUploadDocumentWidgetIsOpen(true)}
          disabled={false}
        >
          upload document
        </StyledUploadDocumentButton>
        <UploadDocumentWidget
          isPdf={isPdf}
          setShowPdf={setShowPdf}
          image={verificationDocumentFileData?.image}
          previewURL={verificationDocumentFileData?.previewURL}
          uploadDocumentWidgetIsOpen={uploadDocumentWidgetIsOpen}
          setImage={(image) =>
            setVerificationDocumentFileData((fileData) => ({
              ...fileData,
              image,
            }))
          }
          setPreviewURL={(previewURL) =>
            setVerificationDocumentFileData((fileData) => ({
              ...fileData,
              previewURL,
            }))
          }
          setSelectedDocument={(document) =>
            setVerificationDocumentFileData((fileData) => ({
              ...fileData,
              document,
            }))
          }
          setSelectedDocumentBlob={(blob) =>
            setVerificationDocumentFileData((fileData) => ({
              ...fileData,
              blob,
            }))
          }
          closeUploadDocumentWidget={() => setUploadDocumentWidgetIsOpen(false)}
        />
        <SelectContainer>
          <StyledSelect onChange={handleBankOnChange} value={bankAccount?.bank?.uuid || ''}>
            <StyledOption value=""></StyledOption>
            {bankOptions}
          </StyledSelect>
          <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
        </SelectContainer>
        {bankAccount?.country?.alpha_2 === 'IL' && branchesResponseIsLoading ? (
          <Detail>loading branches...</Detail>
        ) : bankAccount?.country?.alpha_2 === 'IL' ? (
          <SelectContainer>
            <StyledSelect onChange={handleBranchOnChange} value={bankAccount?.branch?.uuid || ''}>
              <StyledOption value=""></StyledOption>
              {branchOptions}
            </StyledSelect>
            <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
          </SelectContainer>
        ) : unityfiFundingAccount ? (
          <Detail>UnityFi ID: {unityfiFundingAccount?.funding_account_id}</Detail>
        ) : (
          <span></span>
        )}
        {bankAccount?.country?.alpha_2 === 'US' && (
          <>
            <SelectContainer>
              <StyledSelect onChange={handleTypeOnChange} value={bankAccount?.type || ''}>
                <StyledOption value=""></StyledOption>
                {typeOptions}
              </StyledSelect>
              <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
            </SelectContainer>
            <span></span>
          </>
        )}
        {bankAccount?.country?.alpha_2 === 'IL' ? (
          <Detail>{bankAccount?.routing_number || 'Routing #'}</Detail>
        ) : (
          <EditableInput
            placeholder="Routing #"
            value={bankAccount?.routing_number || ''}
            onChange={handleRoutingNumberOnChange}
          />
        )}
        <EditableInput
          placeholder="Account #"
          value={bankAccount?.account_number || ''}
          onChange={handleAccountNumberOnChange}
        />
        {bankAccount?.country?.alpha_2 === 'US' && (
          <span style={{ gridColumn: 'span 2' }}>
            <legend>US Billing Address</legend>
            <AddressDetails
              address={bankAccountBillingAddress}
              setAddress={setBankAccountBillingAddress}
            />
          </span>
        )}
        {bankAccountHolders.length >= 1 && (
          <span style={{ gridColumn: 'span 2' }}>
            <legend>Holders</legend>
            {holderRows}
          </span>
        )}
        <StyledButton
          transparent={!bankAccount?.is_verified}
          onClick={handleVerifyAccount}
          disabled={!verificationButtonIsEnabled}
        >
          {bankAccount?.is_verified ? 'verified' : 'verify account'}
        </StyledButton>
        <StyledButton onClick={handleDeleteOnClick} disabled={!deleteButtonIsEnabled}>
          delete
        </StyledButton>
      </SingleAccountForm>
      <RowSeparator />
    </>
  );
};

export const BankAccountsDetails = ({
  country,
  banks,
  bankAccounts,
  setBankAccounts,
  bankAccountDocuments,
  bankAccountHolders,
  bankAccountBillingAddresses,
  setBankAccountBillingAddresses,
  user,
  getBankAccountDocuments,
}) => {
  const [savingIsActive, setSavingIsActive] = useState(false);

  const handleRemoveBankAccount = (bankAccountToDelete) =>
    setBankAccounts((bankAccounts) =>
      bankAccounts?.filter((bankAccount) => bankAccount?.uuid !== bankAccountToDelete?.uuid),
    );

  const bankAccountRows = bankAccounts?.map((bankAccount, index) => (
    <BankAccountRow
      key={index}
      banks={banks}
      user={user}
      getBankAccountDocuments={getBankAccountDocuments}
      bankAccount={bankAccount}
      bankAccountDocument={bankAccountDocuments?.find(
        (bankAccountDocument) =>
          bankAccountDocument.bank_account === bankAccount.uuid &&
          bankAccountDocument.type === 'BANK_VERIFICATION',
      )}
      bankAccountBillingAddress={bankAccountBillingAddresses?.find(
        (bankAccountBillingAddress) => bankAccountBillingAddress.bank_account === bankAccount.uuid,
      )}
      setBankAccount={(bankAccount) =>
        setBankAccounts((bankAccounts) => {
          const items = [...bankAccounts];
          items[index] = bankAccount;
          return items;
        })
      }
      setBankAccountBillingAddress={(bankAccountBillingAddress) =>
        setBankAccountBillingAddresses((bankAccountBillingAddresses) => {
          const items = [...bankAccountBillingAddresses];
          const indexOfAddress = bankAccountBillingAddresses.findIndex(
            (address) => address.uuid === bankAccountBillingAddress.uuid,
          );
          items[indexOfAddress] = bankAccountBillingAddress;
          return items;
        })
      }
      bankAccountHolders={bankAccountHolders?.filter(
        (bankAccountHolder) => bankAccountHolder.bank_account === bankAccount.uuid,
      )}
      handleRemoveBankAccount={() => handleRemoveBankAccount(bankAccount)}
      setSavingIsActive={setSavingIsActive}
    />
  ));

  if (!bankAccounts?.length) return;

  return (
    <>
      <StyledFieldset>
        <StyledLegend>{country === 'IL' ? 'Israeli' : 'American'} Bank Accouts</StyledLegend>
        <BankAccountsForm>{bankAccountRows}</BankAccountsForm>
      </StyledFieldset>
      <SavingContainer active={savingIsActive}>saving bank account...</SavingContainer>
    </>
  );
};
