import styled from 'styled-components';
import useAxios from 'axios-hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Logo from '../../assets/imgs/logo.svg';
import { useEffect, useRef, useState } from 'react';
import { THEME } from '../../constants';
import { Loading } from '../../components/loading';

import axios from 'axios';
import FacebookLogin from '@greatsumini/react-facebook-login';

import { useGoogleLogin } from '@react-oauth/google';
import { debounce } from '@mui/material';

const Wrapper = styled.div`
  background-color: #171c2d;
  height: 100vh;
  width: 100vw;
  overflow: auto;
`;

const SignUpPanel = styled.div`
  width: calc(100% - 20px);
  max-width: 386px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const StyledImage = styled.img.attrs({
  className: 'StyledImage',
})`
  width: 200px;
  margin: 0 auto;
  display: block;
`;

const RegistrationEmailContainer = styled.div.attrs({
  className: 'RegistrationEmailContainer',
})``;

const SocialAuthButtonsContainer = styled.div.attrs({
  className: 'SocialAuthButtonsContainer',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding: 10px 1px;
  padding-top: 5px;
`;

const StyledSocialAuthButton = styled.button.attrs({
  className: 'StyledSocialAuthButton',
})<{ provider: any }>(
  ({ provider }) => `
    color: hsla(0, 0%, 100%, 0.53);
    font-weight: 400;
    background-color: ${provider === 'google' ? '#4285f4' : '#3b5998'};
    min-height: 42px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
 `,
);

const StyledHeader = styled.h1.attrs({
  className: 'StyledHeader',
})`
  text-align: center;
  padding-left: 0px;
  display: block;
  color: #fff;
  font-size: 35px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 18px;
  font-weight: 900;
`;

const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})<{ isFilledOut: any }>(
  ({ isFilledOut }) => `
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 20.5px 12px;
  margin-bottom: 12px;
  height: 70px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
  cursor: text;
  width: 100%;
  border: 1px solid ${isFilledOut ? 'rgb(251, 185, 25)' : 'rgb(204, 204, 204)'};
  // border: 1px solid ${isFilledOut ? 'blue' : 'red'};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &.h-70 {
    min-height: 70px;
  }
  &:visited {
    border: 1px solid #fbb919;
    opacity: 0.8;
    outline: none;
  }
  &:focus,
  &:active,
  &:hover {
    // border: 1px solid #fbb919;
    // border-color: rgb(251, 185, 25);
    outline: none;
    opacity: 1;
  }
`,
);

const WrapperFlex = styled.div`
  display: flex;
  button {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
  }
`;

const StyledRegistrationButton = styled.button.attrs({
  className: 'StyledRegistrationButton',
})<{ disabled }>(
  ({ disabled }) => `
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    font-family: 'Fontsfree net proxima nova';
    font-size: 28px;
    font-weight: 500;
    border: none;
    color: rgb(255, 255, 255);
    margin-left: 0;
    width: 100%;
    background-color: ${disabled ? '#cccccc' : 'rgb(251, 185, 25)'};
    opacity: ${disabled ? 0.8 : 0.95};
    cursor: ${disabled ? 'default' : 'pointer'};
  `,
);

const MessageHintContainer = styled.div.attrs({
  className: 'MessageHintContainer',
})<{ color: any }>(
  ({ color }) => `
    min-height: 30px;
    color: ${color};
    padding: 0 0 10px 0;
    margin: 0 5px;
  `,
);

const TextContainer = styled.div.attrs({
  className: 'TextContainer',
})`
  margin-top: 15px;
  padding-right: 11px;
  padding-left: 11px;
  font-family: 'Fontsfree net proxima nova';
  font-weight: 300;
  color: hsla(0deg, 0%, 100%, 0.53);
  line-height: 22px;
  padding-bottom: 22px;
`;

const YellowText = styled.span.attrs({
  className: 'YellowText',
})`
  color: ${THEME.yellow};
`;

const RegistrationEmail = ({ setToken }) => {
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<any>('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [resgisterButtonIsEnabled, setRegisterButtonIsEnabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const referrer = searchParams.get('referrer');
  const leadSource = localStorage.getItem('leadSource')

  const [{ loading: loginIsLoading }, login] = useAxios(
    {
      url: '/tokens/session/',
      method: 'POST',
    },
    { manual: true },
  );
  const [{ loading: loadingValidateEmail }, validateEmail] = useAxios(
    {
      url: 'authorization/validate_email/',
      method: 'post',
      data: {
        email: email,
      },
    },

    { manual: true, autoCancel: false },
  );
  const [{ loading: loadingValidatePassword }, validatePassword] = useAxios(
    {
      url: 'authorization/validate_password/',
      method: 'post',
      data: {
        password1: password1,
        password2: password2,
      },
    },
    { manual: true, autoCancel: false },
  );
  const [{ loading: registerNewUserIsLoading }, registerNewUser] = useAxios(
    {
      url: 'registration/new_user/',
      method: 'post',
      data: {
        email: email,
        password: password1,
        referrer: referrer,
        promotion: leadSource,
      },
    },
    { manual: true },
  );

  const [{ loading: loadingSocial }, loginAsSocial] = useAxios(
    {
      url: '/login/social/token_user/',
      method: 'POST',
    },
    { manual: true },
  );

  const [{ loading: loadingUserInfo }, getUserByuuid] = useAxios(
    {
      url: '',
      method: 'GET',
    },
    { manual: true },
  );
  const [{data}, postLead] = useAxios(
    {
      url: '/leads/',
      method: 'POST',
      data: {
        promotion: leadSource
      }
    }, 
    { manual: true },
  )

  const responseFacebook = async (response) => {
    console.log(JSON.parse(atob(response.signedRequest.split('.')[1])));
    const code = JSON.parse(atob(response.signedRequest.split('.')[1])).code;
    try {
      const { data } = await loginAsSocial({
        data: {
          provider: 'facebook',
          code: code,
          redirect_uri: 'postmessage',
        },
      });
      if (data && data?.token) {
        if (data && data?.token) {
          const { data: userData } = await getUserByuuid({
            url: '/users/' + data.uuid,
            headers: {
              authorization: 'Token ' + data?.token,
            },
          });

          setToken({
            token: data?.token,
            user: userData,
          });
          if (userData?.is_superuser) return (window.location.href = '/transfers');
          else if (userData?.is_registered) return (window.location.href = '/account');
          else return (window.location.href = '/join');
        }
        if (data?.user?.is_superuser) return (window.location.href = '/transfers');
        else if (data?.user?.is_registered) return (window.location.href = '/account');
        else return (window.location.href = '/join');
      }
    } catch (error) {
      console.log(error);
      // window.location.href = '/signup';
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      try {
        const { data } = await loginAsSocial({
          data: {
            provider: 'google-oauth2',
            code: codeResponse.code,
            redirect_uri: 'postmessage',
          },
        });
        if (data && data?.token) {
          const { data: userData } = await getUserByuuid({
            url: '/users/' + data.uuid,
            headers: {
              authorization: 'Token ' + data?.token,
            },
          });
          await setToken({
            token: data?.token,
            user: userData,
          });
          if (leadSource) await postLead();
          if (userData?.is_superuser) return (window.location.href = '/transfers');
          else if (userData?.is_registered) return (window.location.href = '/account');
          else return (window.location.href = '/join');
        }
      } catch (error) {
        console.log(error);
        // window.location.href = '/signup';
      }
    },
    flow: 'auth-code',
    select_account: true,
  });

  useEffect(() => {
    document.body.style.overscrollBehavior = 'none';

    return () => {
      document.body.style.overscrollBehavior = '';
    };
  }, []);

  useEffect(() => {
    if (!email) return setEmailErrorMessage('');

    validateEmail()
      .then((response) => {
        if (response.data === 'ok') {
          setEmailErrorMessage(null);
          setEmailIsValid(true);
        } else if (response.data === 'email exists') {
          setEmailErrorMessage(
            <span>
              Email already exists, log in{' '}
              <a href="signin" style={{ textDecoration: 'underline', color: 'red' }}>
                {' '}
                here
              </a>{' '}
              instead.
            </span>,
          );
          setEmailIsValid(false);
        } else {
          setEmailErrorMessage(response.data);
          setEmailIsValid(false);
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_CANCELED') return;
      });
  }, [email]);

  useEffect(() => {
    if (!password1 || !password2) return setPasswordErrorMessage('');

    validatePassword()
      .then((response) => {
        if (response.data === 'ok') {
          setPasswordErrorMessage(null);
          setPasswordIsValid(true);
        } else {
          const messages = JSON.parse(response.data);
          setPasswordErrorMessage(messages.join(' '));
          setPasswordIsValid(false);
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_CANCELED') return;
      });
  }, [password1, password2]);

  useEffect(() => {
    if (loadingValidateEmail) setRegisterButtonIsEnabled(false);
    else setRegisterButtonIsEnabled(emailIsValid && passwordIsValid);
  }, [loadingValidateEmail]);

  useEffect(() => {
    if (loadingValidatePassword) setRegisterButtonIsEnabled(false);
    else setRegisterButtonIsEnabled(emailIsValid && passwordIsValid);
  }, [loadingValidatePassword]);

  useEffect(() => {
    if (emailIsValid && passwordIsValid) setRegisterButtonIsEnabled(true);
    else setRegisterButtonIsEnabled(false);
  }, [emailIsValid, passwordIsValid]);

  const handleRegisterOnClick = () => {
    setShowLoader(true);
    registerNewUser().then((response) => {
      onLogin();
    });
  };

  const onLogin = async () => {
    try {
      setRegisterButtonIsEnabled(false);
      const { data } = await login({
        data: {
          username: email,
          password: password1,
        },
      });
      if (data && data?.token) {
        setToken(data);
        window.location.href = '/join';
      }
    } catch (error: any) {
      // setError(error.response.data.non_field_errors.join(' '));
    }
  };

  const responseGoogle = (response) => {
    console.log(response);
  };

  if (showLoader) return <Loading />;

  return (
    <RegistrationEmailContainer>
      <StyledHeader>Signup with OlehPay</StyledHeader>
      <WrapperFlex>
        <button
          className="auth0-lock-social-button"
          id="google"
          data-provider="google"
          type="button"
          onClick={() => googleLogin()}
        >
          Sign up with Google
        </button>
        <a
          href={`${process.env.REACT_APP_OLEHPAY_ENDPOINT}/login/facebook/`}
          className="auth0-lock-social-button facebook-btn"
          id="facebook"
          data-provider="facebook"
          type="button"
        >
          Sign up with Facebook
        </a>
        {/* <FacebookLogin
          useRedirect
          appId={process.env.REACT_APP_FACEBOOK_APPID}
          // fields="name,email"
          onSuccess={responseFacebook}
          onFail={(error) => {
            console.log('Login facebook Failed!', error);
          }}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              className="auth0-lock-social-button facebook-btn"
              id="facebook"
              data-provider="facebook"
              type="button"
            >
              Sign up with Facebook
            </button>
          )}
        /> */}

        {/* <GoogleLogin
          clientId="17966651723-oa732l02vfkk7p627dvaj64vl5a1ohvd.apps.googleusercontent.com"
          render={(renderProps) => (
            <StyledSocialAuthButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              provider="google"
            >
              Sign up with Google
            </StyledSocialAuthButton>
          )}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        /> */}
        {/* <StyledSocialAuthButton provider="google">Sign up with Google</StyledSocialAuthButton> */}
        {/* <StyledSocialAuthButton provider="facebook">Sign up with Facebook</StyledSocialAuthButton> */}
      </WrapperFlex>
      <StyledInput
        placeholder="Email"
        id="email-signup"
        value={email}
        onChange={(event) => setEmail(event.target.value.toLowerCase())}
        isFilledOut={email}
      />
      <StyledInput
        placeholder="Password"
        type="password"
        id="password-signup"
        onChange={(event) => setPassword1(event.target.value)}
        isFilledOut={password1}
      />
      <StyledInput
        placeholder="Confirm Password"
        type="password"
        id="confirm-password-signup"
        onChange={(event) => setPassword2(event.target.value)}
        isFilledOut={password2}
      />
      <MessageHintContainer
        color={
          loadingValidateEmail || loadingValidatePassword
            ? 'hsla(0deg, 0%, 100%, 0.53)'
            : emailIsValid && passwordIsValid
            ? 'green'
            : 'red'
        }
      >
        {loadingValidateEmail || loadingValidatePassword
          ? 'checking...'
          : emailErrorMessage ||
            passwordErrorMessage ||
            (emailIsValid && passwordIsValid
              ? 'Great email! Strong password!'
              : emailIsValid
              ? 'Email ok but invalid password.'
              : passwordIsValid
              ? 'Password ok but invalid email.'
              : null)}
      </MessageHintContainer>
      <StyledRegistrationButton
        onClick={handleRegisterOnClick}
        disabled={!resgisterButtonIsEnabled || loadingSocial || loadingUserInfo}
      >
        Continue to Registration
      </StyledRegistrationButton>
      <TextContainer>
        Our dedicated team is here to help. Once you signup,{' '}
        <YellowText>we&apos;ll have a team member reach out right away! </YellowText>
        <br />
        <br />
        Already have an account?{' '}
        <a href="/signin">
          <YellowText>Login here.</YellowText>
        </a>
      </TextContainer>
    </RegistrationEmailContainer>
  );
};

export const SignUp = ({ setToken }) => {
  return (
    <Wrapper>
      <SignUpPanel>
        <StyledImage src={Logo} alt="logo" />
        <RegistrationEmail setToken={setToken} />
      </SignUpPanel>
    </Wrapper>
  );
};
