import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = tokenString;
    return userToken;
  };

  const getUser: {
    is_superuser: boolean,
  } = () => {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return {};
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (data) => {
    if (!data) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      setUser(null);
      setToken(null);
      return;
    } else {
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
    }

    setUser(data.user);
    setToken(data.token);
  };

  const removeToken = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  return {
    setToken: saveToken,
    removeToken,
    token,
    user,
  };
}
