import useAxios from "axios-hooks";

import { StyledStatusButton } from "./status-buttons-style";

const PendingButton = ({
  row,
  getPendingTransfers,
  getProcessingTransfers,
  isUpdatingData,
  setIsUpdatingData,
}) => {
  const [{ loading: loadingSubmit }, submitTransfer] = useAxios(
    {
      url: `/transfers/${row?.uuid}/submit/`,
      method: "POST",
    },
    { manual: true }
  );

  // const buttonIsDisabled = !row.is_processable || loadingSubmit || isUpdatingData
  const buttonIsDisabled = !row.is_processable || loadingSubmit;

  const handleOnClick = () => {
    if (buttonIsDisabled) {
      return;
    }

    console.log("SUBMIT", row?.number, row?.uuid, row?.status);

    setIsUpdatingData(true);
    submitTransfer()
      .then((response) => {
        getPendingTransfers();
        getProcessingTransfers();
        setIsUpdatingData(false);
      })
      .catch((error) => {
        setIsUpdatingData(false);
        const data = error?.response?.data;
        if (!data) return console.log(error);
        let errorMessage = "";
        if (typeof data === "string") {
          errorMessage = data;
          alert(errorMessage);
          return;
        }
        for (const key in data) {
          // eslint-disable-next-line no-loop-func
          data[key].forEach((message) => {
            errorMessage += `[${key}]: ${message}\n`;
          });
        }
        alert(errorMessage);
      });
  };

  return (
    <StyledStatusButton onClick={handleOnClick} disabled={buttonIsDisabled}>
      {row?.status}
    </StyledStatusButton>
  );
};

export default PendingButton;
