import React, { FC } from 'react';
import { THEME } from '../../constants';

import styled from 'styled-components';

const WrapperButton: any = styled.button`
  margin-left: 15px;
  min-width: 120px;
  cursor: pointer;
  height: auto;
  padding: 16px;
  border: 1px solid ${THEME.yellow};
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 100%;
  opacity: 0.95;
  background: ${(props: any) => (props.isBorder ? 'transparent' : THEME.yellow)};
  color: ${(props: any) => (props.isBorder ? THEME.yellow : THEME.white)};
  & :hover {
    opacity: 1;
  }
  & :disabled {
    background-color: #cccccc;
    border-color: transparent;
    opacity: 0.8 !important;
    cursor: default;
  }
`;

interface button {
  typeButton?: any;
  onClick?: any;
  className?: string;
  label?: string;
  disabled?: boolean;
  style?: any;
  isBorder?: boolean;
}

export const Button: FC<button> = ({
  typeButton = 'button',
  onClick,
  className,
  label,
  disabled,
  style,
  isBorder,
}) => {
  return (
    <>
      <WrapperButton
        type={typeButton}
        onClick={onClick}
        className={className}
        disabled={disabled}
        style={style}
        isBorder={isBorder}
      >
        {label}
      </WrapperButton>
    </>
  );
};
