import styled from 'styled-components';
import { useState, useEffect } from 'react';

import {
  ClickHereContainer,
  DocumentPreviewImage,
  DocumentPreviewImageContainer,
  DocumentTypeContainer,
  IframePreview,
  ResidencyDocumentFormContainer,
  SkipTextContainer,
  StyledUploadDocumentButton,
} from './residency-verification.style'

import {
  SubtitleContainer,
  StyledFooter,
  StyledNextButton,
  StyledSelect,
  DownArrowIcon,
} from '../common-components';
import { UploadDocumentWidget } from '../../../components/upload-document-widget';
import ICON_ARROW_DOWN from '../../../assets/imgs/icon/arrow_card_down.svg';


export const RegistrationResidencyVerification = ({
  title,
  handleNextOnClick,
  residencyDocument,
  residencyDocumentFileData,
  setResidencyDocumentFileData,
  nextButtonIsEnabled,
  setNextButtonIsEnabled,
  setCurrentStep,
}) => {
  const [uploadDocumentWidgetIsOpen, setUploadDocumentWidgetIsOpen] = useState(false);
  const [isPdf, setShowPdf] = useState(false);
  const [buttonTitle, setButtonTitle] = useState(
    residencyDocumentFileData?.document || residencyDocument?.file
      ? 'Document Uploaded'
      : 'Upload Document',
  );
  
  useEffect(() => {
    if (residencyDocumentFileData?.blob || residencyDocument?.file) setNextButtonIsEnabled(true);
    else setNextButtonIsEnabled(false);
  }, [residencyDocument, residencyDocumentFileData]);

  const handleUploadOneLaterOnClick = () => {
    setCurrentStep((step) => step += 1);
  }

  return (
    <>
      <SubtitleContainer>
        Upload a student or work visa, Israeli utility bill, or army Ishur to demonstrate connection
        to Israel.
      </SubtitleContainer>

      {(residencyDocumentFileData?.document && residencyDocumentFileData?.document !== '') ||
      residencyDocument?.file ? (
        <DocumentPreviewImageContainer>
          {residencyDocument?.file_metadata?.content_type === 'application/pdf' ||
          residencyDocumentFileData?.image?.type === 'application/pdf' ? (
            <IframePreview
              src={residencyDocument?.file || residencyDocumentFileData?.previewURL}
              frameBorder="0"
            ></IframePreview>
          ) : (
            <DocumentPreviewImage
              src={residencyDocumentFileData?.document || residencyDocument?.file}
            />
          )}
        </DocumentPreviewImageContainer>
      ) : (
        ''
      )}
      <ResidencyDocumentFormContainer>
        <StyledUploadDocumentButton
          onClick={() => setUploadDocumentWidgetIsOpen(true)}
          onMouseEnter={() =>
            setButtonTitle(
              residencyDocumentFileData?.document || residencyDocument?.file
                ? 'Upload Different Document'
                : 'Upload Document',
            )
          }
          onMouseLeave={() =>
            setButtonTitle(
              residencyDocumentFileData?.document || residencyDocument?.file
                ? 'Document Uploaded'
                : 'Upload Document',
            )
          }
          disabled={false}
        >
          {buttonTitle}
        </StyledUploadDocumentButton>
        <DocumentTypeContainer>
          <StyledSelect
            style={{ borderColor: '#fbb919' }}
            // onChange={handleCountryOnChange}
            // value={identification?.country || (entity === 'ISRAELI_CITIZEN' ? 'IL' : 'US')}
          >
            <option>Visa</option>
            <option>Army Ishur</option>
            <option>Arnona Bill</option>
            <option>Utility Bill</option>
            <option>Other Official Government Document</option>
          </StyledSelect>
          <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
        </DocumentTypeContainer>
        <SkipTextContainer>
          Don't have a document right now? Click {' '}
          <ClickHereContainer onClick={handleUploadOneLaterOnClick}>
            here
          </ClickHereContainer>
          {' '}to skip this step and upload later.
        </SkipTextContainer>
        <StyledNextButton onClick={handleNextOnClick} disabled={!nextButtonIsEnabled}>
          Next
        </StyledNextButton>
      </ResidencyDocumentFormContainer>
      <StyledFooter>
        Because you are not an Israeli citizen, we need to demonstrate your association with Israel.
        We can demonstrate your association by a student visa, utility bill, army ishur, or any
        other official government document with your name on it. Note: we cannot accept a rental
        contract as an association with Israel.
      </StyledFooter>
      <UploadDocumentWidget
        isPdf={isPdf}
        setShowPdf={setShowPdf}
        image={residencyDocumentFileData?.image}
        previewURL={residencyDocumentFileData?.previewURL}
        uploadDocumentWidgetIsOpen={uploadDocumentWidgetIsOpen}
        setImage={(image) =>
          setResidencyDocumentFileData((fileData) => ({
            ...fileData,
            image,
          }))
        }
        setPreviewURL={(previewURL) =>
          setResidencyDocumentFileData((fileData) => ({
            ...fileData,
            previewURL,
          }))
        }
        setSelectedDocument={(document) =>
          setResidencyDocumentFileData((fileData) => ({
            ...fileData,
            document,
          }))
        }
        setSelectedDocumentBlob={(blob) =>
          setResidencyDocumentFileData((fileData) => ({
            ...fileData,
            blob,
          }))
        }
        closeUploadDocumentWidget={() => setUploadDocumentWidgetIsOpen(false)}
      />
    </>
  );
};
