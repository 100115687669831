/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../hooks/axios';
import { HeaderHome } from '../../components/header-home';
import { FooterHome } from '../../components/footer-home';
import { Loading } from '../../components/loading';
import moment from 'moment';
import { useLocation } from 'react-router';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Home = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken }) => {
  const refVideo = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [{ data, loading, error }] = useAxios('/exchange_rates/?size=1');
  const [rate, setRate] = useState() as any;
  const [isReady, setReady] = useState(false);
  const [isReadyToLoadJs, setReadyToLoadJS] = useState(false);
  const [fakeLoading, setShowFakeLoading] = useState(true);

  const query = useQuery()
  const source = query?.get('source')

  useEffect(() => {
    if (!source) return
    localStorage.setItem('leadSource', source)
  }, [source])
  
  useEffect(() => {
    if (data) {
      const rateRs = data?.results.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      );
      setRate(rateRs[0]);
      setReady(true);
    }
  }, [data]);

  useEffect(() => {
    if (rate && isReady && isReadyToLoadJs) {
      setShowFakeLoading(false);
      const listAnimate = document.getElementsByClassName('animate-transform');

      for (let i = 0; i <= listAnimate.length; i++) {
        if (listAnimate[i]) {
          listAnimate[i]['style'].transform =
            'translateX(0%) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)';
        }
      }

      if (!refVideo.current) {
        return;
      }
      const video = document.createElement('video');
      video.autoplay = true;
      video.loop = true;
      video.muted = true; // fixes autoplay in chrome
      video.setAttribute('playsinline', 'true'); // fixes autoplay in webkit (ie. mobile safari)
      video.setAttribute('width', '870');
      video.setAttribute('height', '490');
      video.style.marginTop = '-9px';
      // const source = document.createElement('source');
      video.src = 'https://olehpay-v2-static-files.s3.eu-central-1.amazonaws.com/oleh-pay.mp4'; //VideoOleh;
      (refVideo.current as any).appendChild(video);
    }
  }, [rate, isReady, isReadyToLoadJs]);

  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('src', 'https://code.jquery.com/jquery-3.4.1.min.js')
    script.setAttribute('id', 'jquery')
    document.body.appendChild(script) 

    // now wait for it to load...
    script.onload = () => {
        setReadyToLoadJS(true)
    }  
  }, [])
  

  return (
    <div className="home-content">
      <script src=""></script>

      {(!data || !rate || !isReady || fakeLoading || !isReadyToLoadJs) && <Loading></Loading>}

      {data && rate && isReady && isReadyToLoadJs && (
        <>
          <HeaderHome
            userIsAdmin={userIsAdmin}
            userIsRegistered={userIsRegistered}
            userIsAuthenticated={userIsAuthenticated}
            setToken={setToken}
          />
          <div
            data-w-id="62bd5f12-1640-f05b-2045-0cc4cb6f0a4f"
            style={{ display: 'none', opacity: 0 }}
            className="fees-breakdown-lightbox"
          >
            <div
              data-w-id="995e7ffc-c6e5-d104-7ede-e00f56d6859f"
              style={{
                transform:
                  'translateX(0) translateY(-80PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
              }}
              className="fees-breakdown-container animate-transform"
            >
              <div className="fees-breakdown-headeing-wrapper">
                <div className="b1">Fees Breakdown</div>
                <div data-w-id="e7650a07-b436-bb22-fe3b-142483a73ef7" className="close-cta-wrapper">
                  <img src={require('../../assets/imgs/close.svg').default} />
                </div>
              </div>
              <div
                data-easing="ease-out-quad"
                data-duration-in="150"
                data-duration-out="100"
                className="w-tabs"
              >
                <div className="tabs-menu w-tab-menu">
                  <a data-w-tab="check" className="tab w-inline-block w-tab-link">
                    <div className="tab-label">CHECK</div>
                    <div className="check tab-amount">$0</div>
                  </a>
                  <a data-w-tab="atm" className="tab w-inline-block w-tab-link">
                    <div className="tab-label">ATM</div>
                    <div className="atm tab-amount">$0</div>
                  </a>
                  <a data-w-tab="wire" className="tab w-inline-block w-tab-link">
                    <div className="tab-label">WIRE</div>
                    <div className="wire tab-amount">$0</div>
                  </a>
                  <a data-w-tab="olehpay" className="tab w-inline-block w-tab-link w--current">
                    <div className="tab-label">OLEHPAY</div>
                    <div className="oleh tab-amount">$0</div>
                  </a>
                </div>
                <div className="w-tab-content">
                  <div data-w-tab="check" className="w-tab-pane">
                    <div className="fee-breakdown-list-container">
                      <div className="fee-row">
                        <div className="c2">Average Check Fee (1%)</div>
                        <div className="c2 check-fee">$0.00</div>
                      </div>
                      <div className="fee-row">
                        <div className="c2">
                          Average Changer Exchange Rate Compared to Expected Rate
                        </div>
                        <div className="check-rate c2">3.3906</div>
                      </div>
                      <div className="fee-row">
                        <div className="c2">Converted Amount</div>
                        <div className="check-total c2">$0.00</div>
                      </div>
                      <div className="fee-row-final">
                        <div className="c2">Total Received</div>
                        <div className="check-total-received c2">
                          <span>₪</span>0.00
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="atm" className="w-tab-pane">
                    <div className="fee-breakdown-list-container">
                      <div className="fee-row">
                        <div className="c2">Average ATM Fee (3%) + $5</div>
                        <div className="c2 atm">$0</div>
                      </div>
                      <div className="fee-row">
                        <div className="c2">
                          Average Bank Exchange Rate Compared to Expected Rate
                        </div>
                        <div className="atm-rate c2">3.3475</div>
                      </div>
                      <div className="fee-row">
                        <div className="c2">Converted Amount</div>
                        <div className="atm-total c2">$-5.00</div>
                      </div>
                      <div className="fee-row-final">
                        <div className="c2">Total Received</div>
                        <div className="atm-total-received c2">
                          <span>₪</span>-17.25
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="wire" className="w-tab-pane">
                    <div className="fee-breakdown-list-container">
                      <div className="fee-row">
                        <div className="c2">Avg Sending + Receiving Wire Fees</div>
                        <div className="c2">$67</div>
                      </div>
                      <div className="fee-row">
                        <div className="c2">
                          Average Inter-Bank Exchange Rate Compared to Expected Rate
                        </div>
                        <div className="wire-rate c2">3.3768</div>
                      </div>
                      <div className="fee-row">
                        <div className="c2">Converted Amount</div>
                        <div className="wire-total c2">$-67.00</div>
                      </div>
                      <div className="fee-row-final">
                        <div className="c2">Total Received</div>
                        <div className="wire-total-received c2">
                          <span>₪</span>-231.22
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="olehpay" className="w-tab-pane w--tab-active">
                    <div className="fee-breakdown-list-container">
                      <div className="fee-row">
                        <div className="c2">Entire OlehPay Fee</div>
                        <div className="c2 oleh">$0</div>
                      </div>
                      <div className="fee-row">
                        <div className="c2">
                          <a
                            className="c2a"
                            target="_blank"
                            href="http://www.boi.org.il/en/Markets/ExchangeRates/Pages/Default.aspx"
                            rel="noreferrer"
                          >
                            OlehPay Midmarket
                          </a>{' '}
                          Exchange Rate
                        </div>
                        <div className="boi-rate c2">3.451</div>
                      </div>
                      <div className="fee-row">
                        <div className="c2">Converted Amount</div>
                        <div className="oleh-total c2">$0.00</div>
                      </div>
                      <div className="fee-row-final">
                        <div className="c2">Total Received</div>
                        <div className="oleh-total-received c2">
                          <span>₪</span>0.00
                        </div>
                      </div>
                      <div className="fee-row-final">
                        <div className="c2">
                          *OlehPay&apos;s mission is to leave you with the highest number of Shekel
                          for this transfer compared to other methods of money transfer.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="hero" className="hero w-clearfix" style={{ overflow: 'hidden' }}>
            <div
              data-w-id="b0906193-d6ac-47cd-82fe-e013798928dc"
              style={{
                transform:
                  'translateX(0) translateY(-80PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
                opacity: 1,
              }}
              className="hero-container w-container animate-transform"
            >
              <div className="hero-copy-wrapper">
                <h1 className="text-align-center left-align-text-mobile">
                  A simple, cost-effective way to send money to & from Israel.
                </h1>
                <div className="hero-apply-cta-wrapper mobile-call-to-action">
                  {!userIsAuthenticated ? (
                    <>
                      <a href="/signup" className="primary-cta w-button">
                        GET STARTED
                      </a>
                    </>
                  ) : (
                    <div className="hero-apply-cta-wrapper">
                      <a
                        href={userIsAdmin ? '/transfers' : userIsRegistered ? '/account' : '/join'}
                        className="primary-cta w-button"
                      >
                        {userIsAdmin
                          ? 'Manage'
                          : userIsRegistered
                          ? 'My Account'
                          : 'Continue Registration'}
                      </a>
                    </div>
                  )}
                  {/* <a href="/signup" className="primary-cta w-button">
                    GET STARTED
                  </a> */}
                </div>
                <h3
                  className="tcb-mid text-weight-reg text-align-center left-align-text-mobile"
                  style={{}}
                >
                  Cheaper than banks with our fair and transparent pricing.
                  <br />
                  <a
                    style={{ color: 'inherit', fontSize: 'inherit' }}
                    target="_blank"
                    href="https://www.boi.org.il/en/economic-roles/financial-markets/exchange-rates/us-dollar/"
                    rel="noreferrer"
                  >
                    Last <span style={{ textDecoration: 'underline' }}>BOI Rate</span> as of{' '}
                    {rate ? moment(rate?.date).format('MMM DD, YYYY') : ''} at 3:30 PM IST:{' '}
                  </a>
                  <a
                    id="exchangerateindex"
                    style={{ color: '#fbb919', fontSize: 'inherit' }}
                    target="_blank"
                    href="https://www.boi.org.il/en/economic-roles/financial-markets/exchange-rates/us-dollar/"
                    rel="noreferrer"
                  >
                    <span className="rate">{rate ? rate?.amount : ''}USD/ILS</span>
                  </a>
                  <br />
                  {/* Cheapest rates in Israel{' '}
                  <a
                    href="/info#pricing"
                    target="_blank"
                    style={{ color: '#fbb919', fontSize: 'inherit' }}
                  >
                    guaranteed.
                  </a> */}
                </h3>
              </div>
              <div className="calculator-container">
                <div className="currency-calculator-container w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="currency-calculator-form-container"
                  >
                    <div className="textfield-wrapper">
                      <div className="textfield-icon-wrapper">
                        <img src={require('../../assets/imgs/american-flag.svg').default} />
                      </div>
                      <div className="textfield-label">SEND</div>
                      <input
                        type="text"
                        className="textfield clear-padding w-input"
                        name="USD"
                        data-name="USD"
                        placeholder="$ 0.00"
                        id="USD"
                        required
                        maxLength={12}
                      />
                      {rate && (
                        <input
                          type="hidden"
                          id="BOI_RATE"
                          className="boi-rate"
                          value={rate?.amount}
                        />
                      )}
                    </div>
                    <div className="send-cta-wrapper">
                      <a href="/signup" className="send-cta w-inline-block">
                        <div className="c1">SEND</div>

                        <div className="send-icon-wrapper">
                          <img src={require('../../assets/imgs/send.svg').default} />
                        </div>
                      </a>
                      <div
                        className="savings-blurb-wrapper"
                        data-w-id="6365514a-d7b1-d791-3f63-7f7d28a92372"
                      >
                        <div>
                          <span className="savings"></span>
                        </div>
                      </div>
                      <div
                        data-w-id="81fb950a-c872-f3b5-b1f5-57c011a26d07"
                        style={{
                          transform:
                            'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                          backgroundColor: 'rgb(47, 55, 72)',
                        }}
                        className="label-dark"
                      >
                        <div
                          style={{ color: 'rgb(143, 161, 178)' }}
                          data-w-id="f6f6bdd8-8ad3-e777-a352-8fad896771ed"
                          className="fee-breakdown dark-label-text"
                        >
                          FEES BREAKDOWN
                        </div>
                      </div>
                    </div>
                    <div className="textfield-wrapper">
                      <div className="textfield-icon-wrapper">
                        <img src={require('../../assets/imgs/israeli-flag.svg').default} />
                      </div>
                      <div className="textfield-label">RECEIVE</div>
                      <input
                        type="text"
                        className="textfield clear-padding w-input"
                        maxLength={12}
                        name="ILS"
                        data-name="ILS"
                        placeholder="₪ 0.00"
                        id="ILS"
                        required
                      />
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
              <div
                id="transfer-comparison"
                className="transfer-options-container w-hidden-medium w-hidden-small w-hidden-tiny"
              >
                <div
                  data-w-id="55a2b39b-1dde-6681-71fd-3ad44893ad87"
                  className="transfer-options-wrapper"
                >
                  <div className="c1 text-align-center tcb-mid">CHECK</div>
                  <h3 className="check tcb-light text-align-center clear-top-padding">$0</h3>
                </div>
                <div
                  data-w-id="4fcea8e3-afcd-9d4c-fa6d-38859d3f4863"
                  className="transfer-options-wrapper"
                >
                  <div className="c1 text-align-center tcb-mid">ATM</div>
                  <h3 className="atm tcb-light text-align-center clear-top-padding">$0</h3>
                </div>
                <div
                  data-w-id="b4be4d11-5592-a947-7fc0-19b9ec2460c8"
                  className="transfer-options-wrapper"
                >
                  <div className="c1 text-align-center tcb-mid">WIRE</div>
                  <h3 className="wire tcb-light text-align-center clear-top-padding">$0</h3>
                </div>
                <div className="vs-div-container">
                  <div className="vs-copy-wrapper">
                    <div className="vs-text">vs</div>
                  </div>
                  <div className="vs-div"></div>
                </div>
                <div
                  data-w-id="958a3538-a00c-ded2-8b5f-0aaf34b5f8f0"
                  className="transfer-options-olehpay-wrapper"
                >
                  <div className="c1 tcb-mid text-align-center">OLEHPAY</div>
                  <h3 className="oleh text-align-center clear-top-padding">$0</h3>
                  <div className="yellow-label">BEST RATE</div>
                </div>
              </div>
            </div>
            <div className="hero-illo-bottom-container">
              <div
                id="bird"
                // data-w-id="bf5e3945-5a8b-cb77-912b-41abdf5dc9fc"
                style={{
                  transform:
                    'translateX(-150PX) translateY(30PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
                }}
                className="hero-illo-bird-wrapper w-hidden-medium w-hidden-small w-hidden-tiny animate-transform"
              >
                <img
                  // data-w-id="ad217367-b902-30d0-1aeb-2474e74dd3cb"
                  style={{
                    transform:
                      'translateX(0) translateY(0PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
                  }}
                  src={require('../../assets/imgs/bird.svg').default}
                />
              </div>

              {/* <div
            id="bird"
            data-w-id="ad217367-b902-30d0-1aeb-2474e74dd3cb"
            style={{
              transform:
                'translateX(-150PX) translateY(30PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
            }}
            className="hero-illo-bird-wrapper w-hidden-medium w-hidden-small w-hidden-tiny"
          >
            <img
              src={require('../../assets/imgs/bird.svg').default}
              style={{
                transform:
                  'translateX(0) translateY(0PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
              }}
            />
          </div> */}
              <div
                // data-w-id="dedb8485-eb29-610b-6810-47b672222302"
                style={{
                  transform:
                    'translateX(20%) translateY(0) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
                }}
                className="hero-illo-right-wrapper animate-transform"
              >
                <img src={require('../../assets/imgs/right.svg').default} />
              </div>
              <div className="hero-illo-bottom-wrapper">
                <div
                  style={{
                    transform:
                      'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                  }}
                  className="hero-illo-bottom-base-wrapper animate-transform"
                >
                  <img src={require('../../assets/imgs/bottom.svg').default} />
                </div>
              </div>
              <div
                // data-w-id="824201b2-5d93-6cf1-5f9d-2d5a556f7559"
                style={{
                  transform:
                    'translateX(-20%) translateY(0) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
                }}
                className="hero-illo-left-wrapper w-hidden-medium w-hidden-small w-hidden-tiny animate-transform"
              >
                <img src={require('../../assets/imgs/left.svg').default} />
              </div>
            </div>
          </div>
          <div className="_desktop-only landing-video _desk" id="demo">
            <div className="video-browser test">
              <div className="controls"></div>
              <div ref={refVideo} />
            </div>
          </div>
          <div data-w-id="4e20f8b8-5ebc-9aab-b280-52750d68029a" className="why">
            <div
              className="why-container w-container"
              data-w-id="7314dd3b-cee3-ed90-7808-7a346bbe1d06"
              style={{
                willChange: 'transform',
                transform:
                  'translateX(0px) translateY(0%) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
              }}
            >
              <div
                data-w-id="6f82cbd6-1c24-d68b-df46-6baf3404132f"
                style={{
                  transform:
                    'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                  opacity: 1,
                }}
                className="why-heading-wrapper"
              >
                <h2>Why OlehPay...</h2>
              </div>
              <div
                data-w-id="817b1336-d373-79db-6dfe-929603c89a72"
                style={{
                  transform:
                    'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                  opacity: 1,
                }}
                className="reasons-why-container"
              >
                <div className="reason-container">
                  <div className="reason-illo-wrapper">
                    <img src={require('../../assets/imgs/fast.svg').default} />
                  </div>
                  <div className="reason-copy-wrapper">
                    <h3 className="clear-padding">Quick and Easy Signup</h3>
                    <div className="b2 tcb-mid">
                      Easily provide all information at signup. Start sending transfers immediately.
                    </div>
                    <a href="https://olehpay.co.il/info" className="text-button-small w-button">
                      Learn More
                    </a>
                  </div>
                </div>
                <div className="reason-container">
                  <div className="reason-illo-wrapper">
                    <img src={require('../../assets/imgs/fdic.svg').default} />
                  </div>
                  <div className="reason-copy-wrapper">
                    <h3 className="clear-padding">Fair Pricing</h3>
                    <div className="b2 tcb-mid">
                      Transparent fees and no hidden traps such as markups on the exchange rate or
                      outgoing/receiving international wire fees.
                    </div>
                    <a href="https://olehpay.co.il/info" className="text-button-small w-button">
                      Learn More
                    </a>
                  </div>
                </div>
                <div className="reason-container">
                  <div className="reason-illo-wrapper">
                    <img src={require('../../assets/imgs/no-visit.svg').default} />
                  </div>
                  <div className="reason-copy-wrapper">
                    <h3 className="clear-padding">Great Customer Service</h3>
                    <div className="b2 tcb-mid">
                      Call us anytime, call us at 3:00am, we&apos;re here for you! OlehPay was built
                      by Olim like you.
                    </div>
                    <a href="https://olehpay.co.il/info" className="text-button-small w-button">
                      Learn More
                    </a>
                  </div>
                </div>
                <div className="reason-container">
                  <div className="reason-illo-wrapper">
                    <img src={require('../../assets/imgs/no-fees.svg').default} />
                  </div>
                  <div className="reason-copy-wrapper">
                    <h3 className="clear-padding">Low Minimums</h3>
                    <div className="b2 tcb-mid">
                      Transfer as little as $300 at the official exchange rate.
                    </div>
                    <a href="https://olehpay.co.il/info" className="text-button-small w-button">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-w-id="26c87773-b748-f7f4-3bb6-c5d05bba4727" className="how">
            <div
              data-w-id="26c87773-b748-f7f4-3bb6-c5d05bba4728"
              className="how-container w-container"
              style={{
                willChange: 'transform',
                transform:
                  'translateX(0px) translateY(0%) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
              }}
            >
              <div
                data-w-id="26c87773-b748-f7f4-3bb6-c5d05bba4729"
                style={{
                  transform:
                    'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                  opacity: 1,
                }}
                className="how-heading-wrapper"
              >
                <h2 className="text-align-center">Works as easy as one, two, three!</h2>
              </div>
              <div
                data-w-id="26c87773-b748-f7f4-3bb6-c5d05bba472c"
                style={{
                  transform:
                    'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                  opacity: 1,
                }}
                className="how-steps-container"
              >
                <div className="step-container">
                  <div className="step-number-wrapper">
                    <h2 className="text-weight-reg clear-padding">1</h2>
                  </div>
                  <div className="step-copy-wrapper">
                    <div className="b1 text-align-center">Upload your information</div>
                    <div className="c2 tcb-mid text-align-center">
                      During registration we&apos;ll ask you for everything we need, uploaded right
                      through our website.
                    </div>
                  </div>
                </div>
                <div className="step-container">
                  <div className="step-number-wrapper">
                    <h2 className="text-weight-reg clear-padding">2</h2>
                  </div>
                  <div className="step-copy-wrapper">
                    <div className="b1 text-align-center">Digitally add your bank accounts</div>
                    <div className="c2 tcb-mid text-align-center">
                      We support every bank. And, we employ the highest security standards in the
                      industry to make sure your information stays safe.
                    </div>
                  </div>
                </div>
                <div className="step-container">
                  <div className="step-number-wrapper">
                    <h2 className="text-weight-reg clear-padding">3</h2>
                  </div>
                  <div className="step-copy-wrapper">
                    <div className="b1 text-align-center">Choose your amount and click send</div>
                    <div className="c2 tcb-mid text-align-center">
                      Just two clicks. Enter the amount you&apos;d like to send - we&apos;ll convert
                      it and deposit the funds directly into your bank. See daily{' '}
                      <span style={{ textDecoration: 'underline' }}>cut-off times</span> for
                      currency conversion and transfer submission.
                    </div>
                  </div>
                </div>
                <div className="step-container">
                  <div className="step-check-wrapper">
                    <img src={require('../../assets/imgs/check.svg').default} />
                  </div>
                  <div className="step-copy-wrapper">
                    <div className="b1 text-align-center">We handle the rest!</div>
                    <div className="c2 tcb-mid text-align-center">
                      Once you place your transfer, your funds will be received in as little as
                      three days.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="support">
            <div
              data-w-id="ee99df03-d224-2b00-1d8d-b27795baf117"
              className="support-container w-container"
            >
              <div className="support-copy-container">
                <h2
                  data-w-id="6aa4f75e-6e59-4c45-1648-52796a8d1014"
                  style={{
                    transform:
                      'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                    opacity: 1,
                  }}
                  className="text-align-center-mobile"
                >
                  Technology isn&apos;t your thing? Call us!{' '}
                </h2>
                <div className="support-number-container">
                  <h2
                    id="israeli-tel"
                    data-w-id="2f3638ef-ddfa-be77-1bb5-fe7e2fd5e4c7"
                    style={{
                      transform:
                        'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                      opacity: 1,
                    }}
                    className="phone text-weight-reg tcb-light clear-padding phone"
                  >
                    +972 (2) 376-2028
                  </h2>
                </div>
              </div>
              <div
                data-w-id="b0182b98-7e84-81ef-5a92-768ee36bf776"
                style={{
                  transform:
                    'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                }}
                className="support-illo-wrapper"
              >
                <img src={require('../../assets/imgs/support.svg').default} />
              </div>
            </div>
          </div>

          <FooterHome />
          {isReadyToLoadJs && (
            <>
              <script src={require('../../assets/static.js')}></script>
              <script src={require('../../assets/webflow.js')}></script>
            </>
          )}
        </>
      )}
    </div>
  );
};
