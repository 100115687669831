import { useEffect } from 'react';
import styled from 'styled-components';

import { THEME } from '../../../constants';
import { StyledNextButton, StyledFooter, SubtitleContainer } from '../common-components';

const FormGridContainer = styled.div.attrs({
  className: 'FormGridContainer',
})`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 12px;
  margin-bottom: 12px;
`;

const Option = styled.div.attrs({
  className: 'Option',
})<{ selected: boolean }>(
  ({ selected }) => `
    display: flex;
    height: 70px;
    padding-left: 16px;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 8px;
    background-color: ${selected ? 'rgba(255, 193, 7, 0)' : 'rgba(255, 193, 7, 0)'};
    border-color: ${selected ? '#fbb919' : ''};
    opacity: 0.8;
    font-family: 'Fontsfree net proxima nova';
    color: ${selected ? 'white' : 'white'};
    font-size: 23px;
    font-weight: 300;
    cursor: pointer;
    &:hover {
      opacity: 1
    }
  `,
);

export const RegistrationEntity = ({
  entity,
  setEntity,
  handleNextOnClick,
  nextButtonIsEnabled,
  setNextButtonIsEnabled,
}) => {
  useEffect(() => {
    setNextButtonIsEnabled(Boolean(entity));
  }, [entity]);
  return (
    <>
      <SubtitleContainer>Citizenship</SubtitleContainer>
      <FormGridContainer>
        <Option
          selected={entity === 'ISRAELI_CITIZEN'}
          onClick={() => setEntity('ISRAELI_CITIZEN')}
        >
          I hold Israeli Citizenship
        </Option>
        <Option
          selected={entity === 'NON_ISRAELI_CITIZEN'}
          onClick={() => setEntity('NON_ISRAELI_CITIZEN')}
        >
          I do not hold Israeli Citizenship
        </Option>
      </FormGridContainer>
      <StyledNextButton onClick={handleNextOnClick} disabled={!nextButtonIsEnabled}>
        Next
      </StyledNextButton>
      <StyledFooter>
        We may ask you different information depending on your citizenship status.
      </StyledFooter>
    </>
  );
};
