import styled from 'styled-components';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import moment from 'moment';

import { THEME } from '../../constants';

const WrapCard = styled.div.attrs({
  className: 'WrapCard',
})`
  background-color: ${THEME.ligher_dark_blue_2};
  border-radius: 10px;
  padding: 5px 25px;
  color: ${THEME.white};
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  box-shadow: rgb(0 10 20 / 40%) 0px 8px 24px;
`;

const WrapTitle = styled.div.attrs({
  className: 'WrapTitle',
})`
  // background-color: ${THEME.ligher_dark_blue_2};
  border-radius: 10px;
  padding: 10px;
  color: ${THEME.white};
  margin: 5px 0;
`;

const SectionContainer = styled.div.attrs({
  className: 'SectionContainer',
})`
  margin-top: 2em;
`;

const SubscriptionDescription = styled.div.attrs({
  className: 'SubscriptionDescription',
})`
  display: flex;
  align-items: center;
  justitfy-content: center;
`;

const WrapBankIcon = styled.div.attrs({
  className: 'WrapBankIcon',
})`
  height: 100%;
  margin-left: 2px;
  margin-right: -1px;
  margin-top: 2px;
`;

const StyledAccountBalanceIcon = styled(AccountBalanceIcon).attrs({
  className: 'StyledAccountBalanceIcon',
})`
  height: 0.7em !important;
`;

export const StandingOrders = ({ subscriptions }) => {
  const subscriptionCards = subscriptions
    ?.filter((subscription) => subscription.is_active)
    ?.map((subscription) => (
      <>
        <WrapCard>
          <SubscriptionDescription>
            Your standing order of {subscription?.type === 'USD_TO_ILS' ? '$' : '₪'}
            {subscription?.amount} from{' '}
            <WrapBankIcon>
              <StyledAccountBalanceIcon />
            </WrapBankIcon>
            {subscription?.bank_account_sending?.name} to{' '}
            <WrapBankIcon>
              <StyledAccountBalanceIcon />
            </WrapBankIcon>
            {subscription?.bank_account_receiving?.name} will next be billed on{' '}
            {moment(subscription.next_transfer_date).format('MMMM Do')}.
          </SubscriptionDescription>
        </WrapCard>
        <br />
      </>
    ));
  return (
    <SectionContainer>
      <WrapTitle>Standing Orders</WrapTitle>
      {subscriptions?.length ? (
        subscriptionCards
      ) : (
        <WrapCard>
          <p>Looks like you don&apos;t have any standing orders right now.</p>
        </WrapCard>
      )}
      <br />
      <WrapCard>
        <p>What are standing orders?</p>
        <p>
          Standing orders allow you to automatically send money to yourself or a family member each
          month. If you&apos;re interested in setting up a standing order, contact support at{' '}
          <a style={{ color: '#fbb919' }} href="mailto:support@olehpay.co.il">
            support@olehpay.co.il
          </a>{' '}
          and we&apos;ll get you set up.
        </p>
      </WrapCard>
    </SectionContainer>
  );
};
