import styled from "styled-components";
import DatePicker from 'react-widgets/DatePicker';

import { THEME } from "../../../constants";

export const FormGridContainer = styled.div.attrs({
    className: 'FormGridContainer',
  })`
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 1fr;
    gap: 12px;
    margin-bottom: 12px;
  `;
  
  export const StyledUploadDocumentButton = styled.button.attrs({
    className: 'StyledUploadDocumentButton',
  })`
    height: 70px;
    border-radius: 8px;
    font-family: 'Fontsfree net proxima nova';
    font-size: 28px;
    font-weight: 500;
    border: none;
    color: rgb(255, 255, 255);
    margin-bottom: 12px;
    width: 100%;
    background-color: rgb(251, 185, 25);
    opacity: 0.95;
    cursor: pointer;
  `;
  
  export const StyledInput = styled.input.attrs({
    className: 'StyledInput',
  })<{ isFilledOut?: any }>`
    border-radius: 8px;
    background-color: transparent;
    opacity: 0.8;
    font-family: 'Fontsfree net proxima nova';
    color: #fff;
    padding: 20.5px 12px;
    height: 70px;
    font-size: 23px;
    line-height: 23px;
    font-weight: 300;
    cursor: text;
    width: 100%;
    border: 1px solid
      ${(props: any) => (props.isFilledOut ? 'rgb(251, 185, 25)' : 'rgb(204, 204, 204)')};
    // border: 1px solid ${(props: any) => (props.isFilledOut ? 'blue' : 'red')};
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &.h-70 {
      min-height: 70px;
    }
    &:visited {
      border: 1px solid #fbb919;
      opacity: 0.8;
      outline: none;
    }
    &:focus,
    &:active,
    &:hover {
      // border: 1px solid #fbb919;
      // border-color: rgb(251, 185, 25);
      outline: none;
      opacity: 1;
    }
  `;
  export const DatePickerContainer = styled.div.attrs({
    className: 'DatePickerContainer',
  })<{ isFilledOut?: any }>`
    .rw-widget-picker {
      border-color: ${(props: any) => (props.isFilledOut ? '#fbb919' : 'rgb(204, 204, 204)')};
    }
  `;
  
  export const StyledDatePicker = styled(DatePicker).attrs({
    className: 'StyledDatePicker',
  })`
    height: 70px;
    &.rw-state-focus .rw-widget-picker {
      box-shadow: none;
      border: 1px solid #fbb919;
    }
    .rw-widget-container {
      opacity: 0.8;
    }
    .rw-widget-input,
    .rw-widget-picker {
      color: white;
      font-size: inherit;
      height: 100%;
      border-radius: 8px;
      background-color: transparent;
      font-family: Fontsfree net proxima nova;
      color: #fff;
      padding: 10px 6px;
      font-size: 23px;
      line-height: 23px;
      font-weight: 300;
      &:focus,
      &:active,
      &:hover {
        border-color: #fbb919;
        outline: none;
        opacity: 1;
      }
    }
    .selected .rw-widget-input,
    .selected {
      border-color: #fbb919;
    }
    .rw-picker-btn {
      border-color: inherit;
      color: white;
      &:hover {
        color: ${THEME.dark_blue};
      }
    }
    .rw-calendar-popup {
      right: -10px;
      left: auto;
    }
  `;
  
  export const IdentificationTypeContainer = styled.div.attrs({
    className: 'IdentificationTypeContainer',
  })`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `;
  
  export const DownArrowIcon = styled.img.attrs({
    className: 'DownArrowIcon',
  })`
    position: absolute;
    right: 12px;
    cursor: pointer;
  `;
  
  export const StyledSelect = styled.select.attrs({
    className: 'StyledSelect',
  })`
    appearance: none;
    background-color: transparent;
    opacity: 0.8;
    font-family: 'Fontsfree net proxima nova';
    color: #fff;
    padding: 20.5px 12px;
    font-size: 23px;
    line-height: 23px;
    font-weight: 300;
    cursor: pointer;
    width: 100%;
    border: 1px solid #fbb919;
    border-radius: 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    option {
      color: #222;
    }
    &:focus,
    &:active,
    &:hover {
      border: 1px solid #fbb919;
      outline: none;
      opacity: 1;
    }
  `;
  
  export const DocumentPreviewImageContainer = styled.div.attrs({
    className: 'DocumentPreviewImageContainer',
  })`
    width: 100%;
    max-height: 100%;
    border-radius: 10px;
    margin-bottom: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
  `;
  
  export const DocumentPreviewImage = styled.img.attrs({
    className: 'DocumentPreviewImage',
  })`
    width: 100%;
    max-height: 100%;
  `;
  
  export const IframePreview = styled.iframe`
    width: 100%;
    height: 300px;
  `;

  export const SkipTextContainer = styled.p.attrs({
    className: 'skip-text-container',
  })`
    padding-left: 11px;
    color: hsla(0deg, 0%, 100%, 0.53);
  `;

  export const ClickHereContainer = styled.span.attrs({
    className: 'click-here-container',
  })`
    text-decoration: underline;
    color: ${THEME.light_blue_link};
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
  `;