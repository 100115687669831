import { useState, useEffect } from 'react';
import { debounce } from '@mui/material';
import styled from 'styled-components';
import moment from 'moment';

import { UploadDocumentWidget } from '../../../../components/upload-document-widget';
import { PDFPreview } from '../../../../components/pdf-preview';

import {
  StyledFieldset,
  StyledLegend,
  EditableInput,
  StyledSelect,
  StyledDatePicker,
  DownArrowIcon,
  StyledButton,
  SavingContainer,
  StyledOption,
} from '../../common-components';
import ICON_ARROW_DOWN from '../../../../assets/imgs/icon/arrow_card_down.svg';
import useAxios from 'axios-hooks';
import { useIsMount } from '../../../../hooks/useIsMount';

const FormGridContainer = styled.div.attrs({
  className: 'FormGridContainer',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 1em;
  margin-bottom: 1em;
`;

const StyledUploadDocumentButton = styled.button.attrs({
  className: 'StyledUploadDocumentButton',
})`
  // height: 70px;
  padding: 1em;
  border-radius: 8px;
  font-family: 'Fontsfree net proxima nova';
  // font-size: 28px;
  // font-weight: 600;
  border: none;
  color: rgb(255, 255, 255);
  margin-bottom: 0.75em;
  width: 100%;
  background-color: rgb(251, 185, 25);
  opacity: 0.95;
  cursor: pointer;
`;

const IdentificationTypeContainer = styled.div.attrs({
  className: 'IdentificationTypeContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const DocumentPreviewImageContainer = styled.div.attrs({
  className: 'DocumentPreviewImageContainer',
})`
  width: 100%;
  // height: 100%;
  height: calc(100% - 265px - 93px);
  border-radius: 10px;
  margin-bottom: 1em;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const DocumentPreviewImage = styled.img.attrs({
  className: 'DocumentPreviewImage',
})`
  width: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const IframePreview = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 500px;
`;

export const IdentificationDetails = ({ designation, identification, setIdentification }) => {
  const isFirstRender = useIsMount();
  const [verificationButtonIsEnabled, setVerificationButtonIsEnabled] = useState(true);
  const [uploadDocumentWidgetIsOpen, setUploadDocumentWidgetIsOpen] = useState(false);
  const [identificationFileData, setIdentificationFileData] = useState(null);
  const [isPdf, setShowPdf] = useState(false);
  const [{ loading: identificationIsSaving }, updateIdentification] = useAxios(
    {
      url: `/identifications/${identification?.uuid}/`,
      method: 'PATCH',
    },
    { manual: true },
  );

  const issueDate = identification?.issue_date
    ? moment(identification.issue_date, 'YYYY-MM-DD').toDate()
    : null;
  const expirationDate = identification?.expiration_date
    ? moment(identification.expiration_date, 'YYYY-MM-DD').toDate()
    : null;
  const identificationTypeOptions = [
    { label: 'State ID', value: 'STATE_IDENTIFICATION_CARD' },
    { label: 'Drivers License', value: 'DRIVERS_LICENSE' },
    { label: 'Passport', value: 'PASSPORT' },
    { label: 'Other', value: 'OTHER' },
  ].map((option) => (
    <StyledOption key={option.value} value={option.value}>
      {option.label}
    </StyledOption>
  ));

  const countryOptions = [
    { label: 'United States', value: 'US' },
    { label: 'Israel', value: 'IL' },
    { label: 'Other', value: 'OO' },
  ].map((option) => (
    <StyledOption key={option.value} value={option.value}>
      {option.label}
    </StyledOption>
  ));

  const handleIdentificationTypeOnChange = (event) =>
    setIdentification((identification) => ({
      ...identification,
      type: event.target.value,
    }));

  const handleCountryOnChange = (event) =>
    setIdentification((identification) => ({
      ...identification,
      country: event.target.value,
    }));

  const handleNumberOnChange = (event) => {
    setIdentification((identification) => ({
      ...identification,
      number: event.target.value,
    }));
  };

  const handleIssueDateOnChange = (date) => {
    setIdentification((identification) => ({
      ...identification,
      issue_date: date?.toISOString()?.slice(0, 10),
    }));
  };

  const handleExpirationDateOnChange = (date) => {
    setIdentification((identification) => ({
      ...identification,
      expiration_date: date?.toISOString()?.slice(0, 10),
    }));
  };

  const handleImageOnClick = () => {
    window.open(identification.file, '_blank');
  };

  const handleVerifyOnClick = () =>
    setIdentification((identification) => ({
      ...identification,
      is_verified: !identification?.is_verified,
    }));

  useEffect(() => {
    if (isFirstRender) return;
    setVerificationButtonIsEnabled(false);
    const debouncedUpdate = debounce(() => {
      const identificationForm = new FormData();
      if (identificationFileData?.blob)
        identificationForm.append(
          'file',
          identificationFileData?.blob,
          identificationFileData?.image?.name,
        );
      identificationForm.append('country', identification?.country);
      identificationForm.append('issue_date', identification?.issue_date);
      identificationForm.append('expiration_date', identification?.expiration_date);
      identificationForm.append('number', identification?.number);
      identificationForm.append('type', identification?.type);
      identificationForm.append('is_verified', identification?.is_verified);
      updateIdentification({
        data: identificationForm,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response) => {
          setVerificationButtonIsEnabled(true);
        })
        .catch((error) => {
          if (error.code === 'ERR_CANCELED') return;
        });
    }, 500);
    debouncedUpdate();
    return () => {
      debouncedUpdate.clear();
    };
  }, [identification, identificationFileData?.blob]);
  return (
    <>
      <StyledFieldset>
        <StyledLegend>{designation} ID</StyledLegend>
        {(identificationFileData?.document && identificationFileData?.document !== '') ||
        identification?.file ? (
          <DocumentPreviewImageContainer>
            {identification?.file_metadata?.content_type === 'application/pdf' ||
            identificationFileData?.image?.type === 'application/pdf' ? (
              <IframePreview
                src={identification?.file || identificationFileData?.previewURL}
                frameBorder="0"
              ></IframePreview>
            ) : (
              <DocumentPreviewImage
                onClick={handleImageOnClick}
                src={identificationFileData?.document || identification?.file}
              />
            )}
          </DocumentPreviewImageContainer>
        ) : null}
        <StyledUploadDocumentButton
          onClick={() => setUploadDocumentWidgetIsOpen(true)}
          disabled={false}
        >
          upload document
        </StyledUploadDocumentButton>
        <UploadDocumentWidget
          isPdf={isPdf}
          setShowPdf={setShowPdf}
          image={identificationFileData?.image}
          previewURL={identificationFileData?.previewURL}
          uploadDocumentWidgetIsOpen={uploadDocumentWidgetIsOpen}
          setImage={(image) =>
            setIdentificationFileData((fileData) => ({
              ...fileData,
              image,
            }))
          }
          setPreviewURL={(previewURL) =>
            setIdentificationFileData((fileData) => ({
              ...fileData,
              previewURL,
            }))
          }
          setSelectedDocument={(document) =>
            setIdentificationFileData((fileData) => ({
              ...fileData,
              document,
            }))
          }
          setSelectedDocumentBlob={(blob) =>
            setIdentificationFileData((fileData) => ({
              ...fileData,
              blob,
            }))
          }
          closeUploadDocumentWidget={() => setUploadDocumentWidgetIsOpen(false)}
        />
        <FormGridContainer>
          <IdentificationTypeContainer style={{ gridColumn: 'span 2' }}>
            <StyledSelect onChange={handleCountryOnChange} value={identification?.country}>
              <option value=""></option>
              {countryOptions}
            </StyledSelect>
            <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
          </IdentificationTypeContainer>
          <IdentificationTypeContainer style={{ gridColumn: 'span 2' }}>
            <StyledSelect onChange={handleIdentificationTypeOnChange} value={identification?.type}>
              <option value=""></option>
              {identificationTypeOptions}
            </StyledSelect>
            <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
          </IdentificationTypeContainer>
          <EditableInput
            style={{ gridColumn: 'span 2' }}
            placeholder="Number"
            value={identification.number || ''}
            onChange={handleNumberOnChange}
          />
          <StyledDatePicker
            value={issueDate}
            onChange={handleIssueDateOnChange}
            calendarProps={{ defaultView: 'century', defaultCurrentDate: new Date('1970') }}
            placeholder="Issued"
            valueDisplayFormat={{ dateStyle: 'short' }}
            valueEditFormat={{ dateStyle: 'short' }}
            onMouseLeave={() => {
              (document.activeElement as any).blur();
            }}
          />
          <StyledDatePicker
            value={expirationDate}
            onChange={handleExpirationDateOnChange}
            calendarProps={{ defaultView: 'century', defaultCurrentDate: new Date('1970') }}
            placeholder="Expires"
            valueDisplayFormat={{ dateStyle: 'short' }}
            valueEditFormat={{ dateStyle: 'short' }}
            onMouseLeave={() => {
              (document.activeElement as any).blur();
            }}
          />
          <StyledButton
            transparent={!identification?.is_verified}
            onClick={handleVerifyOnClick}
            disabled={!verificationButtonIsEnabled}
          >
            {identification?.is_verified ? 'verified' : 'verify id'}
          </StyledButton>
        </FormGridContainer>
      </StyledFieldset>
      <SavingContainer active={identificationIsSaving}>saving identification...</SavingContainer>
    </>
  );
};
