import styled from 'styled-components';
import { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-widgets/DatePicker';
import moment from 'moment';
import 'react-widgets/scss/styles.scss';

import {
  StyledNextButton,
  StyledFooter,
  SubtitleContainer,
  YellowText,
  StyledInput,
} from '../common-components';
import { THEME } from '../../../constants';
import ICON_REFRESH from '../../../assets/imgs/icon/refresh_icon.svg';

const FormGridContainer = styled.div.attrs({
  className: 'FormGridContainer',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 12px;
`;

const StyledDatePicker = styled(DatePicker).attrs({
  className: 'StyledDatePicker',
})`
  height: 70px;
  &.rw-state-focus .rw-widget-picker {
    box-shadow: none;
    border: 1px solid #fbb919;
  }
  .rw-widget-container {
    opacity: 0.8;
  }
  .rw-widget-input,
  .rw-widget-picker {
    border-color: rgb(204, 204, 204);
    color: white;
    font-size: inherit;
    height: 100%;
    border-radius: 8px;
    background-color: transparent;
    font-family: Fontsfree net proxima nova;
    color: #fff;
    padding: 10px 6px;
    font-size: 23px;
    line-height: 23px;
    font-weight: 300;
    &:focus,
    &:active,
    &:hover {
      border-color: #fbb919;
      outline: none;
      opacity: 1;
    }
  }
  .selected .rw-widget-input,
  .selected {
    border-color: #fbb919;
  }
  .rw-picker-btn {
    border-color: inherit;
    color: white;
    &:hover {
      color: ${THEME.dark_blue};
    }
  }
  .rw-calendar-popup {
    right: -10px;
    left: auto;
  }
`;

const SexSelectorContainer = styled.div.attrs({
  className: 'SexSelectorContainer',
})`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  // padding: 0 0.857em;
  padding: 20.5px 12px;
  font-size: 23px;
  line-height: 27px;
  font-weight: 300;
  cursor: pointer;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &:focus,
  &:active,
  &:hover {
    border: 1px solid #fbb919;
    outline: none;
    opacity: 1;
  }
`;

const RefreshIcon = styled.img.attrs({
  className: 'RefreshIcon',
})`
  max-height: 23px;
  float: right;
`;

export const RegistrationPersonalDetails = ({
  profile,
  setProfile,
  handleNextOnClick,
  nextButtonIsEnabled,
  setNextButtonIsEnabled,
}) => {
  const datepicker_ref = useRef() as any;
  const [dateOfBirthPlaceholder, setDateOfBirthPlaceholder] = useState('Date of Birth');
  const dateOfBirth = profile?.date_of_birth
    ? moment(profile?.date_of_birth, 'YYYY-MM-DD').toDate()
    : null;

  useEffect(() => {
    if (profile?.first_name && profile?.last_name && profile?.date_of_birth)
      setNextButtonIsEnabled(true);
    else setNextButtonIsEnabled(false);
  }, [profile]);

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const handleFirstNameOnChange = (event) =>
    setProfile((profile) => ({
      ...profile,
      first_name: capitalize(event.target.value),
    }));

  const handleLastNameOnChange = (event) =>
    setProfile((profile) => ({
      ...profile,
      last_name: capitalize(event.target.value),
    }));

  const handleDateOfBirthOnChange = (date) => {
    console.log()
    setProfile((profile) => ({
      ...profile,
      date_of_birth: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const handleSexOnChange = () =>
    setProfile((profile) => ({
      ...profile,
      sex: profile?.sex === 'M' ? 'F' : 'M',
    }));
    
  return (
    <>
      <SubtitleContainer>Your basic details</SubtitleContainer>
      <FormGridContainer>
        <StyledInput
          placeholder="First Name"
          value={profile?.first_name || ''}
          onChange={handleFirstNameOnChange}
          style={{ borderColor: profile?.first_name ? '#fbb919' : '' }}
          isFilledOut={profile?.first_name}
        />
        <StyledInput
          placeholder={'Last Name'}
          value={profile?.last_name || ''}
          onChange={handleLastNameOnChange}
          style={{ borderColor: profile?.last_name ? '#fbb919' : '' }}
          isFilledOut={profile?.last_name}
        />
        <StyledDatePicker
          ref={datepicker_ref}
          containerClassName={dateOfBirth ? 'selected' : ''}
          style={{ gridColumn: 'span 2', borderColor: dateOfBirth ? '#fbb919' : '' }}
          value={dateOfBirth}
          onChange={handleDateOfBirthOnChange}
          calendarProps={{ defaultView: 'century', defaultCurrentDate: new Date('1970') }}
          placeholder={dateOfBirthPlaceholder}
          valueDisplayFormat={{ dateStyle: 'long' }}
          valueEditFormat={{ dateStyle: 'long' }}
          onFocus={() => setDateOfBirthPlaceholder('mm/dd/yyyy')}
          onBlur={() => setDateOfBirthPlaceholder('Date of Birth')}
          onMouseLeave={() => {
            (document.activeElement as any).blur();
          }}
        />
        <SexSelectorContainer
          onClick={handleSexOnChange}
          style={{ gridColumn: 'span 2', borderColor: profile?.sex ? '#fbb919' : '' }}
        >
          {profile?.sex === 'M' ? 'Male' : 'Female'}
          <RefreshIcon src={ICON_REFRESH} alt="" />
        </SexSelectorContainer>
      </FormGridContainer>
      <StyledNextButton onClick={handleNextOnClick} disabled={!nextButtonIsEnabled}>
        {'Next'}
      </StyledNextButton>
      <StyledFooter>
        Israeli law requires we capture information on the <YellowText>beneficiary</YellowText> of
        funds. If that&apos;s not you, fill in the entire registration form using the
        beneficiary&apos;s details. Beneficiaries can be{' '}
        <YellowText>yourself, a child, or a parent</YellowText>.
      </StyledFooter>
    </>
  );
};
