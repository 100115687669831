import { makeUseAxios } from 'axios-hooks';

import { configure } from 'axios-hooks';
import LRU from 'lru-cache';
import Axios from 'axios';
import * as Sentry from '@sentry/react';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_OLEHPAY_ENDPOINT,
});
// request interceptor to add token to request headers
axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      const basicAuth = 'Token ' + token;
      config.headers = {
        authorization: basicAuth,
      } as any;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// response interceptor intercepting 401 responses, refreshing token and retrying the request
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;

    if (error?.response?.status === 401 && !config._retry) {
      // we use this flag to avoid retrying indefinitely if
      // getting a refresh token fails for any reason
      config._retry = true;
      // localStorage.setItem('token', await refreshAccessToken());

      return axios(config);
    }

    Sentry.captureException(error);

    return Promise.reject(error);
  },
);

const cache = new LRU({ max: 10 });

configure({ axios, cache });

export const useAxios = makeUseAxios({
  axios: axios,
});
