import styled from 'styled-components';
import useAxios from 'axios-hooks';

import { useEffect, useRef, useState } from 'react';
import { THEME } from '../../../constants';
import { Loading } from '../../../components/loading';

const StyledHeader = styled.h1.attrs({
  className: 'StyledHeader',
})`
  text-align: center;
  padding-left: 0px;
  display: block;
  color: #fff;
  font-size: 35px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-weight: 900;
`;

const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 20.5px 12px;
  margin-bottom: 12px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
  cursor: text;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &.h-70 {
    min-height: 70px;
  }
  &:focus,
  &:active,
  &:hover {
    border: 1px solid #fbb919;
    outline: none;
    opacity: 1;
  }
`;

const StyledButton = styled.button.attrs({
  className: 'StyledButton',
})<{ disabled }>(
  ({ disabled }) => `
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    font-family: 'Fontsfree net proxima nova';
    font-size: 28px;
    font-weight: 600;
    border: none;
    color: rgb(255, 255, 255);
    margin-left: 0;
    width: 100%;
    background-color: ${disabled ? '#cccccc' : 'rgb(251, 185, 25)'};
    opacity: ${disabled ? 0.8 : 0.95};
    cursor: ${disabled ? 'default' : 'pointer'};
  `,
);

export const Subtitle = styled.div.attrs({
  className: 'Subtitle',
})`
  display: inline-block;
  margin-bottom: 25px;
  padding-left: 12px;
  font-family: 'Fontsfree net proxima nova';
  font-size: 23px;
  font-weight: 300;
  line-height: 24px;
  color: #555;
`;

const TextContainer = styled.div.attrs({
  className: 'TextContainer',
})`
  margin-top: 15px;
  padding-right: 11px;
  padding-left: 11px;
  font-family: 'Fontsfree net proxima nova';
  font-weight: 300;
  color: hsla(0deg, 0%, 100%, 0.53);
  line-height: 22px;
`;

const YellowLink = styled.a.attrs({
  className: 'YellowLink',
})`
  color: ${THEME.yellow};
`;

const ErrorMessageContainer = styled.div.attrs({
  className: 'ErrorMessageContainer',
})<{ isValid: boolean }>(
  ({ isValid }) => `
    min-height: 30px;
    color: ${isValid ? 'green' : 'red'};
    padding: 0 0 10px 0;
  `,
);

export const ChangePassword = ({ token }) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [updatePasswordButtonIsEnabled, setUpdatePasswordButtonIsEnabled] = useState(false);

  const [{ loading: loadingValidatePassword }, validatePassword] = useAxios(
    {
      url: 'authorization/validate_password/',
      method: 'post',
      data: {
        password1: password1,
        password2: password2,
      },
    },
    { manual: true },
  );
  const [{ data: updatePasswordResponse, loading: updatePasswordIsLoading }, updatePassword] =
    useAxios(
      {
        url: 'password_reset/confirm/',
        method: 'POST',
        data: {
          token,
          password: password1,
        },
      },
      {
        manual: true,
      },
    );

  useEffect(() => {
    if (!password1 || !password2) return setPasswordErrorMessage('');

    validatePassword()
      .then((response) => {
        if (response.data === 'ok') {
          setPasswordErrorMessage('Strong password!');
          setPasswordIsValid(true);
        } else {
          const messages = JSON.parse(response.data);
          setPasswordErrorMessage(messages.join(' '));
          setPasswordIsValid(false);
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_CANCELED') return;
      });
  }, [password1, password2]);

  useEffect(() => {
    if (loadingValidatePassword) setUpdatePasswordButtonIsEnabled(false);
    else setUpdatePasswordButtonIsEnabled(passwordIsValid);
  }, [loadingValidatePassword]);

  useEffect(() => {
    if (passwordIsValid) setUpdatePasswordButtonIsEnabled(true);
    else setUpdatePasswordButtonIsEnabled(false);
  }, [passwordIsValid]);

  const handleOnClick = () => {
    updatePassword().then((response) => (window.location.href = 'signin'));
  };

  if (updatePasswordIsLoading || updatePasswordResponse) return <Loading opacity={0.9} />;

  return (
    <>
      <StyledHeader>OlehPay Password Reset</StyledHeader>
      <Subtitle>Set a new password</Subtitle>
      <StyledInput
        placeholder="New Password"
        type="password"
        onChange={(event) => setPassword1(event.target.value)}
      />
      <StyledInput
        placeholder="Confirm New Password"
        type="password"
        onChange={(event) => setPassword2(event.target.value)}
      />
      <ErrorMessageContainer isValid={passwordIsValid}>
        {loadingValidatePassword ? 'Checking...' : passwordErrorMessage}
      </ErrorMessageContainer>
      <StyledButton onClick={handleOnClick} disabled={!updatePasswordButtonIsEnabled}>
        Update Password
      </StyledButton>
      <TextContainer>
        Our dedicated team is here to help. You can always shoot us an email at{' '}
        <YellowLink href="mailto:support@olehpay.co.il">support@olehpay.co.il</YellowLink>
      </TextContainer>
    </>
  );
};
