import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import moment from 'moment';

import {
  StyledFieldset,
  StyledLegend,
  EditableInput,
  Detail,
  TwoOptionContainer,
  RefreshIcon,
  StyledButton,
  StyledLink,
  SavingContainer,
} from '../../common-components';
import ICON_REFRESH from '../../../../assets/imgs/icon/refresh_icon.svg';
import { useIsMount } from '../../../../hooks/useIsMount';

const UserDetailsForm = styled.div.attrs({
  className: 'UserDetailsForm',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
`;

export const UserDetails = ({
  user,
  setUser,
  profile,
  setProfile,
  bitachRecipient,
  unityfiLocationUser,
}) => {
  const handleEntityOnChange = () =>
    setUser((user) => ({
      ...user,
      entity: user.entity === 'ISRAELI_CITIZEN' ? 'NON_ISRAELI_CITIZEN' : 'ISRAELI_CITIZEN',
    }));

  const handleUpdateNotes = (event) =>
    setProfile((profile) => ({
      ...profile,
      notes: event.target.value,
    }));

  return (
    <>
      <StyledFieldset>
        <StyledLegend>User Details</StyledLegend>
        <UserDetailsForm>
          <Detail> Joined: {moment(user?.created_at).format('MMM Do, YYYY')} </Detail>
          <Detail>Last login: {moment(user?.last_login).format('MMM Do, YYYY')}</Detail>
          <Detail>{user?.entity}</Detail>
          <EditableInput
            value={profile?.notes || ''}
            onChange={handleUpdateNotes}
            placeholder="notes"
          />
          {bitachRecipient ? (
            <StyledLink transparent href={bitachRecipient?.link}>
              Bitach ID: {bitachRecipient?.primary_key}
            </StyledLink>
          ) : (
            <span></span>
          )}
          {unityfiLocationUser ? (
            <Detail>UnityFi ID: {unityfiLocationUser?.location_user_id}</Detail>
          ) : null}
        </UserDetailsForm>
      </StyledFieldset>
    </>
  );
};
