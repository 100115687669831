import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Logo from '../../assets/imgs/logo.svg';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAxios } from '../../hooks/axios';
import axios from 'axios';
import { notification } from 'antd';
import FacebookLogin from '@greatsumini/react-facebook-login';

import { useGoogleLogin } from '@react-oauth/google';

const Wrapper = styled.div`
  background-color: #171c2d;
  height: 100vh;
  width: 100vw;
  overflow: auto;
`;

const WrapperFlex = styled.div`
  display: flex;
  button {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
  }
`;
const WrapperDiv = styled.div``;

const LoginPanel = styled.div`
  width: calc(100% - 20px);
  max-width: 386px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  .logo-img {
    width: 200px;
    margin: 0 auto;
    display: block;
  }
  h2 {
    text-align: center;
    padding-left: 0px;
    display: block;
    color: hsla(0, 0%, 100%, 0.53);
  }
  .submit-button-2 {
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: rgb(251, 185, 25);
    font-family: 'Fontsfree net proxima nova';
    font-size: 28px;
    opacity: 0.95;
    font-weight: 500;
    border: none;
    color: rgb(255, 255, 255);
    margin-left: 0;
    width: 100%;
    &:disabled {
      background-color: #cccccc;
      border-color: transparent;
      opacity: 0.8 !important;
      cursor: default;
    }
  }
`;

export const SignInFromFacebook = ({ setToken }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const _location = useLocation();
  const _navigate = useNavigate();

  const [{ loading: loadingSocial }, loginAsSocial] = useAxios(
    {
      url: '/login/social/token_user/',
      method: 'POST',
    },
    { manual: true },
  );

  const [{ loading: loadingUserInfo }, getUserByuuid] = useAxios(
    {
      url: '',
      method: 'GET',
    },
    { manual: true },
  );

  const loginAsFb = async (code) => {
    try {
      const { data } = await loginAsSocial({
        data: {
          provider: 'facebook',
          code: code,
          redirect_uri: `${process.env.REACT_APP_OLEHPAY_ENDPOINT}/complete/facebook/`,
        },
      });

      if (data && data?.token) {
        if (data && data?.token) {
          const { data: userData } = await getUserByuuid({
            url: '/users/' + data.uuid,
            headers: {
              authorization: 'Token ' + data?.token,
            },
          });

          setToken({
            token: data?.token,
            user: userData,
          });
          if (userData?.is_superuser) return (window.location.href = '/transfers');
          else if (userData?.is_registered) return (window.location.href = '/account');
          else return (window.location.href = '/join');
        }
        if (data?.user?.is_superuser) return (window.location.href = '/transfers');
        else if (data?.user?.is_registered) return (window.location.href = '/account');
        else return (window.location.href = '/join');
      }
    } catch (error: any) {
      console.log(error);
      setError(
        error.response.data.non_field_errors
          ? error.response.data.non_field_errors.join(' ')
          : JSON.stringify(error.response.data),
      );
    }
  };

  useEffect(() => {
    if (searchParams && searchParams.get('code')) {
      const code = searchParams.get('code');
      loginAsFb(code);
    }
  }, [searchParams]);

  useEffect(() => {
    document.body.style.overscrollBehavior = 'none';

    return () => {
      document.body.style.overscrollBehavior = '';
    };
  }, []);

  return (
    <Wrapper>
      <LoginPanel>
        <img src={Logo} alt="logo" className="logo-img" style={{ marginBottom: '58px' }} />
        <h2 className="text-block-2">
          {error ? (
            //{error}
            <span style={{ color: 'red' }}>{error}</span>
          ) : (
            'Sign in to your OlehPay account'
          )}
        </h2>

        <Button
          disabled={loadingSocial || loadingUserInfo}
          className="submit-button-2"
          label={'Signing in with facebook...'}
        />
        <WrapperDiv className="paragraph-2">
          Don&apos;t have an account?{' '}
          <a href="/signup">
            <span className="text-span-4">join OlehPay here.</span>
            <br />
          </a>
          Forgot your password?{' '}
          <a href="/forgot">
            <span className="text-span-4">click here.</span>
          </a>
        </WrapperDiv>
      </LoginPanel>
    </Wrapper>
  );
};
