import styled from 'styled-components/macro';
import { THEME } from '../../constants';

const ReferralHeader = styled.div`
  font-size: 30px !important;
`;

const WrapContent = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const ReferralInfo = styled.div`
  width: 100%;
  height: 35vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #222a42;
    padding: 10px;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #465170;
  }
`;

const WrapTitle = styled.div.attrs({
  className: 'WrapTitle',
})`
  // background-color: ${THEME.ligher_dark_blue_2};
  border-radius: 10px;
  color: ${THEME.white};
  margin-top: 30px;
  font-size: 25px;
`;

const Text = styled.div.attrs({
  className: 'Text',
})`
  font-family: 'Fontsfree net proxima nova';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin: 5px 0;
  color: rgba(255, 255, 255, 0.9);
  padding-right: 30px;
  width: 100%;
`;

const ReferralContainer = styled.div`
  background: #222a42;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #465170;
  border-radius: 6px;
  margin-bottom: 30px;
  width: 100%;
  color: #fff;
  font-size: 12px;
  padding: 15px;
  position: relative;
  height: 45px;
`;

const SubmitAction = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  right: 10px;
  top: 7.5px;
  width: 30px;
  height: 30px;
  background: #fbac1f;
  & img {
    position: relative;
    top: 1px;
  }
  &:hover {
    box-shadow: 0 0 10px rgba(255, 255, 255, 1);
  }
`;
// leaving this here for when referral metrics are up and running on API
const ReferralMetrics = styled.div`
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  background: #222a42;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #465170;
  border-radius: 6px;
  color: #fff;
  & > div {
    border-width: 1px 1px 1px 1px;
    padding: 5px 10px;
    border-style: solid;
    border-color: #465170;
  }
  & > :first-child {
    border-top-left-radius: 6px;
  }
  & > :last-child {
    border-bottom-right-radius: 6px;
  }
  & > :nth-child(2) {
    border-top-right-radius: 6px;
  }
  & > :nth-child(3) {
    border-bottom-left-radius: 6px;
  }
`;

const NoReferrals = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  font-size: 30px;
  color: #222a42;
  height: 10vh;
  align-items: center;
`;

const RefCode = styled.div`
  width: calc(100% - 35px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const IconBubble = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 100%;
  background-color: #25304e;
`;

const InfoContainer = styled.div`
  position: relative;
  & img {
    position: absolute;
    top: 30px;
  }
  & span,
  & div {
    text-align: center;
    color: ${THEME.white};
  }
  & > div {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export default {
  InfoContainer,
  IconBubble,
  RefCode,
  NoReferrals,
  ReferralMetrics,
  SubmitAction,
  ReferralContainer,
  Text,
  WrapTitle,
  ReferralInfo,
  WrapContent,
  ReferralHeader,
};
