import styled from "styled-components";

import { THEME } from "../../../constants";

export const ResidencyDocumentFormContainer = styled.div.attrs({
    className: 'ResidencyDocumentFormContainer',
  })`
    display: grid;
    grid-template-rows: 1fr 1fr auto 1fr;
    gap: 12px;
    width: 100%;
  `;
  
  export const StyledUploadDocumentButton = styled.button.attrs({
    className: 'StyledUploadDocumentButton',
  })`
    height: 70px;
    border-radius: 8px;
    font-family: 'Fontsfree net proxima nova';
    font-size: 28px;
    font-weight: 500;
    border: none;
    color: rgb(255, 255, 255);
    width: 100%;
    background-color: rgb(251, 185, 25);
    opacity: 0.95;
    cursor: pointer;
  `;
  
  export const DocumentPreviewImageContainer = styled.div.attrs({
    className: 'DocumentPreviewImageContainer',
  })`
    width: 100%;
    // height: 100%;
    border-radius: 10px;
    margin-bottom: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
  `;
  
  export const DocumentPreviewImage = styled.img.attrs({
    className: 'DocumentPreviewImage',
  })`
    width: 100%;
    max-height: 100%;
  `;
  
  export const DocumentTypeContainer = styled.div.attrs({
    className: 'DocumentTypeContainer',
  })`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `;
  
  export const IframePreview = styled.iframe`
    width: 100%;
    height: 300px;
  `;

  export const SkipTextContainer = styled.p.attrs({
    className: 'skip-text-container',
  })`
    padding-left: 11px;
    color: hsla(0deg, 0%, 100%, 0.53);
    margin: 0;
  `;

  export const ClickHereContainer = styled.span.attrs({
    className: 'click-here-container',
  })`
    text-decoration: underline;
    color: ${THEME.light_blue_link};
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
  `;