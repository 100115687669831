import { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import 'react-widgets/scss/styles.scss';

import {
  StyledFieldset,
  StyledLegend,
  EditableInput,
  StyledSelect,
  DownArrowIcon,
  SavingContainer,
  StyledOption,
} from '../../common-components';
import ICON_ARROW_DOWN from '../../../../assets/imgs/icon/arrow_card_down.svg';
import { useIsMount } from '../../../../hooks/useIsMount';

const PhoneForm = styled.div.attrs({
  className: 'PhoneForm',
})`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  gap: 1em;
  margin-bottom: 1em;
`;

const PhoneCountryCodeContainer = styled.div.attrs({
  className: 'PhoneCountryCodeContainer',
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const PhoneDetails = ({ phoneNumber, setPhoneNumber }) => {
  const isFirstRender = useIsMount();
  const [phoneNumberCountryCodeValue, setPhoneNumberCountryCodeValue] = useState(
    phoneNumber?.country,
  );
  const [{ loading: phoneNumberIsSaving }, updatePhoneNumber] = useAxios(
    {
      url: `/phone_numbers/${phoneNumber?.uuid}/`,
      method: 'PATCH',
      data: {
        country: phoneNumber?.country,
        number: phoneNumber?.number || '',
        area_code: phoneNumber?.area_code || '',
      },
    },
    { manual: true },
  );

  const phoneNumberCountryOptions = [
    { alpha2: 'US', countryCode: '+1' },
    { alpha2: 'IL', countryCode: '+972' },
  ].map((option) => (
    <StyledOption key={option.alpha2} value={option.alpha2}>
      {option.countryCode}
    </StyledOption>
  ));

  const handlePhoneNumberCountryOnChange = (event) => {
    const countryCode = event.target.value === 'IL' ? '+972' : '+1';
    setPhoneNumber((phoneNumber) => ({
      ...phoneNumber,
      country: event.target.value,
      country_code: countryCode,
    }));
    setPhoneNumberCountryCodeValue(event.target.value);
  };

  const handleOnPhoneNumberOnKeyPress = (event) => {
    const key = event.which;
    if ((key < 48 || key > 57) && (key < 96 || key > 105) && key != 8)
      return event.preventDefault();
  };

  const handlePhoneNumberOnChange = (event) => {
    setPhoneNumber((phoneNumber) => ({
      ...phoneNumber,
      number: event.target.value,
    }));
  };

  const handlePhoneAreaCodeOnChange = (event) => {
    setPhoneNumber((phoneNumber) => ({
      ...phoneNumber,
      area_code: event.target.value,
    }));
  };

  useEffect(() => {
    if (isFirstRender) return;
    updatePhoneNumber().catch((error) => {
      if (error.code === 'ERR_CANCELED') return;
      console.log(error);
      alert(JSON.stringify(error?.response?.data));
    });
  }, [phoneNumber]);

  return (
    <>
      <StyledFieldset>
        <StyledLegend>Phone Number</StyledLegend>
        <PhoneForm>
          <PhoneCountryCodeContainer style={{ gridColumn: 'span 7' }}>
            <StyledSelect
              onChange={handlePhoneNumberCountryOnChange}
              value={phoneNumberCountryCodeValue}
            >
              <option value=""></option>
              {phoneNumberCountryOptions}
            </StyledSelect>
            <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
          </PhoneCountryCodeContainer>
          <EditableInput
            style={{ gridColumn: 'span 4' }}
            value={phoneNumber?.area_code || ''}
            onChange={handlePhoneAreaCodeOnChange}
            onKeyPress={handleOnPhoneNumberOnKeyPress}
            placeholder="Area Code"
          ></EditableInput>
          <EditableInput
            style={{ gridColumn: 'span 9' }}
            value={phoneNumber?.number || ''}
            onChange={handlePhoneNumberOnChange}
            onKeyPress={handleOnPhoneNumberOnKeyPress}
            placeholder="Phone Number"
          ></EditableInput>
        </PhoneForm>
      </StyledFieldset>
      <SavingContainer active={phoneNumberIsSaving}>saving phone...</SavingContainer>
    </>
  );
};
