export const THEME = {
  light_dark_blue: '#1B2337',
  ligher_dark_blue: '#25304E',
  ligher_dark_blue_2: '#222A42',
  dark_blue: '#1C2338',
  light_blue: '#202D53',
  light_blue_link: '#ccccff',
  yellow: '#FBAC1F',
  blue: '#194BFB',
  green: '#4FB47C',
  gray: '#96A1C1',
  gray2: '#a5b0c6',
  light_gray: 'rgba(165, 176, 198, 0.52)',
  red: '#A21818',
  white: '#fff',
  linear_bg: 'radial-gradient(69.18% 85.12% at 47.48% 86.3%, #0D1D43 0%, #181D2C 100%)',
  linear_bg_2: 'linear-gradient(0deg, #1B2337, #1B2337)',
  border_color: '#465170',
  breakpoint: 768,
  breakpointTablet: 992,
  breakpointHeight: 730,
};

export const CUSTOM_MODAL_STYLE = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
    border: 'none', //'1px solid #353F56',
    background: '#353F56',
    borderRadius: '8px',
    color: '#fff',
    padding: 0,
    height: '536px',
    maxWidth: '360px',
    width: 'calc(100vw - 20px)',
  },
};
