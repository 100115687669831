import styled from 'styled-components';
import { THEME } from '../../../constants';

export const SubtitleContainer = styled.div.attrs({
  className: 'SubtitleContainer',
})`
  display: inline-block;
  margin-bottom: 25px;
  padding-left: 12px;
  font-family: 'Fontsfree net proxima nova';
  font-size: 23px;
  font-weight: 300;
  line-height: 24px;
  color: hsla(0, 0%, 100%, 0.53);
`;

export const StyledNextButton = styled.button.attrs({
  className: 'StyledNextButton',
})<{ disabled }>(
  ({ disabled }) => `
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    font-family: 'Fontsfree net proxima nova';
    font-size: 28px;
    font-weight: 500;
    border: none;
    color: rgb(255, 255, 255);
    margin-left: 0;
    width: 100%;
    background-color: ${disabled ? '#cccccc' : 'rgb(251, 185, 25)'};
    opacity: ${disabled ? 0.8 : 0.95};
    cursor: ${disabled ? 'default' : 'pointer'};
  `,
);

export const StyledFooter = styled.div.attrs({
  className: 'StyledFooter',
})`
  margin-top: 15px;
  padding-right: 11px;
  padding-left: 11px;
  font-family: 'Fontsfree net proxima nova';
  font-weight: 300;
  color: hsla(0deg, 0%, 100%, 0.53);
  line-height: 22px;
  padding-bottom: 22px;
`;

export const DownArrowIcon = styled.img.attrs({
  className: 'DownArrowIcon',
})`
  position: absolute;
  right: 12px;
  cursor: pointer;
`;

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})<{ isFilledOut: any }>(
  ({ isFilledOut }) => `
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 20.5px 12px;
  height: 70px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
  cursor: text;
  width: 100%;
  border: 1px solid ${isFilledOut ? 'rgb(251, 185, 25)' : 'rgb(204, 204, 204)'};
  // border: 1px solid ${isFilledOut ? 'blue' : 'red'};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &.h-70 {
    min-height: 70px;
  }
  &:visited {
    border: 1px solid #fbb919;
    opacity: 0.8;
    outline: none;
  }
  &:focus,
  &:active,
  &:hover {
    // border: 1px solid #fbb919;
    // border-color: rgb(251, 185, 25);
    outline: none;
    opacity: 1;
  }
`,
);

export const StyledSelect = styled.select.attrs({
  className: 'StyledSelect',
})`
  appearance: none;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 20.5px 12px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
  cursor: pointer;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  option {
    color: #222;
  }
  &:focus,
  &:active,
  &:hover {
    border: 1px solid #fbb919;
    outline: none;
    opacity: 1;
  }
`;

export const YellowText = styled.span.attrs({
  className: 'YellowText',
})`
  color: ${THEME.yellow};
`;

export const YellowLink = styled.a.attrs({
  className: 'YellowLink',
})`
  text-decoration: none;
  cursor: pointer;
  color: ${THEME.yellow};
  &:hover {
    opacity: 0.9;
  }
`;
