import moment from 'moment';

export const chartOptions: any = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  layout: {
    padding: {
      top: 25,
    },
  },
  options: {
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    // legend: {
    //   align: 'start' as const,
    //   position: 'bottom' as const,
    //   labels: {
    //     usePointStyle: true,
    //     boxWidth: 6,
    //     boxHeight: 6,
    //     color: '#fff',
    //   },
    // },

    tooltip: {
      // position: 'custom',
      caretSize: 0,
      callbacks: {
        title: (tooltipItem, data) => {
          document.getElementById('tooltipValueChart').innerHTML =
            parseFloat(tooltipItem[0].formattedValue).toFixed(3) + ' USD/ILS';
          document.getElementById('current-date').style.display = 'none';
          document.getElementById('current-date-hover').style.display = 'block';
          document.getElementById('current-date-hover').innerHTML =
            moment(tooltipItem[0].label).format('MMM DD, YYYY') + ' 15:30 IST';
          return null;
        },
        label: () => null,
        afterLabel: () => null,
      },
      backgroundColor: 'transparent',
      // callbacks: {
      //   title: function (tooltipItem, data) {
      //     return data['labels'][tooltipItem[0]['index']];
      //   },
      //   label: function (tooltipItem, data) {
      //     return null;
      //   },
      //   afterLabel: function (tooltipItem, data) {
      //     return null;
      //   },
      // label: function (context) {
      //   debugger;
      //   let label = context.dataset.label || '';

      //   if (label) {
      //     label += ': ';
      //   }
      //   if (context.parsed.y !== null) {
      //     label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
      //       context.parsed.y,
      //     );
      //   }
      //   return label;
      // },
      // },
    },
    legend: { display: false },
  },
  scales: {
    x: {
      border: {
        dashOffset: 20,
        dash: [4, 4],
      },
      ticks: {
        color: '#fff',
        display: false,
      },
      beginAtZero: true,
      grid: {
        display: false,
        color: function (context: any) {
          return '#4F4F4F';
        },
        borderDash: [8, 4],
      },
    },
    y: {
      beginAtZero: false,
      suggestedMin: 3.0,
      suggestedMax: 4.0,
      ticks: {
        color: '#96a1c1',
        fontSize: 6,
        display: true,
        // padding: 25,
      },
      border: {
        dashOffset: 20,
        dash: [4, 4],
      },
      grid: {
        display: false,
        color: function (context: any) {
          return '#4F4F4F';
        },
      },
    },
  },
};

export const chartLineHoverTooltipPlugin = [
  {
    id: 'custom_hover_tooltip',
    afterDraw: (chart) => {
      if (chart.tooltip?._active?.length) {
        const x = chart.tooltip._active[0].element.x;
        const y = chart.tooltip._active[0].element.y;
        const yAxis = chart.scales.y;
        const ctx = chart.ctx;
        const activePoint = chart.tooltip._active[0];
        const topY = yAxis.top;
        const bottomY = yAxis.bottom;
        const xTT = x;
        ctx.font = 'normal 500 12px Inter';
        ctx.fillStyle = 'rgb(165, 176, 198)';
        ctx.fillText(
          chart.data.labels[activePoint.index],
          xTT - (activePoint.index < 94 ? 40 : 80),
          topY - 10,
        );

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#FBB919';
        ctx.stroke();

        // ctx.fillText('zzz', x, 0);

        ctx.restore();

        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, 6, 0, 2 * Math.PI, false);
        ctx.fillStyle = '#FBB919';
        ctx.fill();
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#FBB919';
        ctx.stroke();

        // ctx.fillText('zzz', x, 0);

        ctx.restore();
      } else {
        document.getElementById('current-date').style.display = 'block';
        document.getElementById('current-date-hover').style.display = 'none';
        document.getElementById('tooltipValueChart').innerHTML = '';
        document.getElementById('current-date-hover').innerHTML = '';
      }
    },
  },
];
