/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../hooks/axios';
import { HeaderHome } from '../../components/header-home';
import { FooterHome } from '../../components/footer-home';

import moment from 'moment';
export const FAQ = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken }) => {
  return (
    <div className="home-content text-white">
      <HeaderHome
        userIsAdmin={userIsAdmin}
        userIsRegistered={userIsRegistered}
        userIsAuthenticated={userIsAuthenticated}
        setToken={setToken}
        isGrayHeader
      />

      <section className="main">
        <div
          id="indexPageCalcHolder"
          className="calculator_holder"
          style={{ margin: '0 auto 0', maxWidth: '100%' }}
        >
          <div className="marginBottom">
            <div className="spacing_half started2">
              <nav style={{ textAlign: 'center' }}>
                <div className="menu-footer-menu-unregistered-container" style={{ margin: 'auto' }}>
                  <ul id="primary-menu" className="add_nav">
                    <li className="menu-item current-menu-item">
                      <a href="#registering">Registering</a>
                    </li>
                    <li className="menu-item">
                      <a href="#transfers">Transfers</a>
                    </li>
                    <li className="menu-item">
                      <a href="#security">Security and OlehPay</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="info_full_upload upload_mobile3 started1">
                <div
                  style={{ width: '100%', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                >
                  <div
                    className="registerSize started_mobile started text-white"
                    style={{ backgroundColor: 'rgba(55, 65, 88, 0.35)', marginBottom: '80px' }}
                  >
                    <h3 className="startedH3">General</h3>
                    <h4
                      className="startedH4 postedByDate dateInfoMobile"
                      style={{ textAlign: 'right' }}
                    >
                      Last Updated September 29, 2020
                    </h4>

                    <h4 className="startedH4" id="registering">
                      What is OlehPay?
                    </h4>
                    <p className="startedP">
                      OlehPay lets users transfer money seamlessly and transparently to Israel
                      without various fees one would otherwise be subject to with converntional
                      methods, such as sending and receiving bank fees, SWIFT wire fees, ATM
                      withdrawal fees, or check processing fees.
                    </p>
                    <br />

                    <h4 className="startedH4">What are the fees for using OlehPay?</h4>
                    <p className="startedP">
                      See the{' '}
                      <a href="https://olehpay.co.il/info#pricing" target="_blank" rel="noreferrer">
                        pricing and pricing terms
                      </a>{' '}
                      for a quick cost breakdown. To establish transparency, OlehPay exchanges money
                      at the Bank of Israel daily rate and makes no money on the arbitrage of the
                      exchange rate. OlehPay often undercuts the costs of other systems for moving
                      money to Israel, as OlehPay processes transfers domestically in both
                      jurisdictions, removing all the associated banking and processing costs.
                      <br /> For instance, if one sends $1000 with OlehPay, $980 converted at the
                      rate that is released daily by the Bank of Israel is deposited into your
                      Israeli bank account. OlehPay eliminates all other sending, receiving,
                      processing or withdrawing fees otherwise charged by your bank.
                    </p>
                    <br />

                    <h4 className="startedH4">
                      What documents do I need to open an account with OlehPay?
                    </h4>
                    <p className="startedP">
                      For those with Israeli citizenship: a Tudat Zehut, any secondary government
                      issued ID (such as an Israeli or American passport, driver&apos;s license, or
                      Teudat Oleh), and a blank or voided copy of a check OR bank statement from
                      your Israeli processing account.
                      <br /> For Americans without Israeli citizenship: a US passport, driver&apos;s
                      license, and a copy of one of your Israel checks OR a bank statement. Notice:
                      If you do not hold Israeli citizenship, OlehPay may ask for proof of residency
                      through a utility bill or address.
                    </p>
                    <br />

                    <h4 className="startedH4">
                      Does it cost money to open an account with OlehPay?
                    </h4>
                    <p className="startedP">No. OlehPay is 100% free and always will be.</p>
                    <br />

                    <h4 className="startedH4">How do I submit documents to OlehPay?</h4>
                    <p className="startedP">
                      All documents required by OlehPay can be uploaded during the registration
                      process. Should Olehpay require additional documents, we may request them
                      through email at <b>support@olehpay.co.il</b>.
                    </p>
                    <br />

                    <h4 className="startedH4">Do I need to give OlehPay my bank details?</h4>
                    <p className="startedP">
                      Israeli bank accounts are onboarded by providing a blank or voided check OR
                      bank statement during the registration process.
                      <br />
                      From your OlehPay account, you can add an American or Israeli bank account by
                      selecting &quot;Add A Bank Account&quot;. A console will appear allowing you
                      to select your bank. Follow the prompts to connect your account.
                      <br />
                      If you do not find your bank, contact support@olehpay.co.il, and a team member
                      will help you connect the account manually.
                    </p>
                    <br />

                    <h4 className="startedH4">
                      Can I use OlehPay if I am not either an American citizen, an Oleh/ah
                      Hadash/ah, or an Israeli citizen?
                    </h4>
                    <p className="startedP">
                      Yes! <br /> Not American: You can use OlehPay if you are not an American
                      citizen. <br /> Not Israeli Citizen: If you do not hold a valid Teudat Zehut
                      you can still use OlehPay. You may be requested to provide proof of residency.
                      American citizens without Israeli citizenship can open a bank account at the
                      Postal Bank בנק הדואר.
                      <br /> Not an Oleh/ah Hadash/ah: despite our name, anyone who needs to send
                      money to Israeli can use OlehPay.
                    </p>
                    <br />
                    <h4 className="startedH4">
                      Can I use OlehPay to send money from an LLC, corporate account, brokerage or
                      investment account?
                    </h4>
                    <p className="startedP">
                      OlehPay can only debit personal accounts. We are unable at the moment to
                      accept funds from corporate accounts, such as LLCs.
                    </p>
                    <br />
                    <h4 className="startedH4">
                      Do I need an Israeli bank account to receive money?
                    </h4>
                    <p className="startedP">
                      Yes. If you do not have an Israeli account, you can open a bank account at the
                      Postal Bank בנק הדואר. without being a citizen. While Postal Bank cannot
                      receive international wires, you can send money to your Postal Bank account
                      using OlehPay.
                    </p>
                    <h4 className="startedH4">
                      Can I receive money to my Israeli account from an American account not owned
                      by me?
                    </h4>
                    <p className="startedP">
                      Aside from parents sending money to children, OlehPay cannot facilitate third
                      party payments. To inquire about whether OlehPay can facilitate your transfer,
                      contact <b>support@olehpay.co.il</b>.
                    </p>
                    <br />
                    <h4 className="startedH4" id="transfers">
                      Does OlehPay have a minimum transfer size?
                    </h4>

                    <p className="startedP">Yes. Our minimum transfder size is $300.</p>
                    <br />
                    <h4 className="startedH4">What currencies does OlehPay offer?</h4>
                    <p className="startedP">
                      At this moment, OlehPay offers only USD as the originating currency and NIS as
                      the receiving currency.
                    </p>
                    <br />

                    <h4 className="startedH4">
                      How long does it take for money to reach my account?
                    </h4>
                    <p className="startedP">
                      After your account is approved, transfers take between 4-5 business days to
                      receive funds in your Israeli bank account. For an in depth explanation, see
                      our <a href="https://olehpay.co.il/transfer-times">tranfer times page</a>.
                    </p>
                    <br />
                    <h4 className="startedH4">How do I receive my money?</h4>
                    <p className="startedP">
                      You receive Shekel deposited directly into your Israeli bank account.
                    </p>
                    <br />
                    <h4 className="startedH4">How do I cancel a transfer?</h4>
                    <p className="startedP">
                      We allow cancellations only in certain circumstances. Please send an email
                      immediately to
                      <b>support@olehpay.co.il</b> to cancel a transfer.
                    </p>
                    <br />
                    <h4 className="startedH4">Is there a maximum amount I can transfer?</h4>
                    <p className="startedP">
                      There are no maximum transfer limits with OlehPay. We{' '}
                      <a href="/info#pricing">gauruntee the cheapest rates</a> for all transfer
                      sizes. If you have questions or concerns regarding transfering funds through
                      OlehPay, please get in touch with us by email at info@olehpay.co.il. Please
                      include details about your case.
                    </p>
                    <br />
                    <h4 className="startedH4">Where can I find my pending transactions?</h4>
                    <p className="startedP">
                      Pending transactions are logged within your{' '}
                      <a href="/acocunt">
                        <b>account</b>
                      </a>{' '}
                      page. You must be logged in to view your account page.
                    </p>
                    <br />
                    <h4 className="startedH4">Does OlehPay accept checks?</h4>
                    <p className="startedP">No, OlehPay does not accept checks.</p>
                    <br />
                    <h4 className="startedH4">How do I initiate a transfer?</h4>
                    <p className="startedP">
                      You can easily initiate a transfer from your account page by choosing the bank
                      you&apos;d like to draw from. You do not need to call your bank to send a
                      transfer, as OlehPay runs the transfer as a debit straight from your US
                      account. Shekels are posted directly to your Israeli account.
                    </p>
                    <br />
                    <h4 className="startedH4" id="security">
                      Is my personal information secure?
                    </h4>

                    <p className="startedP">
                      OlehPay adheres to the highest banking security protocols in the industry to
                      keep information safe. For more information on how OlehPay goes great lengths
                      to keep your info secure visit our{' '}
                      <a href="https://olehpay.co.il/info#security">security page</a>.
                    </p>
                    <br />
                    <h4 className="startedH4">Is my money safe with OlehPay?</h4>
                    <p className="startedP">
                      OlehPay is regulated and licenced through its partners in both Israel and the
                      United States. Our partners enforce rigorous compliance and reporting
                      requirements to ensure we meet the strictest regulatory standards in both
                      jurisdictions. For more informantion see our{' '}
                      <a href="https://olehpay.co.il/info#security">security page</a>.
                    </p>
                    <br />
                    <h4 className="startedH4">
                      Why is OlehPay cheaper than money changers and banks?
                    </h4>
                    <p className="startedP">
                      OlehPay eliminates all costs associated with conventional methods of sending
                      money, such as sending and receiving bank fees, SWIFT wire fees, ATM
                      withdrawal fees, or check processing fees. <br /> OlehPay&apos;s greatest
                      advantage over conventional methods of sending money is that OlehPay can run
                      transactions domestically in both the United States and Israel, while
                      balancing currency pulls in both jurisdictions. OlehPay manages its own
                      exchange and passes the inexpensive and innovative method of sending money on
                      to its customers.
                    </p>
                    <br />
                    <h4 className="startedH4">
                      Where is OlehPay located, and can I come for a visit?
                    </h4>
                    <p className="startedP">
                      OlehPay is a technology company with no visitable address. Our team, however,
                      is always available to chat. We have representatives around Israel and in
                      California. Send us an email at <b>support@olehpay.co.il</b> if you&apos;d
                      like to schedule a chat, an in person meeting, or visit.
                    </p>
                    <br />
                    <p className="startedP">
                      If you found this article helpful, let us know!
                      <br /> For more information or general inquiries contact us at{' '}
                      <a href="mailto:info@olehpay.co.il">info@olehpay.co.il</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterHome isHideIntro />
    </div>
  );
};
