import styled from 'styled-components'

import { THEME } from '../../../../constants'

export const StyledStatusButton = styled.button.attrs({
  className: 'StyledButton',
})<{ transparent?: boolean }>(
  ({ transparent = false }) => `
    // min-width: 100px;
    cursor: pointer;
    height: auto;
    padding: 16px;
    border: 1px solid ${THEME.yellow};
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 100%;
    opacity: 0.95;
    background: ${transparent ? 'transparent' : THEME.yellow};
    color: ${THEME.white};
    &:hover {
      opacity: 1;
    }
    &:disabled {
      background-color: #cccccc;
      border-color: transparent;
      opacity: 0.8 !important;
      cursor: default;
    }
    &:hover {
      opacity: 0.9;
    }
  `,
)
