
import styled from "styled-components";

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
`

export const WrapAllPage = styled.div`
  @media (max-width: 992px) {
    .home-content .send-cta-wrapper {
      order: inherit;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      a {
        height: 30px;
        min-width: 95px;
        padding-left: 10px;
        padding-right: 10px;
        .c1 {
        }
      }
      .label-dark {
        display: flex;
        margin-top: 4px;

        width: 100%;
        .dark-label-text {
          font-size: 8px;
        }
      }
    }
    .home-content .currency-calculator-form-container {
      flex-direction: row;
      gap: 7px;
      .textfield-wrapper {
        height: 50px;
        min-height: 50px;
        min-width: 110px;
        .textfield-icon-wrapper {
          margin-left: 5px;
          top: 7px;
        }
        .textfield-label {
          margin-top: 7px;
          margin-left: 41px;
        }
        input {
          padding: 40px 10px 27px 40px;
          font-size: 12px;
        }
      }
    }
    .home-content .textfield-wrapper {
      min-width: auto;
      flex: 1;
    }
    .home-content .text-align-center.left-align-text-mobile {
      font-weight: 500;
      font-size: 38px;
      line-height: 48px;
      padding-right: 25px;
    }
    .home-content
      .tcb-mid.text-weight-reg.text-align-center.left-align-text-mobile {
      font-size: 12px;
      line-height: 20px;
      font-weight: 300;
    }
    .home-content .header-webflow {
      height: 77px !important;
    }
    .home-content {
      .header-logo-wrapper {
        padding-left: 14px !important;
      }
      .menu-button {
        display: none;
      }
      .mobile-call-to-action {
        position: fixed;
        right: 14px;
        top: 0;
        z-index: 999;
        a {
          font-size: 12px;
        }
      }
    }
  }
`;

export const WrapDigitalAd = styled.div`
  .section-ad {
    position: relative;
    padding: 34px 77px;
    text-align: center;
    background: #fbac1f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 48px;
      margin: 0;
      color: #fff;
    }
  }
  .custom-container {
    margin: 170px auto;
    max-width: 776px;
    padding: 21px;
    border-radius: 15px;
    background: rgb(255 255 255 / 7%);

    .wrap-form-signup {
      display: flex;
      flex-direction: column;
      gap: 24px;
      input {
        background: #171d2d;
        border: none;
        border-radius: 7px;
        padding: 24px;
        font-weight: 300;
      }
      .yellow-button {
        padding: 22px 51px;
        font-size: 18px;
        font-weight: 300;
        color: #fff;
        border-radius: 7px;
        background: #fbac1f;
        border: none;
      }
      .position-relative {
        width: 100%;
        flex: 1;
        position: relative;
        input {
          width: 100%;
        }
        .circle-mark {
          position: absolute;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          border: 1px solid #3a3f4c;
          right: 23px;
          top: 23px;
        }
      }
      .flex-buttons {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: center;
        button {
          font-size: 24px;
          background: #4284f4;
          font-weight: 300;
          font-family: "Proxima Nova";
          color: #fff;
          padding: 24px;
          flex: 1 1;
          border-radius: 7px;
          &:last-child {
            background: #3c5997;
          }
        }
      }
    }
  }
  .reviews-container {
    max-width: 1388px;
    margin: 0 auto;
    padding: 60px;
    margin-bottom: 100px;
    position: relative;
  }
  .p-message {
    max-width: 1110px;
    margin: 0 auto;
    padding: 41px;
    background: rgb(255 255 255 / 7%);
    border-radius: 15px;
    font-size: 22px;
    font-weight: 300;
    color: #fff;
    text-align: center;
    border: 1px solid #fff;
    line-height: 34px;
  }
  .logo-section {
    margin: 160px auto;
    width: fit-content;
  }
  .footer-message {
    font-size: 18px;
    font-weight: 300;
    padding: 18px;
    background: #194bfb;
    text-align: center;
    color: #fff;
  }

  @media (max-width: 992px) {
    padding: 35px;
    .custom-container {
      padding: 15px 11px;
      .wrap-form-signup {
        gap: 13px;
        padding: 15px 11px;
        input {
          font-size: 12px;
          padding: 24px 16px;
        }
      }
      .flex-buttons {
        flex-direction: column;
        gap: 13px;
        button {
          font-size: 14px !important;
          width: 100%;
        }
      }
    }
    .yellow-button {
      padding: 14px 32px !important;
    }
    .section-ad {
      left: -34px;
      width: calc(100% + 68px);
      padding: 17px 34px;
      h2 {
        font-size: 22px;
      }
    }
    .reviews-container {
      padding: 0 !important;
    }
    .p-message {
      font-size: 12px;
    }
    .logo-section svg {
      width: 100%;
    }
    .footer-message {
      position: relative;
      bottom: -34px;
      left: -34px;
      width: calc(100% + 64px);
      z-index: 999;
      font-size: 12px;
    }
  }
`;