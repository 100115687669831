import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import styled from 'styled-components';

import { RegistrationAddressAndPhone } from './address-and-phone';
import { RegistrationEntity } from './entity-selection';
import { RegistrationPersonalDetails } from './personal-details';
import { Loading } from '../../components/loading';
import { RegistrationIdentification } from './identifications';
import { RegistrationHebrewName } from './hebrew-name';
import { RegistrationResidencyVerification } from './residency-verification-document';
import { THEME } from '../../constants';
import Logo from '../../assets/imgs/logo.svg';
import Complete from '../../assets/imgs/complete.png';
import Incomplete from '../../assets/imgs/incomplete.png';
import Back from '../../assets/imgs/back-arrow.svg';

const SignUpPanelWrapper = styled.div.attrs({
  className: 'SignUpPanelWrapper',
})`
  background-color: #171c2d;
  height: 100vh;
  width: 100vw;
  overflow: auto;
`;

const SignUpPanel = styled.div.attrs({
  className: 'SignUpPanel',
})`
  width: calc(100% - 20px);
  max-width: 386px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const LogoImage = styled.img.attrs({
  className: 'Logo',
})`
  width: 200px;
  margin: 0 auto;
  display: block;
`;

const BackArrowContainer = styled.div.attrs({
  className: 'BackArrowContainer',
})`
  position: relative;
  width: 100%;
  cursor: pointer;
  text-align: center;
  left: -6px;
`;

const BackArrowImage = styled.img.attrs({
  className: 'BackArrowImage',
})`
  position: static;
  width: 10px;
  padding-top: 16px;
  padding-bottom: 10px;
  float: none;
  clear: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
`;

const RegistrationNavigationContainer = styled.div.attrs({
  className: 'RegistrationNavigationContainer',
})`
  margin-top: 8px;
  height: 54px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  gap: 0;
`;

const ProgressBarContainer = styled.div.attrs({
  clasName: 'ProgressBarContainer',
})`
  width: 100%;
`;

const ProgressBarBackground = styled.div.attrs({
  className: 'ProgressBarBackground',
})`
  height: 12px;
  margin-top: 20px;
  margin-right: 0%;
  margin-left: 0%;
  float: none;
  border-radius: 20px;
  background-color: hsla(0deg, 0%, 93%, 0.4);
  opacity: 1;
`;

const ProgressBarFill = styled.div.attrs({
  className: 'ProgressBarFill',
})<{ percent: number }>(
  ({ percent }) => `
    width: ${percent}%;
    background-color: ${THEME.yellow};
    opacity: 1;
    height: 12px;
    border-radius: inherit;
    transition: 1s ease;
    overflow: hidden;
  `,
);

const CheckIconContainer = styled.div.attrs({
  className: 'CheckIconContainer',
})`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
`;

const CheckIconImage = styled.img.attrs({
  className: 'CheckIconImage',
})`
  height: 25px;
`;

const Subtitle = styled.div.attrs({
  className: 'Subtitle',
})`
  text-align: center;
  padding-left: 0px;
  display: block;
  display: block;
  color: #fff;
  font-size: 35px;
  line-height: 40px;
  margin-top: 0px;
  // margin-bottom: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-weight: 500;
  // padding-left: 20px;
`;

export const Registration = ({ setToken }) => {
  const [{ data: userResponse }] = useAxios('/users/');
  const [{ data: addressesResponse }] = useAxios('/addresses/');
  const [{ data: aliasResponse }] = useAxios('/aliases/');
  const [{ data: phoneNumbersResponse }] = useAxios('/phone_numbers/');
  const [{ data: profilesResponse }] = useAxios('/profiles/');
  const [{ data: residencyDocumentResponse }] = useAxios(
    '/documents/?type__value=RESIDENCY_VERIFICATION',
  );
  const [{ data: identificationsResponse }] = useAxios('/identifications/');

  const user = userResponse?.results[0];

  const [currentStep, setCurrentStep] = useState(0);
  const [entity, setEntity] = useState(user?.entity);
  const [profile, setProfile] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [address, setAddress] = useState(null);
  const [alias, setAlias] = useState(null);
  const [residencyDocument, setResidencyDocument] = useState(null);
  const [residencyDoucmentFileData, setResidencyDocumentFileData] = useState(null);
  const [primaryIdentification, setPrimaryIdentification] = useState(null);
  const [secondaryIdentification, setSecondaryIdentification] = useState(null);
  const [primaryIdentificationFileData, setPrimaryIdentificationFileData] = useState(null);
  const [secondaryIdentificationFileData, setSecondaryIdentificationFileData] = useState(null);
  const [nextButtonIsEnabled, setNextButtonIsEnabled] = useState(false);

  const [{ loading: loadingUpdateUser }, updateUser] = useAxios(
    {
      url: `/users/${user?.uuid}/`,
      method: 'PATCH',
      data: {
        entity,
      },
    },
    { manual: true },
  );
  const [{ loading: loadingUpdateProfile }, updateProfile] = useAxios(
    {
      url: `/profiles/${profile?.uuid}/`,
      method: 'PATCH',
    },
    { manual: true },
  );
  const [{ loading: loadingUpdateAddress }, updateAddress] = useAxios(
    {
      url: `/addresses/${address?.uuid}/`,
      method: 'PATCH',
      data: {
        ...address,
        region: address?.country === 'IL' ? null : address?.region,
      },
    },
    { manual: true },
  );
  const [{ loading: loadingUpdateAlias }, updateAlias] = useAxios(
    {
      url: `/aliases/${alias?.uuid}/`,
      method: 'PATCH',
      data: alias,
    },
    { manual: true },
  );
  const [{ loading: loadingUpdatePhoneNumber }, updatePhoneNumber] = useAxios(
    {
      url: `/phone_numbers/${phoneNumber?.uuid}/`,
      method: 'PATCH',
      data: phoneNumber,
    },
    { manual: true },
  );
  const [{ loading: loadingUpdateResidencyVerification }, updateResidencyVerification] = useAxios(
    {
      url: `/documents/${residencyDocument?.uuid}/`,
      method: 'PATCH',
    },
    { manual: true },
  );
  const [{ loading: loadingUpdatePrimaryIdentification }, updatePrimaryIdentification] = useAxios(
    {
      url: `/identifications/${primaryIdentification?.uuid}/`,
      method: 'PATCH',
    },
    { manual: true },
  );
  const [{ loading: loadingUpdateSecondaryIdentification }, updateSecondaryIdentification] =
    useAxios(
      {
        url: `/identifications/${secondaryIdentification?.uuid}/`,
        method: 'PATCH',
      },
      { manual: true },
    );
  useEffect(() => {
    document.body.style.overscrollBehavior = 'none';

    return () => {
      document.body.style.overscrollBehavior = '';
    };
  }, []);

  useEffect(() => {
    if (user) setEntity(user?.entity);
  }, [user]);

  useEffect(() => {
    if (!profilesResponse?.results[0]) return;
    const profileData = profilesResponse?.results[0];
    delete profileData?.is_approved;
    delete profileData?.is_registered;
    profileData.sex = profileData?.sex || 'M';
    setProfile(profileData);
  }, [profilesResponse]);

  useEffect(() => {
    if (!phoneNumbersResponse?.results[0]) return;
    const phoneNumberData = phoneNumbersResponse?.results[0];
    phoneNumberData.country = phoneNumber?.country || 'IL';
    setPhoneNumber(phoneNumberData);
  }, [phoneNumbersResponse]);

  useEffect(() => {
    if (!addressesResponse?.results[0]) return;
    const addressData = addressesResponse?.results[0];
    addressData.country = addressData?.country || 'IL';
    setAddress(addressData);
  }, [addressesResponse]);

  useEffect(() => {
    setAlias(aliasResponse?.results[0]);
  }, [aliasResponse]);

  useEffect(() => {
    setResidencyDocument(residencyDocumentResponse?.results[0]);
  }, [residencyDocumentResponse]);

  useEffect(() => {
    if (!identificationsResponse?.results || !identificationsResponse?.results[0]) return;
    const country = entity === 'NON_ISRAELI_CITIZEN' ? 'US' : 'IL';
    const primaryIdentificationData = identificationsResponse?.results[0];
    primaryIdentificationData.type = primaryIdentificationData?.type || 'STATE_IDENTIFICATION_CARD';
    primaryIdentificationData.country = primaryIdentificationData?.country || country;
    const secondaryIdentificationData = identificationsResponse?.results[1];
    secondaryIdentificationData.type = secondaryIdentificationData?.type || 'DRIVERS_LICENSE';
    secondaryIdentificationData.country = secondaryIdentificationData?.country || country;
    setPrimaryIdentification(primaryIdentificationData);
    setSecondaryIdentification(secondaryIdentificationData);
  }, [identificationsResponse]);

  useEffect(() => {
    if (!entity) return;
    const country = entity === 'ISRAELI_CITIZEN' ? 'IL' : 'US';
    if (!primaryIdentification?.country)
      setPrimaryIdentification((primaryIdentification) => ({
        ...primaryIdentification,
        country: primaryIdentification?.country || country,
      }));
    if (!secondaryIdentification?.country)
      setSecondaryIdentification((secondaryIdentification) => ({
        ...secondaryIdentification,
        country: secondaryIdentification?.country || country,
      }));
  }, [entity]);

  const redirect = async () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    if (token && user) {
      user.is_registered = true;
      await setToken({ token, user });
      window.location.href = '/account';
    } else {
      setCurrentStep(5);
    }
  };
  useEffect(() => {
    if (
      currentStep !== 6 ||
      loadingUpdateUser ||
      loadingUpdateProfile ||
      loadingUpdatePhoneNumber ||
      loadingUpdateAddress ||
      loadingUpdateAlias ||
      loadingUpdateResidencyVerification ||
      loadingUpdatePrimaryIdentification ||
      loadingUpdateSecondaryIdentification
    )
      return;
    redirect();
  }, [
    currentStep,
    loadingUpdateUser,
    loadingUpdateProfile,
    loadingUpdatePhoneNumber,
    loadingUpdateAddress,
    loadingUpdateAlias,
    loadingUpdateResidencyVerification,
    loadingUpdatePrimaryIdentification,
    loadingUpdateSecondaryIdentification,
  ]);

  const handleBackArrowOnClick = () => {
    if (currentStep === 0) return;
    setCurrentStep((step) => step - 1);
  };

  const sendResidencyVerification = () => {
    const residencyVerificationForm = new FormData();
    if (residencyDoucmentFileData?.blob)
      residencyVerificationForm.append(
        'file',
        residencyDoucmentFileData?.blob,
        residencyDoucmentFileData?.image?.name,
      );
    updateResidencyVerification({
      data: residencyVerificationForm,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  const sendPrimaryIdentification = () => {
    const primaryIdentificationForm = new FormData();
    if (primaryIdentificationFileData?.blob)
      primaryIdentificationForm.append(
        'file',
        primaryIdentificationFileData?.blob,
        primaryIdentificationFileData?.image?.name,
      );
    primaryIdentificationForm.append('country', primaryIdentification?.country);
    primaryIdentificationForm.append('issue_date', primaryIdentification?.issue_date);
    primaryIdentificationForm.append('expiration_date', primaryIdentification?.expiration_date);
    primaryIdentificationForm.append('number', primaryIdentification?.number);
    primaryIdentificationForm.append('type', primaryIdentification?.type);
    updatePrimaryIdentification({
      data: primaryIdentificationForm,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  const sendSecondaryIdentification = () => {
    const secondaryIdentificationForm = new FormData();
    if (secondaryIdentificationFileData?.blob)
      secondaryIdentificationForm.append(
        'file',
        secondaryIdentificationFileData?.blob,
        secondaryIdentificationFileData?.image?.name,
      );
    secondaryIdentificationForm.append('country', secondaryIdentification?.country);
    secondaryIdentificationForm.append('issue_date', secondaryIdentification?.issue_date);
    secondaryIdentificationForm.append('expiration_date', secondaryIdentification?.expiration_date);
    secondaryIdentificationForm.append('number', secondaryIdentification?.number);
    secondaryIdentificationForm.append('type', secondaryIdentification?.type);
    updateSecondaryIdentification({
      data: secondaryIdentificationForm,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  const handleNextOnClick = () => {
    switch (currentStep) {
      case 0:
        updateProfile({
          data: {
            first_name: profile?.first_name,
            last_name: profile?.last_name,
            date_of_birth: profile?.date_of_birth,
            sex: profile?.sex,
          },
        });
        break;
      case 1:
        updateUser();
        break;
      case 2:
        updateAddress();
        updatePhoneNumber();
        break;
      case 3:
        if (entity === 'ISRAELI_CITIZEN') updateAlias();
        else sendResidencyVerification();
        break;
      case 4:
        sendPrimaryIdentification();
        break;
      case 5:
        sendSecondaryIdentification();
        updateProfile({
          data: {
            is_registered: true,
          },
        });
        break;
    }
    setCurrentStep((step) => step + 1);
  };

  const renderFormSignUp = () => {
    switch (currentStep) {
      case 0:
        return (
          <RegistrationPersonalDetails
            profile={profile}
            setProfile={setProfile}
            handleNextOnClick={handleNextOnClick}
            nextButtonIsEnabled={nextButtonIsEnabled}
            setNextButtonIsEnabled={setNextButtonIsEnabled}
          />
        );
      case 1:
        return (
          <RegistrationEntity
            entity={entity}
            setEntity={setEntity}
            handleNextOnClick={handleNextOnClick}
            nextButtonIsEnabled={nextButtonIsEnabled}
            setNextButtonIsEnabled={setNextButtonIsEnabled}
          />
        );
      case 2:
        return (
          <RegistrationAddressAndPhone
            address={address}
            setAddress={setAddress}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            handleNextOnClick={handleNextOnClick}
            nextButtonIsEnabled={nextButtonIsEnabled}
            setNextButtonIsEnabled={setNextButtonIsEnabled}
          />
        );
      case 3:
        return entity === 'ISRAELI_CITIZEN' ? (
          <RegistrationHebrewName
            alias={alias}
            setAlias={setAlias}
            handleNextOnClick={handleNextOnClick}
            nextButtonIsEnabled={nextButtonIsEnabled}
            setNextButtonIsEnabled={setNextButtonIsEnabled}
          />
        ) : (
          <RegistrationResidencyVerification
            title="Upload Proof of Residency"
            handleNextOnClick={handleNextOnClick}
            residencyDocument={residencyDocument}
            residencyDocumentFileData={residencyDoucmentFileData}
            setResidencyDocumentFileData={setResidencyDocumentFileData}
            nextButtonIsEnabled={nextButtonIsEnabled}
            setNextButtonIsEnabled={setNextButtonIsEnabled}
            setCurrentStep={setCurrentStep}
          />
        );
      case 4:
        return (
          <RegistrationIdentification
            title={`Upload a primary ${entity === 'ISRAELI_CITIZEN' ? 'Israeli' : 'American'} ID`}
            handleNextOnClick={handleNextOnClick}
            identification={primaryIdentification}
            setIdentification={setPrimaryIdentification}
            identificationFileData={primaryIdentificationFileData}
            setIdentificationFileData={setPrimaryIdentificationFileData}
            nextButtonIsEnabled={nextButtonIsEnabled}
            setNextButtonIsEnabled={setNextButtonIsEnabled}
            entity={entity}
            setCurrentStep={setCurrentStep}
            designation="primary"
          />
        );
      case 5:
        return (
          <RegistrationIdentification
            title={`Upload a secondary ID`}
            handleNextOnClick={handleNextOnClick}
            identification={secondaryIdentification}
            setIdentification={setSecondaryIdentification}
            identificationFileData={secondaryIdentificationFileData}
            setIdentificationFileData={setSecondaryIdentificationFileData}
            nextButtonIsEnabled={nextButtonIsEnabled}
            setNextButtonIsEnabled={setNextButtonIsEnabled}
            setCurrentStep={setCurrentStep}
            entity={entity}
            designation="secondary"
          />
        );
      case 6:
        return <Loading />;
    }
  };

  if (
    !addressesResponse ||
    !identificationsResponse ||
    !phoneNumbersResponse ||
    !profilesResponse ||
    !userResponse
  )
    return <Loading />;
  return (
    <SignUpPanelWrapper>
      <SignUpPanel>
        <LogoImage src={Logo} alt="logo" />
        <RegistrationNavigationContainer>
          <BackArrowContainer onClick={handleBackArrowOnClick}>
            {currentStep === 0 ? null : <BackArrowImage src={Back} width="20" alt="" />}
          </BackArrowContainer>
          <ProgressBarContainer>
            <ProgressBarBackground>
              <ProgressBarFill percent={currentStep * 16.6667}></ProgressBarFill>
            </ProgressBarBackground>
          </ProgressBarContainer>
          <CheckIconContainer>
            <CheckIconImage src={nextButtonIsEnabled ? Complete : Incomplete} alt="" />
          </CheckIconContainer>
        </RegistrationNavigationContainer>
        <Subtitle>Register Your Account</Subtitle>
        {renderFormSignUp()}
      </SignUpPanel>
    </SignUpPanelWrapper>
  );
};
