/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useEffect } from 'react';
import { THEME } from '../../constants';

import styled from 'styled-components';

export const HeaderHome: FC<{
  userIsRegistered;
  userIsAuthenticated;
  setToken;
  isGrayHeader?;
  userIsAdmin?;
}> = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken, isGrayHeader }) => {
  useEffect(() => {
    setTimeout(() => {
      const listAnimate = document.getElementsByClassName('animate-transform');
      for (let i = 0; i <= listAnimate.length; i++) {
        if (listAnimate[i]) {
          listAnimate[i]['style'].transform =
            'translateX(0%) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)';
        }
      }
    }, 0);
  }, []);

  return (
    <>
      <div
        className="header-webflow w-nav animate-transform"
        style={{
          transform:
            'translateX(0) translateY(-80PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)',
        }}
      >
        <div className="header-container w-container">
          <a href="https://olehpay.co.il" className="header-logo-wrapper w-nav-brand">
            <img src={require('../../assets/imgs/logo.svg').default} className="logo-dark" />
          </a>
          <nav role="navigation" className="header-nav-container w-nav-menu">
            <a
              href="/"
              className="header-nav-link w-nav-link w--current"
              style={{ maxWidth: '940px' }}
            >
              Home
            </a>
            <a href="/info" className="header-nav-link w-nav-link" style={{ maxWidth: '940px' }}>
              Why OlehPay
            </a>
            {userIsAuthenticated && (
              <>
                <a
                  // href="javascript:;"
                  onClick={() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    setToken(null);
                    setTimeout(() => {
                      window.location.href = '/';
                    }, 0);
                  }}
                  className="header-nav-link w-nav-link"
                  style={{ maxWidth: '940px', cursor: 'pointer' }}
                >
                  Logout
                </a>
                <div className="hero-apply-cta-wrapper">
                  <a
                    href={userIsAdmin ? '/transfers' : userIsRegistered ? '/account' : '/join'}
                    className="primary-cta w-button"
                  >
                    {userIsAdmin
                      ? 'Manage'
                      : userIsRegistered
                      ? 'My Account'
                      : 'Continue Registration'}
                  </a>
                </div>
              </>
            )}

            {!userIsAuthenticated && (
              <>
                <a
                  href="/signin"
                  className="header-nav-link w-nav-link"
                  style={{ maxWidth: '940px' }}
                >
                  Sign In
                </a>
                <div className="hero-apply-cta-wrapper">
                  <a href="/signup" className="primary-cta w-button">
                    GET STARTED
                  </a>
                </div>
              </>
            )}
          </nav>
          <div className="menu-button w-nav-button">
            <img src={require('../../assets/imgs/menu.svg').default} />
          </div>
        </div>
        <div
          className="header-bg"
          style={{ backgroundColor: isGrayHeader ? 'rgba(55, 65, 88, 0.95' : '' }}
        ></div>
        <div className="w-nav-overlay" data-wf-ignore=""></div>
      </div>
    </>
  );
};
