import styled from 'styled-components';
import useAxios from 'axios-hooks';

import Logo from '../../assets/imgs/logo.svg';
import { useEffect, useRef, useState } from 'react';
import { THEME } from '../../constants';
import { Loading } from '../../components/loading';
import { SubmitEmail } from './submit-email';
import { ChangePassword } from './change-password';
import { useSearchParams } from 'react-router-dom';

const Wrapper = styled.div`
  background-color: #171c2d;
  height: 100vh;
  width: 100vw;
  overflow: auto;
`;

const PasswordResetPanel = styled.div`
  width: calc(100% - 20px);
  max-width: 386px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const StyledImage = styled.img.attrs({
  className: 'StyledImage',
})`
  width: 200px;
  margin: 0 auto;
  display: block;
`;

const RegistrationEmailContainer = styled.div.attrs({
  className: 'RegistrationEmailContainer',
})``;

export const PasswordReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState(searchParams.get('token'));

  return (
    <Wrapper>
      <PasswordResetPanel>
        <StyledImage src={Logo} alt="logo" />
        <RegistrationEmailContainer>
          {token ? <ChangePassword token={token} /> : <SubmitEmail />}
        </RegistrationEmailContainer>
      </PasswordResetPanel>
    </Wrapper>
  );
};
