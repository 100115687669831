import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { THEME } from '../../constants';

import styled from 'styled-components';

import * as PDFJS from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import './pdf.scss';

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface PDF {
  file?: any;
  styleContainer?: any;
}

export const PDFPreview: FC<PDF> = ({ file, styleContainer }) => {
  const [pdf, setPDF] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const canvasRef = useRef() as any;

  const renderPage = useCallback(async ({ pdfDoc, pageNum, scale }) => {
    if (!canvasRef || !canvasRef.current) {
      return;
    }
    const page = await pdfDoc.getPage(pageNum);

    const ctx = (canvasRef.current as any).getContext('2d');

    const viewport = page.getViewport({ scale });
    if (document.body.clientWidth < 840) {
      canvasRef.current.width = document.body.clientWidth - 150;
      canvasRef.current.height = document.body.clientHeight - 300;
    } else {
      canvasRef.current.width = viewport.width;
      canvasRef.current.height = viewport.height;
    }

    page.render({
      canvasContext: ctx,
      viewport: viewport,
    });
  }, []);

  const prevPage = () => {
    if (currentPage > 1) {
      renderPage({ pdfDoc: pdf, pageNum: currentPage - 1, scale });
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < numPages) {
      renderPage({ pdfDoc: pdf, pageNum: currentPage + 1, scale });
      setCurrentPage(currentPage + 1);
    }
  };

  const zoomOut = () => {
    renderPage({ pdfDoc: pdf, pageNum: currentPage, scale: scale - 0.1 });
    setScale(scale - 0.1);
  };

  const zoomIn = () => {
    renderPage({ pdfDoc: pdf, pageNum: currentPage, scale: scale + 0.1 });
    setScale(scale + 0.1);
  };

  useEffect(() => {
    const fetchPdf = async () => {
      const loadingTask = PDFJS.getDocument(file);

      const pdfDoc = await loadingTask.promise;

      setPDF(pdfDoc);

      setNumPages(pdfDoc._pdfInfo.numPages);

      renderPage({ pdfDoc, pageNum: 1, scale: 1 });

      setLoaded(true);
    };

    fetchPdf();
  }, [renderPage, file]);

  return (
    <div className="pdf-container" style={styleContainer || {}}>
      <div className="container">
        {loaded ? (
          <div className="menu-bar">
            <div className="title">Pdf Previewer</div>
            <button>
              <i className="gg-play-track-prev" onClick={prevPage}></i>
            </button>
            <button>
              <i className="gg-play-track-next" onClick={nextPage}></i>
            </button>
            <div className="pagination">
              Page {currentPage} / {numPages}
            </div>
            {/* <i className="gg-zoom-in" onClick={zoomIn} />
            <i className="gg-zoom-out" onClick={zoomOut} /> */}
          </div>
        ) : (
          <h2 style={{ color: '#fff', textAlign: 'center', fontSize: '40px' }}>Loading...</h2>
        )}
        <div className="canvas-container">
          <div>
            <canvas ref={canvasRef} />
          </div>
        </div>
      </div>
    </div>
  );
};
