import { useState, useRef, useEffect } from "react";
import ReactCrop, { Crop, PercentCrop } from "react-image-crop";
import styled from "styled-components";
import "react-image-crop/dist/ReactCrop.css";

import { Camera } from "../camera";
import { PDFPreview } from "../pdf-preview";
import "./upload-document-widget.css";

import { Range } from "react-range";

const WrapRange = styled.div`
  position: absolute;
  top: -30px;
  width: 200px;
  left: -72px;
  padding: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px #525252;
  border-radius: 4px;
`;

const WrapButtonControl = styled.div`
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
const ButtonRotate = styled.button`
  border: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font: inherit;
  font-size: 115%;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;
`;

const ModalBackground = styled.div.attrs({
  className: "ModalBackground",
})<{ isModalWrapper: boolean }>(
  ({ isModalWrapper }) => `
    ${
      !isModalWrapper
        ? `position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(144, 144, 144, 0.98);
  z-index: 900;
  `
        : ""
    }
`
);

const UploadModal = styled.div.attrs({
  className: "uppload-modal",
})<{ isModalWrapper: boolean }>(
  ({ isModalWrapper }) => `
    @media (max-width: 840px) {
      ${
        isModalWrapper
          ? `
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
        `
          : `
        width: 90vw;
        height: 80vh;
        top:10vh;
        left: 5vw;
        `
      }
       
      & .center-middle {
        transform: none;
      }
      & #dragDropElement {
        font-size: 12px;
        padding: 2rem 0;
      }
      & > section {
        display: flex;
        height: calc(500px - 200px);
      }
      .preview {
        min-height: auto;
        margin-bottom: 15px;
      }
    }
`
);

const WrapTab = styled.li.attrs({
  className: "WrapTab",
})<{ isActive: boolean }>(
  ({ isActive }) => `
  ${isActive ? "background-color: white;" : null}
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`
);

const WrapButton = styled.button.attrs({
  className: "WrapButton",
})`
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const WrapModalPrimaryButton = styled.button.attrs({
  className: "WrapModalPrimaryButton",
})`
  background-color: #fbb919;
  color: #fff;
  border: none;
  transition: 0.3s;
  font: inherit;
  font-size: 115%;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const WrapCurrentPage = styled.div.attrs({
  className: "WrapCurrentPage",
})`
  padding: 5px;
  display: flex;
  flex: 1 0;
  width: 100%;
`;

const YourFileTabSVG = () => (
  <svg
    className="svg-inline--fa fa-file fa-w-12 fa-fw"
    aria-hidden="true"
    data-prefix="fas"
    data-icon="file"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    data-fa-i2svg=""
  >
    <path
      fill="currentColor"
      d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
    ></path>
  </svg>
);

const UploadFileTabSVG = () => (
  <svg
    className="svg-inline--fa fa-upload fa-w-16 fa-fw"
    aria-hidden="true"
    data-prefix="fas"
    data-icon="upload"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    data-fa-i2svg=""
  >
    <path
      fill="currentColor"
      d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
    ></path>
  </svg>
);

const CameraTabSVG = () => (
  <svg
    className="svg-inline--fa fa-camera fa-w-16 fa-fw"
    aria-hidden="true"
    data-prefix="fas"
    data-icon="camera"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    data-fa-i2svg=""
  >
    <path
      fill="currentColor"
      d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z"
    ></path>
  </svg>
);

const UploadFromURLTabSVG = () => (
  <svg
    className="svg-inline--fa fa-link fa-w-16 fa-fw"
    aria-hidden="true"
    data-prefix="fas"
    data-icon="link"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    data-fa-i2svg=""
  >
    <path
      fill="currentColor"
      d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
    ></path>
  </svg>
);

const RightArrowSVG = () => {
  return (
    <svg
      className="svg-inline--fa fa-arrow-right fa-w-14 fa-fw"
      aria-hidden="true"
      data-prefix="fas"
      data-icon="arrow-right"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      data-fa-i2svg=""
    >
      <path
        fill="currentColor"
        d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
      ></path>
    </svg>
  );
};

const SpinnerSVG = () => {
  return (
    <svg
      style={{ width: "50px", height: "50px" }}
      className="svg-inline--fa fa-spinner fa-w-16 fa-4x fa-spin fa-fw"
      aria-hidden="true"
      data-prefix="fas"
      data-icon="spinner"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <path
        fill="currentColor"
        d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
      ></path>
    </svg>
  );
};

const CircledCheckSVG = () => {
  return (
    <svg
      style={{ width: "55px", height: "55px" }}
      className="svg-inline--fa fa-check-circle fa-w-16 fa-4x fa-fw"
      aria-hidden="true"
      data-prefix="fas"
      data-icon="check-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <path
        fill="currentColor"
        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
      ></path>
    </svg>
  );
};

const NavbarTab = ({ handleTabOnClick, isActive, svg, title }) => {
  return (
    <WrapTab onClick={handleTabOnClick} isActive={isActive}>
      <WrapButton>
        {svg}
        {title}
      </WrapButton>
    </WrapTab>
  );
};

const CropFileSection = ({
  previewURL,
  setSelectedDocumentBlob,
  setSelectedDocument,
  setPreviewURL,
  closeUploadDocumentWidget,
  isPdf,
  image,
}) => {
  const imgRef = useRef<any>(null);
  const ref = useRef(null);
  const [naturalHeight, setNaturalHeight] = useState(0);
  const [naturalWidth, setNaturalWidth] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isOpenRotateSlide, setOpenRotateSlide] = useState(false);

  const [isUploaded, setIsUploaded] = useState(false);
  const [completedCrop, setCompletedCrop] = useState<PercentCrop>({
    unit: "%", // Can be 'px' or '%'
    width: 99,
    height: 99,
    x: 0.5,
    y: 0.5,
  });
  const [crop, setCrop] = useState<Crop>({
    unit: "%", // Can be 'px' or '%'
    width: 99,
    height: 99,
    x: 0.5,
    y: 0.5,
  });

  useEffect(() => {
    const handleOnClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenRotateSlide(false);
      }
    };
    document.addEventListener("mousedown", handleOnClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleOnClickOutside);
    };
  }, [ref]);

  const handleImageOnLoad = () => {
    const ntW = imgRef?.current?.naturalWidth;
    const ntH = imgRef?.current?.naturalHeight;
    const TO_RADIANS = Math.PI / 180;
    const rotateRads = (rotation || 0) * TO_RADIANS;
    const size = {
      width: ntW,
      height: ntH,
    };
    const h = ntH;
    const w = ntW;
    let c = Math.cos(rotateRads);
    let s = Math.sin(rotateRads);
    if (s < 0) {
      s = -s;
    }
    if (c < 0) {
      c = -c;
    }
    size.width = h * s + w * c;
    size.height = h * c + w * s;

    setNaturalHeight(size.height);
    setNaturalWidth(size.width);
  };

  const generateBlob = async (cropProp: any, image: any) => {
    const blob = await new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      canvas.width = naturalWidth;
      canvas.height = naturalHeight;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;
      const newImage = new Image();
      newImage.crossOrigin = "anonymous";
      newImage.onload = () => {
        const TO_RADIANS = Math.PI / 180;
        const rotateRads = (Number(rotation) || 0) * TO_RADIANS;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const cx = canvas.width / 2;
        const cy = canvas.height / 2;
        ctx.translate(cx, cy);
        ctx.rotate(rotateRads);

        ctx.drawImage(
          newImage,
          -newImage.naturalWidth / 2,
          -newImage.naturalHeight / 2
        );

        // ctx.drawImage(
        //   newImage,
        //   (cropProp.x * naturalWidth) / 100,
        //   (cropProp.y * naturalHeight) / 100,
        //   (cropProp.width * naturalWidth) / 100,
        //   (cropProp.height * naturalHeight) / 100,
        //   0,
        //   0,
        //   (cropProp.width * naturalWidth) / 100,
        //   (cropProp.height * naturalHeight) / 100,
        // );

        canvas.toBlob((blob) => {
          const newImageCrop = new Image();
          newImageCrop.crossOrigin = "anonymous";
          newImageCrop.onload = () => {
            const canvas2 = document.createElement("canvas");
            let addMore = 0;
            if (rotation !== 0) {
              addMore = 1.5;
            }
            canvas2.width = (cropProp.width * newImageCrop.naturalWidth) / 100;
            canvas2.height =
              (cropProp.height * newImageCrop.naturalHeight) / 100;

            const ctxCrop = canvas2.getContext("2d");
            if (!ctxCrop) return;

            ctxCrop.drawImage(
              newImageCrop,
              ((cropProp.x - addMore) * newImageCrop.naturalWidth) / 100,
              ((cropProp.y - addMore) * newImageCrop.naturalHeight) / 100,
              ((cropProp.width + (addMore === 1.5 ? 40 : 0)) *
                newImageCrop.naturalWidth) /
                100,
              ((cropProp.height + (addMore === 1.5 ? 40 : 0)) *
                newImageCrop.naturalHeight) /
                100,
              0,
              0,
              ((cropProp.width + (addMore === 1.5 ? 40 : 0)) *
                newImageCrop.naturalWidth) /
                100,
              ((cropProp.height + (addMore === 1.5 ? 40 : 0)) *
                newImageCrop.naturalHeight) /
                100
            );

            canvas2.toBlob((blob_) => {
              resolve(blob_);
            }, "image/jpeg");
          };
          newImageCrop.src = window.URL.createObjectURL(blob);
          // resolve(blob);
        }, "image/jpeg");
      };
      newImage.src = image.src;
    });
    return blob;
  };

  return isUploaded ? (
    <div className="center-middle">
      <div>
        <CircledCheckSVG />
      </div>
      <div style={{ fontSize: "18px" }}>Uploaded</div>
    </div>
  ) : (
    <div className="crop-container">
      <div className="preview">
        {isPdf ? (
          <PDFPreview file={previewURL} />
        ) : (
          <>
            <ReactCrop
              key={`img-${rotation}`}
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(_, percentCrop) => setCompletedCrop(percentCrop)}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={previewURL}
                onLoad={handleImageOnLoad}
                onChange={handleImageOnLoad}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  transform: "rotate(" + rotation + "deg)",
                }}
              />
            </ReactCrop>
          </>
        )}
      </div>
      <div className="bottom-buttons mt-2">
        <WrapButtonControl className="cta d-flex">
          <ButtonRotate ref={ref} onClick={() => setOpenRotateSlide(true)}>
            {isOpenRotateSlide && (
              <WrapRange>
                <Range
                  step={1}
                  min={-360}
                  max={360}
                  values={[rotation]}
                  onChange={(values) => {
                    console.log(values);
                    setRotation(values[0]);
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "6px",
                        width: "100%",
                        backgroundColor: "#ccc",
                        borderRadius: 4,
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "15px",
                        width: "15px",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        boxShadow: "0px 0px 6px #4e4b4b",
                      }}
                    />
                  )}
                />
              </WrapRange>
            )}
            <i className="fa fa-undo" aria-hidden="true"></i>
          </ButtonRotate>
          <WrapModalPrimaryButton
            onClick={async () => {
              if (isPdf) {
                setSelectedDocumentBlob(image);
                setPreviewURL(previewURL);
                setSelectedDocument(previewURL);
              } else {
                const croppedImageBlob = await generateBlob(
                  completedCrop,
                  imgRef.current
                );
                if (!croppedImageBlob && image) {
                  closeUploadDocumentWidget();
                  setIsUploaded(false);
                  return;
                }
                const previewURL = URL.createObjectURL(croppedImageBlob as any);
                setSelectedDocumentBlob(croppedImageBlob);
                setSelectedDocument(previewURL);
                setPreviewURL(previewURL);
              }
              setIsUploaded(true);
              setTimeout(() => {
                closeUploadDocumentWidget();
                setIsUploaded(false);
              }, 1000);
            }}
          >
            {isPdf ? "Upload" : <>Crop &amp; upload</>}
          </WrapModalPrimaryButton>
        </WrapButtonControl>
      </div>
    </div>
  );
};

const UploadFileSection = ({
  setCurrentTab,
  setImage,
  setPreviewURL,
  setShowPdf,
}) => {
  const inputFile = useRef(null);
  const [isDragging, setDragging] = useState(false);

  const handleOnDragLeave = (event: any) => {
    setDragging(false);
    event.preventDefault();
  };
  const handleOndragOver = (event: any) => {
    event.preventDefault();
    setDragging(true);
  };
  const handleOndrop = (event: any) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();
    debugger
    //let's grab the image file
    const imageFile = event.nativeEvent.dataTransfer.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      return;
    }
    if (imageFile.name.match(/\.(pdf)$/i)) {
      setImage(imageFile);
      setShowPdf(true);
      setPreviewURL(URL.createObjectURL(imageFile));
      setCurrentTab(0);
      return;
    }
    setShowPdf(false);
    setImage(imageFile);
    setPreviewURL(URL.createObjectURL(imageFile));
    setCurrentTab(0);
  };
  const handleOnButtonClick = () => {
    // `current` points to the mounted file input element
    (inputFile as any).current.click();
  };
  const handleOnChangeFile = (event: any) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();
    //let's grab the image file
    const imageFile = event.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      return;
    }
    if (imageFile.name.match(/\.(pdf)$/i)) {
      setImage(imageFile);
      setShowPdf(true);
      setPreviewURL(URL.createObjectURL(imageFile));
      setCurrentTab(0);
      return;
    }
    setShowPdf(false);
    setImage(imageFile);
    setPreviewURL(URL.createObjectURL(imageFile));
    setCurrentTab(0);
  };

  return (
    <div className="center-middle">
      <div
        id="dragDropElement"
        className={`mb-full ${isDragging ? "active" : ""}`}
        onDragLeave={handleOnDragLeave}
        onDragOver={handleOndragOver}
        onDrop={(e) => handleOndrop(e)}
        onClick={handleOnButtonClick}
      >
        Drag and drop here to upload
      </div>
      <p className="mb-full">
        <em>or</em>
      </p>
      <WrapModalPrimaryButton onClick={handleOnButtonClick}>
        Choose a file &nbsp;
        <RightArrowSVG />
      </WrapModalPrimaryButton>
      <input
        type="file"
        id="dragDropFileElt"
        ref={inputFile}
        onChange={handleOnChangeFile}
      />
    </div>
  );
};

const CameraSection = ({ setImage, setPreviewURL, setCurrentTab }) => {
  const [cardImage, setCardImage] = useState();
  return (
    <Camera
      onCapture={(blob: any) => {
        const imageFile = blob;
        setImage(imageFile);
        setPreviewURL(URL.createObjectURL(imageFile));
        setCurrentTab(0);
      }}
      onClear={() => setCardImage(undefined)}
    />
  );
};

const UploadFromURLSection = ({ setImage, setCurrentTab, setPreviewURL }) => {
  const [isFetchingImg, setFetchingImg] = useState(false);
  const [isShowErrorFile, setShowErrorFile] = useState(false);
  const [urlImage, setUrl] = useState(null);

  const handleInputUrl = async () => {
    if (urlImage === "") {
      return;
    }
    setFetchingImg(true);
    setShowErrorFile(false);

    const blob = await new Promise((resolve, reject) => {
      fetch(
        `https://images.weserv.nl/?url=${encodeURIComponent(
          urlImage.replace(/^https?:\/\//i, "")
        )}`
      )
        .then((e) => {
          return resolve(e.blob());
        })
        .catch(() => {
          setShowErrorFile(true);
          setTimeout(() => {
            setShowErrorFile(false);
          }, 2000);
          reject();
        });
    });
    setFetchingImg(false);
    if ((blob as any).type.indexOf("image") === -1) {
      setShowErrorFile(true);
      setTimeout(() => {
        setShowErrorFile(false);
      }, 1500);
      return;
    }
    setImage(blob as any);
    setPreviewURL(URL.createObjectURL(blob as any));
    setCurrentTab(0);
    return blob;
  };

  return (
    <>
      {isShowErrorFile && (
        <div className="errorMessage" style={{ display: "block" }}>
          <strong>Error: </strong>This file type is not allowed.
        </div>
      )}
      {isFetchingImg && (
        <div className="center-all">
          <div>
            <SpinnerSVG />
          </div>
          <br />
          <div style={{ fontSize: "16px" }}>Uploading...</div>
        </div>
      )}
      {!isFetchingImg && (
        <div className="center-middle">
          <label>
            <div>Link to file</div>
            <input
              value={urlImage}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              id="microLinkInput"
              type="text"
              placeholder="http://example.com/image.jpg"
            />
          </label>
          {/* <WrapModalPrimaryButton onClick={handleInputUrl}>
            Import from URL &nbsp;
            <RightArrowSVG />
          </WrapModalPrimaryButton> */}
        </div>
      )}
    </>
  );
};

const UploadDocumentWidgetContent = ({
  currentTab,
  image,
  previewURL,
  setCurrentTab,
  setImage,
  setPreviewURL,
  setSelectedDocumentBlob,
  setSelectedDocument,
  closeUploadDocumentWidget,
  isPdf,
  setShowPdf,
}) => {
  const sections = {
    0: (
      <CropFileSection
        previewURL={previewURL}
        isPdf={isPdf}
        image={image}
        setSelectedDocumentBlob={setSelectedDocumentBlob}
        setSelectedDocument={setSelectedDocument}
        setPreviewURL={setPreviewURL}
        closeUploadDocumentWidget={closeUploadDocumentWidget}
      />
    ),
    1: (
      <UploadFileSection
        setCurrentTab={setCurrentTab}
        setImage={setImage}
        setShowPdf={setShowPdf}
        setPreviewURL={setPreviewURL}
      />
    ),
    2: (
      <CameraSection
        setImage={setImage}
        setPreviewURL={setPreviewURL}
        setCurrentTab={setCurrentTab}
      />
    ),
    3: (
      <UploadFromURLSection
        setCurrentTab={setCurrentTab}
        setPreviewURL={setPreviewURL}
        setImage={setImage}
      />
    ),
  };

  return sections[currentTab];
};

export const UploadDocumentWidget = ({
  image,
  previewURL,
  uploadDocumentWidgetIsOpen,
  setImage,
  setPreviewURL,
  setSelectedDocumentBlob,
  setSelectedDocument,
  closeUploadDocumentWidget,
  isPdf,
  setShowPdf,
  isModalWrapper = false,
}) => {
  const [currentTab, setCurrentTab] = useState(1);
  const backgroundRef = useRef();
  const widgetRef = useRef();

  const handleClose = async () => {
    await setCurrentTab(1);
    await closeUploadDocumentWidget();
  };

  const handleBackgroundOnClick = (event) => {
    if (!(widgetRef?.current as HTMLElement).contains(event.target))
      handleClose();
  };

  if (!uploadDocumentWidgetIsOpen) return;

  return (
    <ModalBackground
      onMouseDown={handleBackgroundOnClick}
      ref={backgroundRef}
      isModalWrapper={isModalWrapper}
    >
      <UploadModal ref={widgetRef} isModalWrapper={isModalWrapper}>
        <aside>
          <nav>
            <ul>
              {image && (
                <NavbarTab
                  handleTabOnClick={() => setCurrentTab(0)}
                  isActive={currentTab == 0}
                  svg={<YourFileTabSVG />}
                  title="Your File"
                />
              )}
              <NavbarTab
                handleTabOnClick={() => setCurrentTab(1)}
                isActive={currentTab == 1}
                svg={<UploadFileTabSVG />}
                title="Upload File"
              />
              <NavbarTab
                handleTabOnClick={() => setCurrentTab(2)}
                isActive={currentTab == 2}
                svg={<CameraTabSVG />}
                title="Camera"
              />
              {/* <NavbarTab
                handleTabOnClick={() => setCurrentTab(3)}
                isActive={currentTab == 3}
                svg={<UploadFromURLTabSVG />}
                title="Import from URL"
              /> */}
            </ul>
          </nav>
          {/* <a
              className="uppload-branding"
              href="https://uppload.js.org/?utm_source=uppload-widget&amp;utm_medium=0-4-1&amp;utm_campaign=olehpay.co.il"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Uppload
            </a> */}
        </aside>
        <section>
          {/* <div className="errorMessage"></div> */}
          <WrapCurrentPage>
            <UploadDocumentWidgetContent
              currentTab={currentTab}
              image={image}
              isPdf={isPdf}
              setShowPdf={setShowPdf}
              previewURL={previewURL}
              setCurrentTab={setCurrentTab}
              setImage={setImage}
              setPreviewURL={setPreviewURL}
              setSelectedDocumentBlob={setSelectedDocumentBlob}
              setSelectedDocument={setSelectedDocument}
              closeUploadDocumentWidget={handleClose}
            />
          </WrapCurrentPage>
        </section>
      </UploadModal>
    </ModalBackground>
  );
};
