import React, { useState } from 'react';
import { Outlet, Link, redirect, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../assets/imgs/logo.svg';
import ICON_USER from '../../assets/imgs/icon/user.svg';
import ICON_CURRENCY from '../../assets/imgs/icon/currency.svg';
import ICON_FILE from '../../assets/imgs/icon/file.svg';
import ICON_BILL from '../../assets/imgs/icon/bill.svg';
import ICON_SETTING from '../../assets/imgs/icon/setting.svg';
import ICON_REFERRAL from '../../assets/imgs/icon/referral_icon.svg';
import ICON_LOG_OUT from '../../assets/imgs/icon/log_out.svg';
import ICON_ARROW_RIGHT from '../../assets/imgs/icon/arrow_right.svg';
import ICON_BANK from '../../assets/imgs/icon/bank.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { THEME } from '../../constants';

const Divice = styled.div`
  border-bottom: 1px solid #a5b0c673;
  margin-bottom: 30px;
`;
const NavBar = styled.nav<{ isOpenNavBarMobile?: boolean }>`
  overflow: auto;
  padding: 32px 32px;
  @media (max-width: 768px) {
    padding: 16px 16px;
  }
  background: ${THEME.light_dark_blue};
  height: 100vh;
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s all;
  h2 {
    font-family: 'Fontsfree net proxima nova';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-top: 24px;
    color: #ffffff;
    // border-bottom: 1px solid ${THEME.dark_blue};
  }

  @media (max-height: ${THEME.breakpointHeight}px) {
    left: ${(props) => (props.isOpenNavBarMobile ? '0' : '-100%')};
    position: fixed;
    z-index: 999;
  }

  @media screen and (min-width: ${THEME.breakpoint}px) and (max-width: ${THEME.breakpointTablet}px) {
    left: ${(props) => (props.isOpenNavBarMobile ? '0' : '-100%')};
    position: fixed;
    z-index: 999;
  }

  @media (max-width: ${THEME.breakpoint}px) {
    position: fixed;
    z-index: 999;
    left: ${(props) => (props.isOpenNavBarMobile ? '0' : '-100%')};
    .wrap-bar-footer {
      display: none
    }
  }
`;

const ToggleNavBar = styled.div<{ isOpenNavBarMobile?: boolean }>`
  display: none;
  padding: 8px 10px;
  color: #fff;
  transition: 0.55s all;
  border-radius: 0px 4px 4px 0px;
  left: ${(props) => (props.isOpenNavBarMobile ? '200px' : '0')};
  top: 32px;
  @media (max-height: ${THEME.breakpointHeight}px) {
    display: block;
    position: absolute;
    background: ${THEME.yellow};
    z-index: 999;
  }

  @media screen and (max-width: ${THEME.breakpointTablet}px) {
    display: block;
    position: absolute;
    background: ${THEME.yellow};
    z-index: 999;
  }

  @media (max-width: ${THEME.breakpoint}px) {
    top: 86px;
    border-radius: 4px 0px 0px 4px;
    left: auto;
    right: 0;
    position: fixed;
  }
`;

const ContainerPage = styled.div`
  background: ${THEME.linear_bg};
  width: calc(100% - 200px);
  @media (max-width: ${THEME.breakpoint}px) {
    width: 100%;
    // overflow-x: hidden;
  }
  @media (max-height: ${THEME.breakpointHeight}px) {
    width: 100%;
  }
  @media screen and (min-width: ${THEME.breakpoint}px) and (max-width: ${THEME.breakpointTablet}px) {
    width: 100%;
  }
`;

const WrapperPage = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: ${THEME.breakpoint}px) {
    flex-direction: column;
  }
`;

const LogoPage = styled.img`
  width: 100%;
  position: relative;
`;

const TitleWrapList = styled.div`
  display: flex;
  flex-direction: column;
  .wrap-link {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    img {
      margin-right: 15px;
    }
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #a5b0c6;
    }
  }
`;

const WrapListLink = styled.div`
  display: flex;
  flex-direction: column;
  // height: calc(100% - 200px);
  overflow: auto;
  // width: calc(100% + 64px);
  position: relative;
  margin-left: -32px;
  padding: 0 32px;
  .wrap-link {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    cursor: pointer;
    img {
      margin-right: 15px;
    }
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #a5b0c6;
    }
  }
  & :hover {
    color: rgb(255, 255, 255, 0.9);
    filter: brightness(2);
  }
`;

const WrapCard = styled.div`
  margin-bottom: 40px;
  margin-left: -20px;
  margin-right: -20px;
  transition-duration: 200ms;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
  }
  &:hover {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    cursor: pointer;
    transform: translateY(-2px);
  }
  @media (max-width: ${THEME.breakpoint}px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const WrapBarTop = styled.div`
  height: calc(100% - 228px);
  @media (max-height: ${THEME.breakpointHeight}px) {
    height: auto;
  }
`;
const WrapBarFooter = styled.div``;
const WrapBarFooterMobile = styled.div`
  display: none;
  @media (max-width: ${THEME.breakpoint}px) {
    display: block;
    padding: 30px 16px;
    background: #0f1d3f;
  }
`;

const HeaderSecMobile = styled.div`
  display: none;
  @media (max-width: ${THEME.breakpoint}px) {
    display: block;
    position: absolute;
    top: 20px;
    left: 16px;
    img {
      margin: 0;
    }
    h2 {
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      margin-top: 10px;
      color: #ffffff;
    }
  }
`;

const StyledLink = styled(Link).attrs({
  className: 'StyledLink',
})<{ selected: boolean }>(
  ({ selected }) => `
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    cursor: pointer;
    img {
      margin-right: 15px;
    }
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #a5b0c6;
    }
  ${selected ? 'color: rgb(255, 255, 255, 0.9);' : null}
  ${selected ? 'filter: brightness(2);' : null}
  `,
);

export const MainLayout = ({ setToken, is_superuser, firstName }) => {
  const navigate = useNavigate();

  const [isOpenNavBarMobile, setIsOpenNavBarMobile] = useState(false);
  const handleLogoutOnClick = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    await setToken(null);
    navigate('/');
  };

  const { pathname: currentPath } = useLocation();

  return (
    <WrapperPage>
      <ToggleNavBar
        isOpenNavBarMobile={isOpenNavBarMobile}
        onClick={() => {
          setIsOpenNavBarMobile(!isOpenNavBarMobile);
        }}
      >
        <i className="fa fa-bars" aria-hidden="true"></i>
      </ToggleNavBar>
      <NavBar isOpenNavBarMobile={isOpenNavBarMobile}>
        <WrapBarTop>
          <a href="/">
            <LogoPage src={Logo} alt="" className="oleh-logo" />
          </a>
          <TitleWrapList>
            <div className="wrap-link">
              <h2>Hello, {firstName}</h2>
            </div>
          </TitleWrapList>
          <WrapListLink>
            {is_superuser ? (
              <>
                <StyledLink selected={currentPath === '/user'} to="/user">
                  <img src={ICON_USER} alt="Users" />
                  <span>Users</span>
                </StyledLink>
                <StyledLink selected={currentPath === '/email'} to="/email">
                  <img src={ICON_USER} alt="Users" />
                  <span>Mass Email</span>
                </StyledLink>
                <StyledLink selected={currentPath === '/transaction'} to="/transaction">
                  <img src={ICON_USER} alt="Transactions" />
                  <span>Transactions</span>
                </StyledLink>
                <StyledLink selected={currentPath === '/subscription'} to="/subscription">
                  <img src={ICON_USER} alt="subscriptions" />
                  <span>Subscriptions</span>
                </StyledLink>
                <StyledLink selected={currentPath === '/account-lookup'} to="/account-lookup">
                  <img src={ICON_USER} alt="Transactions" />
                  <span>Accounts</span>
                </StyledLink>
                <Divice></Divice>
              </>
            ) : (
              <>
                <StyledLink selected={currentPath === '/account'} to="/account">
                  <img src={ICON_CURRENCY} alt="Transfers" />
                  <span>Transfers</span>
                </StyledLink>
                <StyledLink selected={currentPath === '/profile'} to="/profile">
                  <img src={ICON_USER} alt="Profile" />
                  <span>Profile</span>
                </StyledLink>
                <StyledLink selected={currentPath === '/documents'} to="/documents">
                  <img src={ICON_FILE} alt="Documents" />
                  <span>Documents</span>
                </StyledLink>
                <StyledLink selected={currentPath === '/subscriptions'} to="/subscriptions">
                  <img src={ICON_BILL} alt="Standing Orders" />
                  <span>Standing Orders</span>
                </StyledLink>
                {/* <StyledLink selected={currentPath === '/referrals'} to="/referrals">
                  <img src={ICON_REFERRAL} alt="Referrals" />
                  <span>Referrals</span>
                </StyledLink> */}
              </>
            )}
          </WrapListLink>
        </WrapBarTop>
        <WrapBarFooter className='wrap-bar-footer'>
          {/* <Link to="/subscriptions">
            <WrapCard className="oleh-card">
              <div className="oleh-card-header">
                <h4>
                  <b>NEW:</b> Automated Transfers
                </h4>
              </div>
              <div className="oleh-card-body">
                <p>
                  Send money to yourself or a family member automatically each month with a standing
                  order.
                </p>
                <a className="footer-link">
                  SETUP SUBSCRIPTION TRANSFERS <img src={ICON_ARROW_RIGHT} />
                </a>
              </div>
            </WrapCard>
          </Link> */}
          <Link to="contact">
            <WrapCard className="oleh-card">
              <div className="oleh-card-header">
                <h4>
                  <b>NEW:</b> 3rd Party Transfers
                </h4>
              </div>
              <div className="oleh-card-body">
                <p>
                  Send or receive money to a 3rd party. Contact us to get started.
                </p>
                <a className="footer-link">
                  SETUP A 3RD PARTY TRANSFER NOW <img src={ICON_ARROW_RIGHT} />
                </a>
              </div>
            </WrapCard>
          </Link>
          <WrapListLink>
            {/* <div className="wrap-link">
              <img src={ICON_SETTING} alt="Settings" />
              <span></span>
            </div> */}
            <div className="wrap-link mb-0" onClick={handleLogoutOnClick}>
              <img src={ICON_LOG_OUT} alt="Log Out" />
              <span>Log Out</span>
            </div>
          </WrapListLink>
        </WrapBarFooter>
      </NavBar>
      <HeaderSecMobile>
        <a href="/">
          <LogoPage src={Logo} alt="" className="oleh-logo" />
        </a>
        <h2>Hello, {firstName}</h2>
      </HeaderSecMobile>
      <ContainerPage className="main-page-wrap">
        <Outlet />
      </ContainerPage>
      <WrapBarFooterMobile>
        <WrapCard className="oleh-card">
          <div className="oleh-card-header">
            <h4>
              <b>NEW:</b> Automated Transfers
            </h4>
          </div>
          <div className="oleh-card-body">
            <p>
              Send money to yourself or a family member automatically each month with a standing
              order. Reach out to support@olehpay.co.il for more information.
            </p>

            <a className="footer-link">
              SETUP SUBSCRIPTION TRANSFERS <img src={ICON_ARROW_RIGHT} />
            </a>
          </div>
        </WrapCard>

        <WrapListLink>
          {/* <div className="wrap-link">
            <img src={ICON_SETTING} alt="Settings" />
            <span>Settings</span>
          </div> */}
          <div className="wrap-link mb-0" onClick={handleLogoutOnClick}>
            <img src={ICON_LOG_OUT} alt="Log Out" />
            <span>Log Out</span>
          </div>
        </WrapListLink>
      </WrapBarFooterMobile>
      <ToastContainer style={{ zIndex: 999 }} draggable={false} autoClose={false} />
    </WrapperPage>
  );
};
