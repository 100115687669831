import styled from "styled-components";

import { THEME } from "../../constants";

export const ImageContainer = styled.div.attrs({
  className: "image-container",
})`
  display: flex;
  justify-content: center;
`;

export const PromotionsBody = styled.div.attrs({
  className: "promotions-body",
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  height: 100vh;
  width: 100%;
  padding: 60px 10px 10px 10px;
`;

export const Subtitle = styled.h3.attrs({
  className: "subtitle",
})`
  color: ${THEME.gray};
  margin: 3%;
`;

export const SendButton = styled.div.attrs({
  className: "styled-button",
})`
  margin: 3%;
  display: flex;
  justify-content: space-around;
  min-width: 120px;
  max-width: 120px;
  height: fit-content;
  cursor: pointer;
  padding: 16px;
  border: 1px solid ${THEME.yellow};
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 100%;
  opacity: 0.95;
  background: ${(props: any) =>
    props.isBorder ? "transparent" : THEME.yellow};
  color: ${(props: any) => (props.isBorder ? THEME.yellow : THEME.white)};
  & :hover {
    opacity: 1;
  }
  & :disabled {
    background-color: #cccccc;
    border-color: transparent;
    opacity: 0.8 !important;
    cursor: default;
  }
`;

export const TextContainer = styled.div.attrs({
  className: "text-container",
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Title = styled.h1.attrs({
  className: "title",
})`
  color: ${THEME.white};
  margin: 3%;
`;

export const HeroSection = styled.div.attrs({
  id: "hero",
  className: "hero w-clearfix",
})`
  overflow: hidden !important;
  min-height: auto !important;
  height: 100vh !important;
  @media (max-width: ${THEME.breakpoint}px) {
    overflow: auto !important;
  }
`;

export const AdSection = styled.div`
  padding: 32px;
  padding-top: 77px;
  overflow: hidden !important;
  min-height: auto !important;
  height: 100vh !important;
`;
