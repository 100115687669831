import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { AdminTransfersContent } from './transfers';
import { AdminAccountsContent } from './accounts';
import { AdminUsersContent } from './users';
import { AdminReportsContent } from './reports';

const WrapContainer = styled.div.attrs({
  className: 'WrapContainer',
})`
  display: flex;
  height: 100%;
  width: 100%;
`;

const WrapPage = styled.div.attrs({
  className: 'WrapPage',
})`
  padding: 32px 32px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const Admin = ({ setToken }) => {
  const currentPath = useLocation().pathname;

  return (
    <WrapContainer>
      <WrapPage>
        {currentPath === '/users' && <AdminUsersContent />}
        {currentPath === '/accounts' && <AdminAccountsContent setToken={setToken} />}
        {currentPath === '/transfers' && <AdminTransfersContent />}
        {currentPath === '/reports' && <AdminReportsContent />}
      </WrapPage>
    </WrapContainer>
  );
};
