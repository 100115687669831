/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC, useState, useRef, useEffect } from 'react';
import { useAxios } from '../../hooks/axios';
import { Outlet, Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { THEME } from '../../constants';
import { toast } from 'react-toastify';
import ICON_ARROW_UP from '../../assets/imgs/icon/arrow_card_up.svg';
import ICON_ARROW_DOWN from '../../assets/imgs/icon/arrow_card_down.svg';
import 'react-toastify/dist/ReactToastify.css';
import { Loading } from '../loading';

const WrapIncompleteBankAccount = styled.div.attrs({
  className: 'WrapIncompleteBankAccount',
})`
  margin-top: 15px;
  padding: 19px;
  background: ${THEME.ligher_dark_blue};
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 63px;
  cursor: pointer;
  &:hover {
    background: #394e7d;
  }
`;

const WrapBankAccount = styled.div.attrs({
  className: `WrapBankAccount`,
})<{ isOpen: boolean }>(
  ({ isOpen }) => `
  // margin-top: 15px;
  padding: 19px;
  background: ${THEME.ligher_dark_blue};
  border-radius: ${isOpen ? '6px 6px 0px 0px;' : '6px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  height: 63px;
  cursor: pointer;
  img {
    cursor: pointer;
  };
  &:hover {
    background: #394e7d;
  }
`,
);

const Text = styled.div.attrs({
  className: 'Text',
})`
  font-family: 'Fontsfree net proxima nova';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.9);
  padding-right: 30px;
  width: 100%;
  text-transform: uppercase;
`;

const WrapCard = styled.div.attrs({
  className: 'WrapCard',
})`
  position: relative;
`;

const WrapDetail = styled.div.attrs({
  className: 'WrapDetail',
})`
  position: absolute;
  z-index: 1000;
  width: 100%;
  margin-top: -15px;
  background: #1c253b;
  & > .d-flex {
    justify-content: space-between;
  }
  h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 8px;
    line-height: 10px;
    color: #ffffff;
    margin: 0;
    padding-right: 10px;
  }
  p {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: #a5b0c6;
    letter-spacing: 0;
  }
`;

const WrapDetailsContainer = styled.div.attrs({
  className: 'WrapDetailsContainer',
})`
  margin-top: 0px;
  padding: 15px 25px;
  background: #2d3d62;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  border-radius: 0px 0px 6px 6px;
`;

const WrapAccountActionButton = styled.div.attrs({
  className: 'WrapAccountActionButton',
})`
  & :hover {
    opacity: 0.9;
  }
`;

const WrapAccountEditButton = styled.div.attrs({
  className: 'WrapAccountEditButton',
})`
  border: 1px solid ${THEME.yellow};
  border-radius: 3px;
  & :hover {
    color: ${THEME.white};
    background-color: ${THEME.yellow};
    opacity: 0.9;
  }
`;

const AccountActionButton = styled.button.attrs({
  className: 'AccountActionButton',
})`
  background: #fbac1f;
  border-radius: 3px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 9px;
  line-height: 8px;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  padding: 12px;
`;

const AccountEditButton = styled.button.attrs({
  className: 'AccountEditButton',
})`
  background: transparent;
  border-radius: 3px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 9px;
  line-height: 8px;
  color: ${THEME.yellow};
  white-space: nowrap;
  cursor: pointer;
  padding: 12px;
`;

export const BankAccountCard: FC<any> = ({
  bankAccount,
  fundingForm,
  setESignatureIOModalIsOpen,
  setTransferPanelIsOpen,
  setSelectedBankAccountSending,
  setActiveESignatureIODocument,
  hasUSBankAccounts,
  hasILBankAccounts,
  kycIsRequired,
  KYCFormDocument,
  setKYCFormDocument,
}) => {
  const ref = React.useRef(null);
  const [isOpen, setIsOpen] = useState<any>(false);
  const [fundingFormInstance, setFundingFormInstance] = useState(fundingForm);
  const [{ loading: createFundingFormIsLoading }, createESignatureIODocument] = useAxios(
    '/esignatureio_documents/',
    {
      manual: true,
    },
  );

  const launchKycModal = kycIsRequired && (!KYCFormDocument || !KYCFormDocument?.is_signed);

  const cannotTransferToast = (countryAdjective) =>
    toast.error(`Connect an ${countryAdjective} account to make a transfer`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'colored',
    });

  const handleCompleteKYCOnClick = () => {
    if (!KYCFormDocument) {
      createESignatureIODocument({
        method: 'POST',
        data: {
          type: 'KNOW_YOUR_CUSTOMER_FORM',
        },
      }).then((response) => {
        setKYCFormDocument(response?.data);
        setActiveESignatureIODocument(response?.data);
        setESignatureIOModalIsOpen(true);
      }).catch((error) => {
        // on error, set create dummy signed kyc so that this is skipped:
        setKYCFormDocument({is_signed: true});
        toast.warning(`There was an error generating a key document, however you should still be able to place a transfer`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'colored',
        });
      });
    } else {
      setActiveESignatureIODocument(KYCFormDocument);
      setESignatureIOModalIsOpen(true);
    }
  }

  const handleStartTransferOnClick = () => {
    if (!hasUSBankAccounts) return cannotTransferToast('American');
    if (!hasILBankAccounts) return cannotTransferToast('Israeli');
    setSelectedBankAccountSending(bankAccount);
    setTransferPanelIsOpen(true);
    setIsOpen(false);
  };

  const handleAuthorizeAccountOnClick = () => {
    if (!fundingFormInstance) {
      createESignatureIODocument({
        method: 'POST',
        data: {
          type: 'FUNDING_FORM',
          bank_account: bankAccount?.uuid,
        },
      }).then((response) => {
        setFundingFormInstance(response?.data);
        setActiveESignatureIODocument(response?.data);
        setESignatureIOModalIsOpen(true);
      }).catch((error) => {
        // on error, set create dummy signed funding form so that this is skipped:
        setFundingFormInstance({is_signed: true});
        toast.warning(`There was an error generating a key document, however you should still be able to place a transfer`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'colored',
        });
      });
    } else {
      setActiveESignatureIODocument(fundingForm);
      setESignatureIOModalIsOpen(true);
    }
  };

  useEffect(() => {
    const handleOnClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOnClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOnClickOutside);
    };
  }, [ref]);

  if (createFundingFormIsLoading) return <Loading opacity={0.9} />;
  
  return (
    <WrapCard ref={ref}>
      {bankAccount?.country?.alpha_2 == 'US' &&
      (!fundingFormInstance || !fundingFormInstance?.is_signed) ? (
        <WrapIncompleteBankAccount onClick={handleAuthorizeAccountOnClick}>
          <Text>
            CLICK TO FINALIZE {bankAccount?.name}{' '}
            {bankAccount?.account_number ? bankAccount?.account_number?.slice(-4) : ''}
          </Text>
        </WrapIncompleteBankAccount>
      ) : (launchKycModal) ? (
        <WrapIncompleteBankAccount onClick={handleCompleteKYCOnClick}>
          <Text>
            Transfer from {bankAccount?.name}{' '}
            {bankAccount?.account_number ? bankAccount?.account_number?.slice(-4) : ''}
          </Text>
        </WrapIncompleteBankAccount>
      ) : (
        <WrapBankAccount isOpen={isOpen} onClick={handleStartTransferOnClick}>
          <Text>
            Transfer from {bankAccount?.name}{' '}
            {bankAccount?.account_number ? bankAccount?.account_number?.slice(-4) : ''}
          </Text>
        </WrapBankAccount>
      )}
      {isOpen && (
        <WrapDetail>
          <WrapDetailsContainer>
            <WrapAccountEditButton>
              <AccountEditButton>EDIT ACCOUNT</AccountEditButton>
            </WrapAccountEditButton>
            <WrapAccountActionButton>
              <AccountActionButton onClick={handleStartTransferOnClick}>
                START TRANSFER
              </AccountActionButton>
            </WrapAccountActionButton>
          </WrapDetailsContainer>
        </WrapDetail>
      )}
    </WrapCard>
  );
};
