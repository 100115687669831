import { Loading } from '../loading';
import { useAxios } from '../../hooks/axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export const SignedESignatureDocument = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [toastSent, setToastSent] = useState(false);
  const [{ data: ESignatureIODocumentResponse }, patchESignatureIODocument] = useAxios({
    url: `/esignatureio_documents/${searchParams.get('esignatureio_document__uuid')}/`,
    method: 'patch',
    data: {
      is_signed: true,
    },
  });
  useEffect(() => {
    if (toastSent) return;
    setToastSent(true);
    toast.success('Document signed. Please wait...', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'colored',
    });
  }, [toastSent]);
  useEffect(() => {
    if (ESignatureIODocumentResponse?.is_signed) navigate('/account');
  }, [ESignatureIODocumentResponse]);
  return (
    <>
      <Loading />;
      <ToastContainer draggable={false} autoClose={false} />
    </>
  );
};
