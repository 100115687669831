/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAxios } from '../../hooks/axios';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { THEME } from '../../constants';
import Select from 'react-select';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { UploadDocumentWidget } from '../upload-document-widget';
import { CUSTOM_MODAL_STYLE } from '../../constants';
import US_FLAG from '../../assets/imgs/flag/american-flag.svg';
import ISRAEL_FLAG from '../../assets/imgs/flag/israeli-flag.svg';
import ArrowBack from '../../assets/imgs/arrow-back.svg';
import states from '../../pages/registration/address-and-phone/states';

const SELECT_THEME = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral50: "#ccc", // Placeholder color
  },
});

const SkipTextContainer = styled.p.attrs({
  className: 'skip-text-container',
})`
  color: hsla(0deg, 0%, 100%, 0.53);
`;

const ClickHereContainer = styled.span.attrs({
  className: 'click-here-container',
})`
  text-decoration: underline;
  color: ${THEME.light_blue_link};
  cursor: pointer;
  &:hover {
      opacity: 0.9;
  }
`;

const WrapModalBody = styled.div.attrs({
  // className: 'WrapModalBody',
})`
  .modal-title {
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 50px;
    letter-spacing: -0.3px;
  }
  .modal-body * {
    font-weight: 300;
  }
  select:invalid {
    color: #ccc;
  }
  .israeli-plaid-h2 {
    background-color: #fafafa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    height: 60px;
  }
`;

const WrapModalContent = styled.div.attrs({
  className: "WrapModalContent",
})`
  padding: 0 calc((100% - 280px) / 2);
  div[class^="ValueContainer"] {
    padding-left: 16px;
  }
  p {
    font-size: 17px;
  }
`;

const WrapNavbarButton = styled.div.attrs({
  className: "WrapNavbarButton",
})<{ disabled: boolean }>(
  ({ disabled }) => `
    cursor: pointer;
    height: 48px;
    width: 48px;
    cursor: ${disabled ? "default" : "pointer"};
  `
);

const WrapBackArrow = styled.img.attrs({
  className: "WrapBackArrow",
})`
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: inherit;
  z-index: 90;
`;

const WrapCloseIcon = styled.div.attrs({
  className: "WrapCloseIcon",
})`
  cursor: pointer;
`;

const WrapBankIcon = styled.div.attrs({
  className: "WrapBankIcon",
})`
  height: 100%;
  margin-right: 7px;
`;

const WrapILBankSelector = styled.div.attrs({
  className: "WrapILBankSelector",
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  gap: 12px;
`;

const WrapILBankLogo = styled.div.attrs({
  className: "WrapILBankLogo",
})`
  display: flex;
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  height: 72px;
  cursor: pointer;
  border: 1px solid #fff;
  overflow: hidden;
  &:hover {
    border: 1px solid ${THEME.gray};
    opacity: 0.9;
  }
`;

const WrapILBankLogoImage = styled.img.attrs({
  className: "WrapILBankLogoImage",
})`
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  height: 72px;
  cursor: pointer;
  border: 1px solid ${THEME.white};
`;

const WrapSelect = styled.div.attrs({
  className: "WrapSelect",
})`
  margin-bottom: 16px;
  color: black;
  cursor: pointer;
  font-size: 20px;
  .css-13cymwt-control,
  .css-t3ipsp-control {
    height: 56px;
  }
`;

const WrapSelectedBankName = styled.div.attrs({
  className: "WrapSelectedBankName",
})`
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
  width: 100%;
`;

const WrapModalButton = styled.button.attrs({
  className: "WrapModalButton",
})(
  ({ disabled }) => `
    cursor: ${disabled ? "default" : "pointer"};
    margin-bottom: 16px;
    -webkit-appearance: button;
    background-color: ${disabled ? "transparent" : "rgba(255, 255, 255, 0.12)"};
    border-radius: 4px;
    box-shadow: ${
      disabled
        ? ""
        : "0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1)"
    };
    border: ${disabled ? "1px solid rgba(255, 255, 255, 0.12)" : ""};
    color: ${disabled ? "rgba(255, 255, 255, 0.12)" : THEME.white};
    font-size: 20px;
    height: 56px;
    width: -webkit-fill-available;
    // margin: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s all;
    &:hover {
      opacity: ${disabled ? 1 : 0.9};
      box-shadow: ${
        disabled
          ? "none"
          : "0 8px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.15)"
      };
    }
  `
);

const WrapModalButtonCountry = styled.button.attrs({
  className: "WrapModalButton bank-country",
})(
  ({ disabled }) => `
    cursor: ${disabled ? "default" : "pointer"};
    -webkit-appearance: button;
    background-color: ${disabled ? "transparent" : "rgba(255, 255, 255, 0.12)"};
    box-shadow: ${
      disabled
        ? ""
        : "0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1)"
    };
    border: ${disabled ? "1px solid rgba(255, 255, 255, 0.12)" : ""};
    color: ${disabled ? "rgba(255, 255, 255, 0.12)" : THEME.white};
    font-size: 20px;
    width: -webkit-fill-available;
    // margin: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s all;
    &:hover {
      opacity: ${disabled ? 1 : 0.9};
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
    }
    height: 120px;
    margin-bottom: 20px;
    border-radius: 6px;
  `
);

const ActiveButton = styled(WrapModalButton)`
  height: 120px;
  margin-bottom: 20px;
  border-radius: 6px;
`;

const WrapClickHere = styled.span.attrs({
  className: "WrapClickHere",
})`
  text-decoration: underline;
  color: ${THEME.light_blue_link};
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const WrapImagePreview = styled.img.attrs({
  className: "WrapImagePreview",
})<{ display: string }>(
  ({ display }) => `
    display: ${display};
    border-radius: 10px;
    margin: 0px 0px 12px 0px;
    max-height: 150px;
    object-fit: contain;
  `
);

const WrapAddAnotherAccountHolder = styled.div.attrs({
  className: "WrapAddAnotherAccountHolder",
})<{ disabled: boolean }>(
  ({ disabled }) => `
    margin-bottom: 5px;
    margin-top: -5px;
    text-decoration: underline;
    color: ${THEME.light_blue_link};
    cursor: ${disabled ? "default" : "pointer"};
    opacity: ${disabled ? 0.5 : 1.0};
    &:hover {
      opacity: ${disabled ? 0.5 : 0.9};
    }
  `
);

const WrapRemoveIcon = styled.div.attrs({
  className: "WrapRemoveIcon",
})<{ disabled: boolean }>(
  ({ disabled }) => `
    cursor: ${disabled ? "default" : "pointer"};
    &:hover {
      color: black;
    }
  `
);

const WrapAccountHolderInput = styled.div.attrs({
  className: "WrapAccountHolderInput Input Input--has-icon",
})<{ disabled: boolean }>(
  ({ disabled }) => `
    opacity: ${disabled ? 0.5 : 1};
  `
);

const FormGridContainer = styled.div.attrs({
  className: "FormGridContainer",
})`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 12px;
`;

const StyledInput = styled.input.attrs({
  className: "StyledInput",
})<{ isFilledOut: any }>(
  ({ isFilledOut }) => `
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 20.5px 12px;
  height: 70px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
  cursor: text;
  width: 100%;
  border: 1px solid ${isFilledOut ? "rgb(251, 185, 25)" : "rgb(204, 204, 204)"};
  // border: 1px solid ${isFilledOut ? "blue" : "red"};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &.h-70 {
    min-height: 70px;
  }
  &:visited {
    border: 1px solid #fbb919;
    opacity: 0.8;
    outline: none;
  }
  &:focus,
  &:active,
  &:hover {
    // border: 1px solid #fbb919;
    // border-color: rgb(251, 185, 25);
    outline: none;
    opacity: 1;
  }
`
);

const WrapUSBillingAddressStateSelect = styled.div.attrs({
  className: "WrapUSBillingAddressStateSelect",
})<{ isFilledOut: any }>(
  ({ isFilledOut }) => `
    grid-column: span 10;
    height: 100%;
    width: 100%;
    appearance: none;
    background-color: transparent;
    font-family: 'Fontsfree net proxima nova';
    z-index: 1000;
    border-radius: 8px;
    cursor: pointer;
    opacity: 0.8;
    border: 1px solid ${
      isFilledOut ? "rgb(251, 185, 25)" : "rgb(204, 204, 204)"
    };
    &:visited {
      border: 1px solid #fbb919;
      opacity: 0.8;
      outline: none;
    }
    &:focus,
    &:active,
    &:hover {
      outline: none;
      border: 1px solid ${
        isFilledOut ? "rgb(251, 185, 25)" : "rgb(204, 204, 204)"
      };
      opacity: 1;
    }
    
    [class*="-control"],
    [class*="-container"] {
      height: 100% !important;
      width: 100% !important;
      font-family: 'Fontsfree net proxima nova'!important;
      font-size: 23px !important;
      line-height: 23px !important;
      font-weight: 300 !important;
      background-color: transparent !important;
      border: none !important;
      cursor: pointer !important;
    }
    [class*="-singleValue"] {
      color: rgb(255, 255, 255) !important;
    }
    [class*="-Input"],
    [class*="-ValueContainer"],
    [class*="-indicatorContainer"] {
      color: rgb(255, 255, 255) !important;
    }
    [class*="-control"] {
      box-shadow: none !important;
    }
    [class*="-menu"] {
      color: black !important;
    }
    &[class*="-indicatorContainer"]:hover,
    &[class*="-indicatorContainer"]:hover {
      color: rgb(255, 255, 255) !important;
    }
  `
);

const CloseIcon = () => (
  <i className="Icon Navbar__icon">
    <svg viewBox="0 0 24 24">
      <path
        fill="#fff"
        d="M10.695 12.5l-4.342 4.343c-.482.481-.468 1.276.03 1.774.498.499 1.293.512 1.774.03l4.343-4.342 4.317 4.317c.482.482 1.276.468 1.775-.03.498-.499.512-1.293.03-1.775L14.305 12.5l4.317-4.317c.482-.482.468-1.276-.03-1.775-.498-.498-1.293-.512-1.775-.03L12.5 10.695 8.157 6.352c-.481-.481-1.276-.468-1.774.03-.499.5-.512 1.294-.03 1.775l4.342 4.343z"
      ></path>
    </svg>
  </i>
);

const USBankSelector = ({
  banks,
  bankOption,
  setBankOption,
  setCurrentStep,
  setSelectedBank,
}) => {
  const options = banks?.map((bank) => {
    return {
      label: bank.name,
      value: bank,
    };
  });
  const other = options?.filter((option) => option.label === "Other")[0];

  const [nextButtonIsEnabled, setNextButtonIsEnabled] = React.useState(false);

  const handleHereOnClick = () => {
    setBankOption(other);
    setSelectedBank(other?.value);
    setCurrentStep(3);
  };

  useEffect(() => {
    setNextButtonIsEnabled(Boolean(bankOption?.value));
  }, [bankOption]);

  return (
    <>
      <WrapSelect>
        <Select
          isClearable={true}
          blurInputOnSelect={true}
          maxMenuHeight={120}
          onChange={(option) => setBankOption(option)}
          options={options}
          value={bankOption}
          placeholder="select a bank"
          theme={(theme) => SELECT_THEME(theme)}
        />
      </WrapSelect>
      <p>
        Can&apos;t find your bank? click{" "}
        <WrapClickHere onClick={handleHereOnClick}>here</WrapClickHere>
      </p>
      <WrapModalButton
        onClick={() => setCurrentStep(3)}
        disabled={!nextButtonIsEnabled}
      >
        Next
      </WrapModalButton>
    </>
  );
};

const ILBankSelector = ({
  banks,
  selectedBank,
  setBranchOption,
  setSelectedBank,
  setCurrentStep,
}) => {
  const ILBankLogos = {
    "04": {
      url: "assets/imgs/banks/bankyahav.png",
      height: "43px",
      width: "85px",
    },
    "09": {
      url: "assets/imgs/banks/postalbank.png",
      height: "52px",
      width: "100px",
    },
    10: {
      url: "assets/imgs/banks/BankLeumiLogo.svg",
      height: "40px",
      width: "110px",
    },
    11: {
      url: "assets/imgs/banks/DiscountBank.png",
      height: "35px",
      width: "70px",
    },
    12: {
      url: "assets/imgs/banks/BankHapoalim.svg",
      height: "50px",
      width: "100px",
    },
    13: {
      url: "assets/imgs/banks/Bank_Igud.svg",
      height: "45px",
      width: "50px",
    },
    14: {
      url: "assets/imgs/banks/bank_otsar_hachayal.png",
      height: "40px",
      width: "100px",
    },
    17: {
      url: "assets/imgs/banks/Marcantile.png",
      height: "36px",
      width: "70px",
    },
    18: {
      url: "assets/imgs/banks/one-zero.png",
      height: "32px",
      width: "120px",
    },
    20: {
      url: "assets/imgs/banks/Mizrahi_Tefahot_logo.svg",
      height: "40px",
      width: "100px",
    },
    26: { url: "assets/imgs/banks/ubank.png", height: "33px", width: "100px" },
    31: {
      url: "assets/imgs/banks/HaBank_Hbinleumi_logo.svg",
      height: "33px",
      width: "100px",
    },
    52: { url: "assets/imgs/banks/Pagi.png", height: "45px", width: "90px" },
    54: {
      url: "assets/imgs/banks/Bank_of_Jerusalem.svg",
      height: "26px",
      width: "100px",
    },
  };
  const other = banks?.filter((bank) => bank.name === "Other")[0];
  const ILBankCards = banks
    ?.filter((bank) => bank.name != "Other")
    ?.map((bank) => (
      <WrapILBankLogo
        key={bank.uuid}
        onClick={() => {
          setSelectedBank(bank);
          setCurrentStep(3);
        }}
      >
        <WrapILBankLogoImage
          style={{
            width: ILBankLogos[bank.code]?.width,
            height: ILBankLogos[bank.code]?.height,
            margin: "auto",
          }}
          src={ILBankLogos[bank.code]?.url}
        />
      </WrapILBankLogo>
    ));

  React.useEffect(() => {
    if (selectedBank) setBranchOption(null);
  }, [selectedBank]);

  const handleHereOnClick = () => {
    setSelectedBank(other);
    setCurrentStep(3);
  };

  return (
    <>
      <WrapILBankSelector>{ILBankCards}</WrapILBankSelector>
      <p style={{ color: "rgb(52, 63, 86)" }}>
        Can&apos;t find your bank? click{" "}
        <WrapClickHere onClick={handleHereOnClick}>here</WrapClickHere>
      </p>
    </>
  );
};

const BankSelector = ({
  selectedCountry,
  banks,
  bankOption,
  setBankOption,
  setBranchOption,
  selectedBank,
  setSelectedBank,
  setCurrentStep,
}) => {
  useEffect(() => {
    setSelectedBank(bankOption?.value);
  }, [bankOption]);
  return (
    <>
      {selectedCountry?.alpha_2 === "IL" ? (
        <ILBankSelector
          banks={banks?.IL}
          selectedBank={selectedBank}
          setSelectedBank={setSelectedBank}
          setCurrentStep={setCurrentStep}
          setBranchOption={setBranchOption}
        />
      ) : (
        <USBankSelector
          banks={banks?.US}
          bankOption={bankOption}
          setBankOption={setBankOption}
          setCurrentStep={setCurrentStep}
          setSelectedBank={setSelectedBank}
        />
      )}
    </>
  );
};

const LockIcon = () => (
  <i className="Icon Input__icon Input__icon--right">
    <svg viewBox="0 0 24 24">
      <path
        d="M17.282 11c.57 0 1.026.444 1.026 1v7c0 .556-.456 1-1.026 1H7.026C6.456 20 6 19.556 6 19v-7c0-.556.456-1 1.026-1H8.05V8c0-2.204 1.843-4 4.103-4s4.102 1.796 4.102 4v3h1.026zm-7.18-3v3h4.103V8c0-1.111-.912-2-2.051-2-1.14 0-2.051.889-2.051 2z"
        fillRule="evenodd"
      ></path>
    </svg>
  </i>
);

const RemoveIcon = ({ disabled }) => (
  <WrapRemoveIcon disabled={disabled}>
    <i className="Icon Input__icon Input__icon--right">
      <svg viewBox="0 0 24 24">
        <path
          // fill="#000"
          d="M10.695 12.5l-4.342 4.343c-.482.481-.468 1.276.03 1.774.498.499 1.293.512 1.774.03l4.343-4.342 4.317 4.317c.482.482 1.276.468 1.775-.03.498-.499.512-1.293.03-1.775L14.305 12.5l4.317-4.317c.482-.482.468-1.276-.03-1.775-.498-.498-1.293-.512-1.775-.03L12.5 10.695 8.157 6.352c-.481-.481-1.276-.468-1.774.03-.499.5-.512 1.294-.03 1.775l4.342 4.343z"
        ></path>
      </svg>
    </i>
  </WrapRemoveIcon>
);

const AccountDetailInput = ({
  handleOnChange,
  placeholder,
  value,
  numbersOnly = false,
  numberAndWordOnly = false,
  includeLockIcon,
}) => {
  const handleOnKeyPress = (event) => {
    if (!numbersOnly) return;
    if (numberAndWordOnly) {
      if (!/[0-9]|[A-Z]|[a-z]/.test(event.key)) {
        event.preventDefault();
        return;
      }
    } else {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
        return;
      }
    }
  };
  return (
    <div className="Input Input--has-icon">
      <div className="Input__field-container">
        <input
          type="text"
          className="Input__field"
          aria-required="true"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          placeholder={placeholder}
          value={value}
          onKeyPress={handleOnKeyPress}
          onChange={handleOnChange}
        />
      </div>
      {includeLockIcon && <LockIcon />}
    </div>
  );
};

const BankAccountDetails = ({
  accountName,
  accountNumber,
  accountTypeOption,
  bankAccountTypes,
  branches,
  branchOption,
  routingNumber,
  selectedBank,
  selectedBranch,
  selectedCountry,
  setAccountName,
  setAccountNumber,
  setAccountType,
  setAccountTypeOption,
  setBranchOption,
  setCurrentStep,
  setRoutingNumber,
  setSelectedBranch,
}) => {
  const typeOptions = bankAccountTypes?.map((type) => ({
    label: type.value.replaceAll("_", " ").toLowerCase(),
    value: type,
  }));
  const branchOptions = branches?.map((branch) => ({
    label: `${branch.code} - ${branch.name}`,
    value: branch,
  }));

  const [nextButtonIsEnabled, setNextButtonIsEnabled] = useState(false);
  const [branchesAreLoading, setBranchesAreLoading] = useState(true);

  useEffect(() => {
    setBranchesAreLoading(!branches?.length);
  }, [branches]);

  useEffect(() => {
    if (selectedCountry?.alpha_2 === 'US') return;
    setRoutingNumber(`${selectedBank?.code}-${selectedBranch?.code}`);
  }, [selectedBranch]);

  useEffect(() => {
    setSelectedBranch(branchOption?.value);
  }, [branchOption]);

  useEffect(() => {
    setAccountType(accountTypeOption?.value);
  }, [accountTypeOption]);

  useEffect(() => {
    setNextButtonIsEnabled(
      (selectedCountry?.alpha_2 === "IL" || accountTypeOption?.value) &&
        routingNumber &&
        accountNumber
    );
  }, [accountTypeOption, routingNumber, accountNumber]);

  return (
    <>
      <WrapSelectedBankName>
        <WrapBankIcon>
          <AccountBalanceIcon />
        </WrapBankIcon>
        {selectedBank?.name}
      </WrapSelectedBankName>
      {selectedCountry?.alpha_2 === "US" && (
        <WrapSelect style={{ textTransform: "capitalize" }}>
          <Select
            value={accountTypeOption}
            blurInputOnSelect={true}
            options={typeOptions}
            maxMenuHeight={180}
            placeholder="Account Type"
            theme={(theme) => SELECT_THEME(theme)}
            onChange={(option) => setAccountTypeOption(option)}
          />
        </WrapSelect>
      )}
      <AccountDetailInput
        placeholder="Account Nickname"
        value={accountName}
        handleOnChange={(event) => setAccountName(event.target.value)}
        numbersOnly={false}
        includeLockIcon={false}
      />
      {selectedCountry?.alpha_2 === 'IL' ? (
        <WrapSelect style={{ textTransform: 'capitalize' }}>
          <Select
            value={branchOption}
            blurInputOnSelect={true}
            options={branchOptions}
            maxMenuHeight={120}
            placeholder="Branch"
            isLoading={branchesAreLoading}
            theme={(theme) => SELECT_THEME(theme)}
            onChange={(option) => setBranchOption(option)}
          />
        </WrapSelect>
      ) : (
        <AccountDetailInput
          placeholder="Routing #"
          value={routingNumber}
          handleOnChange={(event) => setRoutingNumber(event.target.value)}
          numbersOnly={true}
          includeLockIcon={true}
        />
      )}
      <AccountDetailInput
        placeholder="Account #"
        value={accountNumber}
        handleOnChange={(event) => setAccountNumber(event.target.value)}
        numberAndWordOnly={true}
        numbersOnly={true}
        includeLockIcon={true}
      />
      <WrapModalButton
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          margin: "40px",
          marginTop: "0px",
        }}
        onClick={() => setCurrentStep(4)}
        disabled={!nextButtonIsEnabled}
      >
        Next
      </WrapModalButton>
    </>
  );
};

const AccountHolderInput = ({
  disabled,
  accountHolderCard,
  handleAccountHolderOnChange,
  handleAccountHolderOnRemove,
}) => {
  return (
    <WrapAccountHolderInput disabled={disabled}>
      <div className="Input__field-container">
        <input
          disabled={disabled}
          type="text"
          className="Input__field"
          aria-required="true"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          placeholder="Name of Account Holder"
          value={accountHolderCard.value}
          onChange={(event) =>
            handleAccountHolderOnChange(event, accountHolderCard.index)
          }
        />
      </div>
      {accountHolderCard.index ? (
        <span onClick={disabled ? null : handleAccountHolderOnRemove}>
          <RemoveIcon disabled={disabled} />
        </span>
      ) : null}
    </WrapAccountHolderInput>
  );
};

const BankAccountVerification = ({
  accountName,
  accountNumber,
  image,
  previewURL,
  selectedCountry,
  selectedDocument,
  setImage,
  setPreviewURL,
  setSelectedDocument,
  setSelectedDocumentBlob,
  setCurrentStep,
}) => {
  const [uploadDocumentWidgetIsOpen, setUploadDocumentWidgetIsOpen] =
    useState(false);
  const [isPdf, setShowPdf] = useState(false);
  
  const handleNextOnClick = () => {
    setCurrentStep(5);
  };

  const handleUploadOneLaterOnClick = () => {
    setCurrentStep(5);
  }

  return (
    <>
      <Modal
        isOpen={uploadDocumentWidgetIsOpen}
        onRequestClose={() => setUploadDocumentWidgetIsOpen(false)}
        parentSelector={() => document.body}
        style={{
          content: {
            ...CUSTOM_MODAL_STYLE.content,
            ...{
              maxWidth: "800px",
              width: "calc(100vw - 20px)",
              maxHeight: "500px",
              backgroundColor: "rgb(53, 63, 86)", // ? '#fafafa' : '',
            },
          },
        }}
        appElement={document.getElementById("root")}
      >
        <UploadDocumentWidget
          isModalWrapper={true}
          image={image}
          isPdf={isPdf}
          setShowPdf={setShowPdf}
          previewURL={previewURL}
          uploadDocumentWidgetIsOpen={uploadDocumentWidgetIsOpen}
          setImage={setImage}
          setPreviewURL={setPreviewURL}
          setSelectedDocument={setSelectedDocument}
          setSelectedDocumentBlob={setSelectedDocumentBlob}
          closeUploadDocumentWidget={() => setUploadDocumentWidgetIsOpen(false)}
        />
      </Modal>
      <WrapSelectedBankName>
        <WrapBankIcon>
          <AccountBalanceIcon />
        </WrapBankIcon>
        {accountName}
      </WrapSelectedBankName>
      {!isPdf ? (
        <WrapImagePreview
          src={previewURL}
          display={selectedDocument ? "block" : "none"}
        />
      ) : (
        "PDF uploaded."
      )}
      <p>
        Upload a blank or voided check or bank statement reflecting both your name and clear account
        and routing numbers of your {selectedCountry?.alpha_2 === 'US' ? 'American' : 'Israeli'}{' '}
        account ending in {accountNumber.slice(-4)}
      </p>
      <WrapModalButton
        onClick={() => setUploadDocumentWidgetIsOpen(true)}
      >
        {selectedDocument ? "Edit Document" : "Upload Document"}
      </WrapModalButton>
      <SkipTextContainer>
        Don't have a document right now? Click {' '}
        <ClickHereContainer onClick={handleUploadOneLaterOnClick}>
          here
        </ClickHereContainer>
        {' '}to skip this step and upload later.
      </SkipTextContainer>
      <WrapModalButton
        disabled={!selectedDocument}
        type="button"
        onClick={handleNextOnClick}
      >
        Next
      </WrapModalButton>
    </>
  );
};

const BankAccountHolders = ({
  accountHolderObjects,
  accountName,
  isSubmittingBankAccount,
  selectedCountry,
  setAccountHolderObjects,
  setCurrentStep,
  handleCreateBankAccount,
}) => {
  const handleAccountHolderOnChange = (event, index) => {
    setAccountHolderObjects((accountHolderObjects) => {
      const newAccountHolderCards = [...accountHolderObjects];
      const newAccountHolderCard = newAccountHolderCards.filter(
        (accountHolderCard) => accountHolderCard.index === index,
      )[0];
      newAccountHolderCard.value = event.target.value;
      return newAccountHolderCards;
    });
  };

  const handleAccountHolderOnRemove = (index) => {
    const newAccountHolderCards = accountHolderObjects.filter(
      (accountHolder) => accountHolder.index !== index,
    );
    setAccountHolderObjects(newAccountHolderCards);
  };

  const handleAddAnotherHolderOnClick = () => {
    setAccountHolderObjects((accountHolderObjects) => {
      const newAccountHolderCards = [
        ...accountHolderObjects,
        {
          index: accountHolderObjects[accountHolderObjects.length - 1].index + 1,
          value: '',
        },
      ];
      return newAccountHolderCards;
    });
  };

  const accountHolderInputs = accountHolderObjects.map((accountHolderCard) => (
    <AccountHolderInput
      key={accountHolderCard.index}
      disabled={isSubmittingBankAccount}
      accountHolderCard={accountHolderCard}
      handleAccountHolderOnRemove={() => handleAccountHolderOnRemove(accountHolderCard.index)}
      handleAccountHolderOnChange={(event) =>
        handleAccountHolderOnChange(event, accountHolderCard.index)
      }
    />
  ));

  const handleNextOnClick = () => {
    selectedCountry?.alpha_2 === 'US' ? setCurrentStep(6) : handleCreateBankAccount();
  };


  return (
    <>
      <WrapSelectedBankName>
        <WrapBankIcon>
          <AccountBalanceIcon />
        </WrapBankIcon>
        {accountName}
      </WrapSelectedBankName>
      <p>
        Add additional holders to your bank account. Skip this step if you are the sole account holder.
      </p>
      <span>
        {accountHolderInputs}
        {accountHolderObjects.length < 3 && (
          <WrapAddAnotherAccountHolder
            disabled={isSubmittingBankAccount}
            onClick={
              isSubmittingBankAccount ? null : handleAddAnotherHolderOnClick
            }
          >
            add another account holder
          </WrapAddAnotherAccountHolder>
        )}
      </span>
      <WrapModalButton
        disabled={isSubmittingBankAccount}
        type="button"
        onClick={handleNextOnClick}
      >
        {selectedCountry?.alpha_2 === "US"
          ? "Next"
          : isSubmittingBankAccount
          ? "Submitting..."
          : "Submit"}
      </WrapModalButton>
    </>
  );
};

const BankAccountBillingAddress = ({
  accountName,
  accountNumber,
  billingAddress,
  setBillingAddress,
  handleCreateBankAccount,
  isSubmittingBankAccount,
}) => {
  const [USStreetAddress, setUSStreetAddress] = useState(null);

  const billingAddressIsValid =
    billingAddress?.region &&
    billingAddress?.city &&
    billingAddress?.postal &&
    billingAddress?.street_name &&
    billingAddress?.street_number;

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const splitAddress = (billingAddress) => {
    let streetNumber;
    let streetName;
    const firstSpaceIndex = billingAddress.trim().indexOf(" ");

    if (firstSpaceIndex !== -1) {
      // Extract street number and name
      streetNumber = billingAddress.slice(0, firstSpaceIndex);
      streetName = billingAddress.slice(firstSpaceIndex + 1);
    } else {
      // Only a street number or name has been typed so far
      if (isNaN(billingAddress)) {
        streetName = billingAddress;
      } else {
        streetNumber = billingAddress;
      }
    }
    return { streetNumber, streetName };
  };

  const stateSelectOptions = states
    ?.filter((state) => state.code)
    ?.map((state) => ({
      label: state.code,
      value: state.code,
    }));

  const stateOption = billingAddress?.region
    ? {
        label: billingAddress?.region,
        value: billingAddress?.region,
      }
    : null;

  const handleUSStreetAddressOnChange = (event) => {
    setUSStreetAddress(event.target.value);
    const { streetNumber, streetName } = splitAddress(event.target.value);
    setBillingAddress((billingAddress) => ({
      ...billingAddress,
      street_name: streetName ? toTitleCase(streetName) : null,
      street_number: streetNumber,
    }));
  };

  const handleApartmentNumberOnChange = (event) => {
    setBillingAddress((billingAddress) => ({
      ...billingAddress,
      apartment_number: event.target.value,
    }));
  };

  const handleRegionOnChage = (event) => {
    setBillingAddress((billingAddress) => ({
      ...billingAddress,
      region: event.target.value,
    }));
  };

  const handleStateOptionOnChange = (option) =>
    setBillingAddress((billingAddress) => ({
      ...billingAddress,
      region: option?.value,
    }));

  const handlePostalOnChange = (event) => {
    setBillingAddress((billingAddress) => ({
      ...billingAddress,
      postal: event.target.value,
    }));
  };

  const handleCityOnChange = (event) => {
    setBillingAddress((billingAddress) => ({
      ...billingAddress,
      city: toTitleCase(event.target.value),
    }));
  };

  return (
    <>
      <WrapSelectedBankName>
        <WrapBankIcon>
          <AccountBalanceIcon />
        </WrapBankIcon>
        {accountName}
      </WrapSelectedBankName>
      <p>
        Enter the billing address for your US account ending in{" "}
        {accountNumber.slice(-4)}
      </p>
      <FormGridContainer>
        <StyledInput
          style={{ gridColumn: "span 13" }}
          placeholder="Street Address"
          value={USStreetAddress || ""}
          onChange={handleUSStreetAddressOnChange}
          isFilledOut={billingAddress?.street_number}
        />
        <StyledInput
          style={{ gridColumn: "span 7" }}
          placeholder="Apt #"
          value={billingAddress?.apartment_number || ""}
          onChange={handleApartmentNumberOnChange}
          isFilledOut={billingAddress?.apartment_number}
        />
        <StyledInput
          placeholder="City"
          style={{ gridColumn: "span 20" }}
          value={billingAddress?.city || ""}
          onChange={handleCityOnChange}
          isFilledOut={billingAddress?.city}
        />
        <WrapUSBillingAddressStateSelect isFilledOut={billingAddress?.region}>
          <Select
            value={stateOption}
            blurInputOnSelect={true}
            options={stateSelectOptions}
            maxMenuHeight={88}
            placeholder="State"
            theme={(theme) => SELECT_THEME(theme)}
            onChange={(option) => handleStateOptionOnChange(option)}
          ></Select>
        </WrapUSBillingAddressStateSelect>
        <StyledInput
          placeholder="Postal"
          style={{ gridColumn: "span 10" }}
          value={billingAddress?.postal || ""}
          onChange={handlePostalOnChange}
          isFilledOut={billingAddress?.postal}
        />
      </FormGridContainer>
      <WrapModalButton
        disabled={!billingAddressIsValid || isSubmittingBankAccount}
        type="button"
        onClick={handleCreateBankAccount}
      >
        {isSubmittingBankAccount ? "Submitting..." : "Submit"}
      </WrapModalButton>
      <p>
        Don&apos;t have a US biling address? click{" "}
        <WrapClickHere onClick={handleCreateBankAccount}>here</WrapClickHere>
      </p>
    </>
  );
};

const AddBankAccountModalContent = ({
  setBackgroundIsWhite,
  classList,
  close,
  banks,
  bankAccountTypes,
  getBankAccounts,
  addBankAccountRequest,
  createBankAccountDocumentRequest,
  createBankAccountHolderRequest,
  createESignatureIODocument,
  createBankAccountBillingAddressRequest,
  setESignatureIOModalIsOpen,
  setActiveESignatureIODocument,
  countries,
  defaultStep = 1,
}) => {
  // static variables:
  const modalTitles = {
    1: "Select Bank Account Country",
    2: `Add a Bank Account`,
    3: 'Enter Your Account Details',
    4: 'Account Verification',
    5: 'Account Holders',
    6: 'Billing Address',
  };

  // mutable variables:
  const [isSubmittingBankAccount, setIsSubmittingBankAccount] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [currentStep, setCurrentStep] = useState(defaultStep);
  const [selectedCountry, setSelectedCountry] = useState(null) as any;
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState<any>();
  const [previewURL, setPreviewURL] = useState(null);
  const [accountHolderObjects, setAccountHolderObjects] = useState([
    {
      index: 0,
      value: "",
    },
  ]);
  const [billingAddress, setBillingAddress] = useState(null);

  // selected options for dropdowns:
  const [bankOption, setBankOption] = useState(null);
  const [branchOption, setBranchOption] = React.useState(null);
  const [accountTypeOption, setAccountTypeOption] = useState(null);

  // data to be sent via api:
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountHolders, setAccountHolders] = useState([]);
  const [image, setImage] = useState(null);
  const [selectedDocumentBlob, setSelectedDocumentBlob] = useState<any>();

  // requests and responses:
  const [{ data: branchesResponse }, getBranches] = useAxios(
    `/branches/?size=400&bank__uuid=${selectedBank?.uuid}`,
    { manual: true }
  );

  // parsed data from responses:
  const [branches, setBranches] = useState([]);

  const ILBankSelectionStage =
    currentStep === 2 && selectedCountry?.alpha_2 === "IL";

  useEffect(() => {
    if (ILBankSelectionStage) setBackgroundIsWhite(true);
    else setBackgroundIsWhite(false);
  }, [currentStep]);

  useEffect(() => {
    if (!branchesResponse) return;
    setBranches(branchesResponse.results);
  }, [branchesResponse]);

  useEffect(() => {
    setModalTitle(modalTitles[currentStep]);
  }, [currentStep]);

  useEffect(() => {
    setBranches(null);
    if (selectedCountry?.alpha_2 != "IL" || !selectedBank) return;
    getBranches();
  }, [selectedBank]);

  const handleBackArrowOnClick = () => {
    const previousStep = currentStep - 1;
    if (previousStep === 2 || previousStep === 1) setSelectedBank(null);
    if (previousStep === 1) setSelectedCountry(null);
    setCurrentStep(previousStep);
  };

  const launchFundingForm = (bankAccount) => {
    createESignatureIODocument({
      method: "POST",
      data: {
        type: "FUNDING_FORM",
        bank_account: bankAccount?.uuid,
      },
    }).then((response) => {
      close();
      setActiveESignatureIODocument(response?.data);
      setESignatureIOModalIsOpen(true);
    });
  };

  const handleSetCountry = (country, currentStep) => {
    setSelectedCountry(country);
    setCurrentStep(currentStep);
  };

  const handleCreateBankAccount = () => {
    const bankAccountPayload = {
      bank: selectedBank.uuid,
      branch: selectedBranch?.uuid,
      country: selectedCountry.uuid,
      name: accountName,
      routing_number: routingNumber,
      account_number: accountNumber,
      type: accountType?.value,
    };

    setIsSubmittingBankAccount(true);

    addBankAccountRequest({
      method: "post",
      data: bankAccountPayload,
    })
      .then((addBankAccountResponse: any) => {
        const fileName = image?.name || `${accountName}.verification.jpg`;
        const bodyFormData = new FormData();
        if (selectedDocumentBlob instanceof Blob) {
          bodyFormData.append('file', selectedDocumentBlob, fileName);
        } 
        bodyFormData.append('type', 'BANK_VERIFICATION');
        bodyFormData.append('bank_account', addBankAccountResponse.data.uuid);

        createBankAccountDocumentRequest({
          method: "post",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        }).catch((error) => console.log(error));

        const accountHoldersPayload = accountHolders.map((accountHolder) => ({
          name: accountHolder,
          bank_account: addBankAccountResponse.data.uuid,
        }));

        createBankAccountHolderRequest({
          method: "post",
          data: accountHoldersPayload,
        }).catch((error) => console.log(error));

        createBankAccountBillingAddressRequest({
          method: "post",
          data: {
            bank_account: addBankAccountResponse.data.uuid,
            country: "US",
            ...billingAddress,
          },
        }).catch((error) => console.log(error));

        getBankAccounts().then((response) => {
          if (selectedCountry?.alpha_2 === "US")
            launchFundingForm(addBankAccountResponse.data);
          else {
            setIsSubmittingBankAccount(false);
            close();
          }
          toast.success("Your bank account has been added!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "colored",
          });
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <WrapModalBody className={classList}>
      <h2
        className="modal-title"
        style={{
          color: ILBankSelectionStage ? "rgb(53, 63, 86)" : "#ffffff",
        }}
      >
        <WrapNavbarButton disabled={isSubmittingBankAccount}>
          {currentStep > 1 && !isSubmittingBankAccount && (
            <WrapBackArrow onClick={handleBackArrowOnClick} src={ArrowBack} />
          )}
        </WrapNavbarButton>
        {modalTitle}
        <WrapCloseIcon onClick={() => close()}>
          <CloseIcon />
        </WrapCloseIcon>
      </h2>
      <WrapModalContent>
        <div className="d-flex justify-content-between flex-column">
          {currentStep === 1 && (
            <>
              <WrapModalButtonCountry
                id={"bank-trigger-us"}
                onClick={() => {
                  handleSetCountry(countries.US, 2);
                }}
              >
                <div className="flag">
                  <img src={US_FLAG} className="flag-image" />
                </div>
                <div className="add-account-text">
                  {" "}
                  Add a US <br /> Bank Account
                </div>
              </WrapModalButtonCountry>
              <WrapModalButtonCountry
                id={"bank-trigger-il"}
                onClick={() => {
                  handleSetCountry(countries.IL, 2);
                }}
              >
                <div className="flag">
                  <img src={ISRAEL_FLAG} className="flag-image" />
                </div>
                <div className="add-account-text">
                  {" "}
                  Add an Israeli <br /> Bank Account
                </div>
              </WrapModalButtonCountry>
            </>
          )}
          {currentStep === 2 && selectedCountry && (
            <BankSelector
              selectedCountry={selectedCountry}
              banks={banks}
              bankOption={bankOption}
              setBankOption={setBankOption}
              setBranchOption={setBranchOption}
              selectedBank={selectedBank}
              setSelectedBank={setSelectedBank}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 3 && selectedCountry && (
            <BankAccountDetails
              accountName={accountName}
              accountNumber={accountNumber}
              accountTypeOption={accountTypeOption}
              bankAccountTypes={bankAccountTypes}
              branches={branches}
              branchOption={branchOption}
              routingNumber={routingNumber}
              selectedBank={selectedBank}
              selectedCountry={selectedCountry}
              selectedBranch={selectedBranch}
              setAccountName={setAccountName}
              setAccountNumber={setAccountNumber}
              setAccountType={setAccountType}
              setAccountTypeOption={setAccountTypeOption}
              setBranchOption={setBranchOption}
              setCurrentStep={setCurrentStep}
              setSelectedBranch={setSelectedBranch}
              setRoutingNumber={setRoutingNumber}
            />
          )}
          {currentStep === 4 && (
            <BankAccountVerification
              accountName={accountName}
              accountNumber={accountNumber}
              image={image}
              previewURL={previewURL}
              selectedCountry={selectedCountry}
              selectedDocument={selectedDocument}
              setImage={setImage}
              setPreviewURL={setPreviewURL}
              setSelectedDocument={setSelectedDocument}
              setSelectedDocumentBlob={setSelectedDocumentBlob}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 5 && (
            <BankAccountHolders
              accountHolderObjects={accountHolderObjects}
              accountName={accountName}
              isSubmittingBankAccount={isSubmittingBankAccount}
              selectedCountry={selectedCountry}
              setAccountHolderObjects={setAccountHolderObjects}
              setCurrentStep={setCurrentStep}
              handleCreateBankAccount={handleCreateBankAccount}
            />
          )}
          {currentStep === 6 && (
            <BankAccountBillingAddress
              accountName={accountName}
              accountNumber={accountNumber}
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              handleCreateBankAccount={handleCreateBankAccount}
              isSubmittingBankAccount={isSubmittingBankAccount}
            />
          )}
        </div>
      </WrapModalContent>
    </WrapModalBody>
  );
};

export const AddBankAccountModal = ({
  addBankAccountModalIsOpen,
  banks,
  bankAccountTypes,
  closeAddBankAccountModal,
  getBankAccounts,
  addBankAccountRequest,
  createBankAccountDocumentRequest,
  createBankAccountHolderRequest,
  createBankAccountBillingAddressRequest,
  createESignatureIODocument,
  setESignatureIOModalIsOpen,
  setActiveESignatureIODocument,
  countries,
  isTest = false,
  defaultStep = 1,
}) => {
  const [backgroundIsWhite, setBackgroundIsWhite] = useState(false);
  if (isTest) {
    return (
      <AddBankAccountModalContent
        setBackgroundIsWhite={setBackgroundIsWhite}
        bankAccountTypes={bankAccountTypes}
        banks={banks}
        close={closeAddBankAccountModal}
        classList=""
        countries={countries}
        getBankAccounts={getBankAccounts}
        addBankAccountRequest={addBankAccountRequest}
        createBankAccountDocumentRequest={createBankAccountDocumentRequest}
        createBankAccountHolderRequest={createBankAccountHolderRequest}
        createESignatureIODocument={createESignatureIODocument}
        createBankAccountBillingAddressRequest={
          createBankAccountBillingAddressRequest
        }
        setESignatureIOModalIsOpen={setESignatureIOModalIsOpen}
        setActiveESignatureIODocument={setActiveESignatureIODocument}
        defaultStep={defaultStep}
      ></AddBankAccountModalContent>
    );
  }
  return (
    <Modal
      isOpen={addBankAccountModalIsOpen}
      onRequestClose={closeAddBankAccountModal}
      style={{
        content: {
          ...CUSTOM_MODAL_STYLE.content,
          ...{
            maxWidth: "360px",
            width: "calc(100vw - 20px)",
            backgroundColor: backgroundIsWhite ? "#fafafa" : "rgb(53, 63, 86)",
          },
        },
      }}
      appElement={document.getElementById("root")}
    >
      <AddBankAccountModalContent
        setBackgroundIsWhite={setBackgroundIsWhite}
        bankAccountTypes={bankAccountTypes}
        banks={banks}
        close={closeAddBankAccountModal}
        classList=""
        countries={countries}
        getBankAccounts={getBankAccounts}
        addBankAccountRequest={addBankAccountRequest}
        createBankAccountDocumentRequest={createBankAccountDocumentRequest}
        createBankAccountHolderRequest={createBankAccountHolderRequest}
        createESignatureIODocument={createESignatureIODocument}
        createBankAccountBillingAddressRequest={
          createBankAccountBillingAddressRequest
        }
        setESignatureIOModalIsOpen={setESignatureIOModalIsOpen}
        setActiveESignatureIODocument={setActiveESignatureIODocument}
      ></AddBankAccountModalContent>
    </Modal>
  );
};
