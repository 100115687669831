import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { SortingState } from '@tanstack/react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Table } from '../../../../components/table';
import { THEME } from '../../../../constants';
import CONSTANT_TRANSACTION from '../../../../components/transfers-content/constant';
import 'react-tabs/style/react-tabs.css';
import 'react-tooltip/dist/react-tooltip.css';
import { StyledFieldset, StyledLegend } from '../../common-components';
import moment from 'moment';

Modal.defaultStyles.overlay.backgroundColor = 'rgb(144 144 144 / 98%)';

const WrapContent = styled.div`
  margin-top: 30px;
  @media (max-width: ${THEME.breakpoint}px) {
    padding-bottom: 0;
  }
`;

const WrapTrasactionDesktop = styled.div`
  @media (max-width: ${THEME.breakpoint}px) {
    display: none;
  }
`;

const WrapInput = styled.div.attrs({
  className: 'WrapInput',
})``;

export const ReferralDetails = ({ referrals }) => {
  // table columns:
  const columns = [
    {
      accessorFn: (row: any) => row.referee,
      id: 'referee',
      cell: (info: any) => info.getValue(),
      header: () => <span>Referee</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => moment(row.created_at).format('MMM DD, YYYY'),
      id: 'created_at',
      cell: (info: any) => info.getValue(),
      header: () => <span>Joined</span>,
      footer: (props: any) => props.column.id,
    },
  ];

  const [currentPage, setPage] = useState<any>(1);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  if (!referrals?.length) return;

  return (
    <StyledFieldset>
      <StyledLegend>Referrals</StyledLegend>
      <WrapContent>
        <WrapTrasactionDesktop>
          <div>
            <Table
              data={referrals}
              total={referrals.length}
              columns={columns}
              setSorting={setSorting}
              sorting={sorting}
              setPage={setPage}
              page={currentPage}
              pageSize={20}
              loading={false}
            />
          </div>
        </WrapTrasactionDesktop>
      </WrapContent>
    </StyledFieldset>
  );
};
