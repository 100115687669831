import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import moment from 'moment';
import 'moment-timezone';

import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  LineController,
} from 'chart.js';
import { SortingState } from '@tanstack/react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Table } from '../../components/table';
import { THEME } from '../../constants';
import { TransferDetailsModal } from '../../components/transfer-details-modal';
import { chartOptions, chartLineHoverTooltipPlugin } from './options';
import ICON_ARROW_UP_YELLOW from '../../assets/imgs/icon/arrow_up_yellow.svg';
import CONSTANT_TRANSACTION from './constant';
import 'react-tabs/style/react-tabs.css';
import 'react-tooltip/dist/react-tooltip.css';

Modal.defaultStyles.overlay.backgroundColor = 'rgb(144 144 144 / 98%)';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const WrapContent = styled.div.attrs({
  className: 'WrapContent',
})`
  // margin-top: 30px;
  @media (max-width: ${THEME.breakpoint}px) {
    padding-bottom: 0;
    height: auto;
    margin-top: 15px;
  }
  height: calc(100% - 50px);
  width: 100%;
  gap: 0;
`;

const WrapChart = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  position: relative;
  height: calc(100vh - 440px - 150px);
  padding-top: 50px;
  @media (max-width: ${THEME.breakpoint}px) {
    min-height: 175px;
  }
`;

const ChartContainer = styled.div.attrs({
  className: 'ChartContainer',
})`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${THEME.breakpoint}px) and (max-height: 730px) {
    display: none;
  }
  padding: 0 25px;
`;

const TitleChart = styled.h4`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.12em;
  color: #96a1c1;
  display: none;
  position: absolute;
  top: 0;
  left: 32px;
  @media (max-width: ${THEME.breakpoint}px) {
    left: 0;
  }
`;

const TitleChartTie = styled.h4`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.12em;
  color: #96a1c1;
  display: none;
  position: absolute;
  top: 0;
  left: 32px;
  @media (max-width: ${THEME.breakpoint}px) {
    left: 0;
  }
`;

const ToolTipValueChart = styled.div.attrs({
  id: 'tooltipValueChart',
})`
  color: #fff;
  height: 19px;
  position: absolute;
  top: 28px;
  left: 32px;
  @media (max-width: ${THEME.breakpoint}px) {
    left: 0;
  }
`;

const BigNumber = styled.h3`
  font-family: 'Fontsfree net proxima nova';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 8px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  color: ${THEME.white};
  img {
    margin-left: 10px;
  }

  @media (max-width: ${THEME.breakpoint}px) {
    margin-left: -25px;
  }
`;

const ChartFilterItem = styled.div.attrs({
  className: 'ChartFilterItem',
})<{ selected: boolean }>(
  ({ selected }) => `
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${THEME.gray2};
    padding: 2px 8px;
    cursor: pointer;
    opacity: ${selected ? 0.8 : 1};
    background-color: ${selected ? THEME.light_blue : 'none'};
    &:hover {
      opacity: 0.9;
    }
  `,
);

const TitleTable = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${THEME.white};
  margin-top: 20px;
  position: relative;
  display: table;
  padding-bottom: 15px;
  margin-bottom: 20px;
  &:after {
    content: '';
    border: 1px solid ${THEME.yellow};
    width: 80%;
    bottom: 0;
    position: absolute;
    left: 0;
  }
`;

const ViewButton = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  padding: 3px;
  align-items: center;
  text-align: center;
  background: transparent;
  color: #fbb919;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    opacity: 0.9;
  }
`;

const WrapTrasactionDesktop = styled.div`
  @media (max-width: ${THEME.breakpoint}px) {
    display: none;
  }
`;

const Placeholder = styled.div.attrs({
  className: 'Placeholder',
})`
  height: 100%;
  color: rgb(165, 176, 198);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: rgb(165, 176, 198);
  }
`;

const ExchangeRateChart = ({ exchangeRatesResponse }) => {
  const chartRef = useRef<ChartJS>();
  const [dataChart, setDataChart] = useState<any>({ datasets: [] });
  const [showChart, setShowChart] = useState<any>(false);
  const [chartRange, setChartRange] = useState('3M');

  const latest = dataChart.datasets?.[0]?.data?.[dataChart.datasets?.[0]?.data?.length - 1] || 0;
  const beforeLatest = dataChart.datasets?.[0]?.data?.[dataChart.datasets?.[0]?.data?.length - 2] || 0;
  const latestTime = dataChart?.labels?.[dataChart?.labels?.length - 1];

  const plottableData = exchangeRatesResponse?.results?.filter((rate) => {
    const rateDate = new Date(rate?.date);
    const startPoint = new Date();
    if (chartRange === '5D') startPoint.setDate(startPoint.getDate() - 5);
    else if (chartRange === '1M') startPoint.setMonth(startPoint.getMonth() - 1);
    else if (chartRange === '3M') startPoint.setMonth(startPoint.getMonth() - 3);
    else if (chartRange === '1Y') startPoint.setFullYear(startPoint.getFullYear() - 1);
    else if (chartRange === '3Y') startPoint.setFullYear(startPoint.getFullYear() - 3);
    return rateDate >= startPoint;
  });

  useEffect(() => {
    if (!exchangeRatesResponse) return;
    setShowChart(dataChart);
  }, [exchangeRatesResponse]);

  useEffect(() => {
    if (!showChart) return;
    if (!chartRef.current) return;
    const chart = chartRef.current;

    const ctx = chart.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 100);
    // gradient.addColorStop(0, '#eeb11a');
    // gradient.addColorStop(0.75, '#181D2D');
    // gradient.addColorStop(1, '#181D2D');
    gradient.addColorStop(0, 'red');
    gradient.addColorStop(0.75, '#181D2D');
    gradient.addColorStop(1, 'green');

    const labels = [] as any;
    const dataChart = {
      labels: [] as any,
      datasets: [
        {
          label: 'Price',
          data: [] as any,
          borderColor: '#FBB919',
          pointBackgroundColor: '#FBB919',
          // backgroundColor: gradient,
          tension: 0.2,
          pointRadius: 0,
          fill: true,
        },
      ],
    };
    plottableData
      ?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      ?.forEach((rate: any) => {
        labels.push(moment(rate.date).format('MMM DD, YYYY'));
        dataChart.datasets[0].data.push(parseFloat(rate.amount));
      });
    dataChart.labels = labels;
    setDataChart(dataChart);
  }, [showChart, chartRange]);

  const handleFilterOnClick = (range) => {
    setChartRange(range);
  };

  return (
    <ChartContainer>
      <TitleChart>CURRENT BOI RATE:</TitleChart>
      <BigNumber>
        {latest?.toFixed(3)} <span style={{ fontSize: '28px', height: '4px' }}>&nbsp;USD/ILS</span>{' '}
        {beforeLatest !== latest && (
          <img
            src={ICON_ARROW_UP_YELLOW}
            style={
              beforeLatest > latest
                ? { filter: 'saturate(100)', transform: 'rotate(180deg)' }
                : {
                    filter:
                      'invert(20%) sepia(91%) saturate(6980%) hue-rotate(126deg) brightness(99%) contrast(102%)',
                  }
            }
          />
        )}
      </BigNumber>

      <WrapChart>
        <TitleChart id="current-date">
          {moment(latestTime).format('MMM DD, YYYY')} 15:30 IST
        </TitleChart>
        <TitleChartTie id="current-date-hover"></TitleChartTie>
        <ToolTipValueChart></ToolTipValueChart>
        {showChart && (
          <Chart
            ref={chartRef}
            type="line"
            data={dataChart}
            options={chartOptions}
            plugins={chartLineHoverTooltipPlugin}
          />
        )}
      </WrapChart>
      <div className="d-flex">
        <ChartFilterItem onClick={() => handleFilterOnClick('5D')} selected={chartRange === '5D'}>
          5D
        </ChartFilterItem>
        <ChartFilterItem onClick={() => handleFilterOnClick('1M')} selected={chartRange === '1M'}>
          1M
        </ChartFilterItem>
        <ChartFilterItem onClick={() => handleFilterOnClick('3M')} selected={chartRange === '3M'}>
          3M
        </ChartFilterItem>
        <ChartFilterItem onClick={() => handleFilterOnClick('1Y')} selected={chartRange === '1Y'}>
          1Y
        </ChartFilterItem>
        <ChartFilterItem onClick={() => handleFilterOnClick('3Y')} selected={chartRange === '3Y'}>
          3Y
        </ChartFilterItem>
      </div>
    </ChartContainer>
  );
};

export const TransfersContent = ({
  transfers,
  numberOfTransfers,
  exchangeRatesResponse,
  loadingTransfersResponse,
}) => {
  // table columns:
  const columns = [
    {
      accessorFn: (row: any) => row.number,
      id: 'number',
      cell: (info: any) => info.getValue(),
      header: () => <span>ID</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => row.date,
      id: 'date',
      cell: (info: any) => info.getValue(),
      header: () => <span>Date</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'debited',
      header: () => <span>Amount Debited</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'credited',
      header: () => <span>Credited</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'status',
      header: () => <span>Status</span>,
      cell: (info: any) => {
        return (
          <>
            <div
              id={`status-tooltip-${info.getValue()?.toLowerCase()}-${info.row.id}`}
              className={`tag tag-${info.getValue()?.toLowerCase()}`}
            >
              {info.getValue()}
            </div>
            <ReactTooltip
              anchorId={`status-tooltip-${info.getValue()?.toLowerCase()}-${info.row.id}`}
              place="bottom"
              className="adp-tooltip--component"
              content={CONSTANT_TRANSACTION[info.getValue()?.toLowerCase()]}
            />
          </>
        );
      },
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'action',
      header: () => <span>Details</span>,
      cell: (info: any) => {
        return (
          <ViewButton type="button" onClick={() => openModalTransfer(info)}>
            Details
          </ViewButton>
        );
      },
      footer: (props: any) => props.column.id,
    },
  ];

  // table, chart states:
  const [currentPage, setPage] = useState<any>(1);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [transferDetails, setTransferDetails] = React.useState<any>(null);
  const [transferDetailsModalIsOpen, setTransferIDetailsModalsOpen] = React.useState(false);

  const openModalTransfer = (infor) => {
    setTransferDetails(infor.row.original.origin);
    setTransferIDetailsModalsOpen(true);
  };

  const hideColumns = () => {
    const table = document.getElementsByTagName('table')[0];
    const rows = table.getElementsByTagName('tr');

    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].getElementsByTagName('td');

      if (cells.length > 0) {
        cells[0].style.display = 'none'; // hide the first column
        if (cells.length > 3) {
          cells[3].style.display = 'none'; // hide the fourth column
        }
      }
    }
  };

  return (
    <WrapContent>
      <>
        <ExchangeRateChart exchangeRatesResponse={exchangeRatesResponse} />
      </>
      {transfers?.length ? (
        <WrapTrasactionDesktop>
          <TitleTable>Transction History</TitleTable>
          <div>
            <Table
              data={transfers}
              columns={columns}
              setSorting={setSorting}
              sorting={sorting}
              setPage={setPage}
              page={currentPage}
              total={numberOfTransfers}
              pageSize={5}
              loading={loadingTransfersResponse}
            />
          </div>
        </WrapTrasactionDesktop>
      ) : (
        <Placeholder>
          <TitleTable>Transction History</TitleTable>
          <p>You have no trasfers. Your transfers will be displayed here.</p>
        </Placeholder>
      )}
      <TransferDetailsModal
        transferDetailsModalIsOpen={transferDetailsModalIsOpen}
        closeTransferDetailsModal={() => setTransferIDetailsModalsOpen(false)}
        transferDetails={transferDetails}
      />
    </WrapContent>
  );
};

export const TransfersContentMobile = ({
  transfers,
  numberOfTransfers,
  loadingTransfersResponse,
}) => {
  const columns = [
    // {
    //   accessorFn: (row: any) => row.number,
    //   id: 'number',
    //   cell: (info: any) => info.getValue(),
    //   header: () => <span>ID</span>,
    //   footer: (props: any) => props.column.id,
    // },
    {
      accessorFn: (row: any) => row.date,
      id: 'date',
      cell: (info: any) => info.getValue(),
      header: () => <span>Date</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'debited',
      header: () => <span>Amount Debited</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    // {
    //   accessorKey: 'credited',
    //   header: () => <span>Credited</span>,
    //   cell: (info: any) => info.getValue(),
    //   footer: (props: any) => props.column.id,
    // },
    {
      accessorKey: 'status',
      header: () => <span>Status</span>,
      cell: (info: any) => {
        return (
          <>
            <div
              id={`status-tooltip-${info.getValue()?.toLowerCase()}-${info.row.id}`}
              className={`tag tag-${info.getValue()?.toLowerCase()}`}
            >
              {info.getValue()}
            </div>
            <ReactTooltip
              anchorId={`status-tooltip-${info.getValue()?.toLowerCase()}-${info.row.id}`}
              place="bottom"
              className="adp-tooltip--component"
              content={CONSTANT_TRANSACTION[info.getValue()?.toLowerCase()]}
            />
          </>
        );
      },
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'action',
      header: () => <span>Details</span>,
      cell: (info: any) => {
        return (
          <ViewButton type="button" onClick={() => openModalTransfer(info)}>
            Details
          </ViewButton>
        );
      },
      footer: (props: any) => props.column.id,
    },
  ];

  const [currentPage, setPage] = useState<any>(1);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [transferDetails, setTransferDetails] = React.useState<any>(null);
  const [transferDetailsModalIsOpen, setTransferIDetailsModalsOpen] = React.useState(false);

  const openModalTransfer = (infor) => {
    setTransferDetails(infor.row.original.origin);
    setTransferIDetailsModalsOpen(true);
  };

  return (
    <>
      <TitleTable>Transaction History</TitleTable>
      <div className="wrap-table">
        <Table
          data={transfers}
          columns={columns}
          setSorting={setSorting}
          sorting={sorting}
          setPage={setPage}
          page={currentPage}
          total={numberOfTransfers}
          pageSize={5}
          loading={loadingTransfersResponse}
        />
      </div>
      <TransferDetailsModal
        transferDetailsModalIsOpen={transferDetailsModalIsOpen}
        closeTransferDetailsModal={() => setTransferIDetailsModalsOpen(false)}
        transferDetails={transferDetails}
      />
    </>
  );
};
