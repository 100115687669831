import { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import moment from 'moment';

import { EditableInput, StyledFieldset, StyledLegend } from '../common-components';
import { LoadingInPlace } from '../../../components/loading';
import { DataTableContainer, StyledDataTable, StyledLink } from '../accounts/components/datatables';
import { THEME } from '../../../constants';

const UsersContainer = styled.div.attrs({
  className: 'UsersContainer',
})`
  height: 100%;
  position: relative;
`;

const BooleanCell = styled.div.attrs({
  className: 'BooleanCell',
})<{ value: boolean }>(
  ({ value }) => `
    text-transform: uppercase;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 10px;
    line-height: 6px;
    text-align: center;
    letter-spacing: 0.06em;
    padding: 7px;
    min-width: 58px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    color: ${value ? 'white' : 'inherit'};
    background-color: ${value ? THEME.yellow : 'transparent'};
  `,
);

const FilterContainer = styled.div.attrs({
  className: 'FilterContainer',
})`
  width: 50%;
  margin-top: 10px;
  display: flex;
  border-radius: 5px;
  position: absolute;
  bottom: 16px;
`;

const FilterComponent = ({ filterText, onFilter }) => (
  <FilterContainer>
    <EditableInput placeholder="Filter by email or name" value={filterText} onChange={onFilter} />
  </FilterContainer>
);

export const AdminUsersContent = () => {
  const [filterText, setFilterText] = useState('');
  const [{ data: usersResponse, loading: usersResponseIsLoading }] =
    useAxios(`/users/?paginate=false`);

  const filteredItems = usersResponse?.filter(
    (item) =>
      (item.email && item.email.includes(filterText.toLowerCase())) ||
      (item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.last_name && item.last_name.toLowerCase().includes(filterText.toLowerCase())),
  );

  const usersColumns = [
    {
      name: 'Email',
      selector: (row) => (
        <StyledLink href={`/accounts?user=${row.email}`} target="_blank" rel="noreferrer">
          {row.email}
        </StyledLink>
      ),
      sortable: true,
      sortFunction: (a, b) => (a.email > b.email ? 1 : -1),
    },
    {
      name: 'Name',
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
    },
    {
      name: 'Joined',
      selector: (row) => moment(row.created_at).format('MMM DD, YYYY'),
      sortable: true,
      sortFunction: (a, b) => (a.created_at > b.created_at ? 1 : -1),
    },
    {
      name: 'Entity',
      selector: (row) => row.entity,
      sortable: true,
    },
    {
      name: 'Registered',
      selector: (row) => (
        <BooleanCell value={row.is_registered}>{row.is_registered ? 'true' : 'false'}</BooleanCell>
      ),
    },
    {
      name: 'Approved',
      selector: (row) => (
        <BooleanCell value={row.is_approved}>{row.is_approved ? 'true' : 'false'}</BooleanCell>
      ),
    },
    {
      name: 'US Bank Connected',
      selector: (row) => (
        <BooleanCell value={row.us_bank_account_is_connected}>{row.us_bank_account_is_connected ? 'true' : 'false'}</BooleanCell>
      ),
    },
    {
      name: 'IL Bank Connected',
      selector: (row) => (
        <BooleanCell value={row.il_bank_account_is_connected}>{row.il_bank_account_is_connected ? 'true' : 'false'}</BooleanCell>
      ),
    },
    {
      name: '# of Transfers',
      selector: (row) => (
        <BooleanCell value={row.number_of_transfers}>{row.number_of_transfers || ""}</BooleanCell>
      ),
    }
  ];

  return (
    <UsersContainer>
      <StyledFieldset style={{ height: '100%' }}>
        <StyledLegend>Users</StyledLegend>
        <DataTableContainer>
          <StyledDataTable
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columns={usersColumns}
            data={filteredItems}
            progressPending={usersResponseIsLoading}
            pagination
            paginationPerPage={10}
            progressComponent={<LoadingInPlace />}
            fixedHeader
            // dense
          />
        </DataTableContainer>
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} filterText={filterText} />
      </StyledFieldset>
    </UsersContainer>
  );
};
