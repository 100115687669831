import styled from 'styled-components';
import DatePicker from 'react-widgets/DatePicker';
import 'react-widgets/scss/styles.scss';

import { THEME } from '../../../constants';

export const StyledFieldset = styled.div.attrs({
  className: 'StyledFieldset',
})`
  background-color: ${THEME.ligher_dark_blue_2};
  border-radius: 10px;
  padding: 5px 25px;
  color: ${THEME.white};
  box-shadow: rgb(0 10 20 / 40%) 0px 8px 24px;
  border: none;
  padding-bottom: 1em;
  flex: 1;
`;

export const StyledOption = styled.option.attrs({
  className: 'StyledOption',
})`
  color: ${THEME.white} !important;
`;

export const StyledLegend = styled.legend.attrs({
  className: 'StyledLegend',
})`
  background-color: ${THEME.ligher_dark_blue_2};
  border-radius: 10px;
  padding: 10px 20px;
  font-size: smaller;
  text-transform: uppercase;
`;

export const EditableInput = styled.input.attrs({
  className: 'EditableInput',
})`
  // border-radius: 8px;
  // border: 1px solid rgb(204, 204, 204);
  // background-color: transparent;
  // border: 1px solid ${THEME.dark_blue};
  border: none;
  background-color: ${THEME.dark_blue};
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 10px 0.85em;
  // font-size: 23px;
  // line-height: 23px;
  // font-weight: 300;
  cursor: text;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &:focus,
  &:active,
  &:hover {
    // border: 1px solid #fbb919;
    // opacity: 1;
    outline: none;
    border: none;
  }
`;

export const Detail = styled.div.attrs({
  className: 'Detail',
})`
  display: flex;
  // justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 10px 0.85em;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

export const TwoOptionContainer = styled.div.attrs({
  className: 'TwoOptionContainer',
})`
  // border: 1px solid rgb(204, 204, 204);
  // background-color: transparent;
  border: none;
  background-color: ${THEME.dark_blue};
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 10px 0.85em;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &:focus,
  &:active,
  &:hover {
    // border: 1px solid #fbb919;
    // outline: none;
    // opacity: 1;
    border: none;
  }
`;

export const RefreshIcon = styled.img.attrs({
  className: 'RefreshIcon',
})`
  max-height: 15px;
  float: right;
`;

export const StyledDatePicker = styled(DatePicker).attrs({
  className: 'StyledDatePicker',
})`
  // height: 70px;
  .rw-widget-container {
    opacity: 0.8;
    &:hover {
      // border-color: #fbb919;
      // outline: none;
      // opacity: 1;
      border: none;
      outline: none;
    }
  }
  .rw-widget-input {
    padding-left: 6px;
    padding-right: 6px;
  }
  .rw-widget-input,
  .rw-widget-picker {
    // border-color: rgb(204, 204, 204);
    // background-color: transparent;
    border: none;
    background-color: ${THEME.dark_blue};
    color: white;
    font-size: inherit;
    // height: 70px;
    border-radius: 8px;
    font-family: Fontsfree net proxima nova;
    color: #fff;
    // padding: 20.5px 12px;
    // font-size: 23px;
    // line-height: 23px;
    // font-weight: 300;
    &:focus,
    &:active,
    &:hover {
      // border-color: #fbb919;
      // outline: none;
      // opacity: 1;
      border: none;
      outline: none;
    }
  }
  .rw-state-focus {
    border: none;
    outline: none;
    &:focus,
    &:active,
    &:hover {
      // border-color: #fbb919;
      // outline: none;
      // opacity: 1;
      border: none;
      outline: none;
    }
  }
  .rw-picker-btn {
    // border-color: inherit;
    border: none;
    color: white;
    &:hover {
      color: ${THEME.dark_blue};
    }
  }
`;

export const DownArrowIcon = styled.img.attrs({
  className: 'DownArrowIcon',
})`
  position: absolute;
  right: 12px;
  cursor: pointer;
`;

export const StyledSelect = styled.select.attrs({
  className: 'StyledSelect',
})`
  // border: 1px solid rgb(204, 204, 204);
  // background-color: transparent;
  border: none;
  background-color: ${THEME.dark_blue};
  height: 100%;
  appearance: none;
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  padding: 10px 0.85em;
  //   font-size: 23px;
  //   line-height: 23px;
  //   font-weight: 300;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  option {
    color: #222;
  }
  &:focus,
  &:active,
  &:hover {
    // border: 1px solid #fbb919;
    // outline: none;
    // opacity: 1;
    border: none;
    outline: none;
  }
`;

export const StyledButton = styled.button.attrs({
  className: 'StyledButton',
})<{ transparent?: boolean }>(
  ({ transparent = false }) => `
    // min-width: 100px;
    cursor: pointer;
    height: auto;
    padding: 16px;
    border: 1px solid ${THEME.yellow};
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 100%;
    opacity: 0.95;
    background: ${transparent ? 'transparent' : THEME.yellow};
    color: ${THEME.white};
    &:hover {
      opacity: 1;
    }
    &:disabled {
      background-color: #cccccc;
      border-color: transparent;
      opacity: 0.8 !important;
      cursor: default;
    }
    &:hover {
      opacity: 0.9;
    }
  `,
);

export const StyledLink = styled.a.attrs({
  className: 'StyledLink',
})<{ transparent?: boolean }>(
  ({ transparent = false }) => `
    text-align: center;
    min-width: 120px;
    cursor: pointer;
    height: auto;
    padding: 16px;
    border: 1px solid ${THEME.yellow};
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 100%;
    opacity: 0.95;
    background: ${transparent ? 'transparent' : THEME.yellow};
    color: ${THEME.white};
    &:hover {
      opacity: 1;
    }
    &:disabled {
      background-color: #cccccc;
      border-color: transparent;
      opacity: 0.8 !important;
      cursor: default;
    }
    &:hover {
      opacity: 0.9;
    }
  `,
);

export const SavingContainer = styled.div.attrs({
  className: 'SavingContainer',
})<{ active }>(
  ({ active }) => `
    color: white;
    border-radius: 5px;
    position: fixed;
    background-color: red;
    padding: 20px;
    bottom: 20px;
    z-index: 1000;
    transition: right 500ms;
    right: ${active ? '20px' : '-250px'};
  `,
);
