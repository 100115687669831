import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { THEME } from '../../constants';
import { ESignatureIOModal } from '../esignatureio-document-modal';
import useAxios from 'axios-hooks';
import Modal from 'react-modal';
import { CUSTOM_MODAL_STYLE } from '../../constants';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './social-security-number-panel.css';

const SubmitSSNButton = styled.button.attrs({
  className: 'SubmitSSNButton',
})`
  border: 1px solid #fbac1f;
  background: ${THEME.yellow};
  border-radius: 3px;
  font-family: 'Fontsfree net proxima nova';
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 8px;
  color: ${THEME.white};
  padding: 12px 13px;
  white-space: nowrap;
  width: 100%;
  height: 48px;
  margin-left: auto;
  cursor: pointer;
  // margin-bottom: 40px;
  opacity: ${0.9};
  &:hover {
    opacity: 1;
  }
`;

const ConfirmButton = styled.button`
  cursor: pointer;
  -webkit-appearance: button;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  color: ${THEME.white};
  font-size: 20px;
  height: 56px;
  width: -webkit-fill-available;
  margin: 20px 0px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 40px;
  transition-duration: 200ms;
  &:hover {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
    box-shadow: none;
    opacity: 0.3;
  }
`;

const WrapInput = styled.div.attrs({
  className: 'WrapInput',
})`
  padding: 0 calc((100% - 280px) / 2);
  height: calc(100% - 95px);
`;

const LockIcon = () => (
  <i className="Icon Input__icon Input__icon--right">
    <svg viewBox="0 0 24 24">
      <path
        d="M17.282 11c.57 0 1.026.444 1.026 1v7c0 .556-.456 1-1.026 1H7.026C6.456 20 6 19.556 6 19v-7c0-.556.456-1 1.026-1H8.05V8c0-2.204 1.843-4 4.103-4s4.102 1.796 4.102 4v3h1.026zm-7.18-3v3h4.103V8c0-1.111-.912-2-2.051-2-1.14 0-2.051.889-2.051 2z"
        fillRule="evenodd"
      ></path>
    </svg>
  </i>
);

const StyledReactToolTip = styled(ReactTooltip).attrs({
  className: 'StyledReactToolTip',
})`
  background: black;
  line-height: 20px;
  max-width: 300px;
`;

const SocialSecurityNumberModal = ({
  socialSecurityModalIsOpen,
  closeSocialSecurityModal,
  socialSecurityNumberValue,
  setSocialSecurityNumberValue,
  handleConfirmSSN,
  createSSNVerificationDocumentIsLoading,
  updateSSNVerificationDocumentIsLoading,
  confirmButtonIsEnabled,
  setConfirmButtonIsEnabled,
}) => {

  const formatSocialSecurityNumber = (socialSecurityNumberValue) => {
    if (!socialSecurityNumberValue?.length) return '';
    else if (socialSecurityNumberValue?.length <= 3) return socialSecurityNumberValue;
    else if (socialSecurityNumberValue?.length > 3 && socialSecurityNumberValue?.length <= 5)
      return `${socialSecurityNumberValue.slice(0, 3)}-${socialSecurityNumberValue.slice(3, 5)}`;
    else
      return `${socialSecurityNumberValue.slice(0, 3)}-${socialSecurityNumberValue.slice(
        3,
        5,
      )}-${socialSecurityNumberValue.slice(5, 9)}`;
  };

  const socialSecurityNumberIsValid = socialSecurityNumberValue?.length == 9;

  const handleSocialSecurityOnChange = (event) => {
    setSocialSecurityNumberValue(event.target.value?.match(/\d/g)?.join(''));
  };

  useEffect(() => {
    if (createSSNVerificationDocumentIsLoading || updateSSNVerificationDocumentIsLoading)
      setConfirmButtonIsEnabled(false);
    else setConfirmButtonIsEnabled(socialSecurityNumberIsValid);
  }, [createSSNVerificationDocumentIsLoading, updateSSNVerificationDocumentIsLoading]);

  useEffect(() => {
    setConfirmButtonIsEnabled(socialSecurityNumberIsValid);
  }, [socialSecurityNumberIsValid]);

  return (
    <Modal
      style={CUSTOM_MODAL_STYLE}
      isOpen={true}
      onRequestClose={closeSocialSecurityModal}
      appElement={document.getElementById('root')}
    >
      <>
        <h2 className="modal-title">
          <span className="Navbar__button Navbar__button--is-left"></span>
          Verify Social Security Number
          <span
            className="Navbar__button Navbar__button--is-right"
            onClick={closeSocialSecurityModal}
          >
            <i className="Icon Navbar__icon">
              <svg viewBox="0 0 24 24">
                <path
                  fill="#fff"
                  d="M10.695 12.5l-4.342 4.343c-.482.481-.468 1.276.03 1.774.498.499 1.293.512 1.774.03l4.343-4.342 4.317 4.317c.482.482 1.276.468 1.775-.03.498-.499.512-1.293.03-1.775L14.305 12.5l4.317-4.317c.482-.482.468-1.276-.03-1.775-.498-.498-1.293-.512-1.775-.03L12.5 10.695 8.157 6.352c-.481-.481-1.276-.468-1.774.03-.499.5-.512 1.294-.03 1.775l4.342 4.343z"
                ></path>
              </svg>
            </i>
          </span>
        </h2>
        <WrapInput>
          <div className="Input Input--has-icon">
            <div className="Input__field-container">
              <input
                type="phone"
                className="Input__field"
                aria-required="true"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                placeholder="SSN #"
                maxLength={11}
                value={formatSocialSecurityNumber(socialSecurityNumberValue) || ''}
                onChange={handleSocialSecurityOnChange}
              />
            </div>
            <LockIcon />
          </div>
          <p style={{ fontSize: '17px' }}>
            Please provide your SSN. If you do not have a Social Security Number (SSN), please
            provide your Tax Identification Number (TIN).
          </p>
          <p id="ssn-reason" style={{ fontSize: '17px', cursor: 'help' }}>
            Why must I provide my SSN? <i className="fa fa-question-circle" aria-hidden="true"></i>
          </p>
          <StyledReactToolTip
            anchorId={'ssn-reason'}
            place="bottom"
            content={
              'Before you can link your financial accounts to your profile, OlehPay is required by US law to obtain a SSN from every person using its services. After this step, you will be able to link financial accounts and conduct your first transfer.'
            }
          />
          <ConfirmButton disabled={!confirmButtonIsEnabled} onClick={handleConfirmSSN}>
            Confirm
          </ConfirmButton>
        </WrapInput>
      </>
    </Modal>
  );
};

export const SocialSecurityNumberPanel = ({
  SSNVerificationDocument,
  setSSNVerificationDocument,
  activeESignatureIODocument,
  setActiveESignatureIODocument,
  getSocialSecurityNumber,
  socialSecurityNumber,
  isTest = false,
}) => {
  const [confirmButtonIsEnabled, setConfirmButtonIsEnabled] = useState(false);
  const [socialSecurityNumberValue, setSocialSecurityNumberValue] = useState('');
  const [socialSecurityModalIsOpen, setSocialSecurityModalIsOpen] = useState(false);
  const [ESignatureIOModalIsOpen, setESignatureIOModalIsOpen] = useState(false);
  const [{ loading: createSSNVerificationDocumentIsLoading }, createSSNVerificationDocument] =
    useAxios('/esignatureio_documents/', {
      manual: true,
    });
  const [{ loading: updateSSNVerificationDocumentIsLoading }, updateSSNVerificationDocument] =
    useAxios(`/esignatureio_documents/${SSNVerificationDocument?.uuid}/`, {
      manual: true,
    });

  const handleConfirmSSN = () => {
    setConfirmButtonIsEnabled(false);
    if (
      SSNVerificationDocument &&
      socialSecurityNumber?.value &&
      socialSecurityNumber.value !== socialSecurityNumberValue
    ) {
      // handle case where ssn has been updated and there is an esignature document:
      updateSSNVerificationDocument({
        method: 'PATCH',
        data: {
          type: 'SOCIAL_SECURITY_CONFIRMATION',
          social_security_number: socialSecurityNumberValue,
        },
      }).then((response) => {
        setSSNVerificationDocument(response?.data);
        setActiveESignatureIODocument(response?.data);
        setESignatureIOModalIsOpen(true);
        setSocialSecurityModalIsOpen(false);
        getSocialSecurityNumber();
      });
    } else if (!SSNVerificationDocument) {
      // handle case where there is no esignature document:
      createSSNVerificationDocument({
        method: 'POST',
        data: {
          type: 'SOCIAL_SECURITY_CONFIRMATION',
          social_security_number: socialSecurityNumberValue,
        },
      }).then((response) => {
        setSSNVerificationDocument(response?.data);
        setActiveESignatureIODocument(response?.data);
        setESignatureIOModalIsOpen(true);
        setSocialSecurityModalIsOpen(false);
        getSocialSecurityNumber();
      });
    } else {
      setActiveESignatureIODocument(SSNVerificationDocument);
      setESignatureIOModalIsOpen(true);
      setSocialSecurityModalIsOpen(false);
      getSocialSecurityNumber();
    }
  };

  if (isTest) {
    return (
      <SocialSecurityNumberModal
        socialSecurityModalIsOpen={true}
        closeSocialSecurityModal={() => setSocialSecurityModalIsOpen(false)}
        socialSecurityNumberValue={socialSecurityNumberValue}
        setSocialSecurityNumberValue={setSocialSecurityNumberValue}
        handleConfirmSSN={handleConfirmSSN}
        createSSNVerificationDocumentIsLoading={createSSNVerificationDocumentIsLoading}
        updateSSNVerificationDocumentIsLoading={updateSSNVerificationDocumentIsLoading}
        confirmButtonIsEnabled={confirmButtonIsEnabled}
        setConfirmButtonIsEnabled={setConfirmButtonIsEnabled}
      />
    );
  }
  return (
    <>
      <SubmitSSNButton onClick={() => setSocialSecurityModalIsOpen(true)}>
        CONNECT A BANK ACCOUNT
      </SubmitSSNButton>
      {socialSecurityModalIsOpen && (
        <SocialSecurityNumberModal
          socialSecurityModalIsOpen={socialSecurityModalIsOpen}
          closeSocialSecurityModal={() => setSocialSecurityModalIsOpen(false)}
          socialSecurityNumberValue={socialSecurityNumberValue}
          setSocialSecurityNumberValue={setSocialSecurityNumberValue}
          handleConfirmSSN={handleConfirmSSN}
          createSSNVerificationDocumentIsLoading={createSSNVerificationDocumentIsLoading}
          updateSSNVerificationDocumentIsLoading={updateSSNVerificationDocumentIsLoading}
          confirmButtonIsEnabled={confirmButtonIsEnabled}
          setConfirmButtonIsEnabled={setConfirmButtonIsEnabled}
        />
      )}

      <ESignatureIOModal
        ESignatureIOModalIsOpen={ESignatureIOModalIsOpen}
        closeESignatureIOModal={() => setESignatureIOModalIsOpen(false)}
        activeESignatureIODocument={activeESignatureIODocument}
      />
    </>
  );
};
