import Modal from 'react-modal';
import { CUSTOM_MODAL_STYLE } from '../../constants';
import { THEME } from '../../constants';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import styled from 'styled-components';
import moment from 'moment';
import useAxios from 'axios-hooks';
import { useEffect, useRef } from 'react';

const WrapModalTitle = styled.h2`
  margin: 0;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WrapTransferDetail = styled.div`
  justify-content: space-between;
  display: flex;
  text-align: right;
`;

const WrapButton = styled.button`
  cursor: pointer;
  -webkit-appearance: button;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  color: ${THEME.white};
  font-size: 20px;
  height: 56px;
  width: -webkit-fill-available;
  margin: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ESignatureFrame = styled.iframe.attrs({
  className: 'ESignatureFrame',
})`
  width: 100%;
  height: calc(100% - 50px);
  border: none;
`;

const ModalContent = ({ closeESignatureIOModal, activeESignatureIODocument, title }) => {
  return (
    <>
      <WrapModalTitle className="modal-title">
        <span className="Navbar__button Navbar__button--is-left"></span>
        {title}
        <span className="Navbar__button Navbar__button--is-right" onClick={closeESignatureIOModal}>
          <i className="Icon Navbar__icon">
            <svg viewBox="0 0 24 24">
              <path
                fill="#fff"
                d="M10.695 12.5l-4.342 4.343c-.482.481-.468 1.276.03 1.774.498.499 1.293.512 1.774.03l4.343-4.342 4.317 4.317c.482.482 1.276.468 1.775-.03.498-.499.512-1.293.03-1.775L14.305 12.5l4.317-4.317c.482-.482.468-1.276-.03-1.775-.498-.498-1.293-.512-1.775-.03L12.5 10.695 8.157 6.352c-.481-.481-1.276-.468-1.774.03-.499.5-.512 1.294-.03 1.775l4.342 4.343z"
              ></path>
            </svg>
          </i>
        </span>
      </WrapModalTitle>
      <ESignatureFrame src={activeESignatureIODocument?.url} id="eSignaturesIOIframe" />
    </>
  );
};

export const ESignatureIOModal = ({
  ESignatureIOModalIsOpen,
  closeESignatureIOModal,
  activeESignatureIODocument,
}) => {
  const title =
    activeESignatureIODocument?.type?.value === 'FUNDING_FORM'
      ? 'Debit Authorization'
      : activeESignatureIODocument?.type?.value === 'SOCIAL_SECURITY_CONFIRMATION'
      ? 'Verify Social Security Number'
      : null;
  return (
    <Modal
      style={{
        content: {
          position: 'absolute',
          inset: '50% auto auto 50%',
          border: 'none',
          background: 'rgb(53, 63, 86)',
          overflow: 'hidden',
          borderRadius: '8px',
          outline: 'none',
          padding: '0px',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 16px 0px',
          color: 'rgb(255, 255, 255)',
          height: '536px',
          maxWidth: '360px',
          width: '100vw',
        },
      }}
      isOpen={ESignatureIOModalIsOpen}
      onRequestClose={closeESignatureIOModal}
      appElement={document.getElementById('root')}
    >
      <ModalContent
        title={title}
        closeESignatureIOModal={closeESignatureIOModal}
        activeESignatureIODocument={activeESignatureIODocument}
      />
    </Modal>
  );
};
