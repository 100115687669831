import React, { useEffect, useState, useRef } from 'react';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import Modal from 'react-modal';
import moment from 'moment';
import { SortingState } from '@tanstack/react-table';

import { Table } from '../../../../components/table';
import { THEME } from '../../../../constants';
import GREEN_CHECK from '../../../../assets/imgs/green_check.png';
import { DocumentPreviewModal } from '../../../../components/document-preview-modal';
import { StyledButton, StyledFieldset, StyledLegend } from '../../common-components';

Modal.defaultStyles.overlay.backgroundColor = 'rgb(144 144 144 / 98%)';

const TitleTable = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: ${THEME.white};
  margin-top: 20px;
  position: relative;
  display: table;
  padding-bottom: 15px;
  margin-bottom: 20px;
  &:after {
    content: '';
    border: 1px solid ${THEME.yellow};
    width: 100%;
    bottom: 0;
    position: absolute;
    left: 0;
  }
`;

const ViewButton = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  padding: 3px;
  align-items: center;
  text-align: center;
  background: transparent;
  color: #fbb919;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    opacity: 0.9;
  }
`;

const WrapDocumentsDesktop = styled.div`
  @media (max-width: ${THEME.breakpoint}px) {
    display: none;
  }
`;

const WrapGreenCheck = styled.img`
  height: 1.3em;
`;

export const DocumentsDetails = ({ user, documents, getDocuments }) => {
  const [{loading: createESignatureIODocumentIsLoading}, createESignatureIODocument] = useAxios(
    '/esignatureio_documents/',
    {
      manual: true,
    },
  );

  const columns = [
    {
      accessorFn: (row: any) => row.file_metadata.original_name,
      id: 'name',
      cell: (info: any) => info.getValue(),
      header: () => <span>Name</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => moment(row.created_at).format('MMM D, YYYY'),
      id: 'created',
      cell: (info: any) => info.getValue(),
      header: () => <span>Created</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => (row.is_signed ? <WrapGreenCheck src={GREEN_CHECK} /> : null),
      id: 'is_signed',
      cell: (info: any) => info.getValue(),
      header: () => <span>Signed</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => row.type.value.replaceAll('_', ' '),
      id: 'type',
      cell: (info: any) => {
        const cls = info.getValue().replaceAll(' ', '_').toLowerCase();
        return (
          <>
            <div
              id={`status-tooltip-${info.getValue().toLowerCase()}-${info.row.id}`}
              className={`tag tag-${cls}`}
            >
              {info.getValue()}
            </div>
          </>
        );
      },
      header: () => <span>Type</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'action',
      header: () => <span>Preview</span>,
      cell: (info: any) => {
        return (
          <ViewButton type="button" onClick={() => openDocumentPreviewModal(info)}>
            Preview
          </ViewButton>
        );
      },
      footer: (props: any) => props.column.id,
    },
  ];

  // table, chart states:
  const [currentPage, setPage] = useState<any>(1);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [documentInstance, setDocumentInstance] = React.useState<any>(null);
  const [documentPreviewModalIsOpen, setDocumentPreviewModalIsOpen] = React.useState(false);

  const openDocumentPreviewModal = (infor) => {
    setDocumentInstance(infor.row.original);
    setDocumentPreviewModalIsOpen(true);
  };

  if (!documents?.length) return;

  const tableData = documents?.filter((document) => document.file);

  const handleGenerateKYCOnClick = () => {
    createESignatureIODocument({
      method: 'POST',
      data: {
        user: user.email,
        type: 'KNOW_YOUR_CUSTOMER_FORM',
      },
    }).then(response => {
      getDocuments()
    })
  }

  return (
    <StyledFieldset>
      <StyledLegend>Documents</StyledLegend>
      <WrapDocumentsDesktop>
        <div>
          <StyledButton disabled={createESignatureIODocumentIsLoading} onClick={handleGenerateKYCOnClick}>Generate KYC</StyledButton>
        </div>
        <TitleTable>{tableData.length} Documents</TitleTable>
        <div>
          <Table
            data={tableData}
            columns={columns}
            setSorting={setSorting}
            sorting={sorting}
            setPage={setPage}
            page={currentPage}
            pageSize={10}
            total={tableData.length}
          />
        </div>
      </WrapDocumentsDesktop>
      <DocumentPreviewModal
        documentPreviewModalIsOpen={documentPreviewModalIsOpen}
        closeDocumentPreviewModal={() => setDocumentPreviewModalIsOpen(false)}
        documentInstance={documentInstance}
      />
    </StyledFieldset>
  );
};
