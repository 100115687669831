import { useEffect } from 'react';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import moment from 'moment';

import {
  StyledFieldset,
  StyledLegend,
  EditableInput,
  TwoOptionContainer,
  RefreshIcon,
  StyledDatePicker,
  StyledButton,
  Detail,
  SavingContainer,
} from '../../common-components';
import ICON_REFRESH from '../../../../assets/imgs/icon/refresh_icon.svg';
import { useIsMount } from '../../../../hooks/useIsMount';

const PersonalDetailsForm = styled.div.attrs({
  className: 'PersonalDetailsForm',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
`;

export const PersonalDetails = ({ profile, setProfile, socialSecurityNumber }) => {
  const isFirstRender = useIsMount();
  const [{ loading: profileIsSaving }, updateProfile] = useAxios(
    {
      url: `/profiles/${profile?.uuid}/`,
      method: 'PATCH',
    },
    { manual: true },
  );

  const dateOfBirth = profile?.date_of_birth
    ? moment(profile.date_of_birth, 'YYYY-MM-DD').toDate()
    : null;

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const handleFirstNameOnChange = (event) =>
    setProfile((profile) => ({
      ...profile,
      first_name: capitalize(event.target.value),
    }));

  const handleLastNameOnChange = (event) =>
    setProfile((profile) => ({
      ...profile,
      last_name: capitalize(event.target.value),
    }));

  const handleDateOfBirthOnChange = (date) => {
    setProfile((profile) => ({
      ...profile,
      date_of_birth: date?.toISOString()?.slice(0, 10),
    }));
  };

  const handleSexOnChange = () =>
    setProfile((profile) => ({
      ...profile,
      sex: profile.sex === 'M' ? 'F' : 'M',
    }));

  const handlePercentFeeOnChange = (event) =>
    setProfile((profile) => ({
      ...profile,
      percent_fee: event.target.value,
    }));

  useEffect(() => {
    if (isFirstRender) return;
    const { is_approved, ...partialProfile } = profile;
    updateProfile({
      data: partialProfile,
    }).catch((error) => {
      if (error.code === 'ERR_CANCELED') return;
    });
  }, [profile?.first_name, profile?.last_name, profile?.sex, profile?.date_of_birth, profile?.percent_fee, profile?.notes]);

  return (
    <>
      <StyledFieldset>
        <StyledLegend>Personal Details</StyledLegend>
        <PersonalDetailsForm>
          <EditableInput
            value={profile?.first_name || ''}
            onChange={handleFirstNameOnChange}
            placeholder="first name"
          />
          <EditableInput
            value={profile?.last_name || ''}
            onChange={handleLastNameOnChange}
            placeholder="last name"
          />
          <TwoOptionContainer onClick={handleSexOnChange}>
            {profile?.sex === 'M' ? 'Male' : 'Female'}
            <RefreshIcon src={ICON_REFRESH} alt="" />
          </TwoOptionContainer>
          <StyledDatePicker
            value={dateOfBirth}
            onChange={handleDateOfBirthOnChange}
            calendarProps={{ defaultView: 'century', defaultCurrentDate: new Date('1970') }}
            placeholder="Date of Birth m/d/yyyy"
            valueDisplayFormat={{ dateStyle: 'long' }}
            valueEditFormat={{ dateStyle: 'long' }}
            onMouseLeave={() => {
              (document.activeElement as any).blur();
            }}
          />
          <Detail>
            {socialSecurityNumber?.verification_document_is_signed
              ? 'SSN signed and verified'
              : socialSecurityNumber?.value
              ? 'SSN submitted'
              : 'missing SSN'}
          </Detail>
          <span style={{display: 'flex'}}>
            <Detail style={{width: '50%'}}>
              Custom Fee:
            </Detail>
            <EditableInput
              style={{width: '50%'}}
              value={profile?.percent_fee || ''}
              onChange={handlePercentFeeOnChange}
              placeholder="percent fee"
            />
          </span>
        </PersonalDetailsForm>
      </StyledFieldset>
      <SavingContainer active={profileIsSaving}>saving profile...</SavingContainer>
    </>
  );
};
