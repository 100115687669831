import Modal from 'react-modal';
import { CUSTOM_MODAL_STYLE } from '../../constants';
import { THEME } from '../../constants';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import styled from 'styled-components';
import moment from 'moment';

const WrapTransferDetail = styled.div`
  justify-content: space-between;
  display: flex;
  text-align: right;
  margin-bottom: 8px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-family: Vaud, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
`;

const WrapButton = styled.button`
  cursor: pointer;
  -webkit-appearance: button;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  color: ${THEME.white};
  font-size: 20px;
  height: 56px;
  width: -webkit-fill-available;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 40px;
  margin-top: 0px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = ({ closeTransferDetailsModal, transferDetails }) => {
  return (
    <>
      <h2 className="modal-title">
        <span className="Navbar__button Navbar__button--is-left"></span>
        Transfer Details
        <span
          className="Navbar__button Navbar__button--is-right"
          onClick={closeTransferDetailsModal}
        >
          <i className="Icon Navbar__icon">
            <svg viewBox="0 0 24 24">
              <path
                fill="#fff"
                d="M10.695 12.5l-4.342 4.343c-.482.481-.468 1.276.03 1.774.498.499 1.293.512 1.774.03l4.343-4.342 4.317 4.317c.482.482 1.276.468 1.775-.03.498-.499.512-1.293.03-1.775L14.305 12.5l4.317-4.317c.482-.482.468-1.276-.03-1.775-.498-.498-1.293-.512-1.775-.03L12.5 10.695 8.157 6.352c-.481-.481-1.276-.468-1.774.03-.499.5-.512 1.294-.03 1.775l4.342 4.343z"
              ></path>
            </svg>
          </i>
        </span>
      </h2>
      <div className="modal-body body-inheri">
        <WrapTransferDetail>
          <label>Number:</label>
          <span>{transferDetails.number}</span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>Requested:</label>
          <span>{moment(transferDetails.requested_at).format('MMM DD, YYYY')}</span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>Debited:</label>
          <span>
            {transferDetails.currency_sending?.symbol}
            {transferDetails.amount_sending}
          </span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>From:</label>
          <span>
            <AccountBalanceIcon fontSize={'inherit'}></AccountBalanceIcon>{' '}
            {transferDetails.bank_account_sending?.name}
          </span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>Fee:</label>
          <span>
            {transferDetails.fee?.currency?.symbol}
            {transferDetails.fee?.amount || 'TBD'}
          </span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>Percent:</label>
          <span>{transferDetails.fee?.percent || 'TBD'}%</span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>Rate:</label>
          <span>{transferDetails.rate?.amount || 'TBD'}</span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>Date:</label>
          <span>
            {transferDetails.rate?.date
              ? moment(transferDetails.rate?.date).format('MMM DD, YYYY')
              : 'TBD'}
          </span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>Credited:</label>
          <span>
            {transferDetails.currency_receiving?.symbol}
            {transferDetails.amount_receiving || 'TBD'}
          </span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>To:</label>
          <span>
            <AccountBalanceIcon fontSize={'inherit'}></AccountBalanceIcon>{' '}
            {transferDetails.bank_account_receiving?.name}
          </span>
        </WrapTransferDetail>
        <WrapTransferDetail>
          <label>Completed:</label>
          <span>
            {transferDetails.completed_at
              ? moment(transferDetails.completed_at).format('MMM DD, YYYY')
              : 'TBD'}
          </span>
        </WrapTransferDetail>
        <WrapButton onClick={closeTransferDetailsModal}>Close</WrapButton>
      </div>
    </>
  );
};

export const TransferDetailsModal = ({
  transferDetailsModalIsOpen,
  closeTransferDetailsModal,
  transferDetails,
}) => {
  return (
    <Modal
      style={CUSTOM_MODAL_STYLE}
      isOpen={transferDetailsModalIsOpen}
      onRequestClose={closeTransferDetailsModal}
      appElement={document.getElementById('root')}
    >
      <ModalContent
        closeTransferDetailsModal={closeTransferDetailsModal}
        transferDetails={transferDetails}
      />
    </Modal>
  );
};
