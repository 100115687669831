import styled from 'styled-components'
import DataTable from 'react-data-table-component'

import { THEME } from '../../../../../constants'

export const DataTableContainer = styled.div.attrs({
  className: 'DataTableContainer',
})`
  // height: 100%;
  height: calc(100% - 20px);
  position: relative;
  & > div:nth-child(2) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .rdt_Pagination {
    background-color: ${THEME.ligher_dark_blue_2};
    color: ${THEME.gray};
    border-color: ${THEME.ligher_dark_blue};
  }
  > div > nav > div > button:not(:disabled) > svg {
    fill: ${THEME.gray};
  }
`

export const StyledDataTable = styled(DataTable).attrs({
  className: 'StyledDataTable',
})`
  height: 100%;
  .rdt_Table,
  .rdt_TableCol,
  .rdt_TableRow,
  .rdt_TableCol_Sortable,
  .rdt_TableCell,
  .rdt_TableHeader,
  .rdt_TableFooter,
  .rdt_TableHead,
  .rdt_TableHeadRow,
  .rdt_TableBody,
  .rdt_ExpanderRow {
    background-color: ${THEME.ligher_dark_blue_2};
    color: inherit;
  }
  .rdt_Table {
    overflow-y: auto;
  }
  .rdt_TableCol_Sortable {
    font-size: larger;
  }
  .rdt_TableCell {
    color: ${THEME.gray};
    padding-left: 7px;
    padding-right: 7px;
  }
  .rdt_TableCol {
    padding-left: 7px;
    padding-right: 7px;
  }
  .rdt_TableRow,
  .rdt_TableHeadRow {
    border-bottom-color: ${THEME.ligher_dark_blue};
  }
  .rdt_TableCell > div,
  .rdt_TableCell > div > button {
    width: 100%;
  }
  > div {
    height: 100%;
    table-layout: fixed;
  }
  > div > div {
    height: calc(100% - 60px);
  }
  .rdt_TableRow .StyledButton {
    height: 35px;
    padding: 0 15px;
    font-size: 11px !important;
    text-align: center;
  }
`

export const StyledLink = styled.a.attrs({
  className: 'StyledLink',
})`
  color: ${THEME.light_blue_link};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    opacity: 0.9;
  }
`
