/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useEffect } from 'react';
import { THEME } from '../../constants';

import styled from 'styled-components';
export const FooterHome: FC<{
  userIsRegistered?;
  userIsAuthenticated?;
  setToken?;
  isHideIntro?;
}> = ({ userIsRegistered, userIsAuthenticated, setToken, isHideIntro }) => {
  const id = '04a62eba-a824-44fb-890d-5d42dde343b4';
  const lazy = false;

  // Inject Senja script into the head of the dom
  useEffect(() => {
    // Check if the script is already in the head of the dom
    let script = document.querySelector(`script[src="https://widget.senja.io/embed/frame.js"]`) as any;

    if (script) return;

    script = document.createElement('script');
    script.src = 'https://widget.senja.io/embed/frame.js';
    script.async = true;
    script.type = 'text/javascript';
    document.body.append(script);
  }, []);
  return (
    <>
      <div className={`footer-webflow ${isHideIntro ? '' : 'footer-index-webflow'}`}>
        {!isHideIntro && (
          <div className="reviews-container w-container">
            <div
              className="senja-frame-embed"
              data-lazyload={lazy}
              data-id={id}
            ></div>

            <div className="news-container">
              <div
                data-w-id="e2ffdcb2-861a-8fc7-d6a4-b42cec39a173"
                style={{
                  transform:
                    'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
                }}
                className="news-logos-container"
              >
                <div className="news-logo-wrapper">
                  <img src={require('../../assets/imgs/janglo.svg').default} />
                </div>
                <div className="news-logo-wrapper">
                  <img src={require('../../assets/imgs/made-in-JLM.svg').default} />
                </div>
                <div className="news-logo-wrapper">
                  <img src={require('../../assets/imgs/jerusalem-post.svg').default} />
                </div>
                <div className="news-logo-wrapper">
                  <img src={require('../../assets/imgs/the-times-of-israel.svg').default} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          data-w-id="e3729431-472a-4fbb-8f4b-e134e94eb65e"
          style={{
            transform:
              'translateX(0px) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)',
            opacity: '1',
          }}
          className="footer-container w-container"
        >
          <div className="footer-logo-container w-clearfix">
            <div className="footer-logo-wrapper">
              <img
                src={require('../../assets/imgs/logo.svg').default}
                height="20"
                className="footer-logo-light"
              />
            </div>
            <p className="tcp-dark" style={{ fontSize: '14px' }}>
              Made in Jerusalem for your peace of mind.
            </p>
          </div>
          <div className="footer-nav-container">
            <div className="footer-nav-wrapper">
              <div className="c1 tcp-dark">CONNECT</div>
              <a href="https://facebook.com/OlehPayIsrael" className="c1 footer-nav-link">
                Facebook
              </a>
              <a href="/contact" className="c1 footer-nav-link">
                Contact
              </a>
            </div>
            <div className="footer-nav-wrapper">
              <div className="c1 tcp-dark">RESOURCES</div>

              <a href="/join" className="c1 footer-nav-link">
                Getting Started
              </a>
              <a href="/faq" className="c1 footer-nav-link">
                FAQ
              </a>
              <a href="/transfer-times" className="c1 footer-nav-link">
                Transfer Speed
              </a>
              <a href="/security" className="c1 footer-nav-link">
                Security
              </a>
              <a href="/privacy" className="c1 footer-nav-link">
                Privacy
              </a>
              <a href="/terms" className="c1 footer-nav-link">
                Terms
              </a>
            </div>
            <div className="footer-nav-wrapper">
              <div className="c1 tcp-dark">COMPANY</div>
              <a href="/contact" className="c1 footer-nav-link">
                About
              </a>
              <a href="/join" className="c1 footer-nav-link">
                Getting Started
              </a>
              <a href="/info" className="c1 footer-nav-link">
                Why OlehPay
              </a>
            </div>
          </div>
          <div className="footer-disclaimer-wrapper">
            <p className="c2 tcp-dark">
              Copyright © 2015-{new Date().getFullYear()} OlehPay, LLC. All Rights Reserved.{' '}
              <a href="/terms" className="inline-footer-link">
                Terms of Service
              </a>
              ,{' '}
              <a href="/privacy" className="inline-footer-link">
                Privacy Policy
              </a>
              .<br />
              <br />
              <span style={{ fontSize: '10px', margin: 'auto', lineHeight: '20px' }}>
                OlehPay is a branded service provided by various regulated money service businesses
                which possess licenses from the the Israel Ministry of Finance as Currency Services
                Providers. The OlehPay brand and platform is licensed from OlehPay, LLC, which is an
                independent sales organization and not a currency services provider nor an agent
                thereof. OlehPay&apos;s payment providers are also registered in the US with FinCEN
                as Money Service Businesses.
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
