/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../hooks/axios';
import { HeaderHome } from '../../components/header-home';
import { FooterHome } from '../../components/footer-home';

import moment from 'moment';
export const Terms = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken }) => {
  return (
    <div className="home-content text-white">
      <HeaderHome
        userIsAdmin={userIsAdmin}
        userIsRegistered={userIsRegistered}
        userIsAuthenticated={userIsAuthenticated}
        setToken={setToken}
        isGrayHeader
      />

      <section className="main">
        <div
          id="indexPageCalcHolder"
          className="calculator_holder"
          style={{ margin: '0 auto 0', maxWidth: '100%' }}
        >
          <div className="marginBottom">
            <div className="spacing_half started2">
              <nav style={{ textAlign: 'center' }}>
                <div className="menu-footer-menu-unregistered-container" style={{ margin: 'auto' }}>
                  <ul id="primary-menu" className="add_nav">
                    <li className="menu-item current-menu-item">
                      <a href="#about-olehpay">About OlehPay</a>
                    </li>
                    <li className="menu-item">
                      <a href="#services">Using Our Services</a>
                    </li>
                    <li className="menu-item">
                      <a href="#fees">Fees</a>
                    </li>
                    <li className="menu-item">
                      <a href="#transaction-issues">Issues that might occur</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="info_full_upload upload_mobile3 started1">
                <div
                  style={{ width: '100%', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                >
                  <div
                    className="registerSize started_mobile started text-white"
                    style={{ backgroundColor: 'rgba(55, 65, 88, 0.35)', marginBottom: '80px' }}
                  >
                    <h4 className="startedH4">Welcome To Olehpay</h4>
                    <h4
                      className="startedH4 postedByDate dateInfoMobile"
                      style={{ textAlign: 'right' }}
                    >
                      Last Updated March 17, 2020
                    </h4>
                    <p className="startedP" id="secure">
                      This Customer Agreement is between you and OlehPay, LLC. (“OlehPAy”, “we”,
                      “us”, or “our” as applicable), concerning your use of (including any access
                      to) the Services, as defined below and including the Website, App, and other
                      OlehPay Materials.
                    </p>
                    <p className="startedP">
                      By opening, registering, or using a OlehPay Account, or by otherwise using the
                      Services, you agree to be bound by this Customer Agreement and consent to
                      receive communications related to the Services or your OlehPay Account in
                      electronic format whether by email or by an e-signature document, and you
                      affirm that you are at least eighteen (18) years of age and legally capable of
                      entering into this Customer Agreement. You also agree to comply with our{' '}
                      <a href="/privacy">Privacy Policy</a>, which sets forth the terms on which we
                      handle any personal data we collect about you, or that you provide to us. By
                      using our Services, you consent to such processing and you confirm that all
                      data provided by you is accurate.
                    </p>
                    <p className="startedP">
                      Please read carefully all of the terms of these policies and each of the other
                      agreements that apply to you. Your use of certain of the Services may be
                      subject to additional terms and conditions, as communicated by us to you
                      through the Service or by other means, and such additional terms and
                      conditions are incorporated into this Customer Agreement.
                    </p>
                    <p className="startedP">
                      This Customer Agreement contains several sections, and you should read all of
                      them carefully. The headings are for reference only. Some capitalized terms
                      have specific definitions that are defined in the Glossary or within this
                      Customer Agreement. Underlined words in this Customer Agreement contain
                      hyperlinks to further information.
                    </p>
                    <p className="startedP">
                      If we change the Customer Agreement in a way that reduces your rights or
                      increases your responsibilities, we will provide you with 30 days’ prior
                      notice by posting notice by email. Your use of the Services following any
                      changes to this Customer Agreement will constitute your acceptance of such
                      changes. The “Last Updated” legend above indicates when this Customer
                      Agreement was last changed. We may, at any time and without liability, modify
                      or discontinue all or part of the Services (including access via any
                      third-party links); charge, modify or waive certain fees related to the
                      Services; or offer the Services, or certain of the Services, to some or all
                      users.
                    </p>
                    <h4 className="startedH4" id="about-olehpay">
                      About OlehPay
                    </h4>
                    <p className="startedP">
                      OlehPay is a corporation incorporated in Delaware. In the United States, we
                      are licensed as a money transmitter in the states listed through our partners
                      that process transactions requested through our system. We only offer our
                      Services in the states in which we or our providers are licensed or in the
                      states that do not currently require us to be licensed to provide our
                      Services. In other U.S. states, we only offer services in conjunction with our
                      partner financial institution(s).
                    </p>
                    <p className="startedP">
                      The Services enable you to, in supported currencies, Transfer currencies and
                      transmit funds to recipients. We retain full discretion to refuse to accept
                      any user or to complete any instruction to Transfer, receive, send, withdraw
                      or convert money from your OlehPay Account at any time.
                    </p>
                    <h4 className="startedH4">Glossary</h4>
                    <h4 className="startedH4">In this Customer Agreement:</h4>
                    <ul>
                      <p className="startedP">
                        App means the mobile application software, the data supplied with the
                        software and the associated media.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Business Day means a day other than, depending on location, Friday,
                        Saturday, Sunday or a public holiday in the United States or Israel when
                        financial institutions are open for business.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Services means all products, services, content, features, technologies or
                        functions offered by us and all related websites, applications (including
                        the App), and services (including the Website).
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Source Currency means the currency which you use to fund a currency
                        conversion.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Target Currency means the currency that you or your recipient will receive
                        after you convert currency.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Transfer means using your OlehPay Account to, as part of a single
                        transaction, upload, convert and send currency.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        OlehPay Account means the OlehPay Account on the website or mobile app you
                        have opened with us in accordance with the terms of this Customer Agreement
                        for use of our Services.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        OlehPay Materials means any software, data, materials, content and printed
                        and electronic documentation developed and provided by us or our affiliates
                        to you, or available for download from our Website.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Unauthorized Transaction means when money is sent from your OlehPay Account
                        that you did not authorize and that did not benefit you.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Website means any webpage, including but not limited to www.olehpay.co.il,
                        where we provide Services to you.
                      </p>
                    </ul>
                    <h4 className="startedH4" id="services">
                      Using Our Services
                    </h4>
                    <h4 className="startedH4">Opening an OlehPay Account</h4>
                    <p className="startedP">
                      In order to use some or all of the Services, you must first open an OlehPay
                      Account by providing certain information. For legal reasons, all information
                      you provide during the signup process or any time thereafter must be complete,
                      accurate and truthful. You are responsible for keeping your mailing address,
                      email address, telephone number, and other contact information up-to-date in
                      your OlehPay Account profile. To make changes to your profile, login and then
                      go to your account page. We may refuse to provide or may discontinue providing
                      the Services to any person or entity at any time for any reason.
                    </p>
                    <p className="startedP">
                      We treat all activities under an OlehPay Account to be those of the registered
                      user. You must only use the Services to transact on your own account and not
                      on behalf of any other person or entity. You may only open one OlehPay Account
                      unless we explicitly approve the opening of additional accounts. We may refuse
                      the creation of duplicate accounts for the same user. Where duplicate accounts
                      are detected, OlehPay may close or merge these duplicate accounts without
                      notification to you.
                    </p>
                    <h4 className="startedH4">Account Security</h4>
                    <p className="startedP">
                      You, not OlehPay, are responsible for maintaining adequate security and
                      control of any and all IDs, passwords, or any other details that you use to
                      access your OlehPay Account and the Services. You must never disclose your
                      OlehPay Account password. Keep them safe. Change your password regularly. We
                      will never ask you to provide your password to us or to a third party. Tell us
                      if anyone asks for your password, and contact Customer Support if you are not
                      sure about this, or any other security-related aspect of your OlehPay Account.
                      Review third-party recommendations on the creation and use of passwords, for
                      example:{' '}
                      <a href="https://support.google.com/accounts/answer/32040?hl=en">
                        Google&apos;s suggestion for creating a strong password.
                      </a>
                      . You must never let anyone access your OlehPay Account or watch you accessing
                      your OlehPay Account.
                    </p>
                    <p className="startedP">
                      If you suspect your OlehPay Account, login details, password or any other
                      security features are stolen, lost, used without authorization or otherwise
                      compromised, you are advised to change your password. Contact Customer Support
                      immediately if you believe your credentials have been compromised or you are
                      suspicious about the security of your password or any other security features.
                      The compromise of your credentials could enable thieves to access your bank
                      account and attempt transactions not authorized by you. Emailing us is the
                      best way to minimize your risk of loss. In addition, contact us at once if
                      your transaction history for your OlehPay Account shows transactions that you
                      did not initiate. We rely on you to regularly check the transactions history
                      of your OlehPay Account and to contact Customer Support immediately in case
                      you have any questions or concerns.
                    </p>
                    <p className="startedP">
                      We may (but are not obligated to) suspend your OlehPay Account or otherwise
                      restrict its functionality if we have concerns about the security of the
                      OlehPay Account or any of its security features; or potential unauthorized or
                      fraudulent use of your OlehPay Account or any of its security features.
                    </p>
                    <p className="startedP">
                      You must make sure that your e-mail account(s) are secure and only accessible
                      by you, as your e-mail address may be used to reset passwords or to
                      communicate with you about the security of your OlehPay Account. Let Customer
                      Support know immediately if your email address becomes compromised. Never use
                      any functionality that allows login details or passwords to be stored by the
                      computer or browser you are using or to be cached or otherwise recorded.
                      Additional OlehPay products or Services you use may have additional security
                      requirements, as notified to you by us, and you must familiarize yourself with
                      those requirements.
                    </p>
                    <h4 className="startedH4">Verification</h4>
                    <p className="startedP">
                      If you open a OlehPay Account and use certain Services, federal law requires
                      that we verify some of your information. You authorize OlehPay to make any
                      inquiries we consider necessary to validate your identity. These may be made
                      directly or through third parties, including checking commercial databases or
                      credit reports. We may need to ask you for further information, requiring you
                      to provide your date of birth, a taxpayer identification number and other
                      information that will allow us to reasonably identify you. This could include
                      requiring you to take steps to confirm ownership of your email address or
                      financial instruments, ordering a credit report from a credit reporting
                      agency, or verifying your information against third party databases or through
                      other sources. We may also ask to see your driver&apos;s license or other
                      identifying documents at any time. OlehPay reserves the right to close,
                      suspend, or limit access to your OlehPay Account and/or the Services in the
                      event we are unable to obtain or verify this information.
                    </p>
                    <h4 className="startedH4">Our Services</h4>
                    <h4 className="startedH4">Direct Debit (ACH Pull)</h4>
                    <p className="startedP">
                      We primarily draw funds from users&apos; accounts using an automated
                      clearinghouse (ACH) pull direct debit. You will need to provide your bank
                      login details or bank account details, including your bank account number and
                      routing number. When you choose to initiate a transfer using our ACH pull
                      direct debit feature and provide your details, you confirm that your details
                      are correct, that you are authorized to access and transmit funds from your
                      bank account, that your bank account is in good standing with the
                      account-holding financial institution, and that you have the authority to
                      initiate an electronic funds transfer in the amount at issue to or from your
                      bank account.
                    </p>
                    <p className="startedP">
                      When you choose to upload money using our ACH pull direct debit feature and by
                      providing your bank account details and requesting a transaction, you
                      authorize us to initiate electronic credits and debits to your bank account
                      through the ACH network, including any applicable fees and charges, and this
                      authorization shall remain in effect so long as you are a registered user with
                      the Service unless canceled in accordance with this Customer Agreement.
                    </p>
                    <h4 className="startedH4">Sending Money by Wire or ACH Push</h4>
                    <p className="startedP">
                      If you choose to send money using the ACH push or domestic wire transfer
                      method, then your transaction order will remain inactive until we receive your
                      funds to our specified account. Such payments must be made to our specified
                      account via ACH Push or domestic wire transfer. In cases where for any reason
                      the upload transaction is cancelled or refused, then we will promptly return
                      the deposit to your bank account from which the transaction originated. In the
                      event that we are unable to return the deposit, then we will promptly contact
                      you using the most current contact information provided to us by you through
                      your OlehPay registration.
                    </p>
                    <h4 className="startedH4">Other Information About Uploading Money</h4>
                    <p className="startedP">
                      For legal, security, or other reasons, there may be financial limits for
                      particular payment methods or currencies.
                    </p>
                    <h4 className="startedH4">Transferring Money</h4>
                    <p className="startedP">
                      The number of payout methods available to you will depend on a number of
                      factors including where you live and your verification status with us. We
                      cannot guarantee the use of any particular payout method and may change or
                      stop offering a payout method at any time without notice to you, but we will
                      ensure that you will have at least one payout method available to you unless
                      prohibited by applicable law.
                    </p>
                    <p className="startedP">
                      When you Transfer money, you will be charged a fee, which is referenced on the
                      OlehPay homepage. We will let you know the exact amount of the fee when you
                      submit your request.
                    </p>
                    <p className="startedP">
                      When setting Transfer orders or withdrawal requests, you must ensure that the
                      information you provide is correct and complete. We will not be responsible
                      for money sent to the wrong recipient as a result of incorrect information
                      provided by you. If you have provided wrong information to us, you may ask us
                      to assist you in recovering the money, but we cannot guarantee that such
                      efforts will be successful.
                    </p>
                    <p className="startedP">
                      Once we have received your Transfer order, we will send you a confirmation by
                      email that we have received it. Each Transfer order is given a unique transfer
                      number which is shown in the transaction history in your OlehPay Account on
                      the Website. You should quote this transfer number when communicating with us
                      about a particular Transfer.
                    </p>
                    <h4 className="startedH4">Limits on withdrawal and Transfer</h4>
                    <p className="startedP">
                      We may also place limits on the amount of currency you may Transfer at any
                      given time, for more information on the applicable limits, please visit our
                      Frequently Asked Questions page. We may limit the amount of your Transfer
                      order consistent with our obligations under applicable law and at our
                      discretion. If your Transfer order is received by us after 2:00 PM EST on a
                      Business Day or not on a Business Day, your Transfer order will be deemed
                      received on the following Business Day.
                    </p>
                    <h4 className="startedH4">Delay in withdrawal or Transfer</h4>
                    <p className="startedP">
                      We do not have any control over the time it may take for your or a recipient’s
                      bank or payment provider to credit and make available funds to you or your
                      recipient once we make the funds available to you or the recipient’s bank or
                      payment provider.
                    </p>
                    <p className="startedP">
                      We may delay a withdrawal or Transfer, in certain situations, including if we
                      need to confirm that the withdrawal has been authorized by you or if other
                      payments to your OlehPay Account have been reversed (for example, as a result
                      of a chargeback or bank reversal (See Reversals and Chargebacks for more
                      details)). You may also find further information regarding the completion time
                      on the <a href="/transfer-times">Transfer Times</a> page.
                    </p>
                    <h4 className="startedH4">Currency Conversion</h4>
                    <p className="startedP">
                      Our Services include the ability to convert currencies.
                    </p>
                    <p className="startedP">
                      A conversion fee may apply when we perform a currency conversion. You can also
                      find out more information about the fees we charge on the{' '}
                      <a href="/info">Info page</a>.
                    </p>
                    <p className="startedP">
                      We will only process your currency conversion order if we hold or have
                      received the relevant funds. We cannot be responsible for the time it takes
                      for the money to be sent to us by your bank or payment service provider.
                    </p>
                    <p className="startedP">
                      We reserve the right in our sole discretion to refuse any currency conversion
                      order. Reasons for refusal may include but are not limited to an inability to
                      match your OlehPay Account information with your bank account details,
                      incorrect information about the recipient, or insufficient available funds. We
                      generally will attempt to notify you of any refusal, using the contact
                      information provided as part of your registration, stating (where possible)
                      the reasons for such refusal and explaining how to correct any errors.
                      However, we are not required to notify you if such notification would be
                      unlawful.
                    </p>
                    <p className="startedP">
                      Once we have received your currency conversion order, we will send you a
                      confirmation by email that we have received it. Each currency conversion order
                      is given a unique transaction number which is shown in the transaction history
                      on your OlehPay Account. You should quote this transaction number when
                      communicating with us about a particular currency conversion order.
                    </p>
                    <h4 className="startedH4">Exchange rates used to convert currency</h4>
                    <p className="startedP">
                      When you place a currency conversion order, we use the next available rate
                      published by the bank of Israel, or similarly performing rates provided by our
                      processing financial institutions. Exchange rates for a given transaction
                      shall be posted as the transaction rate becomes determined. We use the
                      upcoming rate as a mechanism to remain objective and transparent in our
                      pricing of currency.
                    </p>
                    <h4 className="startedH4">Cancellation of a Transfer order</h4>
                    <h4 className="startedH4">Personal, family or household</h4>
                    <p className="startedP">
                      You have the right to cancel a Transfer order for personal, family or
                      household purposes and obtain a full refund of all funds paid to us, including
                      any fees in connection with that order. In order to cancel, you must contact
                      us by email within two hours of your Transfer order.
                    </p>
                    <p className="startedP">
                      When you contact us, you must provide us with information to help us identify
                      the Transfer order that you wish to cancel, including (i) your name, address
                      and telephone number; (ii) the name of the person receiving the funds; (iii)
                      the dollar amount of the order; and (iv) the intended destination location.
                    </p>
                    <p className="startedP">
                      We will issue a refund within three (3) Business Days of your request,
                      provided that the funds have not been picked up by the designated recipient
                      prior to our receipt of your request for a refund. For the avoidance of doubt,
                      you may not cancel an order under any circumstances if the funds have been
                      disbursed or deposited to the recipient.
                    </p>
                    <h4 className="startedH4">Business or commercial Transfers</h4>
                    <p className="startedP">
                      You have the right to cancel a Transfer order for business or commercial
                      purposes and obtain a full refund of all funds paid to us, including any fees
                      in connection with that order. In order to cancel, you must contact us by
                      email, up until your order has been completed in accordance with your
                      instructions.
                    </p>
                    <p className="startedP">
                      When you contact us, you must provide us with information to help us identify
                      the currency conversion order that you wish to cancel, including (i) your
                      name, address and telephone number; (ii) the name of the person receiving the
                      funds; (iii) the dollar amount of the order; and (iv) the intended destination
                      location.
                    </p>
                    <p className="startedP">
                      We will issue a refund within three (3) Business Days of your request,
                      provided that the funds have not been picked up by the designated recipient
                      prior to our receipt of your request for a refund. For the avoidance of doubt,
                      you may not cancel an order under any circumstances if the funds have been
                      disbursed or deposited to the recipient.
                    </p>
                    <h4 className="startedH4" id="fees">
                      Fees
                    </h4>
                    <p className="startedP">
                      The fees for transferring money, withdrawing money and/or currency conversion
                      will be disclosed to you when you place an order and prior to your confirming
                      the transaction. For clarity, the fees applicable to you are part of this
                      Customer Agreement and are subject to change.
                    </p>
                    <p className="startedP">
                      You agree to pay the relevant fees using your chosen payment method. The fee
                      will be charged at the time when you withdraw money or convert currency. Our
                      fee does not include any fees that your bank or the recipient’s bank may
                      charge. Those fees may be deducted from the final credit amount. We will not
                      process your payment order until we have received the applicable fee from you.
                    </p>
                    <p className="startedP">
                      You are responsible for any taxes which may be applicable to payments you make
                      or receive, and it is your responsibility to collect, report and pay the
                      correct tax to the appropriate tax authority.
                    </p>
                    <p className="startedP">
                      You may access information about all of your Transfers, including related fees
                      and exchange rates, after you have logged into your OlehPay Account. We have
                      allocated a transaction id number to each transaction, you should quote this
                      transaction id number when communicating with us about a particular
                      transaction.
                    </p>
                    <h4 className="startedH4">Closing Your OlehPay Account</h4>
                    <h4 className="startedH4">You can close your OlehPay Account</h4>
                    <p className="startedP">
                      You may end this Customer Agreement and close your OlehPay Account at any time
                      by contacting our Customer Support by email at support@olehpay.co.il. You may
                      terminate your OlehPay Account with us at any time by contacting Customer
                      Support.
                    </p>
                    <p className="startedP">
                      If an account closure was requested and you still have a transfer in process,
                      you must not close your OlehPay Account to avoid an investigation. If you
                      attempt to close your OlehPay Account during an investigation, we may hold
                      your money until the investigation is fully completed. You agree that you will
                      continue to be responsible for all obligations related to your OlehPay Account
                      even after it is closed.
                    </p>
                    <h4 className="startedH4">OlehPay can close your OlehPay Account</h4>
                    <p className="startedP">
                      OlehPay, in its sole discretion, reserves the right to suspend or terminate
                      this Customer Agreement, access to or use of its Service websites, software,
                      systems (including any networks and servers used to provide any of the
                      Services) operated by us or on our behalf or some or all of the Services for
                      any reason and at any time upon notice to you.
                    </p>
                    <h4 className="startedH4">
                      Reasons we may close your OlehPay Account include, but are not limited to:
                    </h4>
                    <ul>
                      <p className="startedP">
                        your breach of any provision of this Customer Agreement or documents
                        referred to in this Customer Agreement;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        we are requested or directed to do so by any competent court of law,
                        government authority or agency, or law enforcement agency;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        we have reason to believe you are in violation or breach of any applicable
                        law or regulation; or
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        we have reason to believe you are involved in any fraudulent activity, money
                        laundering, terrorism financing or other criminal or illegal activity.
                      </p>
                    </ul>
                    <p className="startedP">
                      We may also suspend your OlehPay Account if it has been compromised or for
                      other security reasons; or has been used or is being used without your
                      authorization or fraudulently. See Account Security above for details.
                    </p>
                    <p className="startedP">
                      If we close your OlehPay Account or terminate your use of the Services for any
                      reason, we’ll provide you with notice of our actions. You are responsible for
                      all reversals, chargebacks, fees, fines, penalties and other liability
                      incurred by OlehPay, any other OlehPay customer, or a third party, caused by
                      or arising out of your breach of this Customer Agreement, and/or your use or
                      the use of your authorized third parties of the Services. You agree to
                      reimburse OlehPay, any OlehPay customer, or a third party for any and all such
                      liability. See Reversals and Chargebacks, and Negative Account Balances.
                    </p>
                    <p className="startedP">
                      On termination for any reason, all rights granted to you in connection with
                      the App shall cease, you must immediately delete or remove the App from your
                      devices.
                    </p>
                    <h4 className="startedH4">Communications Between You and Us</h4>
                    <p className="startedP">
                      OlehPay may communicate with you about your OlehPay Account and the Services
                      electronically via email. You will be considered to have received a
                      communication from us, if it’s delivered electronically, 24 hours after the
                      time we post it to our website or email it to you. You will be considered to
                      have received a communication from us, if it’s delivered by mail, three (3)
                      Business Day after we send it or within five days of posting for international
                      post.
                    </p>
                    <p className="startedP">
                      We usually contact you via email. For this reason, you must at all times
                      maintain at least one valid email address in your OlehPay Account profile. You
                      are required to check for incoming messages regularly and frequently, these
                      emails may contain links to further communication on our Website. If you don’t
                      maintain or check your email and other methods of communications, you will
                      miss emails about your transactions and our Services. We cannot be liable for
                      any consequence or loss if you don’t do this.
                    </p>
                    <p className="startedP">
                      In addition to communicating via email, we may contact you via letter or
                      telephone where appropriate. If you use any mobile services, we may, and you
                      agree that we may, also communicate with you via SMS. Any communications or
                      notices sent by Text messages (SMS) will be deemed received the same day.
                    </p>
                    <p className="startedP">
                      If you need a copy of the current Customer Agreement or any other relevant
                      document, please contact Customer Support. You may contact us via email,
                      letter or telephone by using the details specified in the “Contact” section of
                      our Website.
                    </p>
                    <h4 className="startedH4">Issues that May Occur</h4>
                    <h4 className="startedH4" id="transaction-issues">
                      Reversals and Chargebacks
                    </h4>
                    <p className="startedP">
                      If you receive a payment that is later refunded or invalidated for any reason,
                      you are responsible for the full amount of the payment sent to you plus any
                      fees (including any applicable chargeback fee described below). Whenever a
                      transaction is reversed, OlehPay will refund or reverse the transaction from
                      your OlehPay Account in the same currency as the original transaction. If your
                      OlehPay Account balance for a particular currency is insufficient to cover the
                      amount of a refund or reversal, OlehPay will perform a currency conversion in
                      order to refund or reverse the transaction, subject to the exchange rate being
                      offered by OlehPay in the applicable currencies at that time.
                    </p>
                    <h4 className="startedH4">Payments that are reversed</h4>
                    <p className="startedP">
                      Payments to you may be invalidated and reversed by OlehPay if:
                    </p>
                    <ul>
                      <p className="startedP"></p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Our investigation of a bank reversal (sometimes known as an ACH return)
                        finds that the transaction was fraudulent.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">OlehPay sent the payment to you in error.</p>
                    </ul>
                    <ul>
                      <p className="startedP">The payment was unauthorized.</p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        You received the payment for activities that violated this Customer
                        Agreement, the Acceptable Use Policy, or any other agreement between you and
                        OlehPay.
                      </p>
                    </ul>
                    <p className="startedP">
                      When you receive a payment, you are liable to OlehPay for the full amount of
                      the payment sent to you plus any fees if the payment is later invalidated for
                      any reason.
                    </p>
                    <h4 className="startedH4">Chargeback Fees</h4>
                    <p className="startedP">
                      If you receive a an ACH debit following a request for a transfer and you (or a
                      third party) pursue a chargeback for the transaction with your financial
                      institution, then OlehPay may assess you with a chargeback fee (for
                      facilitating the chargeback process) and will remove the charged back funds
                      from your account.
                    </p>
                    <h4 className="startedH4">Negative Account Balances</h4>
                    <p className="startedP">
                      If your OlehPay Account balance becomes negative for any reason, including on
                      account of a Reversal or Chargeback, that negative balance represents an
                      amount you owe to OlehPay and you promise to repay the negative balance
                      immediately without any notice from us. OlehPay may deduct amounts owed from
                      money you upload or money you receive into your OlehPay Account. We may send
                      you reminders or take such other reasonable actions to recover the negative
                      balance from you, for example, we may use a debt collection service or take
                      further legal actions. In order to recover negative account balances from you,
                      we may convert the amount you owe us into U.S dollars.
                    </p>
                    <h4 className="startedH4">Error Resolution</h4>
                    <p className="startedP">
                      You must make sure that the information you provide to us when you send or
                      withdraw money, or convert currency, is accurate. Once a transaction is
                      processed, it cannot be reversed (except where, and to the extent, required by
                      applicable law) and, except as expressly set forth in this Customer Agreement,
                      we will not be liable in any way for any loss you suffer as a result of a
                      transaction being carried out in accordance with your instructions (or a third
                      party acting on your behalf).
                    </p>
                    <p className="startedP">
                      If you believe there to be an error in connection with a transaction or other
                      problem, then you should notify OlehPay Support via email at
                      support@olehpay.co.il as soon as possible, but no later than 180 days of the
                      date we disclosed to you that a transaction has been completed.
                    </p>
                    <p className="startedP">
                      When you do, please tell us as much of the following information as possible:
                      (1) your name and address; (2) the error or problem with the transaction, and
                      why you believe it is an error or problem; (3) the name of the recipient, and
                      if you know it, telephone number or address; (4) the dollar amount of the
                      transfer; and (5) the transfer number.
                    </p>
                    <h4 className="startedH4">
                      What is an Error and how Errors will be addressed by us
                    </h4>
                    <p className="startedP">Errors in the Case of Transfers</p>
                    <p className="startedP">
                      With regard to Transfers sent for personal, family or household purposes, and
                      the payment amount equals or exceeds $15 USD, the following are considered
                      Errors:
                    </p>
                    <ul>
                      <p className="startedP">
                        You paid an incorrect amount for your currency conversion order;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        OlehPay made a computational error, such as a miscalculation of the amount
                        the recipient received;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        The amount stated in the currency conversion order receipt was not made
                        available to the recipient, unless the problem was caused by extraordinary
                        circumstances outside our control;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Funds were available to the recipient later than the date of availability
                        that was disclosed to you on the currency conversion order receipt or were
                        not delivered, unless, extraordinary circumstances outside our control
                        caused the delay, and we could not reasonably anticipate those
                        circumstances, or delays resulted from fraud screenings, or were caused by
                        requirements of the Office of Foreign Assets Controls or similar
                        requirements, or the transfer was made with fraudulent intent; and
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        The list of Transfers on our Website does not reflect a Transfer that you
                        made; and
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        You did not receive any confirmation of your Transfer, such as information
                        required to determine if a Transfer occurred.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        If you believe an Error of the type described above occurred, you must duly
                        contact us in accordance with the information above, and we will investigate
                        and determine whether an error occurred within 90 days (although we
                        typically do this within ten (10) business days) after you contact us.
                      </p>
                    </ul>
                    <p className="startedP">
                      We will inform you of our determination within three (3) Business Days after
                      completing our investigation. If we decide that there was no Error, we will
                      send you a written explanation. You may ask for copies of any documents we
                      used in our investigation. If we determine that an error has occurred, we will
                      offer you the option of obtaining a refund or making available to the
                      designated recipient the funds necessary to resolve the error. Please note
                      that we are only required to refund fees where we failed to make funds
                      available to the recipient by the date of availability we specified, unless
                      you provided incorrect or insufficient information to us, in which case we are
                      not required to refund fees.
                    </p>
                    <p className="startedP">
                      We will rectify any Errors that we discover. If the Error results in:
                    </p>
                    <ul>
                      <p className="startedP">
                        You receiving less than the correct amount to which you were entitled, then
                        we will credit your OlehPay Account for the difference between what you
                        should have received and what you actually received.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        You receiving more than the correct amount to which you were entitled, then
                        we will debit your OlehPay Account for the difference between what you
                        actually received and what you should have received.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Our not completing a transaction on time or in the correct amount, then we
                        will be responsible to you for your losses or damages directly caused by
                        this failure, unless:
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        through no fault of ours, you did not have enough available funds to
                        complete the transaction;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        our system was not working properly and you knew about the breakdown when
                        you started the transaction; or
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        the error was due to extraordinary circumstances outside our control (such
                        as fire, flood or loss of Internet connection), despite our reasonable
                        precautions.
                      </p>
                    </ul>
                    <h4 className="startedH4">What is not considered an Error</h4>
                    <p className="startedP">The following are not considered Errors:</p>
                    <ul>
                      <p className="startedP">
                        If you give someone access to your OlehPay Account (by giving them your
                        login information) and they use your OlehPay Account without your knowledge
                        or permission. You are responsible for transactions made in this situation;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Invalidation and reversal of a payment or transaction as a result of the
                        actions described under Reversals and Chargebacks;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Routine inquiries about your OlehPay Account balance;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Requests for duplicate documentation or other information for recordkeeping
                        purposes;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        An inquiry about the status of a currency conversion order (except where the
                        funds from the order were not made available to the recipient by the
                        disclosed date of availability);
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        A change requested by the recipient of funds sent from you;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        A change in the amount or type of currency received by a designated
                        recipient from the amount or type of currency stated in the disclosure
                        provided you, if we relied on information provided by you in making the
                        disclosure;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Delays that result from OlehPay applying holds or limitations. Our decision
                        about holds or limitations may be based on confidential risk management
                        procedures and the protection of OlehPay, our customers and/or service
                        providers. In addition, we may be restricted by regulation or a governmental
                        authority from disclosing certain information to you about such decisions.
                        We have no obligation to disclose the details of our risk management or
                        security procedures to you;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Delays based on a review of a potentially high-risk transaction;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Your errors in making a transaction (for example, mistyping an amount of
                        money that you are sending or choosing an incorrect Target Currency).
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Delays due to actions of third parties (e.g, your or a recipient&apos;s
                        bank)
                      </p>
                    </ul>
                    <h4 className="startedH4">Protection from Unauthorized Transactions</h4>
                    <p className="startedP">
                      To protect yourself from unauthorized activity in your OlehPay Account, you
                      should regularly log into your OlehPay Account and review the list of your
                      Transfers on the Website. OlehPay will notify you of each transaction by
                      sending an email to your primary email address on file. You should review
                      these transaction notifications to ensure that each transaction was authorized
                      and accurately completed.
                    </p>
                    <p className="startedP">
                      OlehPay will protect you from unauthorized activity in your OlehPay Account.
                      When this protection applies, OlehPay will cover you for the full amount of
                      the unauthorized activity as long as you cooperate with us and follow the
                      procedures described below.
                    </p>
                    <p className="startedP"></p>
                    <h4 className="startedH4">What is an Unauthorized Transaction?</h4>
                    <p className="startedP">
                      An Unauthorized Transaction occurs when money is sent from your OlehPay
                      Account that you did not authorize and that did not benefit you.
                    </p>
                    <h4 className="startedH4">
                      What is not considered an Unauthorized Transaction
                    </h4>
                    <p className="startedP">
                      The following are not considered Unauthorized Transactions:
                    </p>
                    <ul>
                      <p className="startedP">
                        If you give someone access to your OlehPay Account (by giving them your
                        login information) and they use your OlehPay Account without your knowledge
                        or permission. You are responsible for transactions made in this situation.
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        Invalidation and reversal of a payment as a result of the actions described
                        under Reversals and Chargebacks.
                      </p>
                    </ul>
                    <h4 className="startedH4">Reporting an Unauthorized Transaction</h4>
                    <p className="startedP">
                      If you believe your OlehPay login information has been lost or stolen, email
                      us at support@olehpay.co.il.
                    </p>
                    <p className="startedP">
                      Tell us right away if you believe your OlehPay login information has been lost
                      or stolen, or if you believe that transactions have been made in your OlehPay
                      Account without your permission using your login information. You could lose
                      all the money in your OlehPay Account. If you tell us within 60 days after we
                      provide you your OlehPay Account statement showing transfers you did not make,
                      you will be eligible for 100% protection for Unauthorized Transactions.
                    </p>
                    <p className="startedP">
                      Also, if your OlehPay Account statement shows transfers that you did not make,
                      including those made with your OlehPay login information or by other means,
                      tell us at once. If you do not tell us within 60 days after we provided the
                      statement to you, you may not get back any money you lost after the 60 days if
                      we can prove that we could have stopped someone from taking the money if you
                      had told us in time. If a good reason (such as a long trip or a hospital stay)
                      kept you from telling us, we will extend the time periods.
                    </p>
                    <h4 className="startedH4">Complaints</h4>
                    <p className="startedP">
                      If you have a question or complaint regarding the Services, please send an
                      email us at support@olehpay.co.il. You may also contact us by writing to
                      OlehPay, LLC. 1875 Century Park East, Suite 600, Los Angeles, CA 90067. ATTN:
                      OlehPay Compliance. Please note that email communications will not necessarily
                      be secure; accordingly you should not include sensitive information in your
                      email correspondence with us.
                    </p>
                    <h4 className="startedH4">Technology</h4>
                    <h4 className="startedH4">Linking to Our Website</h4>
                    <p className="startedP">
                      You may link to our Website, provided that you comply with the terms and
                      conditions of this Customer Agreement, and follow certain rules. You may link
                      to our Website, provided:
                    </p>
                    <ul>
                      <p className="startedP"></p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        you do so in a way that is fair and legal and does not damage our reputation
                        or take advantage of it;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        you do not suggest any form of association, approval or endorsement on our
                        part where none exists;
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">you do not frame our Website on any other site;</p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        you do not refer to any of our Services as “banking” services; and
                      </p>
                    </ul>
                    <ul>
                      <p className="startedP">
                        the website linking to our Website complies with our Acceptable Use Policy.
                      </p>
                    </ul>
                    <p className="startedP">
                      We reserve the right to revoke such linking permission without notice and for
                      any reason.
                    </p>
                    <h4 className="startedH4">Information Security</h4>
                    <p className="startedP">
                      Please see Account Security above for further details on how to keep your
                      OlehPay Account safe. See also Protection from Unauthorized Transactions.
                    </p>
                    <p className="startedP">
                      You are responsible for configuring your information technology, computer
                      programs and platform in order to access our Services. You should use your own
                      virus protection software. You must not misuse our Services by introducing
                      viruses, trojans, worms, logic bombs or other materials which are malicious or
                      technologically harmful. You must not attempt to gain unauthorized access to
                      the Services, or our Website, our servers, computers or databases. You must
                      not attack the Services, including via our Website with any type of
                      denial-of-service attack. By breaching this provision, you would commit a
                      criminal offence under applicable law, including the Computer Fraud and Abuse
                      Act (18 U.S.C. § 1030). We may report any such breach to the relevant law
                      enforcement authorities and we may co-operate with those authorities by
                      disclosing your identity or other information to them. In the event of such a
                      breach, your right to access and use our Website and/or our Services will
                      cease immediately without notice, and you must immediately cease all such
                      access and use.
                    </p>
                    <h4 className="startedH4">Third Party Materials</h4>
                    <p className="startedP">
                      Certain Website or App functionality may make available access to information,
                      products, services and other materials made available by third parties (“Third
                      Party Materials”), or allow for the routing or transmission of such Third
                      Party Materials, including via links. By using such functionality, you are
                      directing us to access, route and transmit to you the applicable Third Party
                      Materials.
                    </p>
                    <p className="startedP">
                      We neither control nor endorse, nor are we responsible for, any Third Party
                      Materials, including the accuracy, validity, timeliness, completeness,
                      reliability, integrity, quality, legality, usefulness or safety of Third Party
                      Materials, or any intellectual property rights therein. Certain Third Party
                      Materials may, among other things, be inaccurate, misleading or deceptive.
                      Nothing in this Agreement shall be deemed to be a representation or warranty
                      by us with respect to any Third Party Materials. We have no obligation to
                      monitor Third Party Materials, and we may block or disable access to any Third
                      Party Materials (in whole or part) through the Website or App at any time. In
                      addition, the availability of any Third Party Materials through the Website or
                      App does not imply our endorsement of, or our affiliation with, any provider
                      of such Third Party Materials, nor does such availability create any legal
                      relationship between you and any such provider.
                    </p>
                    <p className="startedP">
                      Your use of Third Party Materials is at your own risk and is subject to any
                      additional terms, conditions and policies applicable to such Third Party
                      Materials (such as terms of service or privacy policies of the providers of
                      such Third Party Materials).
                    </p>
                    <h4 className="startedH4">OlehPay’s Rights</h4>
                    <p className="startedP">
                      Limitation on OlehPay’s Liability, Indemnity and Release
                    </p>
                    <p className="startedP">
                      In this section, we use the term “OlehPay” to include OlehPay, LLC., and our
                      affiliates, and each of their respective directors, officers, employees,
                      agents, joint venturers, service providers and suppliers. Our affiliates
                      include each entity that we control, we are controlled by or we are under
                      common control with.
                    </p>
                    <p className="startedP">
                      In no event shall OlehPay be liable for lost profits or for any indirect,
                      incidental, consequential, special, exemplary or punitive damages of any kind,
                      under any contract, tort (including negligence), strict liability or other
                      theory, including damages for loss of profits, use or data, loss of other
                      intangibles, loss of business, loss of security of any information or other
                      materials (including unauthorized interception by third parties of any
                      information or other materials), even if advised in advance of the possibility
                      of such damages or losses, however arising, including negligence, unless and
                      to the extent prohibited by law. Our liability to you or any third parties in
                      any circumstance is limited to the actual amount of direct damages.
                    </p>
                    <p className="startedP">
                      In addition, to the extent permitted by applicable law, OlehPay is not liable,
                      and you agree not to hold OlehPay responsible, for any damages or losses
                      (including, but not limited to, loss of money, goodwill, or reputation,
                      profits, or other intangible losses or any special, indirect, or consequential
                      damages) resulting directly or indirectly from: (1) your use of, or your
                      inability to use, our websites, software, systems (including any networks and
                      servers used to provide any of the Services) operated by us or on our behalf,
                      or any of the Services; (2) delays or disruptions in our Website software,
                      systems (including any networks and servers used to provide any of the
                      Services) operated by us or on our behalf and any of the Services; (3) viruses
                      or other malicious software obtained by accessing our websites, software,
                      systems (including any networks and servers used to provide any of the
                      Services) operated by us or on our behalf or any of the Services or any
                      website or service linked to our websites, software or any of the Services;
                      (4) glitches, bugs, errors, or inaccuracies of any kind in our websites,
                      software, systems (including any networks and servers used to provide any of
                      the Services) operated by us or on our behalf or any of the Services or in the
                      information and graphics obtained from them; (5) the content, actions, or
                      inactions of third parties; (6) a suspension or other action taken with
                      respect to your OlehPay Account; or (7) your need to modify your practices,
                      content, or behavior, or your loss of or inability to do business, as a result
                      of changes to this Customer Agreement or any other OlehPay policy.
                    </p>
                    <h4 className="startedH4">Indemnity</h4>
                    <p className="startedP">
                      Except to the extent prohibited under applicable law, you agree to defend,
                      indemnify and hold harmless OlehPay and its affiliates, and their respective
                      successors and assigns, from and against all claims, liabilities, damages,
                      judgments, awards, losses, costs, expenses and fees (including attorneys’
                      fees) arising out of or relating to (a) your or your authorized third parties
                      use of, or activities in connection with, the Services; and (b) any violation
                      or alleged violation by you of this Customer Agreement or applicable law.
                    </p>
                    <h4 className="startedH4">Release</h4>
                    <p className="startedP">
                      If you have a dispute with any other OlehPay Account holder or a third party
                      that you send money to or receive money from using the Services, you release
                      OlehPay from any and all claims, demands and damages (actual and
                      consequential) of every kind and nature, known and unknown, arising out of or
                      in any way connected with such disputes. In entering into this release you
                      expressly waive any protections (whether statutory or otherwise, for example,
                      California Civil Code § 1542) that would otherwise limit the coverage of this
                      release to include only those claims which you may know or suspect to exist in
                      your favor at the time of agreeing to this release.
                    </p>
                    <h4 className="startedH4">Service Availability</h4>
                    <p className="startedP">
                      We will try to make sure our Services are available to you when you need them.
                      However, we do not guarantee that our Services will always be available or be
                      uninterrupted. We have the right to suspend, withdraw, discontinue or change
                      all or any part of our Service without notice. We will not be liable to you if
                      for any reason our Services are unavailable (in whole or in part) at any time
                      or for any period. You are responsible for making all arrangements necessary
                      for you to have access to the Services (including all hardware and
                      telecommunications services).
                    </p>
                    <h4 className="startedH4">Disclaimer of Warranty</h4>
                    <p className="startedP">
                      The Services are provided “As-Is” “Where Is” and “Where Available” and without
                      any representation or warranty, whether express, implied or statutory. OlehPay
                      specifically disclaims any implied warranties of title, merchantability,
                      fitness for a particular purpose and non-infringement. We disclaim all
                      warranties with respect to the Services to the fullest extent permissible
                      under applicable law, including the warranties of merchantability, fitness for
                      a particular purpose, non-infringement and title.
                    </p>
                    <h4 className="startedH4">Insolvency Proceedings</h4>
                    <p className="startedP">
                      If any type of bankruptcy or insolvency proceeding (e.g., a proceeding
                      commenced under any provision of the United States Bankruptcy Code) is
                      commenced by or against you, we’ll be entitled to recover all reasonable costs
                      or expenses (including reasonable attorneys&apos; fees and expenses) incurred
                      in connection with the enforcement of this Customer Agreement or objections
                      that we supply information in connection with such proceeding.
                    </p>
                    <h4 className="startedH4">Intellectual Property</h4>
                    <p className="startedP">
                      “OlehPay.co.il” and “OlehPay” are all logos related to the Services that are
                      either trademarks or or OlehPay’s licensors. You may not copy, imitate, modify
                      or use them without OlehPay’s prior written consent. In addition, all page
                      headers, custom graphics, button icons, and scripts are service marks,
                      trademarks, and/or trade dress of OlehPay. You may not copy, imitate, modify
                      or use them without our prior written consent. You may use HTML logos provided
                      by OlehPay for the purpose of directing web traffic to the Services. You may
                      not alter, modify or change these HTML logos in any way, use them in a manner
                      that mischaracterizes OlehPay or the Services or display them in any manner
                      that implies OlehPay’s sponsorship or endorsement. All right, title and
                      interest in and to the OlehPay websites, any content thereon, the Services,
                      the technology related to the Services, and any and all technology and any
                      content created or derived from any of the foregoing is the exclusive property
                      of OlehPay and its licensors.
                    </p>
                    <h4 className="startedH4">
                      Miscellaneous Translation of This Customer Agreement
                    </h4>
                    <p className="startedP">
                      Any translation of this Customer Agreement is provided solely for your
                      convenience and is not intended to modify the terms of this Customer
                      Agreement. Only the English language of the Customer Agreement version is an
                      official version. In the event of a conflict between the English version of
                      this Customer Agreement and a version in a language other than English, the
                      English version shall control.
                    </p>
                    <h4 className="startedH4">Privacy</h4>
                    <p className="startedP">
                      Protecting your privacy is very important to us. Please review our{' '}
                      <a href="/privacy">Privacy Policy</a> in order to better understand our
                      commitment to maintaining your privacy, as well as our use and disclosure of
                      your information.
                    </p>
                    <h4 className="startedH4">Governing law and Agreement to Arbitrate</h4>
                    <p className="startedP">
                      You agree that, except to the extent inconsistent with or preempted by federal
                      law and except as otherwise stated in this Customer Agreement, the laws of the
                      State of California, without regard to principles of conflict of laws, will
                      govern this Customer Agreement and any claim or dispute that has arisen or may
                      arise between you and OlehPay, and regardless of your location. Except for
                      disputes that qualify for small claims court, all disputes arising out of or
                      related to this Agreement or any aspect of the relationship between you and
                      OlehPay, whether based in contract, tort, statute, fraud, misrepresentation or
                      any other legal theory, will be resolved through final and binding arbitration
                      before a neutral arbitrator instead of in a court by a judge or jury and you
                      agree that OlehPay and you are each waiving the right to trial by a jury. You
                      agree that any arbitration under this Agreement will take place on an
                      individual basis; class arbitrations and class actions are not permitted and
                      you are agreeing to give up the ability to participate in a class action.
                    </p>
                    <h4 className="startedH4">OlehPay’s role as a service provider</h4>
                    <p className="startedP">
                      Our relationship with you under this Customer Agreement with you is as a
                      payment service provider, and OlehPay is an independent contractor for all
                      purposes. OlehPay is not your agent or trustee.
                    </p>
                    <h4 className="startedH4">Copyright Infringement Claims</h4>
                    <p className="startedP">
                      The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse
                      for copyright owners who believe that material appearing on the Internet
                      infringes their rights under U.S. copyright law. If you believe in good faith
                      that materials available on the Site infringe your copyright, you (or your
                      agent) may send to OlehPay a written notice by mail, e-mail or fax, requesting
                      that OlehPay remove such material or block access to it. If you believe in
                      good faith that someone has wrongly filed a notice of copyright infringement
                      against you, the DMCA permits you to send to OlehPay a counter-notice. Notices
                      and counter-notices must meet the then-current statutory requirements imposed
                      by the DMCA. See http://www.copyright.gov/ for details. Notices and
                      counter-notices must be sent in writing to OlehPay as follows: By mail to
                      OlehPay Legal Counsel, 1875 Century Park East, Suite 600, Los Angeles CA
                      90067; by email to support@olehpay.co.il.
                    </p>
                    <h4 className="startedH4">Unlawful internet gambling notice</h4>
                    <p className="startedP">
                      Restricted transactions as defined in Federal Reserve Regulation are
                      prohibited from being processed through your OlehPay Account or your
                      relationship with OlehPay. Restricted transactions generally include, but are
                      not limited to, transactions in which credit, electronic fund transfers,
                      checks, or drafts are knowingly accepted by gambling businesses in connection
                      with unlawful Internet gambling.
                    </p>
                    <h4 className="startedH4">Export Controls</h4>
                    <p className="startedP">
                      You are responsible for complying with United States export controls and for
                      any violation of such controls, including any United States embargoes or other
                      federal rules and regulations restricting exports. You represent, warrant and
                      covenant that you are not (a) located in, or a resident or a national of, any
                      country subject to a U.S. government embargo or other restriction, or that has
                      been designated by the U.S. government as a “terrorist supporting” country; or
                      (b) on any of the U.S. government lists of restricted end users.
                    </p>
                    <h4 className="startedH4">Other Information About this Customer Agreement</h4>
                    <p className="startedP">
                      You may not transfer or assign any rights or obligations you have under this
                      Customer Agreement without OlehPay’s prior written consent. OlehPay may
                      transfer or assign this Customer Agreement or any right or obligation under
                      this Customer Agreement at any time.
                    </p>
                    <p className="startedP">
                      Each of the paragraphs of this Customer Agreement operates separately. If any
                      court or relevant authority decides that any of them are unlawful, the
                      remaining paragraphs will remain in full force and effect. If we delay in
                      asking you to do certain things or in taking action, it will not prevent us
                      taking steps against you at a later date.
                    </p>
                    <p className="startedP">
                      Our failure to act with respect to a breach of any of your obligations under
                      this Customer Agreement by you or others does not waive our right to act with
                      respect to subsequent or similar breaches.
                    </p>
                    <p className="startedP">
                      This Customer Agreement, including any terms and conditions incorporated
                      herein, is the entire agreement between you and us relating to the subject
                      matter hereof, and supersedes any and all prior or contemporaneous written or
                      oral agreements or understandings. This Agreement is between you and us. No
                      other person shall have any rights to enforce any of its terms.
                    </p>
                    <p className="startedP">
                      For more information or general inquiries contact us at{' '}
                      <a href="mailto:info@olehpay.co.il">info@olehpay.co.il</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterHome isHideIntro />
    </div>
  );
};
