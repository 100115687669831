import useAxios from 'axios-hooks'

import { StyledStatusButton } from './status-buttons-style'

const ProcessingButton = ({ row, getPendingTransfers, getProcessingTransfers, isUpdatingData, setIsUpdatingData }) => {
  const [{ loading: loadingUpdate }, updateTransfer] = useAxios(
    {
      url: `/transfers/${row?.uuid}/update/`,
      method: 'POST',
    },
    { manual: true },
  )

  // const buttonIsDisabled = !row.is_processable || loadingUpdate || isUpdatingData
  const buttonIsDisabled = !row.is_processable || loadingUpdate

  const handleOnClick = () => {
    if (buttonIsDisabled) {
      return
    }

    console.log('UPDATE', row?.number, row?.uuid, row?.status)

    setIsUpdatingData(true)
    updateTransfer()
      .then((response) => {
        getPendingTransfers()
        getProcessingTransfers()
        setIsUpdatingData(false)
      })
      .catch((error) => {
        setIsUpdatingData(false)
        const data = error?.response?.data
        if (!data) return console.log(error)
        let errorMessage = ''
        for (const key in data) {
          // eslint-disable-next-line no-loop-func
          data[key].forEach((message) => {
            errorMessage += `[${key}]: ${message}\n`
          })
        }
        alert(errorMessage)
      })
  }

  return (
    <StyledStatusButton onClick={handleOnClick} disabled={buttonIsDisabled}>
      {row?.status}
    </StyledStatusButton>
  )
}

export default ProcessingButton
