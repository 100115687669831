import React, { FC } from 'react';

interface loading {
  overContent?: boolean;
  opacity?: number;
}

export const Loading: FC<loading> = ({ overContent, opacity = 1 }) => {
  return (
    <div
      className="se-pre-con"
      style={{
        opacity: opacity,
        visibility: opacity ? 'visible' : 'hidden',
        position: overContent ? 'absolute' : 'fixed',
        transition: 'all 1000ms linear 2s'
      }}
    ></div>
  );
};

export const LoadingInPlace: FC<loading> = ({ overContent, opacity }) => {
  return (
    <div
      className="se-pre-con-inplace"
      style={{
        opacity: opacity || 1,
      }}
    ></div>
  );
};

export const LoadingInPlaceTransparent: FC<loading> = ({ overContent, opacity }) => {
  return (
    <div
      className="se-pre-con-inplace-transparent"
      style={{
        opacity: opacity || 1,
      }}
    ></div>
  );
};
