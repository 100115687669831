import React from 'react';
import styled from 'styled-components';
import { useAxios } from '../../hooks/axios';
import { toast } from 'react-toastify';
import { THEME, CUSTOM_MODAL_STYLE } from '../../constants';
import Modal from 'react-modal';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Tooltip } from 'react-tooltip';

Modal.defaultStyles.overlay.backgroundColor = 'rgb(144 144 144 / 98%)';

const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
border-radius: 8px;
background-color: transparent;
opacity: 0.8;
font-family: 'Fontsfree net proxima nova';
color: #fff;
padding: 20.5px 12px;
margin-bottom: 12px;
font-size: 23px;
line-height: 23px;
font-weight: 300;
cursor: text;
width: 100%;
border: 1px solid rgb(204, 204, 204);
box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
&.h-70 {
  min-height: 70px;
}
&:focus,
&:active,
&:hover {
  // border: 1px solid #fbb919;
  outline: none;
  opacity: 1;
}
`;

const ConfirmButton = styled.button<{ disabled: boolean }>(
  ({ disabled }) => `
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  background: ${disabled ? THEME.dark_blue : THEME.yellow};
  color: ${disabled ? THEME.yellow : THEME.white};
  border-radius: 6px;
  padding: 22px;
  margin-top: 14px;
  opacity: 0.95;
  cursor: ${disabled ? 'default' : 'pointer'};
  border: ${disabled ? '1px solid' + THEME.yellow : ''};
  opacity: ${disabled ? 0.5 : 0.9};
  &:hover {
    opacity: ${disabled ? 0.5 : 1};
  }
`,
);

const WrapModalButton = styled.button.attrs({
  className: 'WrapModalButton',
})(
  ({ disabled }) => `
    cursor: ${disabled ? 'default' : 'pointer'};
    margin-bottom: 16px;
    -webkit-appearance: button;
    background-color: ${disabled ? 'transparent' : 'rgba(255, 255, 255, 0.12)'};
    border-radius: 4px;
    box-shadow: ${
      disabled ? '' : '0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1)'
    };
    border: ${disabled ? '1px solid rgba(255, 255, 255, 0.12)' : ''};
    color: ${disabled ? 'rgba(255, 255, 255, 0.12)' : THEME.white};
    font-size: 20px;
    height: 56px;
    width: -webkit-fill-available;
    // margin: 20px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    transition: .5s all;
    &:hover {
      opacity: ${disabled ? 1 : 0.9};
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
    }
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 40px;
  `,
);

const WrapModalTitle = styled.div`
  padding: 0 20px;
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 0 !important;
`;

const WrapModalContent = styled.div.attrs({
  className: 'WrapModalContent',
})`
  padding: 20px calc((100% - 280px) / 2);
  div[class^='ValueContainer'] {
    padding-left: 16px;
  }
  p {
    font-size: 17px;
    font-family: Vaud, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-weight: 400;
    line-height: 22px;
    display: flex;
    gap: 4px;
  }
  h1 {
    font-size: 40px;
    font-family: Vaud, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    margin-top: 0;
    font-weight: 400;
  }
`;

const WrapCloseIcon = styled.div`
  right: 0;
  top: 0;
  position: absolute;
  &:hover {
    cursor: pointer;
  }
`;

const StyledTooltip = styled(Tooltip).attrs({
  className: 'StyledTooltip',
})`
  background: black;
  line-height: 20px;
  max-width: 300px;
`;

const StyledList = styled.div.attrs({
  className: 'StyledList',
})`
  font-size: 15px;
  font-family: Vaud,Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  line-height: 22px;
  margin: 15px 0 25px 0;
`;

const ModalContent = ({
  bankAccountSending,
  bankAccountReceiving,
  currentTransferAmount,
  isUSD,
  closeTransferReasonModal,
  transferDate,
  transferReason,
  setTransferReason,
  setTransferSubmitModalIsOpen,
}) => {
  const currency = isUSD ? '$' : '₪';
  const type = isUSD ? 'USD_TO_ILS' : 'ILS_TO_USD';
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [{ data }, postTransfer] = useAxios({}, { manual: true });
  const prettyTransferAmount = Number(currentTransferAmount)
    ?.toFixed(2)
    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const handleOnClick = () => {
    setIsDisabled(true);
    closeTransferReasonModal();    
    setTransferSubmitModalIsOpen(true);
  };
  return (
    <>
      <WrapModalTitle className="modal-title">
        Reason for Transfer
        <WrapCloseIcon>
          <i className="Icon Navbar__icon" onClick={closeTransferReasonModal}>
            <svg viewBox="0 0 24 24">
              <path
                fill="#fff"
                d="M10.695 12.5l-4.342 4.343c-.482.481-.468 1.276.03 1.774.498.499 1.293.512 1.774.03l4.343-4.342 4.317 4.317c.482.482 1.276.468 1.775-.03.498-.499.512-1.293.03-1.775L14.305 12.5l4.317-4.317c.482-.482.468-1.276-.03-1.775-.498-.498-1.293-.512-1.775-.03L12.5 10.695 8.157 6.352c-.481-.481-1.276-.468-1.774.03-.499.5-.512 1.294-.03 1.775l4.342 4.343z"
              ></path>
            </svg>
          </i>
        </WrapCloseIcon>
      </WrapModalTitle>
      <WrapModalContent>
        <p style={{ marginBottom: 0 }}>
        In a few words, please indicate the purpose of your transfer, for example:
        </p>
        <StyledList>
          <li>Personal expense such as groceries</li>
          <li>Living expenses such as rent, mortgage, or utility bills</li>
          <li>Purchase of a vehicle</li>
          <li>Home improvements or remodeling</li>
          <li>Transfer of funds to a family member</li>
        </StyledList>
        <StyledInput
          value={transferReason}
          onChange={(event) => setTransferReason(event.target.value)}
          placeholder="purpose of transfer"
        />
        <WrapModalButton onClick={handleOnClick} disabled={isDisabled || !transferReason}>
          Continue
        </WrapModalButton>
      </WrapModalContent>
    </>
  );
};

export const TransferReasonModal = ({
  setTransferReasonModalIsOpen,
  setTransferSubmitModalIsOpen,
  modalIsOpen,
  bankAccountSending,
  bankAccountReceiving,
  isUSD,
  transferDate,
  currentTransferAmount,
  transferReason,
  setTransferReason,
}) => {
  return (
    <Modal
      style={CUSTOM_MODAL_STYLE}
      isOpen={modalIsOpen}
      onRequestClose={() => setTransferReasonModalIsOpen(false)}
      isLoading={false}
      appElement={document.getElementById('root')}
    >
      <ModalContent
        bankAccountSending={bankAccountSending}
        bankAccountReceiving={bankAccountReceiving}
        isUSD={isUSD}
        currentTransferAmount={currentTransferAmount}
        closeTransferReasonModal={() => setTransferReasonModalIsOpen(false)}
        transferDate={transferDate}
        transferReason={transferReason}
        setTransferReason={setTransferReason}
        setTransferSubmitModalIsOpen={setTransferSubmitModalIsOpen}
      ></ModalContent>
    </Modal>
  );
};
