import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Modal from 'react-modal';
import moment from 'moment';
import { RightPanel } from '../../components/right-panel';
import { Button } from '../../components/button';
import { AddBankAccountModal } from '../../components/add-bank-account-modal';
import { ReferralsContent } from '../../components/referrals-content';
import { Loading } from '../../components/loading';
import { useAxios } from '../../hooks/axios';
import { THEME } from '../../constants';
import { TransfersContent, TransfersContentMobile } from '../../components/transfers-content';
import 'react-tabs/style/react-tabs.css';
import 'react-tooltip/dist/react-tooltip.css';
import { DocumentsContent } from '../../components/documents-content';
import { ESignatureIOModal } from '../../components/esignatureio-document-modal';
import { ProfileContent } from '../../components/profile-content';
import { useLocation } from 'react-router-dom';
import { StandingOrders } from '../../components/standing-orders';

Modal.defaultStyles.overlay.backgroundColor = 'rgb(144 144 144 / 98%)';

const WrapContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 540px;
  @media (max-width: ${THEME.breakpoint}px) {
    flex-direction: column;
  }
`;

const WrapPage = styled.div`
  padding: 32px 32px;
  padding-bottom: 0px;
  @media (max-width: 768px) {
    padding: 16px 16px;
    height: auto;
  }
  width: calc(100% - 330px);
  min-height: 100%;
  @media (max-width: ${THEME.breakpoint}px) {
    width: 100%;
    overflow-y: hidden;
    min-height: auto;
  }
  height: 100vh;
  overflow: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
`;

const WrapButtonHover = styled.div`
  & :hover {
    opacity: 1;
  }
`;

const HeaderPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  @media (max-width: ${THEME.breakpoint}px) {
    flex-direction: column-reverse;
    justify-content: end;
    align-items: end;
    button {
      margin-bottom: 15px;
    }
  }
`;

const WrapPageContentMobile = styled.div`
  display: none;
  @media (max-width: ${THEME.breakpoint}px) {
    display: block;
    padding-left: 16px;
    padding-top: 16px;
    h2 {
      margin-top: 0;
    }
    input.search {
      min-width: calc(100vw - 70px);
    }
    .wrap-table {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
`;

export const Account = ({ setFirstName, userData }) => {
  const [addBankAccountModalIsOpen, setAddBankAccountModalIsOpen] = React.useState(false);
  const [selectedBankAccountSending, setSelectedBankAccountSending] = useState(null);
  const [activeESignatureIODocument, setActiveESignatureIODocument] = useState(null);
  const [transferPanelIsOpen, setTransferPanelIsOpen] = useState(false);
  const [ESignatureIOModalIsOpen, setESignatureIOModalIsOpen] = useState(false);
  const [SSNVerificationDocument, setSSNVerificationDocument] = useState(null);
  const [KYCFormDocument, setKYCFormDocument] = useState(null);
  const [{ data: usersResponse }] = useAxios('/users/');
  const [{ data: addressesResponse }] = useAxios('/addresses/');
  const [{ data: bankAccountTypesResponse }] = useAxios('/bank_account_types/');
  const [{ data: banksResponse }, getBanks] = useAxios('/banks/');
  const [{ data: bankAccountsResponse }, refetchBankAccounts] = useAxios('/bank_accounts/');
  const [{ data: documentsResponse }] = useAxios('/documents/');
  const [{ data: exchangeRatesResponse }] = useAxios('/exchange_rates/?size=1000');
  const [{ data: fundingFormsResponse }] = useAxios(
    '/esignatureio_documents/?type__value=FUNDING_FORM',
  );
  const [{ data: identificationsResponse }] = useAxios('/identifications/');
  const [{ data: phoneNumbersResponse }] = useAxios('/phone_numbers/');
  const [{ data: profileResponse }, refetchProfile] = useAxios('/profiles/');
  const [{ data: SSNVerificationDocumentResponse }] = useAxios(
    '/esignatureio_documents/?type__value=SOCIAL_SECURITY_CONFIRMATION',
  );
  const [{ data: KYCFormDocumentResponse }] = useAxios(
    '/esignatureio_documents/?type__value=KNOW_YOUR_CUSTOMER_FORM',
  );
  const [{ data: socialSecurityNumberResponse }, getSocialSecurityNumber] = useAxios(
    '/social_security_numbers/',
  );
  const [{ data: subscriptionsResponse }] = useAxios('/subscriptions/');
  const [{ data: transfersResponse, loading: loadingTransfersResponse }, getTransfers] =
    useAxios('/transfers/');
  const [, addBankAccountRequest] = useAxios('/bank_accounts/', {
    manual: true,
  });
  const [, createBankAccountHolderRequest] = useAxios('/bank_account_holders/', { manual: true });
  const [, createBankAccountDocumentRequest] = useAxios('/bank_account_documents/', {
    manual: true,
  });
  const [, createBankAccountBillingAddressRequest] = useAxios('/bank_account_billing_addresses/', {
    manual: true,
  });

  const [{ data: referralsResponse, loading: loadingReferralsResponse }, getReferrals] = useAxios(
    `/referrals/${userData.uuid}`,
    { manual: true },
  );

  const [{ loading: createESignatureIODocumentIsLoading }, createESignatureIODocument] = useAxios(
    '/esignatureio_documents/',
    {
      manual: true,
    },
  );
  const [{ data: countriesResponse }] = useAxios('/countries/');

  const countries = {
    IL: countriesResponse?.results?.filter((country) => country?.alpha_2 === 'IL')[0],
    US: countriesResponse?.results?.filter((country) => country?.alpha_2 === 'US')[0],
  };

  const bankAccountTypes = bankAccountTypesResponse?.results?.sort((a, b) =>
    a.value?.length > b.value?.length ? 1 : -1,
  );
  const bankAccounts = {
    IL: bankAccountsResponse?.results?.filter(
      (bankAccount) => bankAccount?.country?.alpha_2 === 'IL',
    ),
    US: bankAccountsResponse?.results?.filter(
      (bankAccount) => bankAccount?.country?.alpha_2 === 'US',
    ),
  };
  const banks = {
    IL: banksResponse?.results
      ?.filter((bank) => bank?.country?.alpha_2 === 'IL')
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
    US: banksResponse?.results
      ?.filter((bank) => bank?.country?.alpha_2 === 'US')
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
  };
  const documents = documentsResponse?.results;
  const fundingForms = fundingFormsResponse?.results;
  const identifications = identificationsResponse?.results;
  const numberOfDocuments = documentsResponse?.count;
  const numberOfTransfers = transfersResponse?.count;

  const profile = profileResponse?.results[0];
  const socialSecurityNumber = socialSecurityNumberResponse?.results[0];
  const subscriptions = subscriptionsResponse?.results;
  const transfers = transfersResponse?.results?.map((transfer) => ({
    date: moment(transfer.created_at).format('MMM DD, YYYY'),
    credited: transfer.type.currency_out.symbol + (transfer.amount_receiving || 'TBD'),
    debited: transfer.type.currency_in.symbol + (transfer.amount_sending || 'TBD'),
    status: transfer.status,
    number: transfer.number,
    origin: transfer,
  }));
  const accountSetupFlag = bankAccounts?.IL?.length && bankAccounts?.US?.length;
  const transferRate = exchangeRatesResponse?.results[0];
  const user = usersResponse?.results[0];
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [address, setAddress] = useState(null);
  const [selectedTransferBank, setSelectedTransferBank] = useState(null);

  const USDTransferSum = transfersResponse?.results
    ?.filter(transfer => transfer?.currency_sending?.code === "USD" && transfer?.status === 'COMPLETED')
    ?.reduce((sum, transfer) => sum + Number(transfer?.amount_sending), 0);
  const ILSTransferSum = transfersResponse?.results
    ?.filter(transfer => transfer?.currency_sending?.code === "ILS" && transfer?.status === 'COMPLETED')
    ?.reduce((sum, transfer) => sum + Number(transfer?.amount_receiving), 0);
  const compeltedTransferSumEquivalent = USDTransferSum + ILSTransferSum;

  const kycIsRequired = profile?.is_approved && compeltedTransferSumEquivalent >= 10000;
  const atLeastOneAccountConnected = bankAccounts?.US?.length || bankAccounts?.IL?.length
  const bothAccountsConnected = bankAccounts?.US?.length && bankAccounts?.IL?.length

  useEffect(() => {
    if (!phoneNumbersResponse?.results[0]) return;
    const phoneNumberData = phoneNumbersResponse?.results[0];
    phoneNumberData.country = phoneNumber?.country || 'IL';
    setPhoneNumber(phoneNumberData);
  }, [phoneNumbersResponse]);

  useEffect(() => {
    if (!addressesResponse?.results[0]) return;
    const addressData = addressesResponse?.results[0];
    addressData.country = addressData?.country || 'IL';
    setAddress(addressData);
  }, [addressesResponse]);

  useEffect(() => {
    document.body.style.overscrollBehavior = 'none';

    return () => {
      document.body.style.overscrollBehavior = '';
    };
  }, []);

  useEffect(() => {
    if (fundingForms && bankAccounts && bankAccounts.US) {
      bankAccounts.US.forEach((bank) => {
        if (
          fundingForms &&
          fundingForms.find((e) => e.bank_account === bank.uuid) &&
          fundingForms.find((e) => e.bank_account === bank.uuid).is_signed
        ) {
          setSelectedTransferBank(bank);
          return true;
        }
      });
    }
  }, [fundingForms, bankAccounts]);

  useEffect(() => {
    setFirstName(profileResponse?.results[0]?.first_name);
  }, [profileResponse]);

  useEffect(() => {
    setSSNVerificationDocument(SSNVerificationDocumentResponse?.results[0]);
  }, [SSNVerificationDocumentResponse]);

  useEffect(() => {
    setKYCFormDocument(KYCFormDocumentResponse?.results[0]);
  }, [KYCFormDocumentResponse]);

  const handleCompleteKYCOnClick = () => {
    if (!KYCFormDocument) {
      createESignatureIODocument({
        method: 'POST',
        data: {
          type: 'KNOW_YOUR_CUSTOMER_FORM',
        },
      }).then((response) => {
        console.log('response on create', response?.data);
        setKYCFormDocument(response?.data);
        setActiveESignatureIODocument(response?.data);
        setESignatureIOModalIsOpen(true);
      });
    } else {
      setActiveESignatureIODocument(KYCFormDocument);
      setESignatureIOModalIsOpen(true);
    }
  }

  const handleStartATransfer = () => {
    if (kycIsRequired && (!KYCFormDocument || !KYCFormDocument?.is_signed))
      return handleCompleteKYCOnClick();
    if(!selectedTransferBank) {
      return;
    }
    setTransferPanelIsOpen(true);
    setSelectedBankAccountSending(selectedTransferBank);
  };

  const currentPath = useLocation().pathname;
  if (
    !countriesResponse ||
    !addressesResponse ||
    !bankAccountTypesResponse ||
    !bankAccountsResponse ||
    !banksResponse ||
    !documentsResponse ||
    !exchangeRatesResponse ||
    !identificationsResponse ||
    !profileResponse ||
    !transfersResponse
  )
    return <Loading />;

  if (createESignatureIODocumentIsLoading) return <Loading opacity={0.9} />;
  
  return (
    <WrapContainer>
      <WrapPage>
        <HeaderPage>
          {atLeastOneAccountConnected && (
            <WrapButtonHover>
              <a href='contact'>
                <Button label="TRANSFER TO SOMEONE ELSE" />
              </a >
            </WrapButtonHover>
          )}          
          {bankAccounts?.US?.length && bankAccounts?.IL?.length ? (
            <>              
              <WrapButtonHover>
                <Button
                  style={{ fontWeight: '500', opacity: !selectedTransferBank ? 0.25 : 1 }}
                  label="START A TRANSFER"
                  onClick={handleStartATransfer}
                  isBorder
                />
              </WrapButtonHover>
            </>
          ) : null}
        </HeaderPage>
        {currentPath === '/account' && (
          <TransfersContent
            transfers={transfers}
            numberOfTransfers={numberOfTransfers}
            exchangeRatesResponse={exchangeRatesResponse}
            loadingTransfersResponse={loadingTransfersResponse}
          />
        )}
        {currentPath === '/documents' && (
          <DocumentsContent documents={documents} numberOfDocuments={numberOfDocuments} />
        )}
        {currentPath === '/profile' && (
          <ProfileContent
            user={user}
            phoneNumber={phoneNumber}
            profile={profile}
            identifications={identifications}
            address={address}
            setAddress={setAddress}
            setPhoneNumber={setPhoneNumber}
            refetchProfile={refetchProfile}
          />
        )}
        {currentPath === '/referrals' && (
          <ReferralsContent
            user={userData}
            getReferrals={getReferrals}
            referralsResponse={referralsResponse}
          />
        )}
        {currentPath === '/subscriptions' && <StandingOrders subscriptions={subscriptions} />}
      </WrapPage>

      <AddBankAccountModal
        addBankAccountModalIsOpen={addBankAccountModalIsOpen}
        bankAccountTypes={bankAccountTypes}
        banks={banks}
        closeAddBankAccountModal={() => setAddBankAccountModalIsOpen(false)}
        getBankAccounts={refetchBankAccounts}
        addBankAccountRequest={addBankAccountRequest}
        createBankAccountDocumentRequest={createBankAccountDocumentRequest}
        createBankAccountHolderRequest={createBankAccountHolderRequest}
        createESignatureIODocument={createESignatureIODocument}
        createBankAccountBillingAddressRequest={createBankAccountBillingAddressRequest}
        setESignatureIOModalIsOpen={setESignatureIOModalIsOpen}
        setActiveESignatureIODocument={setActiveESignatureIODocument}
        countries={countries}
      />

      <ESignatureIOModal
        ESignatureIOModalIsOpen={ESignatureIOModalIsOpen}
        closeESignatureIOModal={() => setESignatureIOModalIsOpen(false)}
        activeESignatureIODocument={activeESignatureIODocument}
      />

      <RightPanel
        bankAccounts={bankAccounts}
        fundingForms={fundingForms}
        ESignatureIOModalIsOpen={ESignatureIOModalIsOpen}
        setESignatureIOModalIsOpen={setESignatureIOModalIsOpen}
        setAddBankAccountModalIsOpen={setAddBankAccountModalIsOpen}
        getTransfers={getTransfers}
        transferPanelIsOpen={transferPanelIsOpen}
        setTransferPanelIsOpen={setTransferPanelIsOpen}
        setActiveESignatureIODocument={setActiveESignatureIODocument}
        selectedBankAccountSending={selectedBankAccountSending}
        setSelectedBankAccountSending={setSelectedBankAccountSending}
        socialSecurityNumber={socialSecurityNumber}
        activeESignatureIODocument={activeESignatureIODocument}
        SSNVerificationDocument={SSNVerificationDocument}
        setSSNVerificationDocument={setSSNVerificationDocument}
        getSocialSecurityNumber={getSocialSecurityNumber}
        transferRate={transferRate}
        profile={profile}
        transfers={transfersResponse?.results}
        KYCFormDocument={KYCFormDocument}
        setKYCFormDocument={setKYCFormDocument}
        kycIsRequired={kycIsRequired}
      />

      <WrapPageContentMobile>
        <TransfersContentMobile
          transfers={transfers}
          numberOfTransfers={numberOfTransfers}
          loadingTransfersResponse={loadingTransfersResponse}
        />
      </WrapPageContentMobile>
    </WrapContainer>
  );
};
