import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./app";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { ModalProvider } from "react-modal-hook";
import "./style.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
   // Performance Monitoring
} else {
  const userDataString = localStorage.getItem("user");
  let userData;
  if (userDataString) {
    userData = JSON.parse(userDataString);
  }
  Sentry.init({
    dsn: "https://3cc71aad04b84191bab25a0347d77c10@o4505241173426176.ingest.sentry.io/4505241175588864",
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "xhr") {
        // hint.xhr is a whole XHR object that you can use to modify breadcrumb
      }
      return breadcrumb;
    },
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        networkDetailAllowUrls: [
          window.location.origin,
          "https://olehpay.co.il/",
        ],
        networkRequestHeaders: ["X-Custom-Header"],
        networkResponseHeaders: ["X-Custom-Header"],
      }),
    ],
    initialScope: (scope) => {
      if (userData) {
        scope.setUser({ email: userData.email });
        scope.setTag("userid", userData.uuid);
      }
      return scope;
    },
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// function FallbackComponent() {
//   return <div>An error has occurred</div>;
// }

// const myFallback = <FallbackComponent />;
// // Alternatively:
// // const myFallback = () => <FallbackComponent />;

// ReactDOM.render(
//   <React.StrictMode>
//     <Sentry.ErrorBoundary fallback={myFallback} showDialog>
//       <Provider store={store}>
//         <BrowserRouter>
//           <ModalProvider>
//             <App />
//           </ModalProvider>
//         </BrowserRouter>
//       </Provider>
//     </Sentry.ErrorBoundary>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

root.render(
  <BrowserRouter>
    <ModalProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
        <App />
      </GoogleOAuthProvider>
    </ModalProvider>
  </BrowserRouter>
);
