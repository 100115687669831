/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../hooks/axios';
import { HeaderHome } from '../../components/header-home';
import { FooterHome } from '../../components/footer-home';

import moment from 'moment';
export const Contact = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [{ data }, postContact] = useAxios({}, { manual: true });
  const [isSubmited, setIsSubmited] = useState(false);

  const send = () => {
    postContact({
      url: 'contact-us/',
      method: 'post',
      data: {
        email,
        message,
        phone,
      },
    })
      .then((response) => {
        setIsSubmited(true);
      })
      .catch((err) => {
        alert('Request: ' + JSON.stringify(err));
      });
  };

  return (
    <div className="home-content text-white">
      <HeaderHome
        userIsAdmin={userIsAdmin}
        userIsRegistered={userIsRegistered}
        userIsAuthenticated={userIsAuthenticated}
        setToken={setToken}
        isGrayHeader
      />

      <div className="support support-contact">
        <div
          data-w-id="ee99df03-d224-2b00-1d8d-b27795baf117"
          className="support-container w-container"
        >
          <div className="support-copy-container">
            <h2 className="text-align-center-mobile">Israeli Phone Support </h2>
            <div className="support-number-container">
              <h2 id="israeli-tel" className="phone text-weight-reg tcb-light clear-padding">
                +972 (2) 376-2028
              </h2>
            </div>
            <h2 className="text-align-center-mobile">American Phone Support </h2>
            <div className="support-number-container">
              <h2 id="american-tel" className="phone text-weight-reg tcb-light clear-padding">
                +1 (323) 909-4767
              </h2>
            </div>
            <h2 className="text-align-center-mobile">Email Support </h2>
            <div className="support-number-container">
              <h2 id="email-support" className="phone text-weight-reg tcb-light clear-padding">
                support@olehpay.co.il
              </h2>
            </div>
          </div>
          <div className="support-illo-wrapper">
            <img src={require('../../assets/imgs/support.svg').default} />
          </div>
        </div>
        <div
          data-w-id="ee99df03-d224-2b00-1d8d-b27795baf117"
          className="support-container w-container"
        >
          <div className="div-block" style={{ paddingRight: '30px' }}>
            <h1 className="heading-6">Contact Us</h1>
            <h3 className="text-weight-reg tcb-light clear-padding">
              OlehPay is dedicated to providing a more convenient and less expensive transfer option
              for sending money to Israel. With OlehPay, you can transfer funds entirely online, get
              the true exchange rate, and avoid costly wires. To help you with our best service,
              don&apos;t hesitate to contact us today!
            </h3>
          </div>
          <div className="div-block" id="sent">
            {isSubmited ? (
              <h1 className="heading-6">Thanks, Message Sent!</h1>
            ) : (
              <form name="basic-details" id="0" className="w-clearfix">
                <input
                  type="text"
                  className="text-field-17 w-input"
                  maxLength={64}
                  name="email"
                  value={email}
                  placeholder="What's your email?"
                  id="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="tel"
                  className="text-field-17 w-input"
                  maxLength={64}
                  name="phone"
                  value={phone}
                  placeholder="What's your phone number?"
                  id="phone"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
                <textarea
                  name="text"
                  className="text-field-17 w-input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id="message"
                  style={{ height: '130px', padding: '12px', paddingTop: '20px' }}
                  placeholder="How can we help?"
                  rows={14}
                  cols={10}
                  wrap="soft"
                ></textarea>
                <button
                  type="button"
                  disabled={!email || !phone || !message}
                  id="submit_contact_request"
                  className="submit-button-2 w-button submit-form"
                  onClick={send}
                >
                  Send Message
                </button>
              </form>
            )}
          </div>
        </div>
        <div
          data-w-id="ee99df03-d224-2b00-1d8d-b27795baf117"
          className="support-container w-container"
        >
          <div className="div-block">
            <h1 className="heading-6">United States</h1>
            <div className="box">
              <h3>
                1875 Century Park East
                <br />
                Suite 600
                <br />
                Los Angeles, CA 90067
              </h3>
            </div>
          </div>
          <div className="div-block" style={{ paddingRight: '30px' }}>
            <h1 className="heading-6">Israel</h1>
            <div className="box">
              <h3>
                24 Derech Hebron Rd
                <br />
                Jerusalem Media Quarter
                <br />
                Jerusalem, 93542{' '}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <FooterHome isHideIntro />
    </div>
  );
};
