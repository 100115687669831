import styled from 'styled-components';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, Filler } from 'chart.js';
import DatePicker from 'react-widgets/esm/DatePicker';
import moment from 'moment';

import { StyledButton, StyledFieldset, StyledLegend } from '../common-components';
import { LoadingInPlace } from '../../../components/loading';
import { THEME } from '../../../constants';

Chart.register(Filler);


const StyledDatePicker = styled(DatePicker).attrs({
  className: 'StyledDatePicker',
})`
  display: flex;
  height: 70px;
  &.rw-state-focus .rw-widget-picker {
    box-shadow: none;
    border: 1px solid #fbb919;
  }
  .rw-widget-container {
    opacity: 0.8;
  }
  .rw-widget-input,
  .rw-widget-picker {
    color: white;
    font-size: inherit;
    height: 100%;
    border-radius: 8px;
    background-color: transparent;
    font-family: Fontsfree net proxima nova;
    color: #fff;
    padding: 10px 6px;
    font-size: 23px;
    line-height: 23px;
    font-weight: 300;
    &:focus,
    &:active,
    &:hover {
      border-color: #fbb919;
      outline: none;
      opacity: 1;
    }
  }
  .selected .rw-widget-input,
  .selected {
    border-color: #fbb919;
  }
  .rw-picker-btn {
    border-color: inherit;
    color: white;
    &:hover {
      color: ${THEME.dark_blue};
    }
  }
  .rw-calendar-popup {
    right: -10px;
    left: auto;
  }
`;

const ReportsContainer = styled.div.attrs({
  className: 'ReportsContainer',
})`
  display: grid;
  grid-template-rows: auto 1fr 1fr 1fr;
  gap: 1em;
  height: 100%;
`;

const ControlsContainer = styled.div.attrs({
  className: 'ControlsContainer',
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1em;
`;


export const AdminReportsContent = () => {
  const oneYearAgoDate = moment()
    .subtract(1, 'year')
    .date(1)
    .utcOffset(0, true)
    .toDate();

  const [startDate, setStartDate] = useState(oneYearAgoDate)
  const [transferType, setTransferType] = useState('USD_TO_ILS');
  const [transfersDetails, setTransfersDetails] = useState([]);
  const [{ data: transfersResponse, loading: transfersResponseIsLoading }] = useAxios(
    `/transfer-reports/?paginate=false&type__value=${transferType}&requested_at__gte=${startDate.toISOString()}`,
  );
  const [{ data: completedTransfersResponse, loading: completedTransfersResponseIsLoading }] =
    useAxios(
      `/transfer-reports/?paginate=false&status__value=COMPLETED&type__value=${transferType}&completed_at__gte=${startDate.toISOString()}`,
    );
  const [{ data: revenuesResponse, loading: revenuesResponseIsLoading }] = useAxios(
    `/revenue-reports/?paginate=false&transfer__requested_at__gte=${startDate.toISOString()}`,
  );
  const [{ data: usersResponse, loading: usersResponseIsLoading }] = useAxios(
    `/profiles/?paginate=false&created_at__gte=${startDate.toISOString()}`,
  );
  const [{ loading: transfersDetailsResponseIsLoading}, getTransfersDetails] = useAxios(
    `/transfers/?paginate=false&requested_at__gte=${startDate.toISOString()}`,
    {
      manual: true,
    }
  ) 

  const transferTypeTitle = transferType === 'USD_TO_ILS' ? 'USD To ILS' : 'ILS To USD';

  const usersByMonth =
    usersResponse?.reverse()?.reduce((accumulator, profile) => {
      const date = new Date(profile?.created_at);
      const month = `${date.getFullYear()}-${date.getMonth() + 1}`;

      const existingData = accumulator.find((data) => data.month === month);
      if (existingData) {
        existingData.joined += 1;
        existingData.registered += profile?.is_registered ? 1 : 0;
      } else {
        const newData = {
          month,
          joined: 1,
          registered: 1,
        };
        accumulator.push(newData);
      }

      return accumulator;
    }, []) || [];

  const sortedUsersByMonth = usersByMonth?.sort(
    (a, b) => Number(new Date(a.month)) - Number(new Date(b.month)),
  );

  const transferDates = transfersResponse
    ?.map((dataPoint) => dataPoint?.month)
    ?.map((label) =>
      new Date(`${label.slice(0, 7)}-15`).toLocaleString('en-US', {
        month: 'short',
        year: 'numeric',
      }),
    );
  const amounts = transfersResponse?.map((dataPoint) => dataPoint?.total_amount_sending);
  const completedAmounts = completedTransfersResponse?.map(
    (dataPoint) => dataPoint?.total_amount_sending,
  );

  const revenueDates = revenuesResponse
    ?.map((dataPoint) => dataPoint?.month)
    ?.map((label) =>
      new Date(`${label.slice(0, 7)}-15`).toLocaleString('en-US', {
        month: 'short',
        year: 'numeric',
        timeZone: 'America/Los_Angeles',
      }),
    );
  const totalRevenues = revenuesResponse?.map((dataPoint) => dataPoint?.total_amount);

  const userDates = sortedUsersByMonth
    ?.map((dataPoint) => dataPoint?.month)
    ?.map((label) => {
      const [year, month] = label.split('-');
      const monthName = new Date(`${year}-${month}-15`).toLocaleString('en-US', {
        month: 'short',
        year: 'numeric',
      });
      return `${monthName}`;
    });
  const newUsers = sortedUsersByMonth?.map((dataPoint) => dataPoint?.joined);
  const registeredUsers = sortedUsersByMonth?.map((dataPoint) => dataPoint?.registered);

  const transferData = {
    labels: transferDates,
    datasets: [
      {
        label: `${transferTypeTitle} Requested`,
        data: amounts,
        fill: true,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.33,
      },
      {
        label: `${transferTypeTitle} Completed`,
        data: completedAmounts,
        fill: true,
        borderColor: 'rgba(0, 202, 202, 0.5)',
        backgroundColor: 'rgba(0, 202, 202, 0.5)',
        tension: 0.33,
      },
    ],
  };

  const revenueData = {
    labels: revenueDates,
    datasets: [
      {
        label: `Total Revenue in Dollars`,
        data: totalRevenues,
        fill: true,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.33,
      },
      // {
      //   label: `Total USD to ILS Revenue in Dollars`,
      //   data: USDToILSRevenues,
      //   fill: true,
      //   borderColor: 'rgb(85, 202, 202)',
      //   tension: 0.33,
      // },
      // {
      //   label: `Total ILS to USD Revenue in Dollars`,
      //   data: ILSToUSDRevenues,
      //   fill: true,
      //   borderColor: 'rgb(95, 212, 212)',
      //   tension: 0.33,
      // },
    ],
  };

  const usersData = {
    labels: userDates,
    datasets: [
      {
        label: 'New Users',
        data: newUsers,
        fill: true,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.33,
      },
      {
        label: 'Registered Users',
        data: registeredUsers,
        fill: true,
        borderColor: 'rgba(0, 202, 202, 0.5)',
        backgroundColor: 'rgba(0, 202, 202, 0.5)',
        tension: 0.33,
      },
    ],
  };

  const transferChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const formattedValue = `$${value}`;
          return `${dataset.label}: ${formattedValue}`;
        },
      },
    },
  };

  const revenueChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const formattedValue = `$${value}`;
          return `${dataset.label}: ${formattedValue}`;
        },
      },
    },
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const flattenObject = (obj, parentKey = '') => {
    let result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const flattened = flattenObject(obj[key], newKey);
          result = { ...result, ...flattened };
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  };

  const convertToCSV = (flattenedArray) => {
    const header = Object.keys(flattenedArray[0]).join(',');
    const rows = flattenedArray.map(obj => {
      const rowValues = Object.values(obj).map(value => {
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`; // Encapsulate in double quotes if there's a comma
        }
        return value;
      });
      return rowValues.join(',');
    });
  
    return `${header}\n${rows.join('\n')}`;
  };
  
  const handleStartDateOnChange = (date) => {
    console.log(date)
    setStartDate(date)
  }

  const handleTransferReportOnClick = () => {
    getTransfersDetails().then(response => {
      const csvData = response?.data.map(obj => flattenObject(obj))
      setTransfersDetails(csvData)
      const csvText = convertToCSV(csvData)
      const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `transfers.csv`;
      link.click();
    })   
  }

  return (
    <ReportsContainer>
      <StyledFieldset>
        <StyledLegend>Controls</StyledLegend>
        <ControlsContainer>
          <StyledDatePicker
            value={startDate}
            onChange={handleStartDateOnChange}
            calendarProps={{ 
              views: ["year", "decade", "century"], 
              defaultView: 'year', 
              defaultCurrentDate: new Date('2023') 
            }}
            placeholder={"Start Date"}
            valueFormat={{
              month: 'short',
              year: 'numeric',
            }}
            onMouseLeave={() => {
              (document.activeElement as any).blur();
            }}
          />
        <StyledButton
          style={{ margin: '0 3px' }}
          transparent={transferType === 'ILS_TO_USD'}
          onClick={() => setTransferType('USD_TO_ILS')}
        >
          USD to ILS
        </StyledButton>
        <StyledButton
          style={{ margin: '0 3px' }}
          transparent={transferType === 'USD_TO_ILS'}
          onClick={() => setTransferType('ILS_TO_USD')}
        >
          ILS to USD
        </StyledButton>
        <StyledButton
          style={{ margin: '0 3px' }}
          transparent={true}
          onClick={handleTransferReportOnClick}
          disabled={transfersDetailsResponseIsLoading}
        >
          Transfers Report
        </StyledButton>
        </ControlsContainer>
      </StyledFieldset>
      <StyledFieldset>
        <StyledLegend>Transfers</StyledLegend>
        {transfersResponseIsLoading || completedTransfersResponseIsLoading ? (
          <LoadingInPlace />
        ) : (
          <Line data={transferData} options={transferChartOptions} />
        )}
      </StyledFieldset>
      <StyledFieldset>
        <StyledLegend>Revenue</StyledLegend>
        {revenuesResponseIsLoading ? (
          <LoadingInPlace />
        ) : (
          <Line data={revenueData} options={revenueChartOptions} />
        )}
      </StyledFieldset>
      <StyledFieldset>
        <StyledLegend>Users</StyledLegend>
        {usersResponseIsLoading ? (
          <LoadingInPlace />
        ) : (
          <Line data={usersData} options={chartOptions} />
        )}
      </StyledFieldset>
    </ReportsContainer>
  );
};
