import { useState, useEffect } from 'react';
import moment from 'moment';
import 'react-widgets/scss/styles.scss';

import {
  SubtitleContainer,
  StyledFooter,
  StyledNextButton,
  YellowLink,
} from '../common-components';
import {
  ClickHereContainer,
  DatePickerContainer,
  DocumentPreviewImage,
  DocumentPreviewImageContainer,
  DownArrowIcon,
  FormGridContainer,
  IdentificationTypeContainer,
  IframePreview,
  SkipTextContainer,
  StyledDatePicker,
  StyledInput,
  StyledSelect,  
  StyledUploadDocumentButton,
} from './identifications.style';
import { UploadDocumentWidget } from '../../../components/upload-document-widget';
import ICON_ARROW_DOWN from '../../../assets/imgs/icon/arrow_card_down.svg';


export const RegistrationIdentification = ({
  title,
  entity,
  designation,
  handleNextOnClick,
  identification,
  setIdentification,
  identificationFileData,
  setIdentificationFileData,
  nextButtonIsEnabled,
  setNextButtonIsEnabled,
  setCurrentStep,
}) => {
  
  const [uploadDocumentWidgetIsOpen, setUploadDocumentWidgetIsOpen] = useState(false);
  const [issuedPlaceholder, setIssuedPlaceHolder] = useState('Issued');
  const [expiredPlaceholder, setExpiredPlaceHolder] = useState('Expires');
  const [isPdf, setShowPdf] = useState(false);
  const [buttonTitle, setButtonTitle] = useState(
    identificationFileData?.document || identification?.file ? 'Document Uploaded' : 'Upload ID',
  );
  const issueDate = identification?.issue_date
    ? moment(identification.issue_date, 'YYYY-MM-DD').toDate()
    : null;
  const expirationDate = identification?.expiration_date
    ? moment(identification.expiration_date, 'YYYY-MM-DD').toDate()
    : null;
  const identificationTypeOptions = [
    { label: 'National ID', value: 'STATE_IDENTIFICATION_CARD' },
    { label: 'Drivers License', value: 'DRIVERS_LICENSE' },
    { label: 'Passport', value: 'PASSPORT' },
    { label: 'Other Official ID', value: 'OTHER' },
  ].map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));
  const isPrimaryID = designation === 'primary';
  const isIsraeliPrimaryID = entity === 'ISRAELI_CITIZEN' && designation === 'primary';
  const isAmericanPrimaryID = entity === 'NON_ISRAELI_CITIZEN' && designation === 'primary';

  const countryOptions = [
    { label: 'United States', value: 'US' },
    { label: 'Israel', value: 'IL' },
    { label: 'Other', value: null },
  ].map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  const handleIdentificationTypeOnChange = (event) =>
    setIdentification((identification) => ({
      ...identification,
      type: event.target.value,
    }));

  const handleCountryOnChange = (event) =>
    setIdentification((identification) => ({
      ...identification,
      country: event.target.value,
    }));

  const handleNumberOnChange = (event) => {
    setIdentification((identification) => ({
      ...identification,
      number: event.target.value,
    }));
  };

  const handleIssueDateOnChange = (date) => {
    setIdentification((identification) => ({
      ...identification,
      issue_date: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const handleExpirationDateOnChange = (date) => {
    setIdentification((identification) => ({
      ...identification,
      expiration_date: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const handleUploadOneLaterOnClick = () => {
    setCurrentStep((step) => step += 1);
  }

  useEffect(() => {
    if (
      (identificationFileData?.blob || identification?.file) &&
      identification?.number &&
      identification?.issue_date &&
      identification?.expiration_date
    )
      setNextButtonIsEnabled(true);
    else setNextButtonIsEnabled(false);
  }, [identificationFileData, identification]);

  return (
    <>
      <SubtitleContainer>{title}</SubtitleContainer>
      {(identificationFileData?.document && identificationFileData?.document !== '') ||
      identification?.file ? (
        <DocumentPreviewImageContainer>
          {identification?.file_metadata?.content_type === 'application/pdf' ||
          identificationFileData?.image?.type === 'application/pdf' ? (
            <IframePreview
              src={identification?.file || identificationFileData?.previewURL}
              frameBorder="0"
            ></IframePreview>
          ) : (
            <DocumentPreviewImage src={identificationFileData?.document || identification?.file} />
          )}
        </DocumentPreviewImageContainer>
      ) : (
        ''
      )}
      <StyledUploadDocumentButton
        onClick={() => setUploadDocumentWidgetIsOpen(true)}
        onMouseEnter={() =>
          setButtonTitle(
            identificationFileData?.document || identification?.file
              ? 'Upload Different ID'
              : 'Upload ID',
          )
        }
        onMouseLeave={() =>
          setButtonTitle(
            identificationFileData?.document || identification?.file
              ? 'Document Uploaded'
              : 'Upload ID',
          )
        }
        disabled={false}
      >
        {buttonTitle}
      </StyledUploadDocumentButton>
      <UploadDocumentWidget
        isPdf={isPdf}
        setShowPdf={setShowPdf}
        image={identificationFileData?.image}
        previewURL={identificationFileData?.previewURL}
        uploadDocumentWidgetIsOpen={uploadDocumentWidgetIsOpen}
        setImage={(image) =>
          setIdentificationFileData((fileData) => ({
            ...fileData,
            image,
          }))
        }
        setPreviewURL={(previewURL) =>
          setIdentificationFileData((fileData) => ({
            ...fileData,
            previewURL,
          }))
        }
        setSelectedDocument={(document) =>
          setIdentificationFileData((fileData) => ({
            ...fileData,
            document,
          }))
        }
        setSelectedDocumentBlob={(blob) =>
          setIdentificationFileData((fileData) => ({
            ...fileData,
            blob,
          }))
        }
        closeUploadDocumentWidget={() => setUploadDocumentWidgetIsOpen(false)}
      />
      <FormGridContainer>
        {isPrimaryID ? null : (
          <IdentificationTypeContainer style={{ gridColumn: 'span 1' }}>
            <StyledSelect
              onChange={handleCountryOnChange}
              value={identification?.country || (entity === 'ISRAELI_CITIZEN' ? 'IL' : 'US')}
            >
              {countryOptions}
            </StyledSelect>
            <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
          </IdentificationTypeContainer>
        )}
        {isPrimaryID ? null : (
          <IdentificationTypeContainer style={{ gridColumn: 'span 1' }}>
            <StyledSelect onChange={handleIdentificationTypeOnChange} value={identification?.type}>
              {identificationTypeOptions}
            </StyledSelect>
            <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
          </IdentificationTypeContainer>
        )}
        <StyledInput
          style={{ gridColumn: 'span 2' }}
          placeholder={isIsraeliPrimaryID ? 'Teudat Zehut #' : 'Number'}
          value={identification.number || ''}
          onChange={handleNumberOnChange}
          isFilledOut={identification?.number}
        />
        <DatePickerContainer isFilledOut={issueDate}>
          <StyledDatePicker
            value={issueDate}
            onChange={handleIssueDateOnChange}
            onFocus={() => setIssuedPlaceHolder('mm/dd/yyyy')}
            onBlur={() => setIssuedPlaceHolder('Issued')}
            calendarProps={{ defaultView: 'century', defaultCurrentDate: new Date('1970') }}
            placeholder={issuedPlaceholder}
            valueFormat={{
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            }}
            dropUp
            onMouseLeave={() => {
              (document.activeElement as any).blur();
            }}
          />
        </DatePickerContainer>
        <DatePickerContainer isFilledOut={expirationDate}>
          <StyledDatePicker
            value={expirationDate}
            onChange={handleExpirationDateOnChange}
            onFocus={() => setExpiredPlaceHolder('mm/dd/yyyy')}
            onBlur={() => setExpiredPlaceHolder('Expired')}
            calendarProps={{ defaultView: 'century', defaultCurrentDate: new Date('1970') }}
            placeholder={expiredPlaceholder}
            valueFormat={{
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            }}
            dropUp
            onMouseLeave={() => {
              (document.activeElement as any).blur();
            }}
          />
        </DatePickerContainer>
      </FormGridContainer>
      <SkipTextContainer>
        Don't have an ID right now? Click {' '}
        <ClickHereContainer onClick={handleUploadOneLaterOnClick}>
          here
        </ClickHereContainer>
        {' '}to skip this step and upload later.
      </SkipTextContainer>
      <StyledNextButton onClick={handleNextOnClick} disabled={!nextButtonIsEnabled}>
        Next
      </StyledNextButton>
      <StyledFooter>
        {isIsraeliPrimaryID ? (
          "We can accept only a Teudat Zehut as a primary ID. Some Teudat Zehuts do not have an expiration date. If yours doesn't, choose 1/1/2050."
        ) : isAmericanPrimaryID ? (
          'We can accept only an American Passport as a primary ID.'
        ) : (
          <>
            You can upload any photo ID from either country. Also, by clicking next, you acknowledge
            that you have read and agree to OlehPay&apos;s{' '}
            <YellowLink href="/terms">Site Terms</YellowLink> and{' '}
            <YellowLink href="/privacy">Privacy Policy.</YellowLink>
          </>
        )}
      </StyledFooter>
    </>
  );
};
