import React from 'react';
import { Outlet, Link, redirect, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../assets/imgs/logo.svg';
import ICON_USER from '../../assets/imgs/icon/user.svg';
import ICON_CURRENCY from '../../assets/imgs/icon/currency.svg';
import ICON_FILE from '../../assets/imgs/icon/file.svg';
import ICON_BILL from '../../assets/imgs/icon/bill.svg';
import ICON_SETTING from '../../assets/imgs/icon/setting.svg';
import ICON_LOG_OUT from '../../assets/imgs/icon/log_out.svg';
import ICON_ARROW_RIGHT from '../../assets/imgs/icon/arrow_right.svg';
import ICON_BANK from '../../assets/imgs/icon/bank.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { THEME } from '../../constants';

const Divice = styled.div`
  border-bottom: 1px solid #a5b0c673;
  margin-bottom: 30px;
`;

const NavBar = styled.nav`
  overflow: auto;
  padding: 32px 32px;
  background: ${THEME.light_dark_blue};
  height: 100vh;
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    margin-top: 65px;
    color: #ffffff;
    // border-bottom: 1px solid ${THEME.dark_blue};
  }
  @media (max-width: ${THEME.breakpoint}px) {
    position: fixed;
    left: -100%;
  }
`;

const ContainerPage = styled.div.attrs({
  className: 'ContainerPage',
})`
  background: ${THEME.linear_bg};
  width: calc(100% - 200px);
  @media (max-width: ${THEME.breakpoint}px) {
    width: 100%;
    // overflow-x: hidden;
  }
`;

const WrapperPage = styled.div.attrs({
  className: 'WrapperPage',
})`
  display: flex;
  height: 100vh;
  @media (max-width: ${THEME.breakpoint}px) {
    flex-direction: column;
  }
`;

const LogoPage = styled.img`
  width: 148px;
  // position: absolute;
  margin-bottom: 40px;
`;

const WrapListLink = styled.div`
  display: flex;
  flex-direction: column;
  // height: calc(100% - 200px);
  overflow: auto;
  width: calc(100% + 64px);
  position: relative;
  margin-left: -32px;
  padding: 0 32px;
  .wrap-link {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    cursor: pointer;
    img {
      margin-right: 15px;
    }
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #a5b0c6;
    }
  }
  & :hover {
    color: rgb(255, 255, 255, 0.9);
    filter: brightness(2);
  }
`;

const WrapBarTop = styled.div`
  height: calc(100% - 228px);
`;
const WrapBarFooter = styled.div``;

export const AdminLayout = ({ setToken }) => {
  const navigate = useNavigate();

  const handleLogoutOnClick = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    await setToken(null);
    navigate('/');
  };

  return (
    <WrapperPage>
      <NavBar>
        <WrapBarTop>
          <a href="/">
            <LogoPage src={Logo} alt="" className="oleh-logo" />
          </a>
          <WrapListLink>
            <Link to="/users" className="wrap-link">
              <img src={ICON_USER} alt="Users" />
              <span>Users</span>
            </Link>
            <Link to="/accounts" className="wrap-link">
              <img src={ICON_USER} alt="Accounts" />
              <span>Accounts</span>
            </Link>
            <Link to="/transfers" className="wrap-link">
              <img src={ICON_USER} alt="Transfers" />
              <span>Transfers</span>
            </Link>
            <Link to="/subscription" className="wrap-link">
              <img src={ICON_USER} alt="subscriptions" />
              <span>Subscriptions</span>
            </Link>
            <Link to="/reports" className="wrap-link">
              <img src={ICON_USER} alt="reports" />
              <span>Reports</span>
            </Link>
            {/* <Link to="/email" className="wrap-link">
              <img src={ICON_USER} alt="Users" />
              <span>Mass Email</span>
            </Link> */}
            <Divice></Divice>
          </WrapListLink>
        </WrapBarTop>
        <WrapBarFooter>
          <WrapListLink>
            <div className="wrap-link mb-0" onClick={handleLogoutOnClick}>
              <img src={ICON_LOG_OUT} alt="Log Out" />
              <span>Log Out</span>
            </div>
          </WrapListLink>
        </WrapBarFooter>
      </NavBar>
      <ContainerPage>
        <Outlet />
      </ContainerPage>
      <ToastContainer style={{ zIndex: 999 }} draggable={false}/>
    </WrapperPage>
  );
};
