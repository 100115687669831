import styled from 'styled-components';
import { StyledInput } from '../common-components';
import { useEffect, useState } from 'react';

import {
  StyledNextButton,
  StyledFooter,
  SubtitleContainer,
  YellowText,
} from '../common-components';

const FormGridContainer = styled.div.attrs({
  className: 'FormGridContainer',
})`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 12px;
  margin-bottom: 12px;
`;

export const RegistrationHebrewName = ({
  alias,
  setAlias,
  handleNextOnClick,
  nextButtonIsEnabled,
  setNextButtonIsEnabled,
}) => {
  
  const containsHebrew = (char) => {
    return /[\u0590-\u05FF]/.test(char) || char === ' ';
  };

  const handleFirstNameOnChange = (event) => {
    const data = event.target.value;
    const lastLetter = data.slice(-1);
    const isHebrewCharachter = containsHebrew(lastLetter);
    if (!isHebrewCharachter) return;
    setAlias((alias) => ({
      ...alias,
      first_name: event.target.value,
    }));
  };

  const handleLastNameOnChange = (event) => {
    const data = event.target.value;
    const lastLetter = data.slice(-1);
    const isHebrewCharachter = containsHebrew(lastLetter);
    if (!isHebrewCharachter) return;
    setAlias((alias) => ({
      ...alias,
      last_name: event.target.value,
    }));
  };

  useEffect(() => {
    if (alias?.first_name && alias?.last_name) setNextButtonIsEnabled(true);
    else setNextButtonIsEnabled(false);
  }, [alias]);

  return (
    <>
      <SubtitleContainer>What is your name in Hebrew?</SubtitleContainer>
      <FormGridContainer>
        <StyledInput
          placeholder="Hebrew First Name"
          value={alias?.first_name || ''}
          onChange={handleFirstNameOnChange}
          isFilledOut={alias?.first_name}
        />
        <StyledInput
          placeholder="Hebrew Last Name"
          value={alias?.last_name || ''}
          onChange={handleLastNameOnChange}
          isFilledOut={alias?.last_name}
        />
      </FormGridContainer>
      <StyledNextButton onClick={handleNextOnClick} disabled={!nextButtonIsEnabled}>
        Next
      </StyledNextButton>
      <StyledFooter>
        Use the Hebrew name as spelled on official government documents.{' '}
        <YellowText>Tip:</YellowText> use Google Translate if you need an on-screen keyboard.
      </StyledFooter>
    </>
  );
};
