import { useEffect } from 'react';
import { debounce } from '@mui/material';
import Select from 'react-select';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import 'react-widgets/scss/styles.scss';

import {
  StyledFieldset,
  StyledLegend,
  EditableInput,
  StyledSelect,
  DownArrowIcon,
  SavingContainer,
  StyledOption,
} from '../../common-components';
import ICON_ARROW_DOWN from '../../../../assets/imgs/icon/arrow_card_down.svg';
import { useIsMount } from '../../../../hooks/useIsMount';
import { THEME } from '../../../../constants';
import states from '../../../registration/address-and-phone/states';

const SELECT_THEME = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral50: '#ccc', // Placeholder color
  },
});

const AddressForm = styled.div.attrs({
  className: 'AddressForm',
})`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 1em;
  margin-bottom: 1em;
`;

const CountryContainer = styled.div.attrs({
  className: 'CountryContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const WrapRegistrationUSStateSelect = styled.div.attrs({
  className: 'WrapRegistrationUSStateSelect',
})<{ isFilledOut: any }>(
  ({ isFilledOut }) => `
    height: 100%;
    width: 100%;
    appearance: none;
    background-color: ${THEME.dark_blue};
    z-index: 1000;
    border-radius: 8px;
    cursor: pointer;

    [class*="-control"],
    [class*="-container"] {
      height: 100% !important;
      width: 100% !important;
      background-color: transparent !important;
      border: none !important;
      cursor: pointer !important;
    }
    [class*="-singleValue"] {
      opacity: 0.8;
      color: rgb(255, 255, 255) !important;
    }
    [class*="-menu"],
    [class*="-ValueContainer"] {
      opacity: 1 !important;
      color: black !important;
    }
    [class*="-control"] {
      box-shadow: none !important;
    }
  `,
);

export const AddressDetails = ({ address, setAddress }) => {
  const isFirstRender = useIsMount();
  const countryOptions = [
    { label: 'Israel', value: 'IL' },
    { label: 'United States', value: 'US' },
  ].map((option) => (
    <StyledOption key={option.value} value={option.value}>
      {option.label}
    </StyledOption>
  ));
  const [{ loading: addressIsSaving }, updateAddress] = useAxios(
    {
      url: `/addresses/${address?.uuid}/`,
      method: 'PATCH',
      data: address,
    },
    { manual: true },
  );

  useEffect(() => {
    if (isFirstRender) return;
    const debouncedUpdate = debounce(() => {
      updateAddress().catch((error) => {
        if (error.code === 'ERR_CANCELED') return;
      });
    }, 500);
    debouncedUpdate();
    return () => {
      debouncedUpdate.clear();
    };
  }, [address]);

  const stateSelectOptions = states
    ?.filter((state) => state.code)
    ?.map((state) => ({
      label: state.code,
      value: state.code,
    }));

  const stateOption = address?.region
    ? {
        label: address?.region,
        value: address?.region,
      }
    : null;

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleCountryOnChange = (event) =>
    setAddress((address) => ({
      ...address,
      country: event.target.value,
    }));

  const handleStreetNameOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      street_name: toTitleCase(event.target.value),
    }));
  };

  const handleStreetNumberOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      street_number: event.target.value,
    }));
  };

  const handleStateOptionOnChange = (option) =>
    setAddress((address) => ({
      ...address,
      region: option?.value,
    }));

  const handlePostalOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      postal: event.target.value,
    }));
  };

  const handleCityOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      city: toTitleCase(event.target.value),
    }));
  };

  const handleApartmentNumnberOnChange = (event) => {
    setAddress((address) => ({
      ...address,
      apartment_number: event.target.value,
    }));
  };

  return (
    <>
      <StyledFieldset>
        <StyledLegend>Address</StyledLegend>
        <AddressForm>
          <CountryContainer style={{ gridColumn: 'span 2' }}>
            <StyledSelect onChange={handleCountryOnChange} value={address?.country}>
              <option value=""></option>
              {countryOptions}
            </StyledSelect>
            <DownArrowIcon src={ICON_ARROW_DOWN} alt="" />
          </CountryContainer>
          {address?.country === 'US' ? (
            <>
              <EditableInput
                placeholder="Street #"
                value={address?.street_number || ''}
                onChange={handleStreetNumberOnChange}
              />
              <EditableInput
                placeholder="Street Name"
                value={address?.street_name || ''}
                onChange={handleStreetNameOnChange}
              />
            </>
          ) : (
            <EditableInput
              style={{ gridColumn: 'span 2' }}
              placeholder="Street Name"
              value={address?.street_name || ''}
              onChange={handleStreetNameOnChange}
            />
          )}
          {address?.country === 'US' ? (
            <EditableInput
              placeholder="City"
              value={address?.city || ''}
              onChange={handleCityOnChange}
            />
          ) : (
            <EditableInput
              placeholder="House #"
              value={address?.street_number || ''}
              onChange={handleStreetNumberOnChange}
            />
          )}
          {address?.country === 'US' ? (
            <WrapRegistrationUSStateSelect isFilledOut={address?.region}>
              <Select
                value={stateOption}
                blurInputOnSelect={true}
                options={stateSelectOptions}
                maxMenuHeight={180}
                placeholder="State"
                theme={(theme) => SELECT_THEME(theme)}
                onChange={(option) => handleStateOptionOnChange(option)}
                isClearable={true}
              ></Select>
            </WrapRegistrationUSStateSelect>
          ) : (
            <EditableInput
              placeholder="City"
              value={address?.city || ''}
              onChange={handleCityOnChange}
            />
          )}
          <EditableInput
            placeholder="Postal Code"
            value={address?.postal || ''}
            onChange={handlePostalOnChange}
          />
          <EditableInput
            placeholder="Apt #"
            value={address?.apartment_number || ''}
            onChange={handleApartmentNumnberOnChange}
          />
        </AddressForm>
      </StyledFieldset>
      <SavingContainer active={addressIsSaving}>saving address...</SavingContainer>
    </>
  );
};
