/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../hooks/axios';
import { HeaderHome } from '../../components/header-home';
import { FooterHome } from '../../components/footer-home';

import moment from 'moment';
export const Privacy = ({ userIsAdmin, userIsRegistered, userIsAuthenticated, setToken }) => {
  return (
    <div className="home-content text-white">
      <HeaderHome
        userIsAdmin={userIsAdmin}
        userIsRegistered={userIsRegistered}
        userIsAuthenticated={userIsAuthenticated}
        setToken={setToken}
        isGrayHeader
      />

      <section className="main">
        <div
          id="indexPageCalcHolder"
          className="calculator_holder"
          style={{ margin: '0 auto 0', maxWidth: '100%' }}
        >
          <div className="marginBottom">
            <div className="spacing_half started2">
              <nav style={{ textAlign: 'center' }}>
                <div className="menu-footer-menu-unregistered-container" style={{ margin: 'auto' }}>
                  <ul id="primary-menu" className="add_nav">
                    <li className="menu-item current-menu-item">
                      <a href="#case">Personal Information</a>
                    </li>
                    <li className="menu-item">
                      <a href="#cookies">Cookies</a>
                    </li>
                    <li className="menu-item">
                      <a href="#regulations">Law Regulations</a>
                    </li>
                    <li className="menu-item">
                      <a href="#third_parties">Third Parties</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="info_full_upload upload_mobile3 started1">
                <div
                  style={{ width: '100%', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                >
                  <div className="text-white registerSize started_mobile started">
                    <h3 id="case" className="startedH3">
                      Privacy Policy
                    </h3>
                    <p className="startedH4">Overview</p>
                    <p className="startedP">
                      This privacy policy has been compiled to better serve those who are concerned
                      with how their &apos;Personally identifiable information&apos; (PII) is being
                      used online. PII, as used in US privacy law and information security, is
                      information that can be used on its own or with other information to identify,
                      contact, or locate a single person, or to identify an individual in context.
                      Please read our privacy policy carefully to get a clear understanding of how
                      we collect, use, protect or otherwise handle your Personally Identifiable
                      Information in accordance with our website.
                    </p>
                    <h4 className="startedH4">Personal Information</h4>
                    <h4 className="startedH4">
                      What personal information do we collect on our website?
                    </h4>
                    <p className="startedP">
                      When ordering or registering on our site, as appropriate, we ask for
                      identification as required to comply with the financial regulatory environment
                      both in Israel and the United States. We may ask for the client&apos;s name,
                      email address, personal identification information, bank details as required
                      to process transfers, or other details to help you with the experience.
                    </p>
                    <h4 className="startedH4">When do we collect information?</h4>
                    <p className="startedP">
                      We collect information during registration, when transfer orders are placed,
                      from filled out forms, from responses to a survey or marketing communication,
                      or from any other information entered on our site.
                    </p>
                    <h4 className="startedH4">How do we use the information?</h4>
                    <p className="startedP">We may use the information in the following ways:</p>
                    <p className="startedP">
                      - To personalize the client&apos;s experience and to allow us to deliver the
                      type of content most suitable for the client.
                    </p>
                    <p className="startedP">- To improve the functionality of our website</p>
                    <p className="startedP">- To improve customer service</p>
                    <p className="startedP">
                      - To comply with financial regulations as required to process your
                      transactions.
                    </p>
                    <p className="startedP">
                      - To send periodic emails regarding orders or other products{' '}
                    </p>
                    <h4 className="startedH4">We collect email addresses in order to:</h4>
                    <p className="startedP">
                      - Send information, respond to inquiries, and/or other requests{' '}
                    </p>
                    <p className="startedP">
                      - Process orders and to send information and updates pertaining to orders
                    </p>
                    <h4 className="startedH4">How do we protect visitor information?</h4>
                    <p className="startedP">
                      Our website is scanned on a regular basis for security holes and known
                      vulnerabilities in order to make our site as safe as possible.
                    </p>
                    <p className="startedP">
                      Personal information is contained behind secured networks and is only
                      accessible by a limited number of persons who have special access rights to
                      such systems, and are required to keep the information confidential. In
                      addition, all sensitive/credit information you supply is encrypted via Secure
                      Socket Layer 256 bit, industry standard (SSL) technology.
                    </p>
                    <p className="startedP">
                      We implement a variety of security measures when a client places an order
                      enters, submits, or accesses their information to maintain the safety of their
                      personal information.
                    </p>
                    <p className="startedP">
                      All transactions are processed through a gateway provider and are not stored
                      or processed on our servers.
                    </p>

                    <h4 className="startedH4" id="cookies">
                      Cookies
                    </h4>
                    <h4 className="startedH4">Do we use &apos;cookies&apos;?</h4>
                    <p className="startedP">
                      Yes. Cookies are small files that a site or its service provider transfers to
                      the user&apos;s computer hard drive through a Web browser (if allowed) that
                      enables the site&apos;s or service provider&apos;s systems to recognize the
                      browser and capture and remember certain information. For instance, we use
                      cookies to help identity when a client is logging in or making an order for
                      transfer. They are also used to help us understand preferences based on
                      previous or current site activity, which enables us to provide you with
                      improved services. We also use cookies to help us compile aggregate data about
                      site traffic and site interaction so that we can offer better site experiences
                      and tools in the future.
                    </p>
                    <h4 className="startedH4">We use cookies to:</h4>
                    <p className="startedP">- Help remember and process orders for transfer.</p>
                    <p className="startedP">
                      Understand and save client&apos;s preferences for future visits.
                    </p>
                    <p className="startedP">
                      Compile aggregate data about site traffic and site interactions in order to
                      offer better site experiences and tools in the future. We may also use trusted
                      third party services that track this information on our behalf.
                    </p>
                    <p className="startedP">
                      One can choose to have their computer warn them each time a cookie is being
                      sent, or they can choose to turn off all cookies. This is accomplished through
                      the browser&apos;s (like Internet Explorer) settings. Each browser is a little
                      different, but more information is found on their respective help page.
                    </p>
                    <p className="startedP">
                      If the cookies are disabled, some features will be disabled. It will however
                      affect the site experience and some of our services will not function
                      properly.
                    </p>
                    <p className="startedP">
                      However, orders can still be placed even when cookies are disabled.
                    </p>
                    <h4 className="startedP">How does our site handle Do Not Track signals?</h4>
                    <p className="startedP">
                      We honor do not track signals and do not track, plant cookies, or use
                      advertising when a Do Not Track (DNT) browser mechanism is in place.
                    </p>

                    <h4 className="startedH4" id="third_parties">
                      Third Parties
                    </h4>
                    <h4 className="startedH4">Plaid</h4>
                    <p className="startedP">
                      {' '}
                      Client uses Plaid Technologies, Inc. (&quot;Plaid&quot;) to gather End
                      User&apos;s data from financial institutions. By using our service, you grant
                      Client and Plaid the right, power, and authority to act on your behalf to
                      access and transmit your personal and financial information from the relevant
                      financial institution. You agree to your personal and financial information
                      being transferred, stored, and processed by Plaid in accordance with the{' '}
                      <a href="https://plaid.com/legal">Plaid Privacy Policy.</a>
                    </p>
                    <h4 className="startedH4">Third Party Disclosure</h4>
                    <p className="startedP">
                      We do not sell, trade, or otherwise transfer to outside parties&apos;
                      personally identifiable information unless we provide an advance notice. This
                      does not include website hosting partners and other parties who assist us in
                      operating our website, conducting our business, or servicing the client, so
                      long as those parties agree to keep this information confidential. We may also
                      release information when we believe release is appropriate to comply with the
                      law, enforce our site policies, or protect ours or others&apos; rights,
                      property, or safety.
                    </p>
                    <p className="startedP">
                      However, non-personally identifiable visitor information may be provided to
                      other parties for marketing, advertising, data science or other uses.
                    </p>
                    <h4 className="startedH4">Third party links</h4>
                    <p className="startedP">
                      Occasionally, at our discretion, we may include or offer third party products
                      or services on our website. These third party sites have separate and
                      independent privacy policies. We therefore have no responsibility or liability
                      for the content and activities of these linked sites. Nonetheless, we seek to
                      protect the integrity of our site and welcome any feedback about these sites.
                    </p>
                    <h4 className="startedH4">Google</h4>
                    <p className="startedP">
                      Google&apos;s advertising requirements can be summed up by Google&apos;s
                      Advertising Principles. They are put in place to provide a positive experience
                      for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en
                    </p>
                    <p className="startedP">
                      We have not enabled Google AdSense on our site but we may do so in the future.
                    </p>
                    <h4 className="startedP">
                      Does our site allow third party behavioral tracking?
                    </h4>
                    <p className="startedP">
                      It&apos;s also important to note that we do not allow third party behavioral
                      tracking.
                    </p>

                    <h4 className="startedH4" id="regulations">
                      Law Regulations
                    </h4>
                    <h4 className="startedH4">California Online Privacy Protection Act</h4>
                    <p className="startedP">
                      CalOPPA is the first state law in the nation to require commercial websites
                      and online services to post a privacy policy. The law&apos;s reach stretches
                      well beyond California to require a person or company in the United States
                      (and conceivably the world) that operates websites collecting personally
                      identifiable information from California consumers to post a conspicuous
                      privacy policy on its website stating exactly the information being collected
                      and those individuals with whom it is being shared, and to comply with this
                      policy. R See more at:
                    </p>
                    <p className="startedP">
                      <a
                        href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                      </a>
                    </p>
                    <h4 className="startedH4">According to CalOPPA we agree to the following:</h4>
                    <p className="startedP">
                      Users can visit our site anonymously Once this privacy policy is created, we
                      will add a link to it on our home page, or as a minimum on the first
                      significant page after entering our website. Our Privacy Policy link includes
                      the word &apos;Privacy&apos;, and can be easily be found on the page specified
                      above.
                    </p>
                    <p className="startedP">
                      Users will be notified of any privacy policy changes: On our Privacy Policy
                      Link found at the footer of our website.
                    </p>

                    <h4 className="startedH4">COPPA (Children Online Privacy Protection Act)</h4>
                    <p className="startedP">
                      When it comes to the collection of personal information from children under
                      13, the Children&apos;s Online Privacy Protection Act (COPPA) puts parents in
                      control. The Federal Trade Commission, the nation&apos;s consumer protection
                      agency, enforces the COPPA Rule, which spells out what operators of websites
                      and online services must do to protect children&apos;s privacy and safety
                      online.
                    </p>
                    <p className="startedH4">
                      We do not specifically market to children under 13 years old.
                    </p>
                    <h4 className="startedH4">Fair Information Practices</h4>
                    <p className="startedP">
                      The Fair Information Practices Principles form the backbone of privacy law in
                      the United States and the concepts they include have played a significant role
                      in the development of data protection laws around the globe. Understanding the
                      Fair Information Practice Principles and how they should be implemented is
                      critical to comply with the various privacy laws that protect personal
                      information.
                    </p>
                    <p className="startedP">
                      <strong>
                        In order to be in line with Fair Information Practices we will take the
                        following responsive action, should a data breach occur:{' '}
                      </strong>
                      We will notify the users via email within 1 business day.
                    </p>
                    <p className="startedP">
                      We also agree to the individual redress principle, which requires that
                      individuals have a right to pursue legally enforceable rights against data
                      collectors and processors who fail to adhere to the law. This principle
                      requires not only that individuals have enforceable rights against data users,
                      but also that individuals have recourse to courts or a government agency to
                      investigate and/or prosecute non-compliance by data processors.
                    </p>
                    <h4 className="startedH4">CAN SPAM Act</h4>
                    <p className="startedP">
                      The CAN-SPAM Act is a law that sets the rules for commercial email,
                      establishes requirements for commercial messages, gives recipients the right
                      to have emails stopped from being sent to them, and spells out tough penalties
                      for violations.
                    </p>
                    <h4 className="startedH4">
                      To be in accordance with CANSPAM we agree to the following:
                    </h4>
                    <p className="startedP">
                      - NOT use false, or misleading subjects or email addresses
                      <br />
                      - Identify the message as an advertisement in some reasonable way
                      <br />
                      - Include the physical address of our business or site headquarters
                      <br />
                      - Monitor third party email marketing services for compliance, if one is used.
                      <br />
                      - Honor opt-out/unsubscribe requests quickly
                      <br />- Allow users to unsubscribe by using the link at the bottom of each
                      email
                    </p>
                    <p className="startedH4">
                      <strong>
                        If at any time you would like to unsubscribe from receiving future emails,
                        you can email us at{' '}
                      </strong>
                      <a href="mailto:info@olehpay.co.il">
                        <strong>info@olehpay.co.il</strong>
                      </a>{' '}
                      and we will promptly remove you from <strong>ALL </strong>correspondence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterHome isHideIntro />
    </div>
  );
};
